import React from "react";
import Header from "../components/header";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Nutrition3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            quizColor:"",
                quizHeading:"",
                quizFoodname:"",
                quizIcon:"",
                quizCategory:"",
                quizAnswer:"",
                isActive:true,
                selectOptionName:"",
            
            quizArr:[],
            quizNumber:0,
            addXpDetails:"",
            answerStatus:"",
            nextbuttonStatus:false,
        };
        this.addXP = this.addXP.bind(this);
        
    }
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
       
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
       
        this.getNutritionContents();
       
    }

    getNextQuiz(){
        let qzArr = this.state.quizArr
        let qzNo = parseInt(this.state.quizNumber) + 1
        console.log("qzNo",qzNo)
        //console.log("qzArr")
        console.log(qzArr.length)
        if(qzNo+1<=qzArr.length){
            this.setState({
                quizHeading:qzArr[qzNo].contentDetails.quiz.heading,
                quizFoodname:qzArr[qzNo].contentDetails.quiz.foodname,
                quizIcon:qzArr[qzNo].contentDetails.quiz.icon,
                quizCategory:qzArr[qzNo].contentDetails.quiz.category,
                quizAnswer:qzArr[qzNo].contentDetails.quiz.answer,
                isActive:qzArr[qzNo].isActive,
                quizColor:qzArr[qzNo].color,
                quizNumber:qzNo,
                nextbuttonStatus: false,
                answerStatus: ''
            });
           // console.log(this.state);
           
        }
    }

    getQuizAnswer(selectOptionName){
        this.setState({
            nextbuttonStatus: true
        });
        let correctAnswer=this.state.quizAnswer;
        console.log(correctAnswer);
        if(selectOptionName===correctAnswer){
           
            this.setState({
                answerStatus: true,
                selectOptionName:selectOptionName
            });
            this.addXP();
        }else{
            this.setState({
                answerStatus: false,
                selectOptionName:selectOptionName
            });
        }
    }

    addXP(){
        axios.post(path, {
            "xp": 25
        }, {
            headers: { 'Api-Name': 'addXP', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const addXpDetails = res.response;
                this.setState({
                    addXpDetails: addXpDetails
                });
            }
            else {
               
            }
        });
    } 
    async getNutritionContents() {
        const item_id = await localStorage.getItem('itemId')
        axios.post(path, {
            itemId: item_id
        }, {
            headers: { 'Api-Name': 'getNutritionContents', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            localStorage.setItem('itemId',item_id)
            //console.log(item_id);
            if (res.status === true) {
                // console.log(res);
                 const responseContents = res.response.contents;
                 let quizContents = []
                 responseContents.forEach(elem => {
                    if(elem.contentType==="dynamic"){
                     if(elem.category==="quiz"){
                         //console.log(elem)
                         quizContents.push(elem)                        
                     }
                    }
                });
                this.setState({
                 quizArr:quizContents,
                 quizHeading:quizContents[0].contentDetails.quiz.heading,
                 quizFoodname:quizContents[0].contentDetails.quiz.foodname,
                 quizIcon:quizContents[0].contentDetails.quiz.icon,
                 quizCategory:quizContents[0].contentDetails.quiz.category,
                 quizAnswer:quizContents[0].contentDetails.quiz.answer,
                 isActive:quizContents[0].isActive,
                 quizColor:quizContents[0].color,
              });
             }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
  



    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop nutritionBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Nutrition</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
            
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">                       
                                <div className="nutritionC">
                                    <div className="nutriLft">
                                        <img src={path+this.state.quizIcon} alt=""/>
                                        <h4>{this.state.quizHeading}</h4>
                                        {this.state.answerStatus===true?
                                        <div className="nutriDetl">
                                            <h3>{this.state.quizFoodname}</h3>
                                            <div className="nutriButton">Point - 25XP</div>
                                        </div>
                                        :""}
                                        {this.state.answerStatus===false?
                                        <div className="nutriDetl nutriIncorrect">
                                            <h3>Incorrect<br/>Answer!</h3>
                                        </div>
                                        :""}
                                    </div>
                                    <div className="nutriRgt">
                                        <div className="nutriSpl">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/spl.jpg'} alt=""/>
                                            <span className="spl_a" onClick={()=>this.state.nextbuttonStatus===false?this.getQuizAnswer('Grains'):""} style={{cursor:this.state.nextbuttonStatus===false?"pointer":""}}>Grains</span>
                                            <span className="spl_b" onClick={()=>this.state.nextbuttonStatus===false?this.getQuizAnswer('Meat, Fish, Eggs & Beans'):""} style={{cursor:this.state.nextbuttonStatus===false?"pointer":""}}>Meat, Fish,<br/>Eggs &amp;<br/>Beans</span>
                                            <span className="spl_c" onClick={()=>this.state.nextbuttonStatus===false?this.getQuizAnswer('Fruits & Vegetables'):""} style={{cursor:this.state.nextbuttonStatus===false?"pointer":""}}>Fruits &amp; Vegetables</span>
                                            <span className="spl_d" onClick={()=>this.state.nextbuttonStatus===false?this.getQuizAnswer('Dairy'):""} style={{cursor:this.state.nextbuttonStatus===false?"pointer":""}}>Dairy</span>
                                        </div>
                                        {/* <div class="nutriBase">
                                            <div class="nutriTab">
                                                <div class="nutriMain">dsfds</div>
                                            </div>
                                        </div>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/spl.jpg'} alt=""/> */}
                                    </div>
                                    </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                    {this.state.nextbuttonStatus===true &&  parseInt(this.state.quizNumber)+1<(this.state.quizArr).length?
                                                <Link to="#" onClick={()=>this.getNextQuiz()} className="intbtnb">Next</Link>
                                    :'' }
                                     {this.state.nextbuttonStatus===true &&  parseInt(this.state.quizNumber)+1===(this.state.quizArr).length?
                                                <Link to="/nutrition4" className="intbtnb">Next</Link>
                                    : ''}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
                
            </div>
        )
    }
}
export default Nutrition3;