import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",

            sport: "",
            colorVisionUnableTest: "",
            colorVisionTrial1: "",
            colorVisionTrial2: "",
            stereopsisUnableTest: "",
            stereopsisValue: "",

            colorVisionTrial2Div: false,

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.colorVisionTrial1 !== this.state.colorVisionTrial1) {
            if (this.state.colorVisionTrial1 < 8) {
                this.setState({ colorVisionTrial2Div: true })
            }
            if (this.state.colorVisionTrial1 === "") {
                this.setState({ colorVisionTrial2Div: false })
            }
        }
        if (prevState.colorVisionUnableTest !== this.state.colorVisionUnableTest) {
            if (this.state.colorVisionUnableTest === "" || this.state.colorVisionUnableTest === false ) {
               // console.log("i am in update")
                this.setState({
                    colorVisionTrial1: "",
                    colorVisionTrial2: "", 
                })
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
       // console.log(this.state.colorVisionUnableTest);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageOne();
    }

    openingEyePageOne() {
        //console.log("I called");
        let dataToSend = {
             user_id: this.state.sob_scanId,
           
        };

        // console.log(this.state);

       // let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        let colorVisionUnableTest_1 = this.state.colorVisionUnableTest != "" ? dataToSend['colorVisionUnableTest'] = this.state.colorVisionUnableTest : null;
        if (!this.state.colorVisionUnableTest) {
            let colorVisionTrial1_1 = this.state.colorVisionTrial1 != "" ? dataToSend['colorVisionTrial1'] = this.state.colorVisionTrial1 : null;
            if (this.state.colorVisionTrial1 < 8) {
                console.log(this.state.colorVisionTrial2)
                let colorVisionTrial2_1 = this.state.colorVisionTrial2 != "" ? dataToSend['colorVisionTrial2'] = this.state.colorVisionTrial2 : null;
            }
        }
        let stereopsisUnableTest_1 = this.state.stereopsisUnableTest != "" ? dataToSend['stereopsisUnableTest'] = this.state.stereopsisUnableTest : null;
        if (!this.state.stereopsisUnableTest) {
            let stereopsisValue_1 = this.state.stereopsisValue != "" ? dataToSend['stereopsisValue'] = this.state.stereopsisValue : null;
        }

       // console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageFour', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                       
                                    <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div class="compositionBg frmStep2Bg">

                                            <h3>History</h3>
                                            <h4 class="coverTestBordr respCol">Color Vision: &nbsp;&nbsp;
                                                <span class="covrCheck minWdt noPdnBtm">
                                                    <input type="checkbox" name="colorVisionUnableTest" onChange={this.handleChange} /> Unable to test
                                                </span>
                                            </h4>
                                            {
                                                !this.state.colorVisionUnableTest &&
                                                <>
                                                    <div class="coverTestBordr">
                                                        <div class="row">
                                                            <div class="col-xl-7 cvisionFlex">
                                                                <span class="">Trial 1</span>
                                                                <span><input type="number" name="colorVisionTrial1" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">_/9</span></span>
                                                                <span>If less than 8/9</span>
                                                            </div>
                                                            {
                                                                this.state.colorVisionTrial2Div &&
                                                                <>
                                                                    <div class="col-xl-5 cvisionFlex">
                                                                        <span class="">Trial 2</span>
                                                                        <span><input type="number" name="colorVisionTrial2" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">_/9</span></span>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div class="coverTestBordr">
                                                <div class="cvisionFlex stereoResp">
                                                    <h4 class="">Stereopsis: &nbsp;&nbsp; <span class="covrCheck minWdt noPdnBtm">
                                                        <input type="checkbox" name="stereopsisUnableTest" onChange={this.handleChange} /> Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.stereopsisUnableTest &&
                                                        <>
                                                            <span><input type="number" name="stereopsisValue" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">_/6</span></span>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Eyes_Form4;