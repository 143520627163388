import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;
class Amrit_Form1_Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",
            divTrainingYesvalue: "hide",
            divTrainingNovalue: "hide",
            divSunSafetyYesvalue: "hide",
            divSunSafetyNovalue: "hide",
            divTobaccoYesvalue: "hide",
            divTobaccoNovalue: "hide",
            divisFamilySmokevalue: "hide",
            sob_scanId: "",
            divSoftDrinksDropdown: "hide",
            divNutritionOther: "hide",
            divPhysicalYesOther: "hide",
            divPhysicalNoOther: "hide",
            divSunsafetyNoOther: "hide",
            divTobaccoYesOther: "hide",

            glassOfWater:"",
            nutritionDrinks: [],
            drinksValueArr: [],
            calciumSource: "",
            sweetBeverage: "",
            fruitsVegetable: "",
            snackFood: "",
            fastFood: "",
            physicalActivityDayPerWeek: "",
            trainingOutSideSOB: "",
            trainingOutSideSOBYes: [],
            trainingOutSideSOBNo: [],
            watchTvComputer: "",
            handWashTime: [],
            handWashUseSoap: "",
            handWashHaveSoap: "",
            sunProtectSkin: "",
            sunProtectionYes: [],
            sunProtectionNo: [],
            tabaccoUse: "",
            tabaccoUseFreq: "",
            isFamilySmoke: "",
            stepToStopSmoke: [],
            doctorComments: "",
            sunsafeotherval: "",
            otherval: "",
            smokeotherval: "",
            trainingNootherVal: "",
            trainingYesotherVal: "",
            sunProtectionNoOther: "",
            otherType: "",
            soft_drink_type: "",



            sourcesofCalcium: [
                { value: "less than 1 serving per day" },
                { value: "1-2 servings per day" },
                { value: "3-5 servings per day" },
                { value: "more than 5 servings per day" },
                { value: "never" }
            ],

            sweetenedBeverages: [
                { value: "daily" },
                { value: "weekly" },
                { value: "monthly" },
                { value: "never" },
            ],

            snackFoods: [
                { value: "daily" },
                { value: "weekly" },
                { value: "monthly" },
                { value: "never" },
            ],

            fastfood: [
                { value: "daily" },
                { value: "weekly" },
                { value: "monthly" },
                { value: "never" },
            ],

            fruitsandVegetables: [
                { value: "less than 1 serving per day" },
                { value: "1-2 servings per day" },
                { value: "3-5 servings per day" },
                { value: "more than 5 servings per day" },
                { value: "never" }
            ],

            howmanyDays: [
                { value: "No Days" },
                { value: "1-2 days" },
                { value: "3-6 days" },
                { value: "Every day" },
            ],

            howmanyHours: [
                { value: "0-2" },
                { value: "3-4" },
                { value: "5-6" },
                { value: "Over 6 hours" },
            ],

            howfrequently: [
                { value: "daily" },
                { value: "weekly" },
                { value: "monthly" },
            ],






        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeNutrition = this.handleChangeNutrition.bind(this);
        this.handleChangeNutritionDropdown = this.handleChangeNutritionDropdown.bind(this);

        this.handleChangeTraining = this.handleChangeTraining.bind(this);
        this.handleChangeTrainingYesCheckbox = this.handleChangeTrainingYesCheckbox.bind(this);

        this.handleChangeTrainingNoCheckbox = this.handleChangeTrainingNoCheckbox.bind(this);
        this.handleChangeHandWashingCheckbox = this.handleChangeHandWashingCheckbox.bind(this);
        this.handleChangeSunSafety = this.handleChangeSunSafety.bind(this);
        this.handleChangeSunSafetyYesCheckbox = this.handleChangeSunSafetyYesCheckbox.bind(this);
        this.handleChangeSunSafetyNoCheckbox = this.handleChangeSunSafetyNoCheckbox.bind(this);
        this.handleChangeTobacco = this.handleChangeTobacco.bind(this);
        this.handleChangeisFamilySmoke = this.handleChangeisFamilySmoke.bind(this);
        this.handleChangeStepToSmokeCheckbox = this.handleChangeStepToSmokeCheckbox.bind(this);
        this.handleChangeNutritionOther = this.handleChangeNutritionOther.bind(this);
        this.handleChangeSunSafetyNoCheckboxOther = this.handleChangeSunSafetyNoCheckboxOther.bind(this);
        this.handleChangeStepToSmokeCheckboxOther = this.handleChangeStepToSmokeCheckboxOther.bind(this);
        this.handleChangeTrainingYesCheckboxOther = this.handleChangeTrainingYesCheckboxOther.bind(this);
        this.handleChangeTrainingNoCheckboxOther = this.handleChangeTrainingNoCheckboxOther.bind(this);
        
      
    }

    async componentDidMount() {
        window.history.pushState(null, null, document.URL);
        console.log(document.URL)
        window.addEventListener('popstate', function (event) {
            //   window.location.replace("/amrit_medical_professional");
            // history.push("/amrit_medical_professional");
            window.history.forward();
            
        });
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        // console.log(name, value);
        this.setState({
            [name]: value
        });
    }

    handleChangeNutritionDropdown(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
       // const name = target.name;
        // console.log(name, value);
        this.setState({
            soft_drink_type: value
        });
    }

    handleChangeSunSafety(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (value === "Yes") { this.setState({ divSunSafetyYesvalue: "show" }) } else { this.setState({ divSunSafetyYesvalue: "hide" }) }
        if (value === "No") { this.setState({ divSunSafetyNovalue: "show" }) } else { this.setState({ divSunSafetyNovalue: "hide" }) }
    }

    handleChangeTobacco(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        //  console.log(name, value);
        this.setState({
            [name]: value
        });

        if (value === "Yes") { this.setState({ divTobaccoYesvalue: "show" }) } else { this.setState({ divTobaccoYesvalue: "hide" }) }
        if (value === "No") { this.setState({ divTobaccoNovalue: "show" }) } else { this.setState({ divTobaccoNovalue: "hide" }) }
    }


    handleChangeisFamilySmoke(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        // console.log(name, value);
        this.setState({
            [name]: value
        });
        if (value === "Yes") { this.setState({ divisFamilySmokevalue: "show" }) } else { this.setState({ divisFamilySmokevalue: "hide" }) }

    }

    handleChangeTraining(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        //  console.log(name, value);
        this.setState({
            [name]: value
        });
        if (value === "Yes") { this.setState({ divTrainingYesvalue: "show" }) } else { this.setState({ divTrainingYesvalue: "hide" }) }
        if (value === "No") { this.setState({ divTrainingNovalue: "show" }) } else { this.setState({ divTrainingNovalue: "hide" }) }
    }


    handleChangeNutrition(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.drinksValueArr
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push({ "drinksValue": name_value })
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            [name]: options,
            drinksValueArr: options
        });
        if (name_value === "Soft drink") {

            if (value === true && name_value === "Soft drink") { this.setState({ divSoftDrinksDropdown: "show" }) } else { this.setState({ divSoftDrinksDropdown: "hide" }) }
        }

    }


    handleChangeNutritionOther(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //  console.log(name, value);


        if (target.type !== "checkbox" && this.state.nutritionDrinksOther) {
            //for textbox
            this.setState({
                othertype: value,
            });
        }
        else if (target.type === "checkbox") {
            //for checkbox
            this.setState({
                [name]: value,
            });
        }
        if (target.type === "checkbox") {
            if (value === true && name_value === "Other") { this.setState({ divNutritionOther: "show" }) } else { this.setState({ divNutritionOther: "hide" }) }
        }

    }

    handleChangeTrainingYesCheckbox(event) {
        const target = event.target;
       // const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.trainingOutSideSOBYes
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        // console.log(options);
        this.setState({
            [name]: options,
            trainingOutSideSOBNo: []
        });
    }

    handleChangeTrainingNoCheckbox(event) {
        const target = event.target;
       // const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.trainingOutSideSOBNo
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        // console.log(options);
        this.setState({
            [name]: options,
            trainingOutSideSOBYes: []
        });
    }

    handleChangeSunSafetyYesCheckbox(event) {
        const target = event.target;
      //  const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.sunProtectionYes
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        // console.log(options);
        this.setState({
            [name]: options,
            sunProtectionNo: [],
            sunProtectionNoOther: ""
        });
    }

    handleChangeSunSafetyNoCheckboxOther(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, value);


        if (target.type !== "checkbox" && this.state.sunProtectionNoOther) {
            //for textbox
            this.setState({
                sunsafeotherval: value,

            });
        }
        else if (target.type === "checkbox") {
            //for checkbox
            this.setState({
                [name]: value,
            });
        }
        if (target.type === "checkbox") {
            if (value === true && name_value === "Other") { this.setState({ divSunsafetyNoOther: "show" }) } else { this.setState({ divSunsafetyNoOther: "hide" }) }
        }
    }

    handleChangeSunSafetyNoCheckbox(event) {
        const target = event.target;
       // const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.sunProtectionNo
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            [name]: options,
            sunProtectionYes: []
        });
    }

    handleChangeTrainingYesCheckboxOther(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, value);


        if (target.type !== "checkbox" && this.state.trainingOutSideSOBYesOther) {
            //for textbox
            this.setState({
                trainingYesotherVal: value,
            });
        }
        else if (target.type === "checkbox") {
            //for checkbox
            this.setState({
                [name]: value,
            });
        }
        if (target.type === "checkbox") {
            if (value === true && name_value === "Other") { this.setState({ divPhysicalYesOther: "show" }) } else { this.setState({ divPhysicalYesOther: "hide" }) }
        }
    }

    handleChangeTrainingNoCheckboxOther(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, value);


        if (target.type !== "checkbox" && this.state.trainingOutSideSOBNoOther) {
            //for textbox
            this.setState({
                trainingNootherVal: value,
            });
        }
        else if (target.type === "checkbox") {
            //for checkbox
            this.setState({
                [name]: value,
            });
        }
        if (target.type === "checkbox") {
            if (value === true && name_value === "Other") { this.setState({ divPhysicalNoOther: "show" }) } else { this.setState({ divPhysicalNoOther: "hide" }) }
        }
    }




    handleChangeStepToSmokeCheckboxOther(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        // console.log(name, value);


        if (target.type !== "checkbox" && this.state.stepToStopSmokeOther) {
            //for textbox
            this.setState({
                smokeotherval: value,
            });
        }
        else if (target.type === "checkbox") {
            //for checkbox
            this.setState({
                [name]: value,
            });
        }
        if (target.type === "checkbox") {
            if (value === true && name_value === "Other") { this.setState({ divTobaccoYesOther: "show" }) } else { this.setState({ divTobaccoYesOther: "hide" }) }
        }
    }








    handleChangeStepToSmokeCheckbox(event) {
        const target = event.target;
       // const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.stepToStopSmoke
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        // console.log(options);
        this.setState({
            [name]: options,
        });
    }


    handleChangeHandWashingCheckbox(event) {
        const target = event.target;
      //  const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //  console.log(name, name_value);
        // this.setState({
        //     [name]: value
        // });
        const options = this.state.handWashTime
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        // console.log(options);
        this.setState({
            [name]: options,
        });
    }



    handleSubmit(event) {
        // console.log(this.state);
        this.healthPromotionSecondPage();
        event.preventDefault();
    }

    healthPromotionSecondPage() {

        let dataToSend = {
            user_id: this.state.sob_scanId,
            // participant_id: "",
            // form_id: "",
        };


        let nutritiondrinks_prev_val = this.state.nutritionDrinks;
        // console.log(this.state.nutritionDrinks)
        if (this.state.othertype !== "") {
            if (this.state.othertype !== undefined) {
                nutritiondrinks_prev_val.push({ "drinksValue": this.state.othertype })
            }
        }
        // console.log(nutritiondrinks_prev_val)
        if (this.state.soft_drink_type !== "") {

            nutritiondrinks_prev_val.forEach((element, index) => {
                if (element.drinksValue === "Soft drink") {
                    nutritiondrinks_prev_val[index] = { "drinksValue": "Soft drink", "drinksSubValue": this.state.soft_drink_type }
                }
            });
            // console.log(nutritiondrinks_prev_val)
        }


        let trainingOutSideSOBYes_prev_val = this.state.trainingOutSideSOBYes;
        if (this.state.trainingYesotherVal !== "") {
            if (this.state.trainingYesotherVal !== undefined) {

                trainingOutSideSOBYes_prev_val.push(this.state.trainingYesotherVal)
            }
        }

        let trainingOutSideSOBNo_prev_val = this.state.trainingOutSideSOBNo;
        if (this.state.trainingNootherVal !== "") {
            if (this.state.trainingNootherVal !== undefined) {
                trainingOutSideSOBNo_prev_val.push(this.state.trainingNootherVal)
            }
        }

        let stepToStopSmokeOther_prev_val = this.state.stepToStopSmoke;
        if (this.state.smokeotherval !== "") {
            if (this.state.smokeotherval !== undefined) {
                stepToStopSmokeOther_prev_val.push(this.state.smokeotherval)
            }
        }

        let sunProtectionNo_prev_val = this.state.sunProtectionNo;
        if (this.state.sunsafeotherval !== "") {
            if (this.state.sunsafeotherval !== undefined) {
                sunProtectionNo_prev_val.push(this.state.sunsafeotherval)
            }
        }


        let glassOfWater_1 = this.state.glassOfWater !== "" ? dataToSend["glassOfWater"] = this.state.glassOfWater : null;
       // let nutritionDrinks_1 = this.state.nutritionDrinks !== "" ? dataToSend["nutritionDrinks"] = nutritiondrinks_prev_val : null;
        let calciumSource_1 = this.state.calciumSource !== "" ? dataToSend["calciumSource"] = this.state.calciumSource : null;
        let sweetBeverage_1 = this.state.sweetBeverage !== "" ? dataToSend["sweetBeverage"] = this.state.sweetBeverage : null;
        let fruitsVegetable_1 = this.state.fruitsVegetable !== "" ? dataToSend["fruitsVegetable"] = this.state.fruitsVegetable : null;
        let snackFood_1 = this.state.snackFood !== "" ? dataToSend["snackFood"] = this.state.snackFood : null;

        let fastFood_1 = this.state.fastFood !== "" ? dataToSend["fastFood"] = this.state.fastFood : null;
        let physicalActivityDayPerWeek_1 = this.state.physicalActivityDayPerWeek !== "" ? dataToSend["physicalActivityDayPerWeek"] = this.state.physicalActivityDayPerWeek : null;
        let trainingOutSideSOB_1 = this.state.trainingOutSideSOB !== "" ? dataToSend["trainingOutSideSOB"] = this.state.trainingOutSideSOB : null;
        let trainingOutSideSOBYes_1 = this.state.trainingOutSideSOBYes.length >0 ? dataToSend["trainingOutSideSOBYes"] = trainingOutSideSOBYes_prev_val : null;
        let trainingOutSideSOBNo_1 = this.state.trainingOutSideSOBNo.length >0 ? dataToSend["trainingOutSideSOBNo"] = trainingOutSideSOBNo_prev_val : null;
        let watchTvComputer_1 = this.state.watchTvComputer !== "" ? dataToSend["watchTvComputer"] = this.state.watchTvComputer : null;
        let handWashTime_1 = this.state.handWashTime.length >0 ? dataToSend["handWashTime"] = this.state.handWashTime : null;
        let handWashUseSoap_1 = this.state.handWashUseSoap !== "" ? dataToSend["handWashUseSoap"] = this.state.handWashUseSoap : null;
        let handWashHaveSoap_1 = this.state.handWashHaveSoap !== "" ? dataToSend["handWashHaveSoap"] = this.state.handWashHaveSoap : null;

        let sunProtectSkin_1 = this.state.sunProtectSkin !== "" ? dataToSend["sunProtectSkin"] = this.state.sunProtectSkin : null;
        let sunProtectionYes_1 = this.state.sunProtectionYes.length >0 ? dataToSend["sunProtectionYes"] = this.state.sunProtectionYes : null;
        let sunProtectionNo_1 = this.state.sunProtectionNo.length >0 ? dataToSend["sunProtectionNo"] = sunProtectionNo_prev_val : null;
        let tabaccoUse_1 = this.state.tabaccoUse !== "" ? dataToSend["tabaccoUse"] = this.state.tabaccoUse : null;
        let tabaccoUseFreq_1 = this.state.tabaccoUseFreq !== "" ? dataToSend["tabaccoUseFreq"] = this.state.tabaccoUseFreq : null;
        let isFamilySmoke_1 = this.state.isFamilySmoke !== "" ? dataToSend["isFamilySmoke"] = this.state.isFamilySmoke : null;
        let stepToStopSmoke_1 = this.state.stepToStopSmoke.length >0 ? dataToSend["stepToStopSmoke"] = stepToStopSmokeOther_prev_val : null;
        let doctorComments = this.state.doctorComments !== "" ? dataToSend["doctorComments"] = this.state.doctorComments : null;

        console.log(dataToSend);


        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'healthPromotionSecondPage', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes;
                // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //  console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

                if(res.response.pageDone!=="1"){
                    toast.error("Scan Correct Athlete", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.props.history.push("/amrit_medical_professional");
                }

            }else{
                toast.error("Scan Correct Athlete", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.props.history.push("/amrit_medical_professional");
            }

        });
    }



    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }


    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"> <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/hpromotion.png'} alt="" />
                                    <form>
                                        <div className="hpTop">
                                            <div className="hpTxt hpTxtB">

                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong>{this.state.participant_list.contactNo} <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                                                {/* <p><strong>DOB:</strong> <span>{this.state.participant_list.dob}</span></p> */}
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>

                                        <div className="compositionBg">
                                            <h3>Nutrition – Food and Beverage Habits</h3>
                                            {/* <h5>What do you usually drink when you are thirsty? (select all that apply)</h5>
                                            <div className="unabletoTest stpBcheck">
                                                <span><input type="checkbox" name="nutritionDrinks" value="Water" onChange={this.handleChangeNutrition} /> Water</span>
                                                <span><input type="checkbox" name="nutritionDrinks" value="Sports drink" onChange={this.handleChangeNutrition} /> Sports drink</span>
                                                <span><input type="checkbox" name="nutritionDrinks" value="Fruit juice" onChange={this.handleChangeNutrition} /> Fruit juice</span>
                                                <span><input type="checkbox" name="nutritionDrinks" value="Milk product (includes soy)" onChange={this.handleChangeNutrition} /> Milk product (includes soy)</span>
                                                <span><input type="checkbox" name="nutritionDrinks" value="Energy drink" onChange={this.handleChangeNutrition} /> Energy drink</span>
                                            </div>
                                            <div className="stpSpdrink">
                                                <div className="unabletoTest stpBcheck">
                                                    <span className="minWdt"><input type="checkbox" name="nutritionDrinks" value="Soft drink" onChange={this.handleChangeNutrition} /> Soft drink</span>
                                                </div>
                                                <div className={"newSelect " + this.state.divSoftDrinksDropdown}>
                                                    <select name="soft_drink_type" onChange={this.handleChangeNutritionDropdown}>
                                                        <option className="slRedbg" value="">Select Option</option>
                                                        <option value="Diet">Diet</option>
                                                        <option value="Non Diet">Non Diet</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="stpSpdrink">
                                                <div className="unabletoTest stpBcheck">
                                                    <span className="minWdt"><input type="checkbox" name="nutritionDrinksOther" value="Other" onChange={this.handleChangeNutritionOther} /> Other</span>
                                                </div>
                                                <div className={this.state.divNutritionOther} style={{ width: "100%" }}>
                                                    <textarea className="form-control" placeholder="Type" name="othertype" onChange={this.handleChangeNutritionOther}></textarea>
                                                </div>

                                            </div> */}



                                            <h5>How many glasses of water do you drink in a day?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select onChange={this.handleChange} name="glassOfWater">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="1 to 3 glasses">1 to 3 glasses</option>
                                                    <option value="3 to 5 glasses">3 to 5 glasses</option>
                                                    <option value="5 to 10 glasses">5 to 10 glasses</option>
                                                    <option value="more than 10 glasses">more than 10 glasses</option>

                                                </select>
                                            </div>







                                            <div className="row mrgbtm30">
                                                <div className="col-md-6">
                                                    <h5>Sources of Calcium</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="calciumSource">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.sourcesofCalcium.map((eachsourcesofCalcium, i) => (
                                                                    <option key={i} value={eachsourcesofCalcium.value}>{eachsourcesofCalcium.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Sweetened Beverages</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="sweetBeverage">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.sweetenedBeverages.map((eachsweetenedBeverages, i) => (
                                                                    <option key={i} value={eachsweetenedBeverages.value}>{eachsweetenedBeverages.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mrgbtm30">
                                                <div className="col-md-6">
                                                    <h5>Fruits and Vegetables</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="fruitsVegetable">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.fruitsandVegetables.map((eachfruitsandVegetables, i) => (
                                                                    <option key={i} value={eachfruitsandVegetables.value}>{eachfruitsandVegetables.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Snack Foods</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="snackFood">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.snackFoods.map((eachsnackFoods, i) => (
                                                                    <option key={i} value={eachsnackFoods.value}>{eachsnackFoods.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mrgbtm30">
                                                <div className="col-md-6">
                                                    <h5>Fast Food</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="fastFood">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.fastfood.map((eachfastfood, i) => (
                                                                    <option key={i} value={eachfastfood.value}>{eachfastfood.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="compositionBg">

                                            <h3>Physical Activity</h3>
                                            <h5>How many days per week do you exercise for at least 30 minutes?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select onChange={this.handleChange} name="physicalActivityDayPerWeek">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    {this.state.howmanyDays.map((eachhowmanyDays, i) => (
                                                        <option key={i} value={eachhowmanyDays.value}>{eachhowmanyDays.value}</option>

                                                    ))}
                                                </select>
                                            </div>
                                            <h5>Do you exercise outside of your Special Olympics training?</h5>
                                            <div className="newSelect stpBbefore mrgbtm30">
                                                <select onChange={this.handleChangeTraining} name="trainingOutSideSOB">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>
                                            {/* If Yes */}
                                            <div className={this.state.divTrainingYesvalue}>
                                                <h5> What do you do? (select all that apply)</h5>
                                                <div className="unabletoTest stpBcheck">
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Weight training" onChange={this.handleChangeTrainingYesCheckbox} /> Weight training</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Run/Jog" onChange={this.handleChangeTrainingYesCheckbox} /> Run/Jog</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Walk" onChange={this.handleChangeTrainingYesCheckbox} /> Walk</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Dance" onChange={this.handleChangeTrainingYesCheckbox} /> Dance</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Sports" onChange={this.handleChangeTrainingYesCheckbox} /> Sports</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBYes" value="Exercise video" onChange={this.handleChangeTrainingYesCheckbox} /> Exercise video</span>
                                                </div>

                                                <div className="stpSpdrink">
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="minWdt"><input type="checkbox" name="trainingOutSideSOBYesOther" value="Other" onChange={this.handleChangeTrainingYesCheckboxOther} /> Other</span>
                                                    </div>
                                                    <div className={this.state.divPhysicalYesOther} style={{ width: "100%" }}>
                                                        <textarea className="form-control" placeholder="Type" name="trainingYesotherVal" onChange={this.handleChangeTrainingYesCheckboxOther}></textarea>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* If No */}
                                            <div className={this.state.divTrainingNovalue}>
                                                <h5> What do you do? (select all that apply)</h5>
                                                <div className="unabletoTest stpBcheck">
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No interest" onChange={this.handleChangeTrainingNoCheckbox} /> No interest</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No money" onChange={this.handleChangeTrainingNoCheckbox} /> No money</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="Do not know how" onChange={this.handleChangeTrainingNoCheckbox} /> Do not know how</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="Do not know how" onChange={this.handleChangeTrainingNoCheckbox} /> Do not know how</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No transportation" onChange={this.handleChangeTrainingNoCheckbox} /> No transportation</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No one to do it with" onChange={this.handleChangeTrainingNoCheckbox} /> No one to do it with</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No available exercise facility" onChange={this.handleChangeTrainingNoCheckbox} /> No available exercise facility</span>
                                                    <span><input type="checkbox" name="trainingOutSideSOBNo" value="No time" onChange={this.handleChangeTrainingNoCheckbox} /> No time</span>
                                                </div>

                                                <div className="stpSpdrink">
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="minWdt"><input type="checkbox" name="trainingOutSideSOBNoOther" value="Other" onChange={this.handleChangeTrainingNoCheckboxOther} /> Other</span>
                                                    </div>
                                                    <div className={this.state.divPhysicalNoOther} style={{ width: "100%" }}>
                                                        <textarea className="form-control" placeholder="Type" name="trainingNootherVal" onChange={this.handleChangeTrainingNoCheckboxOther}></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <h5>How many hours a day do you watch television or play computer/video games?</h5>
                                            <div className="newSelect stpBbefore mrgbtm30">
                                                <select onChange={this.handleChange} name="watchTvComputer">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    {this.state.howmanyHours.map((eachhowmanyHours, i) => (
                                                        <option key={i} value={eachhowmanyHours.value}>{eachhowmanyHours.value}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="compositionBg">
                                            <h3>Hand Washing</h3>
                                            <h5>When are the most important times to wash your hands? (select all that apply</h5>
                                            <div className="unabletoTest stpBcheck stpBcheckB">
                                                <span><input type="checkbox" name="handWashTime" value="After using the toilet" onChange={this.handleChangeHandWashingCheckbox} /> After using the toilet</span>
                                                <span><input type="checkbox" name="handWashTime" value="Before eating or touching food" onChange={this.handleChangeHandWashingCheckbox} /> Before eating or touching food</span>
                                                <span><input type="checkbox" name="handWashTime" value="Other reason" onChange={this.handleChangeHandWashingCheckbox} /> Other reason</span>
                                                <span><input type="checkbox" name="handWashTime" value="No response/no reasons given" onChange={this.handleChangeHandWashingCheckbox} /> No response/no reasons given</span>
                                            </div>
                                            <h5>Did you use soap last time you washed your hands?</h5>
                                            <div className="newSelect stpBbefore mrgbtm30">
                                                <select onChange={this.handleChange} name="handWashUseSoap">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>
                                            <h5>Do you have soap at your home?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select onChange={this.handleChange} name="handWashHaveSoap">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="compositionBg">
                                            <h3>Sun Safety</h3>
                                            <h5>Do you do anything to protect your skin in the sun?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select onChange={this.handleChangeSunSafety} name="sunProtectSkin">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>
                                            {/* If yes */}
                                            <div className={this.state.divSunSafetyYesvalue}>
                                                <h5>What do you do to protect your skin in the sun? (select all that apply)</h5>
                                                <div className="unabletoTest stpBcheck">
                                                    <span><input type="checkbox" name="sunProtectionYes" value="Use sunscreen" onChange={this.handleChangeSunSafetyYesCheckbox} /> Use sunscreen</span>
                                                    <span><input type="checkbox" name="sunProtectionYes" value="Seek shade" onChange={this.handleChangeSunSafetyYesCheckbox} /> Seek shade</span>
                                                    <span><input type="checkbox" name="sunProtectionYes" value="Wear long sleeves" onChange={this.handleChangeSunSafetyYesCheckbox} /> Wear long sleeves</span>
                                                    <span><input type="checkbox" name="sunProtectionYes" value="Wear a hat" onChange={this.handleChangeSunSafetyYesCheckbox} /> Wear a hat</span>
                                                    <span><input type="checkbox" name="sunProtectionYes" value="Wear sunglasses" onChange={this.handleChangeSunSafetyYesCheckbox} /> Wear sunglasses</span>
                                                    <span><input type="checkbox" name="sunProtectionYes" value="I do not do anything" onChange={this.handleChangeSunSafetyYesCheckbox} /> I do not do anything</span>
                                                </div>
                                            </div>

                                            {/* If No */}
                                            <div className={this.state.divSunSafetyNovalue}>
                                                <h5>What is the reason? (select all that apply)</h5>
                                                <div className="unabletoTest stpBcheck">
                                                    <span><input type="checkbox" name="sunProtectionNo" value="Did not know it was important" onChange={this.handleChangeSunSafetyNoCheckbox} /> Did not know it was important</span>
                                                    <span><input type="checkbox" name="sunProtectionNo" value="No money to buy protection" onChange={this.handleChangeSunSafetyNoCheckbox} /> No money to buy protection</span>
                                                    <span><input type="checkbox" name="sunProtectionNo" value="Do not get sunburned" onChange={this.handleChangeSunSafetyNoCheckbox} /> Do not get sunburned</span>
                                                    <span><input type="checkbox" name="sunProtectionNo" value="Like to be tan" onChange={this.handleChangeSunSafetyNoCheckbox} /> Like to be tan</span>
                                                </div>


                                                <div className="stpSpdrink">
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="minWdt"><input type="checkbox" name="sunProtectionNoOther" value="Other" onChange={this.handleChangeSunSafetyNoCheckboxOther} /> Other</span>
                                                    </div>
                                                    <div className={this.state.divSunsafetyNoOther} style={{ width: "100%" }}>
                                                        <textarea className="form-control" placeholder="Type" name="sunsafeotherval" onChange={this.handleChangeSunSafetyNoCheckboxOther}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="compositionBg">
                                            <h3>Tobacco Use</h3>
                                            <div className="row mrgbtm30">
                                                <div className="col-md-6">
                                                    <h5>Do you use tobacco?</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChangeTobacco} name="tabaccoUse">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 " + this.state.divTobaccoYesvalue}>
                                                    <h5>How frequently?</h5>
                                                    <div className="heightFlex">
                                                        <div className="newSelect">
                                                            <select onChange={this.handleChange} name="tabaccoUseFreq">
                                                                <option value="" className="slRedbg">Select Option</option>
                                                                {this.state.howfrequently.map((eachhowfrequently, i) => (
                                                                    <option key={i} value={eachhowfrequently.value}>{eachhowfrequently.value}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>Do any of your friends or family members smoke near you?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select onChange={this.handleChangeisFamilySmoke} name="isFamilySmoke">
                                                    <option value="" className="slRedbg">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>

                                                </select>
                                            </div>

                                            <div className={this.state.divisFamilySmokevalue}>
                                                <h5>What do you do when they are smoking near you? (select all that apply)</h5>
                                                <div className="unabletoTest stpBcheck">
                                                    <span><input type="checkbox" name="stepToStopSmoke" value="Ask them to stop" onChange={this.handleChangeStepToSmokeCheckbox} /> Ask them to stop</span>
                                                    <span><input type="checkbox" name="stepToStopSmoke" value="Leave the room" onChange={this.handleChangeStepToSmokeCheckbox} /> Leave the room</span>
                                                    <span><input type="checkbox" name="stepToStopSmoke" value="Smoke" onChange={this.handleChangeStepToSmokeCheckbox} /> Smoke</span>
                                                    <span><input type="checkbox" name="stepToStopSmoke" value="I do not do anything" onChange={this.handleChangeStepToSmokeCheckbox} /> I do not do anything</span>
                                                </div>
                                                <div className="stpSpdrink">
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="minWdt"><input type="checkbox" name="stepToStopSmokeOther" value="Other" onChange={this.handleChangeStepToSmokeCheckboxOther} /> Other</span>
                                                    </div>
                                                    <div className={this.state.divTobaccoYesOther} style={{ width: "100%" }}>
                                                        <textarea className="form-control" placeholder="Type" name="smokeotherval" onChange={this.handleChangeStepToSmokeCheckboxOther}></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="compositionBg">
                                            <div className="addlComment">
                                                <h3>Additional comments:</h3>
                                                <textarea className="form-control" name="doctorComments" placeholder="Comment" onChange={this.handleChange}></textarea>
                                            </div>

                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_medical_professional" className="intbtnb">Back</Link>
                                            <button type="submit" onClick={this.handleSubmit} style={{ width: "50%" }} className="backtohome">Submit</button><br /><br />
                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Form1_Step2;