import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Hydration4 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop hydrationBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Hydration</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">                       
                                <div className="hydrationBase urineChrt">
                                    <div className="hydrationLft">
                                        <h3>Urine Chart</h3>
                                        <p>How to tell if you are hydrated</p>
                                    </div>
                                    <div className="hydrationRgt">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/meter.png'} alt=""/>
            
                                        {/* <div className="intBtn"><a href="#" className="intbtnb">Tip</a></div>
                                        <p>Drink water right away,slow down and cool off</p> */}
           
                                    </div>
                                </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link>  
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        )
    }
}
export default Hydration4;