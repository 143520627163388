import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form8 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",

            sport: "",
            refractionRE: "",
            refractionLE: "",
            referralTo: [],
            referralToOther: "",
            recomendation: "",
            noNewRx: "",
            noNewRxBool: "",
            noNewRxValue: "",
            sunglasses: "",
            newRx: "",
            newRxBool: "",
            newRxValue: "",
            newRxPd: "",
            pdLeftValue: "",
            pdRightValue: "",
            newRxPdSphereRE: "",
            newRxPdCylinderRE: "",
            newRxPdAxisRE: "",
            newRxPdVADistRE: "",
            newRxPdSphereLE: "",
            newRxPdCylinderLE: "",
            newRxPdAxisLE: "",
            newRxPdVADistLE: "",
            sportGoggles: "",
            sportGogglesBool: "",
            sportGogglesType: "",
            sportGogglesSphereRE: "",
            sportGogglesCylinderRE: "",
            sportGogglesAxisRE: "",
            sportGogglesVADistRE: "",
            sportGogglesSphereLE: "",
            sportGogglesCylinderLE: "",
            sportGogglesAxisLE: "",
            sportGogglesVADistLE: "",

            referralToDiv: false,

            referralToArray: [" Optometrist", "Ophthalmologist", "Primary care physician", "Neurologist"],

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReferralCheckbox = this.handleReferralCheckbox.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.recomendation !== this.state.recomendation) {
            if (this.state.recomendation !== "No new Rx") {
                this.setState({
                    noNewRx: "",
                    noNewRxBool: "",
                    noNewRxValue: "",
                })
            } else if (this.state.recomendation !== "New Rx") {
                this.setState({
                    newRxBool: "",
                    newRxValue: "",
                    newRxPd: "",
                    pdLeftValue: "",
                    pdRightValue: "",
                    newRxPdSphereRE: "",
                    newRxPdCylinderRE: "",
                    newRxPdAxisRE: "",
                    newRxPdVADistRE: "",
                    newRxPdSphereLE: "",
                    newRxPdCylinderLE: "",
                    newRxPdAxisLE: "",
                    newRxPdVADistLE: "",
                    sportGoggles: "",
                    sportGogglesBool: "",
                    sportGogglesType: "",
                    sportGogglesSphereRE: "",
                    sportGogglesCylinderRE: "",
                    sportGogglesAxisRE: "",
                    sportGogglesVADistRE: "",
                    sportGogglesSphereLE: "",
                    sportGogglesCylinderLE: "",
                    sportGogglesAxisLE: "",
                    sportGogglesVADistLE: "",
                })

            }
        }

        if (prevState.sportGogglesBool !== this.state.sportGogglesBool) {
            if (this.state.sportGogglesBool === "" || this.state.sportGogglesBool === false) {
                this.setState({
                    sportGogglesType: "",
                    sportGogglesSphereRE: "",
                    sportGogglesCylinderRE: "",
                    sportGogglesAxisRE: "",
                    sportGogglesVADistRE: "",
                    sportGogglesSphereLE: "",
                    sportGogglesCylinderLE: "",
                    sportGogglesAxisLE: "",
                    sportGogglesVADistLE: "",
                })
            }
        }

        if (prevState.sportGogglesBool !== this.state.sportGogglesBool) {
            if (this.state.sportGogglesBool === "" || this.state.sportGogglesBool === false) {

            }
        }
    }

    handleReferralCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;

        let options = this.state.referralTo;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value);
            options.splice(index, 1)
        }
        if (name_value === "Other") {
            this.setState({ referralToDiv: value });
            if (value === false && name_value === "Other") {
                this.setState({
                    referralToOther: "",
                })
                // options.slice(-1, 1)
            }
        }
        this.setState({
            referralTo: options
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageEight();
    }

    openingEyePageEight() {
       // console.log("I called");
        let dataToSend = {
            user_id: this.state.sob_scanId,
        };

       // console.log(this.state);

       // let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        let refractionRE_1 = this.state.refractionRE != "" ? dataToSend['refractionRE'] = this.state.refractionRE : null;
        let refractionLE_1 = this.state.refractionLE != "" ? dataToSend['refractionLE'] = this.state.refractionLE : null;
        if (this.state.referralTo.includes('Other')) {
            let options = this.state.referralTo;
            let index = options.indexOf('Other');
            options.splice(index, 1)
            let referralToOther_1 = this.state.referralToOther != "" ? options.push(this.state.referralToOther) : null;
            let referralTo_1 = (this.state.referralTo != "" && (this.state.referralTo).length > 0) ? dataToSend['referralTo'] = this.state.referralTo : null;
        } else {
            let tempReferralTo = this.state.referralTo;
            for (let arrIndex = 0; arrIndex < (tempReferralTo).length; arrIndex++ ) {
                if (!(this.state.referralToArray.includes(tempReferralTo[arrIndex]))) {
                    tempReferralTo.slice(arrIndex, 1);
                }
            }
            this.setState({
                referralTo: tempReferralTo
            })
            let referralTo_1 = (this.state.referralTo != "" && (this.state.referralTo).length > 0) ? dataToSend['referralTo'] = this.state.referralTo : null;
        }

        if (this.state.recomendation === "No new Rx") {
            dataToSend['noNewRxBool'] = true;
            let noNewRxValue_1 = this.state.noNewRxValue != "" ? dataToSend['noNewRxValue'] = this.state.noNewRxValue : null;
            let sunglasses_1 = this.state.sunglasses != "" ? dataToSend['sunglasses'] = this.state.sunglasses : null;
        } else if (this.state.recomendation === "New Rx") {
            dataToSend['newRxBool'] = true;
            let newRxValue_1 = this.state.newRxValue != "" ? dataToSend['newRxValue'] = this.state.newRxValue : null;
            let pdLeftValue_1 = this.state.pdLeftValue != "" ? dataToSend['pdLeftValue'] = this.state.pdLeftValue : null;
            let pdRightValue_1 = this.state.pdRightValue != "" ? dataToSend['pdRightValue'] = this.state.pdRightValue : null;
            let newRxPdSphereRE_1 = this.state.newRxPdSphereRE != "" ? dataToSend['newRxPdSphereRE'] = this.state.newRxPdSphereRE : null;
            let newRxPdCylinderRE_1 = this.state.newRxPdCylinderRE != "" ? dataToSend['newRxPdCylinderRE'] = this.state.newRxPdCylinderRE : null;
            let newRxPdAxisRE_1 = this.state.newRxPdAxisRE != "" ? dataToSend['newRxPdAxisRE'] = this.state.newRxPdAxisRE : null;
            let newRxPdVADistRE_1 = this.state.newRxPdVADistRE != "" ? dataToSend['newRxPdVADistRE'] = this.state.newRxPdVADistRE : null;
            let newRxPdSphereLE_1 = this.state.newRxPdSphereLE != "" ? dataToSend['newRxPdSphereLE'] = this.state.newRxPdSphereLE : null;
            let newRxPdCylinderLE_1 = this.state.newRxPdCylinderLE != "" ? dataToSend['newRxPdCylinderLE'] = this.state.newRxPdCylinderLE : null;
            let newRxPdAxisLE_1 = this.state.newRxPdAxisLE != "" ? dataToSend['newRxPdAxisLE'] = this.state.newRxPdAxisLE : null;
            let newRxPdVADistLE_1 = this.state.newRxPdVADistLE != "" ? dataToSend['newRxPdVADistLE'] = this.state.newRxPdVADistLE : null;
            let sportGogglesBool_1 = this.state.sportGogglesBool != "" ? dataToSend['sportGogglesBool'] = this.state.sportGogglesBool : null;
            if (this.state.sportGogglesBool === true) {
                let sportGogglesType_1 = this.state.sportGogglesType != "" ? dataToSend['sportGogglesType'] = this.state.sportGogglesType : null;
                let sportGogglesSphereRE_1 = this.state.sportGogglesSphereRE != "" ? dataToSend['sportGogglesSphereRE'] = this.state.sportGogglesSphereRE : null;
                let sportGogglesCylinderRE_1 = this.state.sportGogglesCylinderRE != "" ? dataToSend['sportGogglesCylinderRE'] = this.state.sportGogglesCylinderRE : null;
                let sportGogglesAxisRE_1 = this.state.sportGogglesAxisRE != "" ? dataToSend['sportGogglesAxisRE'] = this.state.sportGogglesAxisRE : null;
                let sportGogglesVADistRE_1 = this.state.sportGogglesVADistRE != "" ? dataToSend['sportGogglesVADistRE'] = this.state.sportGogglesVADistRE : null;
                let sportGogglesSphereLE_1 = this.state.sportGogglesSphereLE != "" ? dataToSend['sportGogglesSphereLE'] = this.state.sportGogglesSphereLE : null;
                let sportGogglesCylinderLE_1 = this.state.sportGogglesCylinderLE != "" ? dataToSend['sportGogglesCylinderLE'] = this.state.sportGogglesCylinderLE : null;
                let sportGogglesAxisLE_1 = this.state.sportGogglesAxisLE != "" ? dataToSend['sportGogglesAxisLE'] = this.state.sportGogglesAxisLE : null;
                let sportGogglesVADistLE_1 = this.state.sportGogglesVADistLE != "" ? dataToSend['sportGogglesVADistLE'] = this.state.sportGogglesVADistLE : null;
            }

        }

       // console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageEight', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                <ul>
                                    <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div class="compositionBg">


                                            <h4>Refraction:</h4><br />
                                            <div class="row frmStepbPdn">
                                                <div class="col-md-6">
                                                    <h4>RIGHT EYE </h4>
                                                    <div class="heightFlex retinoStep">
                                                        <span class="per20">20/</span>
                                                        <input type="number" name="refractionRE" onChange={this.handleChange} class="form-controlnum" placeholder="Enter" />
                                                    </div>

                                                </div>
                                                <div class="col-md-6">
                                                    <h4>LEFT EYE</h4>
                                                    <div class="heightFlex retinoStep">
                                                        <span class="per20">20/</span>
                                                        <input type="number" name="refractionLE" onChange={this.handleChange} class="form-controlnum" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="coverTestBordr">Recommendations:</h4>
                                            <div class="stpSpdrink recoBg coverTestBordr">
                                                <div class="recoRes">
                                                    <div class="unabletoTest stpBcheck frmStpd recoMinwdt">
                                                        <span class="radioLft"><input type="radio" id="test5" name="recomendation" onChange={this.handleChange} value="No new Rx" /><label class="noMrgnBtm" for="test5">No new Rx:</label></span>
                                                    </div>
                                                    {
                                                        this.state.recomendation === "No new Rx" &&
                                                        <>

                                                            <div class="newSelect">
                                                                <select name="noNewRxValue" onChange={this.handleChange}>
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="No glasses recommended">No change in glasses recommended</option>
                                                                    <option value="No change in glasses recommended">No change in glasses recommended</option>
                                                                </select>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                {
                                                    this.state.recomendation === "No new Rx" &&
                                                    <>
                                                        <div class="unabletoTest stpBcheck frmStpd">
                                                            <span class="minWdt noPdnBtm"> <input type="checkbox" name="sunglasses" onChange={this.handleChange} /> Sunglasses (plano)</span>
                                                        </div>
                                                    </>

                                                }
                                            </div>

                                            <div class="stpSpdrink recoBg">
                                                <div class="recoRes">
                                                    <div class="unabletoTest stpBcheck frmStpd recoMinwdt">
                                                        <span class="radioLft"><input type="radio" id="recoB" name="recomendation" onChange={this.handleChange} value="New Rx" /><label class="noMrgnBtm" for="recoB">New Rx:</label></span>
                                                    </div>
                                                    {
                                                        this.state.recomendation === "New Rx" &&
                                                        <>
                                                            <div class="newSelect">
                                                                <select>
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Full time Rx">Full time Rx</option>
                                                                    <option value="Distance only">Distance only</option>
                                                                    <option value="Close work only">Close work only</option>
                                                                </select>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                {
                                                    this.state.recomendation === "New Rx" &&
                                                    <>
                                                        <div class="recoPD">
                                                            <span class="autoRe">PD</span>
                                                            <div class="recoPdinp">
                                                                <input type="number" name="pdLeftValue" onChange={this.handleChange} class="form-control" />/
                                                                <input type="number" name="pdRightValue" onChange={this.handleChange} class="form-control" />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>



                                            {
                                                this.state.recomendation === "New Rx" &&
                                                <>
                                                    <div class="row frmStepbPdn">
                                                        <div class="col-md-6">
                                                            <h4>RIGHT EYE </h4>
                                                            <h5>Sphere</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdSphereRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Cylinder</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdCylinderRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Axis</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdAxisRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>VA Distance</h5>
                                                            <div class="heightFlex">
                                                                <div class="cvisionFlex pdTest">
                                                                    <span><input type="number" name="newRxPdVADistRE" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">20/</span></span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-6">
                                                            <h4>LEFT EYE</h4>
                                                            <h5>Sphere</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdSphereLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Cylinder</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdCylinderLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Axis</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="newRxPdAxisLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>VA Distance</h5>
                                                            <div class="heightFlex">
                                                                <div class="cvisionFlex pdTest">
                                                                    <span><input type="number" name="newRxPdVADistLE" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">20/</span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="stpSpdrink">
                                                        <div class="unabletoTest stpBcheck frmStpd">
                                                            <span class="minWdt noPdnBtm"> <input type="checkbox" name="sportGogglesBool" onChange={this.handleChange} /> Sports goggles:</span>
                                                        </div>
                                                        {
                                                            this.state.sportGogglesBool &&
                                                            <>
                                                                <div class="newSelect">
                                                                    <select name="sportGogglesType" onChange={this.handleChange}>
                                                                        <option className="slRedbg" value="">Select Option</option>
                                                                        <option value="Plano">Plano</option>
                                                                        <option value="Rx">Rx</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>

                                                    {
                                                        this.state.sportGogglesBool &&
                                                        <>
                                                            <div class="row frmStepbPdn">
                                                                <div class="col-md-6">
                                                                    <h4>RIGHT EYE </h4>
                                                                    <h5>Sphere</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesSphereRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>Cylinder</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesCylinderRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>Axis</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesAxisRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>VA Distance</h5>
                                                                    <div class="heightFlex">
                                                                        <div class="cvisionFlex pdTest">
                                                                            <span><input type="number" name="sportGogglesVADistRE" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">20/</span></span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-6">
                                                                    <h4>LEFT EYE</h4>
                                                                    <h5>Sphere</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesSphereLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>Cylinder</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesCylinderLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>Axis</h5>
                                                                    <div class="heightFlex">
                                                                        <input type="number" name="sportGogglesAxisLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                                    </div>
                                                                    <h5>VA Distance</h5>
                                                                    <div class="heightFlex">
                                                                        <div class="cvisionFlex pdTest">
                                                                            <span><input type="number" name="sportGogglesVADistLE" onChange={this.handleChange} class="form-control" placeholder="Value" /><span class="undExp">20/</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </>
                                            }
                                            <h4 class="coverTestBordr">Referral to:</h4>
                                            <div class="unabletoTest stpBcheck referralLast">
                                                <span><input type="checkbox" name="referralTo" onChange={this.handleReferralCheckbox} value="Optometrist" /> Optometrist</span>
                                                <span><input type="checkbox" name="referralTo" onChange={this.handleReferralCheckbox} value="Ophthalmologist" /> Ophthalmologist</span>
                                                <span><input type="checkbox" name="referralTo" onChange={this.handleReferralCheckbox} value="Primary care physician" /> Primary care physician</span>
                                                <span><input type="checkbox" name="referralTo" onChange={this.handleReferralCheckbox} value="Neurologist" /> Neurologist</span>
                                            </div>
                                            <div class="stpSpdrink">
                                                <div class="unabletoTest stpBcheck">
                                                    <span class="minWdt">
                                                        <input type="checkbox" name="referralTo" onChange={this.handleReferralCheckbox} value="Other" /> Other
                                                    </span>
                                                </div>
                                                {
                                                    this.state.referralToDiv &&
                                                    <>
                                                        <textarea class="form-control" name="referralToOther" onChange={this.handleChange} placeholder="Type"></textarea>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Eyes_Form8;