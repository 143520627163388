import React from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_type:"",
            startdate:"",
            enddate:"",
            name: " User",
            participant_type_list: [],

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    } 
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getProfile();
        this.getRegistrationDetails();
    }

    validationCheck(){
        //console.log(this.state.participant_type);
        let isValidate=true;
        if(this.state.participant_type===''){
            isValidate = false;
       }   
        return isValidate;
      }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const json_value=JSON.parse(value);
        //console.log(json_value);
        this.setState({
            [name]: json_value.ptype,
            startdate:json_value.startDate,
            enddate:json_value.endDate,
        });
       
    }
    handleSubmit(event) { 
       // console.log(this.state.startdate); console.log(this.state.enddate);
        var today = new Date();
        var today_date = new Date(today).toISOString();
        //var today_date='2022-11-04T00:00:00.000Z';
        if(this.validationCheck()){
            localStorage.setItem('participant_type',this.state.participant_type)

            if(today_date<this.state.startdate){
                localStorage.setItem('reg_status','notstart')
                this.props.history.push("/amrit_registration_status");
            }else if(today_date>this.state.enddate){
                localStorage.setItem('reg_status','closed')
                this.props.history.push("/amrit_registration_status");
            }else{
                this.props.history.push("/amrit_participants_officials");
            }
        }else{
            toast.error("Please Choose Participant Type", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
     }

    
    getRegistrationDetails() {
        axios.post(path, {
            participantType:"",
            eventId:"61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res);
            const ParticipantTypeList = res.response.type;
            if (res.status === true) {
                this.setState({
                    participant_type_list:ParticipantTypeList
                });
                
                //console.log(ContentDetails);
              
               
            }
        });
    }

    getProfile() {
        axios.post(path, {
      
        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                });
            }
        });
      }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                <div className="amritRegdsecondbg">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock noBgregd">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt=""/></Link>
                            <h3>Register Yourself to Participate in the event</h3>
                            <h2>Hi, {this.state.name}</h2>
                            <form>
                                <div className="form-group">
                                <div className="newSelect" >
                                {/* <select name="venue" id="venue" onChange={this.handleChange} required>
                                    <option className="slRedbg" value="">Select Location</option>
                                {this.state.venu_list.map((venuDetails, i) => (
                                    <option value={venuDetails} key={i}>{venuDetails}</option>
                                ))}
                                </select> */}


                                <select name="participant_type" id="participant_type" onChange={this.handleChange} required>
                                    <option className="slRedbg" value="">Select Participant Type</option>
                                {this.state.participant_type_list.map((participantTypeDetails, i) => (
                                    <option value={JSON.stringify({"ptype":participantTypeDetails.type,"startDate":participantTypeDetails.startDate,"endDate":participantTypeDetails.endDate})} key={i}>{participantTypeDetails.type}</option>
                                ))}
                                </select>
                            </div>
                            
                            
                            </div>
                            <button type="submit" className="" onClick={this.handleSubmit}>Submit</button>
                            
                        </form>
                        <Link to="/home">  <button className="backtohome">Back to Home</button></Link>
                        </div>
                        
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Registration;