import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;

class Header extends React.Component {
  constructor(props) {
    super(props);
    //var deviceToken_val = Math.floor(1000 + Math.random() * 90000000);
    this.state = {
      deviceToken: "",
      userId: "",
      AuthToken: "",
      name: " User ",
      profilePic:"",
      title:"",
      message:""

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.feedbackSubmit = this.feedbackSubmit.bind(this);
  }
 

  handleChange(event) {
    const target = event.target;
    const value =target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    //console.log(this.state);
    this.logOut(this.state.deviceToken);
    event.preventDefault();

  }

  async componentDidMount() {
    // Get userdetails from localstorage
    const userId = await localStorage.getItem('SOB_userId')
    const AuthToken = await localStorage.getItem('SOB_authToken')

    //await requestForToken();
    const deviceToken = await localStorage.getItem('SOB_currentToken')
    // console.log("deviceToken for login---------"+deviceToken);
    this.setState({
      deviceToken: deviceToken,
    })
   
    //if(userId && userId.hasOwnProperty('userId')){
    this.setState({
        userId: userId,
        AuthToken: AuthToken,
        deviceToken: deviceToken
    })
    // }
    if(this.state.userId===null){
      this.props.history.push("/");
  }
    this.getProfile();
}

getProfile() {
  axios.post(path, {

  }, {
      headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
  }).then(serverResponse => {
      const res = serverResponse.data;
      if (res.status === true) {
          //console.log(res);
          const ProfileDetails = res.response;
          let firstName = ProfileDetails.name.split(" ")[0]
          this.setState({
              name: firstName,
              profilePic:ProfileDetails.profilePic+"?"
          });
      }
      else {
          // this.props.history.push("/");
          // localStorage.removeItem("SOB_userId");
          // localStorage.removeItem("SOB_authToken");
          // localStorage.removeItem("SOB_refreshToken");

          // toast.error("Some Error Occured", {
          //     position: toast.POSITION.BOTTOM_RIGHT
          // });
      }
  });
}

feedbackSubmit(event) {
  //console.log(this.state);
  this.feedback(this.state.title, this.state.message);
  event.preventDefault();
}

feedback(...params) {
  axios.post(path, {
    title: params[0],
    message: params[1],

  }, {
      headers: { 'Api-Name': 'feedback', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
  }).then(serverResponse => {
      const res = serverResponse.data;
      if (res.status === true) {
          document.getElementById("myfeedbackModal").classList.remove("show", "d-block");
          document.querySelectorAll(".modal-backdrop")
          .forEach(el => el.classList.remove("modal-backdrop"));
          //console.log(res);
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
      }
      else {
          // this.props.history.push("/");
          // localStorage.removeItem("SOB_userId");
          // localStorage.removeItem("SOB_authToken");
          // localStorage.removeItem("SOB_refreshToken");

          // toast.error("Some Error Occured", {
          //     position: toast.POSITION.BOTTOM_RIGHT
          // });
      }
  });
}



  logOut(...params) {
    axios
      .post(path, {
        deviceToken: params[0]

      }, {
        headers: { 'Api-Name': 'logout', 'userId': this.state.userId }
      })
      .then((serverRes) => {
        const res = serverRes.data;
        // console.log(res);

        if (res.status === true) {
          toast.success(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          this.props.history.push("/");
          localStorage.removeItem("SOB_userId");
          localStorage.removeItem("SOB_authToken");
          localStorage.removeItem("SOB_refreshToken");
          localStorage.removeItem("yaTraningId");
          localStorage.removeItem("currentPos");
          localStorage.removeItem("itemId");
          localStorage.removeItem("trainingId");
          localStorage.removeItem("yaItem_Id");
          localStorage.removeItem("current_page_id");
          localStorage.removeItem("exercise_id");
          localStorage.removeItem("name");
          localStorage.removeItem("name_color");
          localStorage.removeItem("yaItemDescription");
          localStorage.removeItem("exercise_inner_page_color");
          localStorage.removeItem("yaAthleteId");
          localStorage.removeItem("SOB_idType");
          localStorage.removeItem("SOB_adminId");
          localStorage.removeItem("SOB_name");
          localStorage.removeItem("participant_type");
          localStorage.removeItem("participant_id");
          localStorage.removeItem("SOB_scanId");

          //localStorage.removeItem("SOB_currentToken");

        } else {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }
  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    });
    return (
      <div>
      <header className="w-100 sticky-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="#"><img src={process.env.PUBLIC_URL + '/assets/images/Logo.png'} alt="" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fal fa-bars"></i>
                  <i className="fal fa-times"></i>
                </button>

                <div className="collapse navbar-collapse mainNav" id="navbarSupportedContent">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/home">Home <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/profile">Profile</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="#" data-toggle="modal" data-target="#myfeedbackModal">Feedback</Link>
                    </li>
                  </ul>
                </div>
                <div className="headEnd">
                  <Link className="nav-link" to="/leaderboard"><button type="button" className="leaderboard">Leaderboard</button></Link>
                  <div className="logDetl">
                    <span className="usrImg"><img src={this.state.profilePic?this.state.profilePic:process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                    <div className="dropdown">
                      <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Hey {this.state.name} <i className="fal fa-angle-down"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link className="dropdown-item" to="#" onClick={this.handleSubmit}><img src={process.env.PUBLIC_URL + '/assets/images/logout.png'} alt="" /> Logout</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div className="modal feedbackModal fade modal-center" id="myfeedbackModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
     
          <div className="modal-content">

          {/* <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div> */}
        <form useref="form" onSubmit={this.feedbackSubmit}>
          <div className="modal-body">
              <img src={process.env.PUBLIC_URL + '/assets/images/modalImg.png'} alt=""/>
              <h3>FEEDBACK</h3>
              <div className="form-group">
              <input type="text" className="form-control" placeholder="Title" name="title" onChange={this.handleChange} required/>
              </div>
              <div className="form-group">
              <textarea className="form-control" rows="3" placeholder="Message" name="message" onChange={this.handleChange} required></textarea>
              </div>
          </div>
          <div className="modal-footer">
          <button type="submit" className="btn">Submit</button>
              <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>        
          </div>
        </form>
          </div>
         
      </div>
    </div>

    
      </div>

      
    )
  }
}
export default Header;