import React from "react";
import Header from "../components/header";
class Feedback extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                  {/* Feedback-Modal  */}
                <div className="modal feedbackModal fade modal-center" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
              
                    {/* <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
              
                    <div className="modal-body">
                        <img src="images/modalImg.png" alt=""/>
                        <h3>FEEDBACK</h3>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Title"/>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" rows="3" placeholder="Message"></textarea>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn">Submit</button>
                        <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>        
                    </div>
                    </div>
                </div>
                </div>
                
            </div>
        )
    }
}
export default Feedback;