import React from "react";
import NewHeader from "../components/newheader";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
var sha1 = require('sha1');

const regexAadhar = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
const regex_password_complex=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
const validEmailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      AuthToken: "",
      name: "",
      dob: "",
      email: "",
      email_fetch:"",
      gender: "",
      country: "",
      countryCode: "",
      phone: "",
      userType: "",
      aadhaarNo: "",
      institution: "",
      experience_years: "",
      experience_months: "",
      flat_room: "",
      building_house: "",
      streetName: "",
      locality: "",
      city_town_village: "",
      imgPreview: process.env.PUBLIC_URL + '/assets/images/upload.png',
      state: "",
      rank: "",
      xp: "",
      profilePic: "",
      imgSrc: "",
      oldPassword: "",
      newPassword: "",
      max_exp_years:60,
      years_array:[],
      month_details: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
      ],
      country_details:
        [
          {
            name: "India",
            code: "IN",
            dial_code: "+91"
          },
          {
            name: "Afghanistan",
            code: "AF",
            dial_code: "+93"
          },
          {
            name: "Åland",
            code: "AX",
            dial_code: "+358"
          },
          {
            name: "Shqipëria",
            code: "AL",
            dial_code: "+355"
          },
          {
            name: "Algeria",
            code: "DZ",
            dial_code: "+213"
          },
          {
            name: "American Samoa",
            code: "AS",
            dial_code: "+1684"
          },
          {
            name: "Andorra",
            code: "AD",
            dial_code: "+376"
          },
          {
            name: "Angola",
            code: "AO",
            dial_code: "+244"
          },
          {
            name: "Anguilla",
            code: "AI",
            dial_code: "+1264"
          },
          {
            name: "Antarctica",
            code: "AQ",
            dial_code: "+672"
          },
          {
            name: "Antigua and Barbuda",
            code: "AG",
            dial_code: "+1268"
          },
          {
            name: "Argentina",
            code: "AR",
            dial_code: "+54"
          },
          {
            name: "Armenia",
            code: "AM",
            dial_code: "+374"
          },
          {
            name: "Aruba",
            code: "AW",
            dial_code: "+297"
          },
          {
            name: "Australia",
            code: "AU",
            dial_code: "+61"
          },
          {
            name: "Österreich",
            code: "AT",
            dial_code: "+43"
          },
          {
            name: "Azerbaijan",
            code: "AZ",
            dial_code: "+994"
          },
          {
            name: "Bahamas",
            code: "BS",
            dial_code: "+1242"
          },
          {
            name: "Bahrain",
            code: "BH",
            dial_code: "+973"
          },
          {
            name: "Bangladesh",
            code: "BD",
            dial_code: "+880"
          },
          {
            name: "Barbados",
            code: "BB",
            dial_code: "+1246"
          },
          {
            name: "Belarus",
            code: "BY",
            dial_code: "+375"
          },
          {
            name: "België",
            code: "BE",
            dial_code: "+32"
          },
          {
            name: "Belize",
            code: "BZ",
            dial_code: "+501"
          },
          {
            name: "Benign",
            code: "BJ",
            dial_code: "+229"
          },
          {
            name: "Bermuda",
            code: "BM",
            dial_code: "+1441"
          },
          {
            name: "ʼbrug-yul",
            code: "BT",
            dial_code: "+975"
          },
          {
            name: "Bolivia",
            code: "BO",
            dial_code: "+591"
          },
          {
            name: "Bosna i Hercegovina",
            code: "BA",
            dial_code: "+387"
          },
          {
            name: "Botswana",
            code: "BW",
            dial_code: "+267"
          },
          {
            name: "Bouvetøya",
            code: "BV",
            dial_code: "+47"
          },
          {
            name: "Brasil",
            code: "BR",
            dial_code: "+55"
          },
          {
            name: "British Indian Ocean Territory",
            code: "IO",
            dial_code: "+246"
          },
          {
            name: "Negara Brunei Darussalam",
            code: "BN",
            dial_code: "+673"
          },
          {
            name: "Bulgaria",
            code: "BG",
            dial_code: "+359"
          },
          {
            name: "Burkina Faso",
            code: "BF",
            dial_code: "+226"
          },
          {
            name: "Burundi",
            code: "BI",
            dial_code: "+257"
          },
          {
            name: "Cambodia",
            code: "KH",
            dial_code: "+855"
          },
          {
            name: "Cameroon",
            code: "CM",
            dial_code: "+237"
          },
          {
            name: "Canada",
            code: "CA",
            dial_code: "+1"
          },
          {
            name: "Cabo Verde",
            code: "CV",
            dial_code: "+238"
          },
          {
            name: "Cayman Islands",
            code: "KY",
            dial_code: "+1345"
          },
          {
            name: "Ködörösêse tî Bêafrîka",
            code: "CF",
            dial_code: "+236"
          },
          {
            name: "Tchad",
            code: "TD",
            dial_code: "+235"
          },
          {
            name: "Chile",
            code: "CL",
            dial_code: "+56"
          },
          {
            name: "China",
            code: "CN",
            dial_code: "+86"
          },
          {
            name: "Christmas Island",
            code: "CX",
            dial_code: "+61"
          },
          {
            name: "Cocos (Keeling) Islands",
            code: "CC",
            dial_code: "+61"
          },
          {
            name: "Colombia",
            code: "CO",
            dial_code: "+57"
          },
          {
            name: "Komori",
            code: "KM",
            dial_code: "+269"
          },
          {
            name: "Republic of Congo",
            code: "CG",
            dial_code: "+242"
          },
          {
            name: "Democratic Republic of Congo",
            code: "CD",
            dial_code: "+243"
          },
          {
            name: "Cook Islands",
            code: "CK",
            dial_code: "+682"
          },
          {
            name: "Costa Rica",
            code: "CR",
            dial_code: "+506"
          },
          {
            name: "Ivory Coast",
            code: "CI",
            dial_code: "+225"
          },
          {
            name: "Hrvatska",
            code: "HR",
            dial_code: "+385"
          },
          {
            name: "Cuba",
            code: "CU",
            dial_code: "+53"
          },
          {
            name: "Cyprus",
            code: "CY",
            dial_code: "+357"
          },
          {
            name: "Czech Republic",
            code: "CZ",
            dial_code: "+420"
          },
          {
            name: "Danmark",
            code: "DK",
            dial_code: "+45"
          },
          {
            name: "Djibouti",
            code: "DJ",
            dial_code: "+253"
          },
          {
            name: "Dominica",
            code: "DM",
            dial_code: "+1767"
          },
          {
            name: "República Dominicana",
            code: "DO",
            dial_code: "+1"
          },
          {
            name: "Ecuador",
            code: "EC",
            dial_code: "+593"
          },
          {
            name: "Egypt",
            code: "EG",
            dial_code: "+20"
          },
          {
            name: "El Salvador",
            code: "SV",
            dial_code: "+503"
          },
          {
            name: "Guinea Ecuatorial",
            code: "GQ",
            dial_code: "+240"
          },
          {
            name: "Eritrea",
            code: "ER",
            dial_code: "+291"
          },
          {
            name: "Eesti",
            code: "EE",
            dial_code: "+372"
          },
          {
            name: "Ethiopia",
            code: "ET",
            dial_code: "+251"
          },
          {
            name: "Falkland Islands",
            code: "FK",
            dial_code: "+500"
          },
          {
            name: "Faroe Islands",
            code: "FO",
            dial_code: "+298"
          },
          {
            name: "Fiji",
            code: "FJ",
            dial_code: "+679"
          },
          {
            name: "Suomi",
            code: "FI",
            dial_code: "+358"
          },
          {
            name: "France",
            code: "FR",
            dial_code: "+33"
          },
          {
            name: "Guyane française",
            code: "GF",
            dial_code: "+594"
          },
          {
            name: "Polynésie française",
            code: "PF",
            dial_code: "+689"
          },
          {
            name: "Territory of the Southern and Antarctic Lands fr",
            code: "TF",
            dial_code: "+262"
          },
          {
            name: "Gabon",
            code: "GA",
            dial_code: "+241"
          },
          {
            name: "Gambia",
            code: "GM",
            dial_code: "+220"
          },
          {
            name: "Georgia",
            code: "GE",
            dial_code: "+995"
          },
          {
            name: "Deutschland",
            code: "DE",
            dial_code: "+49"
          },
          {
            name: "Ghana",
            code: "GH",
            dial_code: "+233"
          },
          {
            name: "Gibraltar",
            code: "GI",
            dial_code: "+350"
          },
          {
            name: "Hellas",
            code: "GR",
            dial_code: "+30"
          },
          {
            name: "Kalaallit Nunaat",
            code: "GL",
            dial_code: "+299"
          },
          {
            name: "Grenada",
            code: "GD",
            dial_code: "+1473"
          },
          {
            name: "Guadeloupe",
            code: "GP",
            dial_code: "+590"
          },
          {
            name: "Guam",
            code: "GU",
            dial_code: "+1671"
          },
          {
            name: "Guatemala",
            code: "GT",
            dial_code: "+502"
          },
          {
            name: "Guernsey",
            code: "GG",
            dial_code: "+44"
          },
          {
            name: "Guinée",
            code: "GN",
            dial_code: "+224"
          },
          {
            name: "Guiné-Bissau",
            code: "GW",
            dial_code: "+245"
          },
          {
            name: "Guyana",
            code: "GY",
            dial_code: "+592"
          },
          {
            name: "Haïti",
            code: "HT",
            dial_code: "+509"
          },
          {
            name: "Heard Island and McDonald Islands",
            code: "HM",
            dial_code: "+0"
          },
          {
            name: "Vaticano",
            code: "VA",
            dial_code: "+379"
          },
          {
            name: "Honduras",
            code: "HN",
            dial_code: "+504"
          },
          {
            name: "Hongkong",
            code: "HK",
            dial_code: "+852"
          },
          {
            name: "Hungary",
            code: "HU",
            dial_code: "+36"
          },
          {
            name: "Ísland",
            code: "IS",
            dial_code: "+354"
          },

          {
            name: "Indonesia",
            code: "ID",
            dial_code: "+62"
          },
          {
            name: "Iran",
            code: "IR",
            dial_code: "+98"
          },
          {
            name: "Iraq",
            code: "IQ",
            dial_code: "+964"
          },
          {
            name: "Ireland",
            code: "IE",
            dial_code: "+353"
          },
          {
            name: "Isle of Man",
            code: "IM",
            dial_code: "+44"
          },
          {
            name: "Israel",
            code: "IL",
            dial_code: "+972"
          },
          {
            name: "Italia",
            code: "IT",
            dial_code: "+39"
          },
          {
            name: "Jamaica",
            code: "JM",
            dial_code: "+1876"
          },
          {
            name: "Japan",
            code: "JP",
            dial_code: "+81"
          },
          {
            name: "Jersey",
            code: "JE",
            dial_code: "+44"
          },
          {
            name: "Jordan",
            code: "JO",
            dial_code: "+962"
          },
          {
            name: "Kazakhstan",
            code: "KZ",
            dial_code: "+7"
          },
          {
            name: "Kenya",
            code: "KE",
            dial_code: "+254"
          },
          {
            name: "Kiribati",
            code: "KI",
            dial_code: "+686"
          },
          {
            name: "North Korea",
            code: "KP",
            dial_code: "+850"
          },
          {
            name: "Korea",
            code: "KR",
            dial_code: "+82"
          },
          {
            name: "The Republic of Kosovo",
            code: "XK",
            dial_code: "+383"
          },
          {
            name: "Kuwait",
            code: "KW",
            dial_code: "+965"
          },
          {
            name: "Kyrgyzstan",
            code: "KG",
            dial_code: "+996"
          },
          {
            name: "Lao PDR",
            code: "LA",
            dial_code: "+856"
          },
          {
            name: "Latvija",
            code: "LV",
            dial_code: "+371"
          },
          {
            name: "Lebanon",
            code: "LB",
            dial_code: "+961"
          },
          {
            name: "Lesotho",
            code: "LS",
            dial_code: "+266"
          },
          {
            name: "Liberia",
            code: "LR",
            dial_code: "+231"
          },
          {
            name: "Libya",
            code: "LY",
            dial_code: "+218"
          },
          {
            name: "Liechtenstein",
            code: "LI",
            dial_code: "+423"
          },
          {
            name: "Lietuva",
            code: "LT",
            dial_code: "+370"
          },
          {
            name: "Luxembourg",
            code: "LU",
            dial_code: "+352"
          },
          {
            name: "Macao",
            code: "MO",
            dial_code: "+853"
          },
          {
            name: "Macedonia",
            code: "MK",
            dial_code: "+389"
          },
          {
            name: "Madagasikara",
            code: "MG",
            dial_code: "+261"
          },
          {
            name: "Malawi",
            code: "MW",
            dial_code: "+265"
          },
          {
            name: "Malaysia",
            code: "MY",
            dial_code: "+60"
          },
          {
            name: "Maldives",
            code: "MV",
            dial_code: "+960"
          },
          {
            name: "Mali",
            code: "ML",
            dial_code: "+223"
          },
          {
            name: "Malta",
            code: "MT",
            dial_code: "+356"
          },
          {
            name: "M̧ajeļ",
            code: "MH",
            dial_code: "+692"
          },
          {
            name: "Martinique",
            code: "MQ",
            dial_code: "+596"
          },
          {
            name: "Mauritania",
            code: "MR",
            dial_code: "+222"
          },
          {
            name: "Maurice",
            code: "MU",
            dial_code: "+230"
          },
          {
            name: "Mayotte",
            code: "YT",
            dial_code: "+262"
          },
          {
            name: "México",
            code: "MX",
            dial_code: "+52"
          },
          {
            name: "Micronesia",
            code: "FM",
            dial_code: "+691"
          },
          {
            name: "Moldova",
            code: "MD",
            dial_code: "+373"
          },
          {
            name: "Monaco",
            code: "MC",
            dial_code: "+377"
          },
          {
            name: "Mongolia",
            code: "MN",
            dial_code: "+976"
          },
          {
            name: "Montenegro",
            code: "ME",
            dial_code: "+382"
          },
          {
            name: "Montserrat",
            code: "MS",
            dial_code: "+1664"
          },
          {
            name: "Morocco",
            code: "MA",
            dial_code: "+212"
          },
          {
            name: "Moçambique",
            code: "MZ",
            dial_code: "+258"
          },
          {
            name: "Myanmar",
            code: "MM",
            dial_code: "+95"
          },
          {
            name: "Namibia",
            code: "NA",
            dial_code: "+264"
          },
          {
            name: "Nauru",
            code: "NR",
            dial_code: "+674"
          },
          {
            name: "Nepal",
            code: "NP",
            dial_code: "+977"
          },
          {
            name: "Nederland",
            code: "NL",
            dial_code: "+31"
          },
          {
            name: "Netherlands Antilles",
            code: "AN",
            dial_code: "+599"
          },
          {
            name: "Nouvelle-Calédonie",
            code: "NC",
            dial_code: "+687"
          },
          {
            name: "New Zealand",
            code: "NZ",
            dial_code: "+64"
          },
          {
            name: "Nicaragua",
            code: "NI",
            dial_code: "+505"
          },
          {
            name: "Niger",
            code: "NE",
            dial_code: "+227"
          },
          {
            name: "Nigeria",
            code: "NG",
            dial_code: "+234"
          },
          {
            name: "Niuē",
            code: "NU",
            dial_code: "+683"
          },
          {
            name: "Norfolk Island",
            code: "NF",
            dial_code: "+672"
          },
          {
            name: "Northern Mariana Islands",
            code: "MP",
            dial_code: "+1670"
          },
          {
            name: "Norge",
            code: "NO",
            dial_code: "+47"
          },
          {
            name: "Oman",
            code: "OM",
            dial_code: "+968"
          },
          {
            name: "Pakistan",
            code: "PK",
            dial_code: "+92"
          },
          {
            name: "Palau",
            code: "PW",
            dial_code: "+680"
          },
          {
            name: "Palestine",
            code: "PS",
            dial_code: "+970"
          },
          {
            name: "Panamá",
            code: "PA",
            dial_code: "+507"
          },
          {
            name: "Papua Niugini",
            code: "PG",
            dial_code: "+675"
          },
          {
            name: "Paraguay",
            code: "PY",
            dial_code: "+595"
          },
          {
            name: "Perú",
            code: "PE",
            dial_code: "+51"
          },
          {
            name: "Pilipinas",
            code: "PH",
            dial_code: "+63"
          },
          {
            name: "Pitcairn Islands",
            code: "PN",
            dial_code: "+64"
          },
          {
            name: "Polska",
            code: "PL",
            dial_code: "+48"
          },
          {
            name: "Portugal",
            code: "PT",
            dial_code: "+351"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1939"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1787"
          },
          {
            name: "Diameter",
            code: "QA",
            dial_code: "+974"
          },
          {
            name: "România",
            code: "RO",
            dial_code: "+40"
          },
          {
            name: "Russia",
            code: "RU",
            dial_code: "+7"
          },
          {
            name: "Rwanda",
            code: "RW",
            dial_code: "+250"
          },
          {
            name: "La Réunion",
            code: "RE",
            dial_code: "+262"
          },
          {
            name: "Saint-Barthélemy",
            code: "BL",
            dial_code: "+590"
          },
          {
            name: "Saint Helena",
            code: "SH",
            dial_code: "+290"
          },
          {
            name: "Saint Kitts and Nevis",
            code: "KN",
            dial_code: "+1869"
          },
          {
            name: "Saint Lucia",
            code: "LC",
            dial_code: "+1758"
          },
          {
            name: "Saint-Martin",
            code: "MF",
            dial_code: "+590"
          },
          {
            name: "Saint-Pierre-et-Miquelon",
            code: "PM",
            dial_code: "+508"
          },
          {
            name: "Saint Vincent and the Grenadines",
            code: "VC",
            dial_code: "+1784"
          },
          {
            name: "Samoa",
            code: "WS",
            dial_code: "+685"
          },
          {
            name: "San Marino",
            code: "SM",
            dial_code: "+378"
          },
          {
            name: "São Tomé e Príncipe",
            code: "ST",
            dial_code: "+239"
          },
          {
            name: "Saudi Arab",
            code: "SA",
            dial_code: "+966"
          },
          {
            name: "Sénégal",
            code: "SN",
            dial_code: "+221"
          },
          {
            name: "Serbia",
            code: "RS",
            dial_code: "+381"
          },
          {
            name: "Seychelles",
            code: "SC",
            dial_code: "+248"
          },
          {
            name: "Sierra Leone",
            code: "SL",
            dial_code: "+232"
          },
          {
            name: "Singapore",
            code: "SG",
            dial_code: "+65"
          },
          {
            name: "Slovensko",
            code: "SK",
            dial_code: "+421"
          },
          {
            name: "Slovenija",
            code: "SI",
            dial_code: "+386"
          },
          {
            name: "Solomon Islands",
            code: "SB",
            dial_code: "+677"
          },
          {
            name: "Soomaaliya",
            code: "SO",
            dial_code: "+252"
          },
          {
            name: "South Africa",
            code: "ZA",
            dial_code: "+27"
          },
          {
            name: "South Sudan",
            code: "SS",
            dial_code: "+211"
          },
          {
            name: "South Georgia",
            code: "GS",
            dial_code: "+500"
          },
          {
            name: "Spain",
            code: "ES",
            dial_code: "+34"
          },
          {
            name: "Sri Lanka",
            code: "LK",
            dial_code: "+94"
          },
          {
            name: "Sudan",
            code: "SD",
            dial_code: "+249"
          },
          {
            name: "Suriname",
            code: "SR",
            dial_code: "+597"
          },
          {
            name: "Svalbard og Jan Mayen",
            code: "SJ",
            dial_code: "+47"
          },
          {
            name: "Swaziland",
            code: "SZ",
            dial_code: "+268"
          },
          {
            name: "Sverige",
            code: "SE",
            dial_code: "+46"
          },
          {
            name: "Schweiz",
            code: "CH",
            dial_code: "+41"
          },
          {
            name: "Syrian Arab Republic",
            code: "SY",
            dial_code: "+963"
          },
          {
            name: "Taiwan",
            code: "TW",
            dial_code: "+886"
          },
          {
            name: "Tajikistan",
            code: "TJ",
            dial_code: "+992"
          },
          {
            name: "Tanzania",
            code: "TZ",
            dial_code: "+255"
          },
          {
            name: "Thailand",
            code: "TH",
            dial_code: "+66"
          },
          {
            name: "Timor-Leste",
            code: "TL",
            dial_code: "+670"
          },
          {
            name: "Togo",
            code: "TG",
            dial_code: "+228"
          },
          {
            name: "Tokelau",
            code: "TK",
            dial_code: "+690"
          },
          {
            name: "Tonga",
            code: "TO",
            dial_code: "+676"
          },
          {
            name: "Trinidad and Tobago",
            code: "TT",
            dial_code: "+1868"
          },
          {
            name: "Tunisia",
            code: "TN",
            dial_code: "+216"
          },
          {
            name: "Turkey",
            code: "TR",
            dial_code: "+90"
          },
          {
            name: "Türkmenistan",
            code: "TM",
            dial_code: "+993"
          },
          {
            name: "Turks and Caicos Islands",
            code: "TC",
            dial_code: "+1649"
          },
          {
            name: "Tuvalu",
            code: "TV",
            dial_code: "+688"
          },
          {
            name: "Uganda",
            code: "UG",
            dial_code: "+256"
          },
          {
            name: "Ukraine",
            code: "UA",
            dial_code: "+380"
          },
          {
            name: "United Arab Emirates",
            code: "AE",
            dial_code: "+971"
          },
          {
            name: "United Kingdom",
            code: "GB",
            dial_code: "+44"
          },
          {
            name: "United States",
            code: "US",
            dial_code: "+1"
          },
          {
            name: "Uruguay",
            code: "UY",
            dial_code: "+598"
          },
          {
            name: "Uzbekistan",
            code: "UZ",
            dial_code: "+998"
          },
          {
            name: "Vanuatu",
            code: "VU",
            dial_code: "+678"
          },
          {
            name: "Venezuela",
            code: "VE",
            dial_code: "+58"
          },
          {
            name: "Vietnam",
            code: "VN",
            dial_code: "+84"
          },
          {
            name: "British Virgin Islands",
            code: "VG",
            dial_code: "+1284"
          },
          {
            name: "United States Virgin Islands",
            code: "VI",
            dial_code: "+1340"
          },
          {
            name: "Wallis et Futuna",
            code: "WF",
            dial_code: "+681"
          },
          {
            name: "Yemen",
            code: "YE",
            dial_code: "+967"
          },
          {
            name: "Zambia",
            code: "ZM",
            dial_code: "+260"
          },
          {
            name: "Zimbabwe",
            code: "ZW",
            dial_code: "+263"
          }
        ],
      errors: {
        newPassword: "",
        confirmPassword: "",
        aadhaarNo: "",
        experience_years: "",
        password:"",
        email:""
      },

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);

  }

  async componentDidMount() {
    // Get userdetails from localstorage
    const userId = await localStorage.getItem('SOB_userId')
    const AuthToken = await localStorage.getItem('SOB_authToken')

    //if(userId && userId.hasOwnProperty('userId')){
    this.setState({
      userId: userId,
      AuthToken: AuthToken
    })
    // }
    if (this.state.userId === null) {
      this.props.history.push("/");
    }
    this.getProfile();
    this.generateDropdown();


  }

  handleChange(event) {
    //console.log(event.target.files[0]);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case "confirmPassword":
        // errors.password = value;
        errors.confirmPassword =
          value !== this.state.newPassword ? "Passwords do not match" : "";
        break;
      case "newPassword":
            errors.password = regex_password_complex.test(value) ? "" : "should contain 8 characters,at least one digit,one lower case,one upper case !";
            break;
      case 'aadhaarNo':
        errors.aadhaarNo =
          value.length === 12 && regexAadhar.test(value)
            ? '' : 'Invalid Aadhar Number!';
        break;
        case "email":
          errors.email = validEmailRegex.test(value) || value==="" ? "" : "Email is not valid!";
          break;
      case 'experience_years':
        errors.experience_years =
          value !== 0
            ? '' : 'Experience in years must not be zero!';
        break;
      default:
        break;
    }

    if (event.target.files && event.target.files[0]) {
      this.setState({
        [name]: event.target.files[0]
      })
      //updating image preview                        
      this.setState({
        imgPreview: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({
        errors,
        [name]: value
      });
    }
  }

  changePasswordSubmit(event) {
    //console.log(this.state);
    this.changePasssword(sha1(this.state.oldPassword), sha1(this.state.newPassword));
    event.preventDefault();
  }

  changePasssword(...params) {
    axios.post(path, {
      oldPassword: params[0],
      newPassword: params[1],

    }, {
      headers: { 'Api-Name': 'changePasssword', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
    }).then(serverResponse => {
      const res = serverResponse.data;
      //console.log(res);
      if (res.status === true) {
        document.getElementById("myModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
          .forEach(el => el.classList.remove("modal-backdrop"));
        //console.log(res);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // this.props.history.push("/");
        // localStorage.removeItem("SOB_userId");
        // localStorage.removeItem("SOB_authToken");
        // localStorage.removeItem("SOB_refreshToken");

        // toast.error("Some Error Occured", {
        //     position: toast.POSITION.BOTTOM_RIGHT
        // });
      }
    });
  }

  generateDropdown(){
    let elements=[]
    for(var i=0;i<this.state.max_exp_years;i++){
      // push the component to elements!
     elements.push(i);
     
    }
    //console.log(elements);

    this.setState({
      years_array: elements,
    })
  }


  handleSubmit(event) {
    console.log(this.state);
    this.updateProfile(this.state.imgSrc, this.state.phone, this.state.country, this.state.countryCode,
      this.state.name, this.state.userType, this.state.gender, this.state.dob, this.state.aadhaarNo,
      this.state.institution, this.state.experience_years, this.state.experience_months, this.state.flat_room,
      this.state.building_house, this.state.streetName, this.state.locality, this.state.city_town_village, this.state.state);
    event.preventDefault();
  }

  updateProfile(...details) {
    //console.log('console'+this.state.imgSrc);
    var current_image;
    if (this.state.imgSrc === "") {
      current_image = this.state.profilePic;
    } else {
      current_image = "";
    }
    //console.log('current_image'+current_image);
    let formData = new FormData();
    formData.append('profilePic', details[0]);   //append the values with key, value pair
    formData.append('phone', details[1]);
    formData.append('country', details[2]);
    formData.append('countryCode', details[3]);
    formData.append('name', details[4]);
    formData.append('userType', details[5]);
    formData.append('gender', details[6]);
    formData.append('dob', details[7]);
    formData.append('aadhaarNo', details[8]);
    formData.append('institution', details[9]);
    let exp = {
      years: details[10],
      months: details[11]
    };
    let adrs = {
      flat_room: details[12],
      building_house: details[13],
      streetName: details[14],
      locality: details[15],
      city_town_village: details[16],
      state: details[17]
    };

    formData.append('experience', JSON.stringify(exp));
    formData.append('address', JSON.stringify(adrs));
    if (details[1] !== "") {
      axios
        .post(path, formData, {
          headers: { 'Api-Name': 'updateProfile', 'Content-Type': 'multipart/form-data', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken, 'previousImage': current_image }
        })
        .then(serverResponse => {
          const res = serverResponse.data;
          //console.log(res);
          if (!res.isError) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
            this.props.history.push("/profile");
          } else {
          }
        });

    } else {
      toast.warn("Please provide some name !", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  getProfile() {
    axios.post(path, {

    }, {
      headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
    }).then(serverResponse => {
      const res = serverResponse.data;
      if (res.status === true) {
        //console.log(res);
        const ProfileDetails = res.response;

        // const address_array = Object.keys(res.response.address).length === 0 ? "":JSON.parse(res.response.address);
        // const experience_array =Object.keys(res.response.experience).length === 0 ?"":JSON.parse(res.response.experience);

        const address_array = res.response.address;
        const experience_array =res.response.experience;

        //Age calculate from dob
        // var today = new Date();
        // var birthDate = new Date(ProfileDetails.dob);  // create a date object directly from `dob1` argument
        // var age_now = today.getFullYear() - birthDate.getFullYear();
        // var m = today.getMonth() - birthDate.getMonth();
        // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //     age_now--;
        // }
        //console.log('age'+age_now);

        this.setState({
          name: ProfileDetails.name,
          dob: ProfileDetails.dob,
          //age: age_now,
          email_fetch: ProfileDetails.email,
          gender: ProfileDetails.gender,
          countryCode: ProfileDetails.countryCode,
          country: ProfileDetails.country,
          phone: ProfileDetails.phone,
          userType: ProfileDetails.userType,
          institution: ProfileDetails.institution,
          experience_years: experience_array!==""?experience_array.years:"",
          experience_months: experience_array!==""?experience_array.months:"",
          flat_room: address_array!==""? address_array.flat_room:"",
          building_house: address_array!==""?address_array.building_house:"",
          streetName: address_array!==""?address_array.streetName:"",
          locality: address_array!==""?address_array.locality:"",
          city_town_village:address_array!==""? address_array.city_town_village:"",
          state: address_array!==""?address_array.state:"",
          rank: ProfileDetails.rank,
          xp: ProfileDetails.xp,
          profilePic: ProfileDetails.profilePic + "?",
          aadhaarNo: ProfileDetails.aadhaarNo
        });

        if (this.state.profilePic !== "") {
          this.setState({
            imgPreview: this.state.profilePic,
          })
        } else {
          this.setState({
            imgPreview: process.env.PUBLIC_URL + '/assets/images/upload.png',
          })
        }
      }
      else {
        // this.props.history.push("/");
        // localStorage.removeItem("SOB_userId");
        // localStorage.removeItem("SOB_authToken");
        // localStorage.removeItem("SOB_refreshToken");

        // toast.error("Some Error Occured", {
        //     position: toast.POSITION.BOTTOM_RIGHT
        // });
      }
    });
  }
  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    });
    const { errors } = this.state;
    return (
      <div>
        <NewHeader {...this.props} />
        <section className="contentTop">&nbsp;</section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="commonContent">
                  <div className="profileBg"><img src={this.state.profilePic} alt="" /></div>
                  <h2 className="mainHdn">ALL TIME SCORE</h2>
                  <div className="resCarousel" data-items="1-2-3-3" data-slide="3" data-speed="900" data-animator="lazy">
                    <div className="resCarousel-inner">

                      <div className="item">
                        <div className="tile active">
                          <img src={process.env.PUBLIC_URL + '/assets/images/score.png'} alt="" />
                        </div>
                      </div>

                      <div className="item">
                        <div className="tile">
                          <div className="titleScore"><img src={process.env.PUBLIC_URL + '/assets/images/numbr.png'} alt="" /> Score</div>
                          <h2>{this.state.xp}</h2>
                        </div>
                      </div>

                      <div className="item">
                        <div className="tile">
                          <div className="titleScore"><img src={process.env.PUBLIC_URL + '/assets/images/rank.png'} alt="" /> Rank</div>
                          <h2>{this.state.rank}</h2>
                        </div>
                      </div>

                    </div>
                    <button className='btn btn-default leftRs'>&larr;</button>
                    <button className='btn btn-default rightRs'>&rarr;</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="contentB">
                  <h2 className="mainHdn">Profile</h2>
                  <div className="profileDetail editProfileTable">
                    <form>
                      <div className="ya4Browse" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/linear.png'})` }}>
                        <div className="box">
                          <div className="browseBx">
                            <div className="js--image-preview"
                              style={{ backgroundImage: `url(${this.state.imgPreview})` }}></div>
                            <h4>Upload Image</h4>
                          </div>
                          <div className="uploadBtn">
                            <label>
                              <input type="file" className="image-upload" name="imgSrc" onChange={this.handleChange} />
                            </label>


                          </div>
                        </div>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th>Name:</th>
                            <td><input type="text" name="name" className="form-control" value={this.state.name} readOnly /></td>
                          </tr>
                          <tr>
                            <th>DOB:</th>
                            <td><input type="text" name="dob" className="form-control" value={this.state.dob} readOnly /></td>
                          </tr>
                          <tr>
                            <th>Gender:</th>
                            <td><input type="text" name="gender" className="form-control" value={this.state.gender} readOnly /></td>
                          </tr>
                          <tr>
                            <th>Aadhar Number :</th>
                            <td>
                            {this.state.aadhaarNo===""?
                              <input type="number" name="aadhaarNo" className="form-control" placeholder="Enter Aadhar Number" value={this.state.aadhaarNo} onChange={this.handleChange} />
                             
                              :
                              <input type="text" name="aadhaarNo" className="form-control" value={this.state.aadhaarNo} readOnly />
                              }
                               <span style={{ color: "red" }}>{errors.aadhaarNo}</span>
                              </td>

                          </tr>


                          <tr>
                            <th>Address:</th>
                            <td>
                              <input type="text" name="flat_room" placeholder="Enter Flat Number" className="form-control" value={this.state.flat_room} onChange={this.handleChange} />
                            </td>
                          </tr>

                          <tr>
                            <th></th>
                            <td>
                              <input type="text" name="building_house" placeholder="Enter Building Name" className="form-control" value={this.state.building_house} onChange={this.handleChange} />
                            </td>
                          </tr>

                          <tr>
                            <th></th>
                            <td>
                              <input type="text" name="streetName" placeholder="Enter Street Name" className="form-control" value={this.state.streetName} onChange={this.handleChange} />
                            </td>
                          </tr>

                          <tr>
                            <th></th>
                            <td>
                              <input type="text" name="locality" placeholder="Enter Locality" className="form-control" value={this.state.locality} onChange={this.handleChange} />
                            </td>
                          </tr>
                          <tr>
                            <th></th>
                            <td>
                              <input type="text" name="city_town_village" placeholder="Enter City" className="form-control" value={this.state.city_town_village} onChange={this.handleChange} />
                            </td>
                          </tr>

                          <tr>
                            <th></th>
                            <td>
                              <input type="text" name="state" placeholder="Enter State" className="form-control" value={this.state.state} onChange={this.handleChange} />
                            </td>
                          </tr>

                          <tr>
                            <th>Country:</th>
                            <td>

                              <select name="country" onChange={this.handleChange} className="form-control" value={this.state.country} readonly disabled>
                                <option value="0" className="dropdown-item active">Select Country</option>
                                {this.state.country_details.map((eachcountryDetails, i) => (
                                  <option value={eachcountryDetails.code} key={i} className="dropdown-item">{eachcountryDetails.name}</option>

                                ))}
                              </select>
                            </td>
                          </tr>



                          <tr>
                            <th>Mobile:</th>
                            <td><input type="text" name="mobile" className="form-control" value={this.state.countryCode+" "+this.state.phone} readOnly /></td>
                          </tr>
                          <tr>
                            <th>Email:</th>
                            <td>
                            {this.state.email_fetch===""?
                             <span style={{fontStyle:"italic",color:"#fc6161"}}>Email not available</span>
                            //  <input type="text" name="email" className="form-control" placeholder="Enter Email ID" value={this.state.email} onChange={this.handleChange} />
                             
                              :
                              <input type="text" name="email" className="form-control" value={this.state.email_fetch} readOnly />
                             
                              }
                               {/* <span style={{ color: "red" }}>{errors.email}</span> */}
                              </td>
                          </tr>
                          <tr>
                            <th>User Category:</th>
                            <td><input type="text" name="userType" className="form-control" value={this.state.userType} readOnly /></td>
                          </tr>
                          <tr>
                            <th>Institutions associated with :</th>
                            <td><input type="text" name="institution" className="form-control" placeholder="Enter Institution Name" value={this.state.institution} onChange={this.handleChange} /></td>
                          </tr>
                          <tr>
                            <th>Experience in relevant field :</th>
                            <td>


                              <tr>
                                <td>
                                <select name="experience_years" onChange={this.handleChange} className="form-control" value={this.state.experience_years}>
                                  <option value="0" className="dropdown-item active">Select Year</option>
                                  {this.state.years_array.map((eachyear, i) => (
                                    
                                    <option value={eachyear} key={i} className="dropdown-item">{eachyear} Year(s)</option>

                                  ))}
                                </select>
                                 
                                </td>
                                <td> 
                                  <select name="experience_months" onChange={this.handleChange} className="form-control" value={this.state.experience_months}>
                                  <option value="0" className="dropdown-item active">Select Month</option>
                                  {this.state.month_details.map((eachmonths, i) => (
                                    
                                    <option value={eachmonths.id} key={i} className="dropdown-item">{eachmonths.id} Month(s)</option>

                                  ))}
                                </select> </td>

                              </tr>


                              {/* <input type="text" name="experience_years" min="1" maxLength="2" placeholder="Experience In Years"  className="form-control" value={this.state.experience_years} onChange={this.handleChange}/>
                                                        <select name="experience_months" onChange={this.handleChange}  className="form-control" value={this.state.experience_months}>
                                                                <option value="0" className="dropdown-item active">Select Months</option>
                                                        {this.state.month_details.map((eachmonths, i) => (
                                                                <option value={eachmonths.id}  key={i} className="dropdown-item">{eachmonths.id}</option>
                                                            
                                                                ))}
                                                        </select> */}

                              <span style={{ color: "red" }}>{errors.experience_years}</span></td>
                          </tr>
                        </thead>
                      </table><br /><br />
                      <div className="intBtn"><Link to="#" className="intbtna" onClick={this.handleSubmit}>Update</Link> <Link to="#" className="intbtnb" data-toggle="modal" data-target="#myModal">Change Password</Link></div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* changepassword-Modal  */}
        <div className="modal feedbackModal fade modal-center" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              {/* <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
              <form useref="form" onSubmit={this.changePasswordSubmit}>
                <div className="modal-body">
                  <img src={process.env.PUBLIC_URL + '/assets/images/changepassword.png'} alt="" />
                  <h3>CHANGE PASSWORD</h3>
                  <div className="form-group">
                    <input type="password" className="form-control" name="oldPassword" placeholder="Old Password" onChange={this.handleChange} required />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="newPassword" placeholder="New Password" onChange={this.handleChange} required />
                    <span style={{color:"red"}}>{errors.password}</span>

                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="confirmPassword" placeholder="Confirm New Password" onChange={this.handleChange} required
                    />
                    <span style={{ color: "red" }}>{errors.confirmPassword}</span>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn">Save</button>
                  <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default EditProfile;