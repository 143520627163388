import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Covid_Awarness3 extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            nextRouteLink:""
        }
        this.getNextRoute = this.getNextRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }

    async componentDidMount() {
       
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        //console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
        //console.log(currentPosition)
        let nextPosition = parseInt(currentPosition)+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        //console.log(nextPosition)
        this.saveCurrentPos(nextPosition);
        let elemIdForRoute = routeOrderArray[nextPosition]
        //console.log(elemIdForRoute)
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        //console.log(nextRouteLink)        
        this.setState({
            nextRouteLink:nextRouteLink
        })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
        
    }

    //This function can be called from every sub pages for  next one
    async getNextRoute(){
    
        let nextRouteLink=this.state.nextRouteLink.toString();
        //Navigate to routeLink    
        this.props.history.push({
            pathname: `${nextRouteLink}`           
        })

        //same methodology can be used in case of previous route link (use -1 instead of +1)
    }

    saveCurrentPos(pos){
        // save currentPos variable to localstorage and set it to 0
    localStorage.setItem('currentPos',pos)
    }
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop covidBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Covid Awareness</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">   
                                                                        
                                <div className="covidB">
                                    <h4>How does it spread</h4> 
                                    <p>The virus spreads from one person to others through</p> 
                                    <div className="covidSpreadBgB">
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue1.png'} alt=""/></div>
                                            <span>Wash your hands often with soap and water for at least 20 seconds</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue2.png'} alt=""/></div>
                                            <span>Cover your mouth and nose with a face covering when around others</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue3.png'} alt=""/></div>
                                            <span>Practice social distancing.This means stay 6 feet or(about 2 meters) away from others</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue4.png'} alt=""/></div>
                                            <span>Stay home as much as possible, and definitely when you are sick</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue5.png'} alt=""/></div>
                                            <span>Clean surfaces with disinfectant</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue6.png'} alt=""/></div>
                                            <span>Don’t touch your eyes, nose and mouth with unwashed hands</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadblue7.png'} alt=""/></div>
                                            <span>Cover your nose and mouth  when you sneeze or cough</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                    <Link to="#" onClick={()=>this.getNextRoute()}   className="intbtnb">Next</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Covid_Awarness3;