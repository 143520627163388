import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "../components/pagination";
var path = apiPath.path;
class YA_Program extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_athletes_list: [],
            search: "",
            yaItemId: "",
            view_status: "",
            currentPage: 1,

        };
        this.athleteList = this.athleteList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.viewExercise = this.viewExercise.bind(this);
    }

    async componentDidMount() {
        localStorage.removeItem("yaAthleteId");
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getTrainings();
        this.athleteList(1);

    }

    loadData = (type) => {
        let cp = this.state.currentPage
        if (type === "P") {
            if (cp > 1) {
                cp = cp - 1
            }
        }
        if (type === "N") {
            cp = cp + 1
        }

        this.athleteList(cp);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    athleteList(currentPageNum) {
        axios.post(path, {
            yaAthleteSearch: this.state.search,
            page_no: currentPageNum,
            records: 10
        }, {
            headers: { 'api-name': 'athleteList', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {

                const AthleteDetails = res.response && res.response.athleteList;
                //console.log(AthleteDetails);

                this.setState({
                    ya_athletes_list: AthleteDetails,
                    currentPage: currentPageNum

                });
                //console.log(this.state.ya_athletes_list);
            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
    getTrainings() {
        axios.post(path, {
        }, {
            headers: { 'Api-Name': 'getTrainings', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const TrainingDetails = res.response.traning;
                // console.log(TrainingDetails);
                TrainingDetails.map((eachtraningDetails, i) => {
                    if (eachtraningDetails.traningName === "Young Athletes") {
                        this.setState({
                            training_id: eachtraningDetails._id
                        });
                    }
                })
                this.getYaItemsApp();


            }
        });
    }


    getYaItemsApp() {
        //console.log(this.state.training_id)
        axios.post(path, {
            yaTraningId: this.state.training_id
        }, {
            headers: { 'api-name': 'getYaItemsApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ItemDetails = res.response.items;
                //console.log(ItemDetails);
                ItemDetails.map((eachitemDetails, i) => {
                    if (eachitemDetails.yaItemType === "multi") {
                        //  console.log( eachitemDetails.yaItemId);

                        this.setState({
                            yaItemId: eachitemDetails.yaItemId
                        });
                    }
                })
            }
        });
    }


    viewExercise(yaAthleteId) {
        axios.post(path, {
            yaAthleteId: yaAthleteId,
            yaItemId: this.state.yaItemId,
            yaContentOrder: ''
        }, {
            headers: { 'api-name': 'viewExercise', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            this.setState({
                view_status: res.status
            });
            // console.log('status'+this.state.view_status);
            if (res.status === false) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            else {
                this.props.history.push({
                    pathname: '/ya_view_exercise',
                    state: {
                        yaAthleteId: yaAthleteId
                    }
                })
            }
        });
    }


    deleteAthlete(yaAthleteId) {
        axios.post(path, {
            yaAthleteId: yaAthleteId,
        }, {
            headers: { 'api-name': 'deleteAthlete', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                window.location.reload(false);
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }
    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                <Header {...this.props} />
                <section className="contentTop yaBg_a" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/yabg2.png'})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 introsx">
                                        <h3>Athletes</h3>
                                        <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>

                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent linearbg" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/linear.png'})` }}>
                                    <div className="linerUser">
                                        <div><img src={process.env.PUBLIC_URL + '/assets/images/linuser.png'} alt="" /></div>
                                        <h4>Add Athelete</h4>
                                    </div>
                                    <button><Link to="/ya_form1" style={{ color: "white", textDecoration: "none" }}>Click here</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent autoCompBg">
                                    <div className="autoCompletebar">
                                        {this.state.ya_athletes_list && this.state.ya_athletes_list.length > 0 ?
                                            <div className="card">
                                                <main className="card__content">
                                                    <div className="card__content-search">
                                                        <input type="text" name="search" className="card__content-search__field" onChange={this.handleChange} />
                                                        <Link to="#" onClick={() => this.athleteList()} className="autoserch"><i className="fal fa-search"></i></Link>
                                                    </div>
                                                    <p className="error" hidden>not found</p>
                                                    <ul className="card__content__user-list">

                                                        {this.state.ya_athletes_list.map((eachyaathleteDetails, i) => (
                                                            <li key={i}>
                                                                <div className="y3Lft">
                                                                    <span>{i + 1}.</span>
                                                                    <img src={eachyaathleteDetails.showInList[0].image} alt="" />
                                                                    <div className="card__content__user-list__info">
                                                                        <h2>{eachyaathleteDetails.showInList[0].name}</h2>
                                                                    </div>
                                                                </div>
                                                                <div className="y3Rgt">
                                                                    <div className="intBtnBx"><Link to="#" onClick={() => this.deleteAthlete(eachyaathleteDetails.yaAthleteId)} className="intbtnax">Delete <i className="fal fa-trash-alt"></i></Link>
                                                                        {eachyaathleteDetails.evaluationDone === false ?
                                                                            <Link to={

                                                                                {
                                                                                    pathname: '/ya_form1',
                                                                                    state: {
                                                                                        yaAthleteId: eachyaathleteDetails.yaAthleteId
                                                                                    }
                                                                                }}

                                                                                className="intbtnbx">Resume</Link>
                                                                            :
                                                                            (
                                                                                <Link to="#" onClick={() => this.viewExercise(eachyaathleteDetails.yaAthleteId)} className="intbtnbx">View</Link>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}

                                                    </ul>

                                                </main>
                                            </div>
                                            : null}
                                    </div>
                                    {/* <div className="row" className="paginationright">

                                        <PaginationComponent loadData={this.loadData} pageNumber={this.state.currentPage} />
                                    </div> */}
                                    <div className="intBtnBx"><Link to="/ya_home" className="intbtnax">Back to Home</Link>
                                        {/* <Link to="#" className="intbtnbx">Next</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default YA_Program;