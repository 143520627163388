import React from "react";
import Header from "../components/header";
class Leaderboard_New extends React.Component {
    render() {
        return (
            <div>
                <Header {...this.props} />
                <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fitb.png'} alt="" />
                                        <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
                                    <div className="fitTopImg">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit1.png'} alt="" />
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit2.png'} alt="" />
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit3.png'} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                    <div className="leaderNew">
                                        <div className="leadInn active">
                                            <p>Introduction to FIT 5 </p>
                                            <h3>01.</h3>
                                            <div className="rangeBg">
                                                <div className="rangeLft rcolor_a"><span className="rangewdth_a">&nbsp;</span></div>
                                                <div className="leadIcon"><i className="fas fa-play-circle"></i></div>
                                            </div>
                                        </div>
                                        <div className="leadInn">
                                            <p>Exercises </p>
                                            <h3>02.</h3>
                                            <div className="rangeBg">
                                                <div className="rangeLft rcolor_b"><span className="rangewdth_b">&nbsp;</span></div>
                                                <div className="leadIcon"><i className="fas fa-lock"></i></div>
                                            </div>
                                        </div>
                                        <div className="leadInn">
                                            <p>Nutrition </p>
                                            <h3>03.</h3>
                                            <div className="rangeBg">
                                                <div className="rangeLft rcolor_c"><span className="rangewdth_c">&nbsp;</span></div>
                                                <div className="leadIcon"><i className="fas fa-lock"></i></div>
                                            </div>
                                        </div>
                                        <div className="leadInn">
                                            <p>Hydration </p>
                                            <h3>04.</h3>
                                            <div className="rangeBg">
                                                <div className="rangeLft rcolor_d"><span className="rangewdth_d">&nbsp;</span></div>
                                                <div className="leadIcon"><i className="fas fa-lock"></i></div>
                                            </div>
                                        </div>
                                        <div className="leadInn">
                                            <p>Covid Awareness </p>
                                            <h3>05.</h3>
                                            <div className="rangeBg">
                                                <div className="rangeLft rcolor_e"><span className="rangewdth_e">&nbsp;</span></div>
                                                <div className="leadIcon"><i className="fas fa-lock"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Leaderboard_New;