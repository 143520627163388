import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class YA_Page9 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/dbg.png'})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 yaHdn">
                                    <h3>Pre-Evaluation</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
       
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
            
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="ya4Top ya8Top">
                                    <span className="preAct"></span>
                                    <span className="current"></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                    <span className=""></span>
                                </div>
                                
                                                    
                                    
                                <div className="page4borderbase page7Base page8Bg">
                                    <h5>03. Responds when parent / caregiver is playful</h5>
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio1" value="option1" checked/>
                                            <label className="form-check-label" for="radio1">
                                                Never
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio2" value="option1"/>
                                            <label className="form-check-label" for="radio2">
                                                Sometimes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio3" value="option1"/>
                                            <label className="form-check-label" for="radio3">
                                                Usually
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio4" value="option1"/>
                                            <label className="form-check-label" for="radio4">
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div><br/><br/>
                                    
                                    <h5>04. Uses household objects for make-believe play</h5>
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radiox" id="radio5" value="option5" checked/>
                                            <label className="form-check-label" for="radio5">
                                                Never
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radiox" id="radio6" value="option6"/>
                                            <label className="form-check-label" for="radio6">
                                                Sometimes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radiox" id="radio7" value="option7"/>
                                            <label className="form-check-label" for="radio7">
                                                Usually
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radiox" id="radio8" value="option8"/>
                                            <label className="form-check-label" for="radio8">
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div><br/><br/>
                                    
                                    <h5>05. Shares toys when told to do so</h5>
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioy" id="radio9" value="option9" checked/>
                                            <label className="form-check-label" for="radio9">
                                                Never
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioy" id="radio10" value="option10"/>
                                            <label className="form-check-label" for="radio10">
                                                Sometimes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioy" id="radio11" value="option11"/>
                                            <label className="form-check-label" for="radio11">
                                                Usually
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioy" id="radio12" value="option12"/>
                                            <label className="form-check-label" for="radio12">
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="intBtnBx intautowidth"><Link to="/ya_page1" className="intbtnax">Back to Main Menu</Link> <Link to="#" className="intbtnbx">Save</Link> <Link to="#" className="intbtnbx">Next</Link></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
                
            </div>
        )
    }
}
export default YA_Page9;