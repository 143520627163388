import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Covid_Awarness extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            nextRouteLink:""
        }
        this.getNextRoute = this.getNextRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }

    async componentDidMount() {
        this.saveCurrentPos(0);
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        //console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
        //console.log(currentPosition)
        let nextPosition = parseInt(currentPosition)+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        //console.log(nextPosition)
        this.saveCurrentPos(nextPosition);
        let elemIdForRoute = routeOrderArray[nextPosition]
        //console.log(elemIdForRoute)
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        //console.log(nextRouteLink)        
        this.setState({
            nextRouteLink:nextRouteLink
        })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
        
    }

    //This function can be called from every sub pages for  next one
    async getNextRoute(){
    
        let nextRouteLink=this.state.nextRouteLink.toString();
        //Navigate to routeLink    
        this.props.history.push({
            pathname: `${nextRouteLink}`           
        })

        //same methodology can be used in case of previous route link (use -1 instead of +1)
    }

    saveCurrentPos(pos){
        // save currentPos variable to localstorage and set it to 0
    localStorage.setItem('currentPos',pos)
    }
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop covidBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Covid Awareness</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
          
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">                       
                                <div className="covidBase">
                                    <div className="covidTopHdn">
                                        <h3>SYMPTOMS</h3>
                                        <p>How to tell if you are hydrated</p>
                                    </div>
                                    <div className="howTotell">
                                        <div className="describe">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/describe1.png'} alt=""/>
                                            <p>COUGH</p>
                                        </div>
                                        <div className="describe">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/describe2.png'} alt=""/>
                                            <p>SHORTNESS OF BREADTH</p>
                                        </div>
                                    </div>
                                    <div className="symptom">Or atleast two of these below symptoms</div>
                                    <div className="basicSymptom">
                                                <div className="resCarousel" data-items="1-2-3-4" data-slide="1" data-animator="lazy">
                                                    <div className="resCarousel-inner">

                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym1.png'} alt=""/>
                                                                <p>Fever</p>
                                                            </div>
                                                        </div>

                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym2.png'} alt=""/>
                                                                <p>CHILLS</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym3.png'} alt=""/>
                                                                <p>REPEATED SHAKING WITH CHILLS</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym4.png'} alt=""/>
                                                                <p>MUSCLE PAIN</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym5.png'} alt=""/>
                                                                <p>HEADACHE</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym6.png'} alt=""/>
                                                                <p>SORE THROAT</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="item">
                                                            <div className="tile">
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/sym7.png'} alt=""/>
                                                                <p>NEW LOSS OF TASTE OR SMELL</p>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <button className='btn btn-default leftRs'><i className="fas fa-chevron-left"></i></button>
                                                    <button className='btn btn-default rightRs'><i className="fas fa-chevron-right"></i></button>
                                                </div>

                                        {/* <ul>
                                            <li>
                                                <img src="images/sym1.png" alt=""/>
                                                    <p>Fever</p>
                                            </li>
                                            <li>
                                                <img src="images/sym2.png" alt=""/>
                                                    <p>CHILLS</p>
                                            </li>
                                            <li>
                                                <img src="images/sym3.png" alt=""/>
                                                    <p>REPEATED SHAKING WITH CHILLS</p>
                                            </li>
                                            <li>
                                                <img src="images/sym4.png" alt=""/>
                                                    <p>MUSCLE PAIN</p>
                                            </li>
                                            <li>
                                                <img src="images/sym5.png" alt=""/>
                                                    <p>HEADACHE</p>
                                            </li>
                                            <li>
                                                <img src="images/sym6.png" alt=""/>
                                                    <p>SORE THROAT</p>
                                            </li>
                                            <li>
                                                <img src="images/sym7.png" alt=""/>
                                                    <p>NEW LOSS OF TASTE OR SMELL</p>
                                            </li>
                                        </ul> */}

                                    </div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/symimg.png'} alt=""/>
                                </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                    <Link to="#" onClick={()=>this.getNextRoute()}   className="intbtnb">Next</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
            </div>
        )
    }
}
export default Covid_Awarness;