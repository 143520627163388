import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",
            divChekboxvalue: "hide",
            divvalue: "show",

            sport: "",
            latestEyeExam: "",
            probExperience: [],
            diffSeeingType: [],
            doubleVisionType: [],
            correctiveLensesString: "",
            correctiveLensesArray: [],
            standardRxType: [],
            contactLensesType: [],
            glassesDuringScreening: "",
            currPresSphereRE: "",
            currPresCylinderRE: "",
            currPresAxisRE: "",
            currPresAddRE: "",
            currPresSphereLE: "",
            currPresCylinderLE: "",
            currPresAxisLE: "",
            currPresAddLE: "",

            diffSeeingDiv: false,
            doubleVisionDiv: false,
            standardRxDiv: false,
            contactLensesDiv: false,


        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAreaCheckbox = this.handleAreaCheckbox.bind(this);
        this.handleChangeCorrectiveLenses = this.handleChangeCorrectiveLenses.bind(this);
        this.handleChangeStandardRxValue = this.handleChangeStandardRxValue.bind(this);
        this.handleChangeContactLensesValue = this.handleChangeContactLensesValue.bind(this);
        this.handleChangeProbExperience = this.handleChangeProbExperience.bind(this);
        this.handleChangeDiffSeeingValue = this.handleChangeDiffSeeingValue.bind(this);
        this.handleChangeDoubleVisionValue = this.handleChangeDoubleVisionValue.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.athleteRefused !== this.state.athleteRefused) {
            if (this.state.athleteRefused === true) {
                this.setState({
                    edentulous: "",
                    untreatedDecayString: "",
                    untreatedDecayArea: [],
                    filledTeeth: "",
                    missingTeethString: "",
                    missingTeethArea: [],
                    sealant: "",
                    injuryString: "",
                    injuryTreated: "",
                    fluorosis: "",
                    gingivalSigns: "",
                    treatmentUrgency: "",
                    mouthguardRecommended: "",
                    mouthguardDelivered: false,
                    fluorideVarnishRecommended: "",
                    fluorideVarnishDelivered: false,
                })
            }
        }
        if (prevState.edentulous !== this.state.edentulous) {
            if (this.state.edentulous === "Yes") {
                this.setState({
                    untreatedDecayString: "",
                    untreatedDecayArea: [],
                    filledTeeth: "",
                    missingTeethString: "",
                    missingTeethArea: [],
                    sealant: "",
                    injuryString: "",
                    injuryTreated: "",
                    fluorosis: "",
                    gingivalSigns: "",
                    treatmentUrgency: "",
                    mouthguardRecommended: "",
                    mouthguardDelivered: false,
                    fluorideVarnishRecommended: "",
                    fluorideVarnishDelivered: false,
                })
            }
        }
        if (prevState.mouthPainString !== this.state.mouthPainString) {
            if (this.state.mouthPainString === "No") {
                this.setState({ mouthPainArea: [] });
            }
        }
        if (prevState.untreatedDecayString !== this.state.untreatedDecayString) {
            if (this.state.untreatedDecayString === "No") {
                this.setState({ untreatedDecayArea: [] });
            }
        }
        if (prevState.missingTeethString !== this.state.missingTeethString) {
            if (this.state.missingTeethString === "No") {
                this.setState({ missingTeethArea: [] });
            }
        }
        if (prevState.injuryString !== this.state.injuryString) {
            if (this.state.injuryString === "No") {
                this.setState({ injuryTreated: "" });
            }
        }
        if (prevState.mouthguardRecommended !== this.state.mouthguardRecommended) {
            if (this.state.mouthguardRecommended === "No") {
                this.setState({ mouthguardDelivered: false });
            }
        }
        if (prevState.fluorideVarnishRecommended !== this.state.fluorideVarnishRecommended) {
            if (this.state.fluorideVarnishRecommended === "No") {
                this.setState({ fluorideVarnishDelivered: false });
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleAreaCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name_value = target.value;
        const name = target.name;
        let options = []
        if (name === 'mouthPainArea') {
            options = this.state.mouthPainArea;
        }
        if (name === 'untreatedDecayArea') {
            options = this.state.untreatedDecayArea;
        }
        if (name === 'missingTeethArea') {
            options = this.state.missingTeethArea;
        }
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        this.setState({
            [name]: options,
        });
    }

    handleChangeCorrectiveLenses(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.correctiveLensesArray;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push({ "correctiveLensesValue": name_value })
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.map(function (o) { return o.correctiveLensesValue; }).indexOf(name_value);
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            correctiveLensesArray: options
        });
        if (name_value === "Standard Rx") {
            this.setState({ standardRxDiv: value });
            if (value === false && name_value === "Standard Rx") { this.setState({ standardRxType: [] }) }
        }
        if (name_value === "Contact lenses") {
            this.setState({ contactLensesDiv: value });
            if (value === false && name_value === "Contact lenses") { this.setState({ contactLensesType: [] }) }
        }
        //console.log(this.state);

    }

    handleChangeStandardRxValue(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
       // console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.standardRxType;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
       // console.log(options);
        this.setState({
            standardRxType: options
        });
    }

    handleChangeContactLensesValue(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
       // console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.contactLensesType;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            contactLensesType: options
        });
    }

    handleChangeProbExperience(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.probExperience;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push({ "probExperienceValue": name_value })
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.map(function (o) { return o.probExperienceValue; }).indexOf(name_value);
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            probExperience: options
        });
        if (name_value === "Difficulty seeing") {
            this.setState({ diffSeeingDiv: value });
            if (value === false && name_value === "Difficulty seeing") { this.setState({ diffSeeingType: [] }) }
        }
        if (name_value === "Double vision") {
            this.setState({ doubleVisionDiv: value });
            if (value === false && name_value === "Double vision") { this.setState({ doubleVisionType: [] }) }
        }
        //console.log(this.state);
    }

    handleChangeDiffSeeingValue(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
       // console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.diffSeeingType;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            diffSeeingType: options
        });
    }

    handleChangeDoubleVisionValue(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;
        //console.log(name, name_value, value);
        // this.setState({
        //     [name]: value
        // });
        let options = this.state.doubleVisionType;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
       // console.log(options);
        this.setState({
            doubleVisionType: options
        });
    }



    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageOne();
    }

    openingEyePageOne() {
        //console.log("I called");
        let dataToSend = {
            user_id: this.state.sob_scanId,
            
        };

        // Problem Experience
        let probExperience_prev_val = this.state.probExperience;
        //console.log(probExperience_prev_val)
        if ((this.state.diffSeeingType).length > 0) {
            probExperience_prev_val.forEach((element, index) => {
                if (element.probExperienceValue === "Difficulty seeing") {
                    probExperience_prev_val[index] = { "probExperienceValue": "Difficulty seeing", "probExperienceSubValue": this.state.diffSeeingType }
                }
            });
            //console.log(probExperience_prev_val)
        }
        if ((this.state.doubleVisionType).length > 0) {
            probExperience_prev_val.forEach((element, index) => {
                if (element.probExperienceValue === "Double vision") {
                    probExperience_prev_val[index] = { "probExperienceValue": "Double vision", "probExperienceSubValue": this.state.doubleVisionType }
                }
            });
           // console.log(probExperience_prev_val)
        }

        // Corrective Lenses
        let correctiveLenses_prev_val = this.state.correctiveLensesArray;
       // console.log(correctiveLenses_prev_val)
        if ((this.state.standardRxType).length > 0) {
            correctiveLenses_prev_val.forEach((element, index) => {
                if (element.correctiveLensesValue === "Standard Rx") {
                    correctiveLenses_prev_val[index] = { "correctiveLensesValue": "Standard Rx", "correctiveLensesSubValue": this.state.standardRxType }
                }
            });
          //  console.log(correctiveLenses_prev_val)
        }
        if ((this.state.contactLensesType).length > 0) {
            correctiveLenses_prev_val.forEach((element, index) => {
                if (element.correctiveLensesValue === "Contact lenses") {
                    correctiveLenses_prev_val[index] = { "correctiveLensesValue": "Contact lenses", "correctiveLensesSubValue": this.state.contactLensesType }
                }
            });
           // console.log(correctiveLenses_prev_val)
        }

       // console.log(this.state);

        let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        // let athleteRefused_1 = this.state.athleteRefused != "" ? dataToSend['athleteRefused'] = this.state.athleteRefused : null;

        let latestEyeExam_1 = this.state.latestEyeExam != "" ? dataToSend['latestEyeExam'] = this.state.latestEyeExam : null;
        let probExperience_1 = this.state.probExperience != "" ? dataToSend['probExperience'] = this.state.probExperience : null;
        let correctiveLensesString_1 = this.state.correctiveLensesString != "" ? dataToSend['correctiveLensesString'] = this.state.correctiveLensesString : null;
        if (this.state.correctiveLensesString === "Yes") {
            let correctiveLensesArray_1 = this.state.correctiveLensesArray != "" ? dataToSend['correctiveLensesArray'] = this.state.correctiveLensesArray : null;
        }
        let glassesDuringScreening_1 = this.state.glassesDuringScreening != "" ? dataToSend['glassesDuringScreening'] = this.state.glassesDuringScreening : null;
        let currPresSphereRE_1 = this.state.currPresSphereRE != "" ? dataToSend['currPresSphereRE'] = this.state.currPresSphereRE : null;
        let currPresCylinderRE_1 = this.state.currPresCylinderRE != "" ? dataToSend['currPresCylinderRE'] = this.state.currPresCylinderRE : null;
        let currPresAxisRE_1 = this.state.currPresAxisRE != "" ? dataToSend['currPresAxisRE'] = this.state.currPresAxisRE : null;
        let currPresAddRE_1 = this.state.currPresAddRE != "" ? dataToSend['currPresAddRE'] = this.state.currPresAddRE : null;
        let currPresSphereLE_1 = this.state.currPresSphereLE != "" ? dataToSend['currPresSphereLE'] = this.state.currPresSphereLE : null;
        let currPresCylinderLE_1 = this.state.currPresCylinderLE != "" ? dataToSend['currPresCylinderLE'] = this.state.currPresCylinderLE : null;
        let currPresAxisLE_1 = this.state.currPresAxisLE != "" ? dataToSend['currPresAxisLE'] = this.state.currPresAxisLE : null;
        let currPresAddLE_1 = this.state.currPresAddLE != "" ? dataToSend['currPresAddLE'] = this.state.currPresAddLE : null;

        //console.log(dataToSend);


        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageOne', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
                
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                        <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                                               
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div>
                                        <div className="compositionBg">

                                            <h3>History</h3>
                                            <h5>When was your last eye exam?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select name="latestEyeExam" onChange={this.handleChange} defaultValue="">
                                                    <option className="slRedbg" value="">Select Option</option>
                                                    <option value="Less than 1 year">Less than 1 year</option>
                                                    <option value="1-3 years">1-3 years</option>
                                                    <option value="More than 3 years">More than 3 years</option>
                                                    <option value="Never">Never</option>
                                                    <option value="Unknown">Unknown</option>
                                                </select>
                                            </div>
                                            <h5>Do you experience any of the following?</h5>
                                            <div className="unabletoTest stpBcheck frStpb">
                                                <div className="row">
                                                    <div className="col-md-7 rgtBordr mrb20">
                                                        <span><input type="checkbox" name="probExperience" onChange={this.handleChangeProbExperience} value="Difficulty seeing" /> Difficulty seeing:</span>
                                                        {
                                                            this.state.diffSeeingDiv &&
                                                            <>
                                                                <span className="farCheck"><input type="checkbox" name="diffSeeingValue" onChange={this.handleChangeDiffSeeingValue} value="Far" /> Far</span>
                                                                <span className="farCheck"><input type="checkbox" name="diffSeeingValue" onChange={this.handleChangeDiffSeeingValue} value="Near" /> Near</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span><input type="checkbox" name="probExperience" onChange={this.handleChangeProbExperience} value="Headaches" /> Headaches</span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-7 rgtBordr mrb20">
                                                        <span><input type="checkbox" name="probExperience" onChange={this.handleChangeProbExperience} value="Double vision" /> Double vision:</span>
                                                        {
                                                            this.state.doubleVisionDiv &&
                                                            <>
                                                                <span className="farCheck"><input type="checkbox" name="doubleVision" onChange={this.handleChangeDoubleVisionValue} value="Far" /> Far</span>
                                                                <span className="farCheck"><input type="checkbox" name="doubleVision" onChange={this.handleChangeDoubleVisionValue} value="Near" /> Near</span>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="col-md-5">
                                                        <span><input type="checkbox" name="probExperience" onChange={this.handleChangeProbExperience} value="Sensitivity to light" /> Sensitivity to light</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>Do you wear corrective lenses (glasses or contacts)?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select name="correctiveLensesString" onChange={this.handleChange} defaultValue="">
                                                    <option className="slRedbg" value="">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.correctiveLensesString === "Yes" &&
                                                <>
                                                    <div className="unabletoTest stpBcheck frStpb">
                                                        <div className="row">
                                                            <div className="col-md-12 rgtBordr mrb20">
                                                                <span><input type="checkbox" name="correctiveLenses" onChange={this.handleChangeCorrectiveLenses} value="Standard Rx" /> Standard Rx</span>
                                                                {
                                                                    this.state.standardRxDiv &&
                                                                    <>
                                                                        <span className="farCheck"><input type="checkbox" name="standardRxSubValue" onChange={this.handleChangeStandardRxValue} value="Full time" /> Full time</span>
                                                                        <span className="farCheck"><input type="checkbox" name="standardRxSubValue" onChange={this.handleChangeStandardRxValue} value="Near only" /> Near only</span>
                                                                        <span className="farCheck"><input type="checkbox" name="standardRxSubValue" onChange={this.handleChangeStandardRxValue} value="Far only" /> Far only</span>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 rgtBordr mrb20">
                                                                <span><input type="checkbox" name="correctiveLenses" onChange={this.handleChangeCorrectiveLenses} value="Sports Rx" /> Sports Rx</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 rgtBordr mrb20">
                                                                <span><input type="checkbox" name="correctiveLenses" onChange={this.handleChangeCorrectiveLenses} value="Contact lenses" /> Contact lenses</span>
                                                                {
                                                                    this.state.contactLensesDiv && <>
                                                                        <span className="farCheck"><input type="checkbox" name="contactLensesSubValues" onChange={this.handleChangeContactLensesValue} value="Soft" /> Soft</span>
                                                                        <span className="farCheck"><input type="checkbox" name="contactLensesSubValues" onChange={this.handleChangeContactLensesValue} value="Hard" /> Hard</span>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <h5>Please check what is worn during screening:</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select name="glassesDuringScreening" onChange={this.handleChange} defaultValue="">
                                                    <option className="slRedbg" value="">Select Option</option>
                                                    <option className="slRedbg" value="With Glasses">With Glasses</option>
                                                    <option value="Without Glasses">Without Glasses</option>
                                                    <option value="With contact lenses">With contact lenses</option>
                                                </select>
                                            </div>
                                            <h4>Current prescription</h4><br />
                                            <div className="row frmStepbPdn">
                                                <div className="col-md-6">
                                                    <h4>Right Eye </h4>
                                                    <h5>Sphere</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresSphereRE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Cylinder</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresCylinderRE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Axis</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresAxisRE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Add</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresAddRE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <h4>left Eye </h4>
                                                    <h5>Sphere</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresSphereLE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Cylinder</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresCylinderLE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Axis</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresAxisLE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                    <h5>Add</h5>
                                                    <div className="heightFlex">
                                                        <input type="number" name="currPresAddLE" onChange={this.handleChange} className="form-control" placeholder="Enter" />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Eyes_Form1;