import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;

class YA_Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_itemDetails_list: [],
            yaTraningId:"",
        };    
        this.getYaContentApp = this.getYaContentApp.bind(this);  

    } 

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaTraningId = await localStorage.getItem('yaTraningId')
        
        if(yaTraningId===null){
            localStorage.setItem('yaTraningId',this.props.location.state.yaTraningId)
        }
        const updated_yaTraningId = await localStorage.getItem('yaTraningId')
        //console.log('yaTraningId'+updated_yaTraningId);
      
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaTraningId: updated_yaTraningId
        })
      
        this.getYaItemsApp();

    }

    getYaItemsApp() {
        axios.post(path, {
            yaTraningId: this.state.yaTraningId
        }, {
            headers: { 'api-name': 'getYaItemsApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               
                const ItemDetails = res.response.items;
                //console.log(ItemDetails);
                this.setState({
                    ya_itemDetails_list: ItemDetails
                    
                });
            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

    async getYaContentApp(yaItem_Id,yaItemType,yaItemDescription) {
        axios.post(path, {
            yaItemId: yaItem_Id
        }, {
            headers: { 'api-name': 'getYaContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(res);
            //console.log(yaItem_Id);
            localStorage.setItem('yaItem_Id',yaItem_Id)
            localStorage.setItem('yaItemDescription',yaItemDescription)
            if(yaItemType==="athlete"){
                this.props.history.push("/ya_program");
            }
            if (res.status === true) {
                if(yaItemType==="video"){
                    this.props.history.push("/ya_athletes");
                }else  if(yaItemType==="image"){
                    this.props.history.push("/ya_equipments");
                }else  if(yaItemType==="athlete"){
                    this.props.history.push("/ya_program");
                }else  if(yaItemType==="multi"){
                    this.props.history.push("/ya_exercise");
                }
               

            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }


 
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop yaBg_a"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/yabg1.png'})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 introsx">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/sublogo1.png'} alt=""/>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="ypMain">
                                {this.state.ya_itemDetails_list.map((eachyaitemDetails, i) => (
                                    <div className="yaInn" key={i} style={{backgroundColor:eachyaitemDetails.yaItemColor}}>
                                        <img src={eachyaitemDetails.yaImage} alt="" key={i}/>
                                        {/* <div className="ypTxt"><Link to="#" onClick={()=>eachyaitemDetails.isYALock === false ?this.getYaContentApp(eachyaitemDetails.yaItemId):''} ><span style={{color:eachyaitemDetails.yaItemNameColor}}>{eachyaitemDetails.yaItemName}</span> {eachyaitemDetails.isYALock === false ?<i className="far fa-play-circle"></i>:<i className="fas flock fa-lock"></i>}</Link></div> */}
                                    
                                        <div className="ypTxt"><Link to="#" onClick={()=>eachyaitemDetails.isYALock === false ?this.getYaContentApp(eachyaitemDetails.yaItemId,eachyaitemDetails.yaItemType,eachyaitemDetails.yaItemDescription):""} ><span style={{color:eachyaitemDetails.yaItemNameColor}}>{eachyaitemDetails.yaItemName}</span> {eachyaitemDetails.isYALock === false ?<i className="far fa-play-circle"></i>:<i className="fas flock fa-lock"></i>}</Link></div>
                                    </div>
                                ))}
                                    
                                   
                                </div>
                                
                                <div className="intBtnBx"><Link to="/home" className="intbtnax">Back to Home</Link> 
                                {/* <Link to="#" className="intbtnbx">Next</Link> */}
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
            </div>
        )
    }
}
export default YA_Home;