import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",

            sport: "",
            coverTestFarUnableTest: "",
            coverTestFar: [],
            coverTestNearUnableTest: "",
            coverTestNear: [],
            latentNystagmus: "",
            nystagmus: "",

            coverTestFarValue: "",
            coverTestFarPhoriaSubValue: [],
            coverTestFarTropeSubValue: [],
            coverTestNearPhoriaSubValue: [],
            coverTestNearTropeSubValue: [],
            farPhoriaRange: "",
            farPhoriaOption1: "",
            farTropeRange: "",
            farTropeOption1: "",
            farTropeOption2: "",
            nearPhoriaRange: "",
            nearPhoriaOption1: "",
            nearTropeRange: "",
            nearTropeOption1: "",
            nearTropeOption2: "",

            farPhoriaDiv: false,
            farTropeDiv: false,
            nearPhoriaDiv: false,
            nearTropeDiv: false,

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFarCheckbox = this.handleFarCheckbox.bind(this);
        this.handleNearCheckbox = this.handleNearCheckbox.bind(this);

    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.coverTestFarUnableTest !== this.state.coverTestFarUnableTest) {
            if (this.state.coverTestFarUnableTest === "" || this.state.coverTestFarUnableTest === false) {
                this.setState({
                    coverTestFar: [],
                    coverTestFarValue: "",
                    coverTestFarPhoriaSubValue: [],
                    coverTestFarTropeSubValue: [],
                    farPhoriaRange: "",
                    farPhoriaOption1: "",
                    farTropeRange: "",
                    farTropeOption1: "",
                    farTropeOption2: "",
                    farPhoriaDiv: false,
                    farTropeDiv: false,
                })
            }
        }
        if (prevState.coverTestNearUnableTest !== this.state.coverTestNearUnableTest) {
            if (this.state.coverTestNearUnableTest === "" || this.state.coverTestNearUnableTest === false) {
                this.setState({
                    coverTestNear: [],
                    coverTestNearValue: "",
                    coverTestNearPhoriaSubValue: [],
                    coverTestNearTropeSubValue: [],
                    nearPhoriaRange: "",
                    nearPhoriaOption1: "",
                    nearTropeRange: "",
                    nearTropeOption1: "",
                    nearTropeOption2: "",
                    nearPhoriaDiv: false,
                    nearTropeDiv: false,
                })
            }
        }
    }

    handleFarCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;

        let options = this.state.coverTestFar;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push({ "coverTestFarValue": name_value })
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.map(function (o) { return o.coverTestFarValue; }).indexOf(name_value);
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            coverTestFar: options
        });
        if (name_value === "Phoria") {
            this.setState({ farPhoriaDiv: value });
            if (value === false && name_value === "Phoria") {
                this.setState({
                    farPhoriaRange: "",
                    farPhoriaOption1: "",
                    coverTestFarPhoriaSubValue: [],
                })
            }
           // console.log(this.state.farPhoriaRange)
        }
        if (name_value === "Trope") {
            this.setState({ farTropeDiv: value });
            if (value === false && name_value === "Trope") {
                this.setState({
                    farTropeRange: "",
                    farTropeOption1: "",
                    farTropeOption2: "",
                    coverTestFarTropeSubValue: [],
                })
            }
        }
    }

    handleNearCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const name_value = target.value;

        let options = this.state.coverTestNear;
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push({ "coverTestNearValue": name_value })
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.map(function (o) { return o.coverTestNearValue; }).indexOf(name_value);
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            coverTestNear: options
        });
        if (name_value === "Phoria") {
            this.setState({ nearPhoriaDiv: value });
            if (value === false && name_value === "Phoria") {
                this.setState({
                    nearPhoriaRange: "",
                    nearPhoriaOption1: "",
                    coverTestNearPhoriaSubValue: [],
                })
            }
        }
        if (name_value === "Trope") {
            this.setState({ nearTropeDiv: value });
            if (value === false && name_value === "Trope") {
                this.setState({
                    nearTropeRange: "",
                    nearTropeOption1: "",
                    nearTropeOption2: "",
                    coverTestNearTropeSubValue: [],
                })
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageThree();
    }

    openingEyePageThree() {
       // console.log("I called");
        let dataToSend = {
            user_id: this.state.sob_scanId,
           
        };

        //console.log(this.state);

        //let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        // FAR PHORIA 
        if (this.state.farPhoriaRange !== "" && ((2 < this.state.farPhoriaRange) && (this.state.farPhoriaRange < 99))) {
            let tempSubArr = this.state.coverTestFarPhoriaSubValue
            tempSubArr.push({ 'range': this.state.farPhoriaRange })
            this.setState({
                coverTestFarPhoriaSubValue: tempSubArr
            })
        }
        if (this.state.farPhoriaOption1 !== "") {
            let tempSubArr = this.state.coverTestFarPhoriaSubValue
            tempSubArr.push({ 'phoriaOption': this.state.farPhoriaOption1 })
            this.setState({
                coverTestFarPhoriaSubValue: tempSubArr
            })
        }
        // FAR TROPE
        if (this.state.farTropeRange !== "" && ((2 < this.state.farTropeRange) && (this.state.farTropeRange < 99))) {
            let tempSubArr = this.state.coverTestFarTropeSubValue
            tempSubArr.push({ 'range': this.state.farTropeRange })
            this.setState({
                coverTestFarTropeSubValue: tempSubArr
            })
        }
        if (this.state.farTropeOption1 !== "") {
            let tempSubArr = this.state.coverTestFarTropeSubValue
            tempSubArr.push({ 'tropeOption1': this.state.farTropeOption1 })
            this.setState({
                coverTestFarTropeSubValue: tempSubArr
            })
        }
        if (this.state.farTropeOption2 !== "") {
            let tempSubArr = this.state.coverTestFarTropeSubValue
            tempSubArr.push({ 'tropeOption2': this.state.farTropeOption2 })
            this.setState({
                coverTestFarTropeSubValue: tempSubArr
            })
        }


        // NEAR PHORIA
        if (this.state.nearPhoriaRange !== "" && ((2 < this.state.nearPhoriaRange) && (this.state.nearPhoriaRange < 99))) {
            let tempSubArr = this.state.coverTestNearPhoriaSubValue
            tempSubArr.push({ 'range': this.state.nearPhoriaRange })
            this.setState({
                coverTestNearPhoriaSubValue: tempSubArr
            })
        }
        if (this.state.nearPhoriaOption1 !== "") {
            let tempSubArr = this.state.coverTestNearPhoriaSubValue
            tempSubArr.push({ 'phoriaOption': this.state.nearPhoriaOption1 })
            this.setState({
                coverTestNearTropeSubValue: tempSubArr
            })
        }
        // NEAR TROPE
        if (this.state.nearTropeRange !== "" && ((2 < this.state.nearTropeRange) && (this.state.nearTropeRange < 99))) {
            let tempSubArr = this.state.coverTestNearTropeSubValue
            tempSubArr.push({ 'range': this.state.nearTropeRange })
            this.setState({
                coverTestNearTropeSubValue: tempSubArr
            })
        }
        if (this.state.nearTropeOption1 !== "") {
            let tempSubArr = this.state.coverTestNearTropeSubValue
            tempSubArr.push({ 'tropeOption1': this.state.nearTropeOption1 })
            this.setState({
                coverTestNearTropeSubValue: tempSubArr
            })
        }
        if (this.state.nearTropeOption2 !== "") {
            let tempSubArr = this.state.coverTestNearTropeSubValue
            tempSubArr.push({ 'tropeOption2': this.state.nearTropeOption2 })
            this.setState({
                coverTestNearTropeSubValue: tempSubArr
            })
        }


        let coverTestFarUnableTest_1 = this.state.coverTestFarUnableTest != "" ? dataToSend['coverTestFarUnableTest'] = this.state.coverTestFarUnableTest : null;
        if (!this.state.coverTestFarUnableTest) {
            let coverTestFar_prev_val = this.state.coverTestFar;
            if (((this.state.coverTestFarPhoriaSubValue).length > 0) || ((this.state.coverTestFarTropeSubValue).length > 0)) {
                coverTestFar_prev_val.forEach((element, index) => {
                    if (element.coverTestFarValue === "Phoria" && ((this.state.coverTestFarPhoriaSubValue).length > 0)) {
                        coverTestFar_prev_val[index] = { "coverTestFarValue": "Phoria", "coverTestFarSubValue": this.state.coverTestFarPhoriaSubValue }
                    }
                    if (element.coverTestFarValue === "Trope" && ((this.state.coverTestFarTropeSubValue).length > 0)) {
                       // console.log("i enter", this.state.coverTestFarTropeSubValue)
                        coverTestFar_prev_val[index] = { "coverTestFarValue": "Trope", "coverTestFarSubValue": this.state.coverTestFarTropeSubValue }
                    }
                });
            }
            this.setState({
                coverTestFar: coverTestFar_prev_val
            })
            let coverTestFar_1 = (this.state.coverTestFar != "" && this.state.coverTestFar.length > 0) ? dataToSend['coverTestFar'] = this.state.coverTestFar : null;

        }
        let coverTestNearUnableTest_1 = this.state.coverTestNearUnableTest != "" ? dataToSend['coverTestNearUnableTest'] = this.state.coverTestNearUnableTest : null;
        if (!this.state.coverTestNearUnableTest) {
            let coverTestNear_prev_val = this.state.coverTestNear;
            if (((this.state.coverTestNearPhoriaSubValue).length > 0) || ((this.state.coverTestNearTropeSubValue).length > 0)) {
                coverTestNear_prev_val.forEach((element, index) => {
                    if (element.coverTestNearValue === "Phoria" && ((this.state.coverTestNearPhoriaSubValue).length > 0)) {
                        coverTestNear_prev_val[index] = { "coverTestNearValue": "Phoria", "coverTestNearSubValue": this.state.coverTestNearPhoriaSubValue }
                    }
                    if (element.coverTestNearValue === "Trope" && ((this.state.coverTestNearTropeSubValue).length > 0)) {
                        coverTestNear_prev_val[index] = { "coverTestNearValue": "Trope", "coverTestNearSubValue": this.state.coverTestNearTropeSubValue }
                    }
                });
            }
            this.setState({
                coverTestNear: coverTestNear_prev_val
            })
            let coverTestNear_1 = (this.state.coverTestNear != "" && this.state.coverTestNear.length > 0) ? dataToSend['coverTestNear'] = this.state.coverTestNear : null;
        }
        let latentNystagmus_1 = this.state.latentNystagmus != "" ? dataToSend['latentNystagmus'] = this.state.latentNystagmus : null;
        let nystagmus_1 = this.state.nystagmus != "" ? dataToSend['nystagmus'] = this.state.nystagmus : null;

       // console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageThree', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
                
            })
            .then((serverRes) => {
                const res = serverRes;
              //  console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                    <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div class="compositionBg frmStep2Bg">


                                            <h4 class="coverTestBordr respCol">Cover Test (FAR): &nbsp;&nbsp; <span class="covrCheck minWdt noPdnBtm">
                                                <input type="checkbox" name="coverTestFarUnableTest" onChange={this.handleChange} /> Unable to test
                                            </span> </h4>
                                            {
                                                !this.state.coverTestFarUnableTest &&
                                                <>
                                                    <div class="coverTestBordr">
                                                        <span class="covrCheck minWdt noPdnBtm">
                                                            <input type="checkbox" name="coverTestFar" onChange={this.handleFarCheckbox} value="Orthophoria" /> Orthophoria:
                                                        </span>
                                                    </div>
                                                    <div class="coverTestBordr dfLxbg dfLxbgB">
                                                        <span class="covrCheck ccPdn minWdt noPdnBtm">
                                                            <input type="checkbox" name="coverTestFar" onChange={this.handleFarCheckbox} value="Phoria" /> Phoria (range 02-99)
                                                        </span>
                                                        {
                                                            this.state.farPhoriaDiv &&
                                                            <>
                                                                <input type="number" name="farPhoriaRange" onChange={this.handleChange} class="form-control" placeholder="Range" />
                                                                <div class="newSelect">
                                                                    <select name="farPhoriaOption1" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="eso">eso</option>
                                                                        <option value="exo">exo</option>
                                                                        <option value="hyper">hyper</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div class="coverTestBordr dfLxbg dfLxbgB">
                                                        <span class="covrCheck ccPdn minWdt noPdnBtm">
                                                            <input type="checkbox" name="coverTestFar" onChange={this.handleFarCheckbox} value="Trope" /> Trope (range 02-99)
                                                        </span>
                                                        {
                                                            this.state.farTropeDiv &&
                                                            <>
                                                                <input type="number" name="farTropeRange" onChange={this.handleChange} class="form-control" placeholder="Range" />
                                                                <div class="newSelect">
                                                                    <select name="farTropeOption1" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="eso">eso</option>
                                                                        <option value="exo">exo</option>
                                                                        <option value="hyper">hyper</option>
                                                                        <option value="hyper/eso">hyper/eso</option>
                                                                        <option value="hyper/eso">hyper/exo</option>
                                                                    </select>
                                                                </div>
                                                                <div class="newSelect">
                                                                    <select name="farTropeOption2" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="Intermittent">Intermittent</option>
                                                                        <option value="Constant">Constant</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }


                                            <h4 class="coverTestBordr respCol">Cover Test (NEAR): &nbsp;&nbsp; <span class="covrCheck minWdt noPdnBtm">
                                                <input type="checkbox" name="coverTestNearUnableTest" onChange={this.handleChange} /> Unable to test
                                            </span> </h4>
                                            {
                                                !this.state.coverTestNearUnableTest &&
                                                <>
                                                    <div class="coverTestBordr">
                                                        <span class="covrCheck minWdt noPdnBtm"> <input type="checkbox" name="coverTestNear" onChange={this.handleNearCheckbox} value="Orthophoria" /> Orthophoria:</span>
                                                    </div>
                                                    <div class="coverTestBordr dfLxbg dfLxbgB">
                                                        <span class="covrCheck ccPdn minWdt noPdnBtm">
                                                            <input type="checkbox" name="coverTestNear" onChange={this.handleNearCheckbox} value="Phoria" /> Phoria (range 02-99)
                                                        </span>
                                                        {
                                                            this.state.nearPhoriaDiv &&
                                                            <>
                                                                <input type="number" name="nearPhoriaRange" onChange={this.handleChange} class="form-control" placeholder="Range" />
                                                                <div class="newSelect">
                                                                    <select name="nearPhoriaOption1" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="eso">eso</option>
                                                                        <option value="exo">exo</option>
                                                                        <option value="hyper">hyper</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>

                                                    <div class="coverTestBordr dfLxbg dfLxbgB">
                                                        <span class="covrCheck ccPdn minWdt noPdnBtm">
                                                            <input type="checkbox" name="coverTestNear" onChange={this.handleNearCheckbox} value="Trope" /> Trope (range 02-99)
                                                        </span>
                                                        {
                                                            this.state.nearTropeDiv &&
                                                            <>
                                                                <input type="number" name="nearTropeRange" onChange={this.handleChange} class="form-control" placeholder="Range" />
                                                                <div class="newSelect">
                                                                    <select name="nearTropeOption1" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="eso">eso</option>
                                                                        <option value="exo">exo</option>
                                                                        <option value="hyper">hyper</option>
                                                                        <option value="hyper/eso">hyper/eso</option>
                                                                        <option value="hyper/eso">hyper/exo</option>
                                                                    </select>
                                                                </div>
                                                                <div class="newSelect">
                                                                    <select name="nearTropeOption2" onChange={this.handleChange}>
                                                                        <option class="slRedbg" value="">Select Option</option>
                                                                        <option value="Intermittent">Intermittent</option>
                                                                        <option value="Constant">Constant</option>
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }

                                            <h4 class="coverTestBordr">
                                                <span class="covrCheck noPdnBtm"><input type="checkbox" name="latentNystagmus" onChange={this.handleChange} /></span> Latent Nystagmus
                                            </h4>

                                            <h4 class="coverTestBordr">
                                                <span class="covrCheck noPdnBtm"><input type="checkbox" name="nystagmus" onChange={this.handleChange} /></span> Nystagmus
                                            </h4>

                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Eyes_Form3;