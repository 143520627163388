import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDKFMpENUtsdItv0Ot_EVBYL6c-unulnGY",
    authDomain: "special-olympics-bharat-339f4.firebaseapp.com",
    projectId: "special-olympics-bharat-339f4",
    storageBucket: "special-olympics-bharat-339f4.appspot.com",
    messagingSenderId: "300915738211",
    appId: "1:300915738211:web:09e13315853f695179da56",
    measurementId: "G-EC4QT877MS"
  };
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: `BMv6XC90mM60-BFBla5XItR8AvghFVNMBYmirWQLkHyuYDXPW5I9L4kXn4vIL2xfTTp3i5brfakFiBgfThw9qz0` })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                localStorage.setItem("SOB_currentToken", currentToken);

                if(currentToken===null){
                    var unique_currentToken = Math.floor(1000 + Math.random() * 90000000);
                    localStorage.setItem("SOB_currentToken", unique_currentToken);
                    return unique_currentToken;
                }
                // Perform any other neccessary action with the token
            } else {
            
                    var unique_currentToken = Math.floor(1000 + Math.random() * 90000000);
                    localStorage.setItem("SOB_currentToken", unique_currentToken);
                    return unique_currentToken;
                // Show permission request UI
               // console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {          
                var unique_currentToken = Math.floor(1000 + Math.random() * 90000000);
                localStorage.setItem("SOB_currentToken", unique_currentToken);
                return unique_currentToken;           
           // console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

