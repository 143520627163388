import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class PhysicalActivity_C extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            previousRouteLink:""
        }
        this.getPreviousRoute = this.getPreviousRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }


//This function can be called from every sub pages for previous one
async getPreviousRoute(){
    //read routeOrderArr from localstorage
    let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
    //console.log(routeOrderArrayString)
    let routeOrderArray = routeOrderArrayString.split(",")
    //console.log(routeOrderArray)
    //read currentPos from localstorage
    let currentPosition = localStorage.getItem('currentPos')
    let previousPosition = currentPosition-1
    this.saveCurrentPos(previousPosition);
    //console.log(previousPosition)
    //getValue of routeOrderArr[previousPosition] which will be an elem._id
    let elemIdForRoute = routeOrderArray[previousPosition]
    //console.log(elemIdForRoute)
    //get value from localStorage with elem._id
    let previousRouteLink = await localStorage.getItem(elemIdForRoute)
    // this.setState({
    //     previousRouteLink:previousRouteLink
    // })
    // let previousRouteLink=this.state.previousRouteLink.toString();
    //Navigate to routeLink
    this.props.history.push({
        pathname: `${previousRouteLink}`          
    })
}

saveCurrentPos(pos){
    // save currentPos variable to localstorage and set it to 0
  localStorage.setItem('currentPos',pos)
}
    render() {
        
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Introduction to FIT 5 </h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>

                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>Physical Activity</h4>
                                <ul className="physicalBullet">
                                    <li>Physical activity is anything you do that makes you move. It’s easy to fit physical activity into your everyday</li>
                                    <li>What do you like to do to be more active?</li>
                                </ul>
                                <div className="piBox">
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py1.svg'} alt=""/></div>
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py2.svg'} alt=""/></div>
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py3.svg'} alt=""/></div>
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py4.svg'} alt=""/></div>
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py5.svg'} alt=""/></div>
                                    <div className="piBoxInnpiBoxInn"><img src={process.env.PUBLIC_URL + '/assets/images/py6.svg'} alt=""/></div>
                                </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                    <Link to="#" onClick={()=>this.getPreviousRoute()} className="intbtnb">Previous</Link>
                                    <Link to="/physical_activity_D" className="intbtnb">Take the Quiz</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              
            </div>
        )
    }
}
export default PhysicalActivity_C;