import React from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import {requestForToken} from "./firebase";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';


var path = apiPath.path;
// import Button from 'react-bootstrap/Button';
class otpComponent extends React.Component {

  constructor(props) {
    super(props);
    var deviceToken_val = Math.floor(1000 + Math.random() * 90000000);
    this.state = 
    { value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "",otp6: "", disable: true,
    userId:"" ,
  
    deviceInfo: {
      deviceName: "lenevo",
      deviceType: "laptop",
      deviceModel: "L",
      deviceUniqueIdentifier: "sgasgsg1234"
    },
    deviceToken: deviceToken_val
  };
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);

  }

  async componentDidMount() {
    // Get userdetails from localstorage
    await requestForToken();
    const userId = await localStorage.getItem('SOB_userId')
    const deviceToken = await localStorage.getItem('SOB_currentToken')
   
   
    this.setState({
        userId: userId,
        deviceToken: deviceToken,
    })
   
    if(this.state.userId===null){
      this.props.history.push("/");
  }
    
   
}

  handleChange(value1, event) {
    //console.log(value1, event)
    this.setState({ [value1]: event.target.value });
  }

  handleClick(event) {
    event.preventDefault();
    //const data = event.target;
    //console.log(data)
    //console.log(this.state.userId);
    let otp = this.state.otp1+this.state.otp2+this.state.otp3+this.state.otp4+this.state.otp5+this.state.otp6
     //console.log(otp) 
     //console.log(this.state);
     this.verifyOtp(this.state.userId,otp,this.state.deviceInfo, this.state.deviceToken);
   

  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      //console.log("next");
     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }

  verifyOtp(...params) {
    axios.post(path, {
        userid: params[0],
        otp: params[1],
        deviceInfo: params[2],
        deviceToken: params[3],

    }, {
        headers: { 'api-name': 'verifyOtp'}
    }).then(serverResponse => {
        const res = serverResponse.data;
       // console.log(res);

        if (res.status === true) {
            toast.success(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        localStorage.setItem("SOB_userId", res.response.userId);
        localStorage.setItem("SOB_authToken", res.response.authToken);
        localStorage.setItem("SOB_refreshToken", res.response.refreshToken);
        this.props.history.push("/home");
     

        }
        else {
            // this.props.history.push("/");
            // localStorage.removeItem("SOB_userId");
            // localStorage.removeItem("SOB_authToken");
            // localStorage.removeItem("SOB_refreshToken");

            toast.error(res.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    });
}




  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="otpContainer">

          <input
            name="otp1"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp1}
            onKeyPress={this.keyPressed}
            onChange={e => this.handleChange("otp1", e)}
            tabIndex="1" maxLength="1" onKeyUp={e => this.inputfocus(e)}

          />
          <input
            name="otp2"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp2}
            onChange={e => this.handleChange("otp2", e)}
            tabIndex="2" maxLength="1" onKeyUp={e => this.inputfocus(e)}

          />
          <input
            name="otp3"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp3}
            onChange={e => this.handleChange("otp3", e)}
            tabIndex="3" maxLength="1" onKeyUp={e => this.inputfocus(e)}

          />
          <input
            name="otp4"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp4}
            onChange={e => this.handleChange("otp4", e)}
            tabIndex="4" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />

          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp5}
            onChange={e => this.handleChange("otp5", e)}
            tabIndex="5" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />

        <input
            name="otp6"
            type="text"
            autoComplete="off"
            className="otpInput"
            value={this.state.otp6}
            onChange={e => this.handleChange("otp6", e)}
            tabIndex="6" maxLength="1" onKeyUp={e => this.inputfocus(e)}
          />
        </div>
        <div className="formBtm"><button type="submit" onClick={this.handleClick}>Validate</button></div>
          {/* <div className="formBtm"><button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Validate</button></div> */}
      </form>
    );
  }
}


export default withRouter(otpComponent);