import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class PhysicalActivity_E extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Introduction to FIT 5 </h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>

                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>Name the sport activity</h4>
                                <ul className="physicD">
                                    <li>Q1. Which sport does this signify ?</li>
                                </ul>
                                <div className="piBoxD">
                                    <div className="piBoxDleft piBoxCorrect"><i className="fas fa-biking"></i></div>
                                    <div className="piBoxDright">
                                        <div className="piQuest piQuestCorrect"><span>A</span>Cycling</div>
                                        <div className="piQuest"><span>B</span>Running</div>
                                        <div className="piQuest"><span>C</span>Swimming</div>
                                        <button type="button">Submit</button>
                                    </div>
                                </div>
                                <div className="suggestions">
                                    <span className="heartCircle"><i className="fas fa-heartbeat"></i></span>
                                        <div className="pointsButton">Point - 25XP</div>
                                        <p>Cycling helps you improve<br/> your blood pressure</p>
                                    </div>
                                <div className="bordrs"></div>
                                <div className="intBtn">
                                    <Link to="/physical_activity_F" className="intbtna">Back to Main Menu</Link> 
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default PhysicalActivity_E;