import React from "react";
import Scanner from "react-webcam-qr-scanner";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class StatusScannerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            scannedId: "",
            participantId: "",
            scanStatus: false,
            medical_type: ""


        };


    }



    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
    
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }

    }
    handleDecode = (result) => {
        console.log(result);
        if (this.state.scanStatus === false) {
            console.log(result.data);

            if (result.data && result.data !== "") {
               //  document.getElementById("statusModal").classList.remove("show", "d-block");
                // console.log("---------------------------");
                document.getElementById("closeStatusModalBtn").click();


                if (this.scanAMID(result.data)) {
                    //console.log("push new form")

                }
            }
        }
    }
    scanAMID(...params) {
        let status = "";
        axios.post(path, {
            scannedId: params[0],
            deviceType: "web",
            scanType: "status",

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                toast.success(res.response.status_to_show, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                console.log(res)
                status = true;
                this.setState({
                    scanStatus: status,
                })
                localStorage.setItem("SOB_scanId", params[0]);
               // localStorage.setItem("SOB_scanType", "status");
                this.props.history.push("/amrit_chapter_participants_details");
               
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                status = false;
            }
            this.setState({
                scanStatus: status
            })
            return status;
        });
    }


    handleScannerLoad = (mode) => {
        // console.log(mode);
    }
    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>

                <Scanner
                    className="some-classname"
                    onDecode={this.handleDecode}
                    onScannerLoad={this.handleScannerLoad}
                    // playsinline
                    // disablepictureinpicture
                    constraints={{
                        audio: false,
                        video: {
                            facingMode: "environment"
                        }
                    }}
                    captureSize={{ width: 1280, height: 720 }}
                />
            </div>
        )
    }
}



export default withRouter(StatusScannerComponent);