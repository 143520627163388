import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Ya_Athletes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_contentDetails_list: [],
            yaItem_Id:"",
            yaItemDescription:"",
            inner_page_color:"",
            content_details_length:""
        };    
        this.getYaContentApp = this.getYaContentApp.bind(this);  
        this.unlockYaItemList=this.unlockYaItemList.bind(this);

    } 

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaItem_Id = await localStorage.getItem('yaItem_Id')
        const yaItemDescription = await localStorage.getItem('yaItemDescription')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaItem_Id: yaItem_Id,
            yaItemDescription:yaItemDescription
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
      
        this.getYaContentApp();
        this.unlockYaItemList();

    }

    unlockYaItemList(){
        axios.post(path , {
        }, {
            headers: { 'Api-Name': 'unlockYaItemList', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                // console.log(res);
                // toast.success(res.message, {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
    getYaContentApp() {
        axios.post(path, {
            yaItemId: this.state.yaItem_Id
        }, {
            headers: { 'api-name': 'getYaContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ContentDetails = res.response.contents;
                //console.log(ContentDetails);
               const content_details_length=ContentDetails.length;
                this.setState({
                    ya_contentDetails_list: ContentDetails,
                    inner_page_color:ContentDetails[0].inner_page_color,
                    content_details_length: content_details_length
                    
                });
               
            }
        });
    }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                 <Header {...this.props} />
                <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.inner_page_color}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:"#a9d6ee"}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3>{this.state.yaItemDescription}</h3>
                                            <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="yaSlidebg">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                        {this.state.ya_contentDetails_list.map((eachyacontentDetails, i) => (
                                            <li data-target="#carouselExampleIndicators" key={i} className={(i===0 ? "active" : "")} data-slide-to={i}></li>
                                        
                                       ))}             
                                        </ol>
                                        <div className="carousel-inner">
                                        {this.state.ya_contentDetails_list.map((eachyacontentDetails, i) => (
                                            <div className= {"carousel-item"+ (i===0 ? " active" : "")} key={i}>
                                            <video width="320" height="240" controls controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                                                <source src={eachyacontentDetails.content_array[0].multimedia} type="video/mp4"/>
                                            </video>
                                            </div>

                                        ))}             
                                            
                                        </div>
           
                                       
           
                                        </div>
                                </div>
                                
                                <div className="intBtnBx"><Link to="/ya_home" className="intbtnax">Back to Main Menu</Link> 
                                 <a href="#carouselExampleIndicators" data-slide="prev" className="carousel-control left intbtnbx">Previous</a>
                                <a href="#carouselExampleIndicators" data-slide="next" className="carousel-control right intbtnbx">Next</a>
                                 </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
            </div>
        )
    }
}
export default Ya_Athletes;