import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",

            sport: "",
            eyeHealthExtUnableTestRE: "",
            eyeHealthExtArrayRE: [],
            eyeHealthExtAbnormalityRE: "",
            eyeHealthExtUnableTestLE: "",
            eyeHealthExtArrayLE: [],
            eyeHealthExtAbnormalityLE: "",
            eyeHealthIntUnableTestRE: "",
            eyeHealthIntArrayRE: [],
            eyeHealthIntAbnormalityRE: "",
            eyeHealthIntUnableTestLE: "",
            eyeHealthIntArrayLE: [],
            eyeHealthIntAbnormalityLE: "",
            pupilsUnableTest: "",
            pupilsValue: "",
            pupilsAbnormalReason: "",

            eyeHealthExt: [
                { id: 'Normal', value: "Normal" },
                { id: 'Ptosis', value: "Ptosis" },
                { id: 'Lid anomaly', value: "Lid anomaly" },
                { id: 'Pterigium/pinguecula', value: "Pterigium/pinguecula" },
                { id: 'Blepharitis', value: "Blepharitis" },
                { id: 'Corneal anomaly', value: "Corneal anomaly" },
                { id: 'Conjunctivitis', value: "Conjunctivitis" },
                { id: 'Iris anomaly', value: "Iris anomaly" },
            ],

            eyeHealthInt: [
                { id: 'Normal', value: "Normal" },
                { id: 'Cataracts', value: "Cataracts" },
                { id: 'Coloboma', value: "Coloboma" },
                { id: 'Retinal anomaly', value: "Retinal anomaly" },
                { id: 'Optic Nerve anomaly', value: "Optic Nerve anomaly" },
                { id: 'Glaucoma suspect', value: "Glaucoma suspect" },
            ]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.eyeHealthExtUnableTestRE !== this.state.eyeHealthExtUnableTestRE) {
            if (this.state.eyeHealthExtUnableTestRE === "" || this.state.eyeHealthExtUnableTestRE === false) {
                this.setState({
                    eyeHealthExtArrayRE: [],
                    eyeHealthExtAbnormalityRE: "",
                })
            }
        }
        if (prevState.eyeHealthExtUnableTestLE !== this.state.eyeHealthExtUnableTestLE) {
            if (this.state.eyeHealthExtUnableTestLE === "" || this.state.eyeHealthExtUnableTestLE === false) {
                this.setState({
                    eyeHealthIntUnableTestRE: "",
                    eyeHealthIntArrayRE: "",
                })
            }
        }
        if (prevState.eyeHealthIntUnableTestRE !== this.state.eyeHealthIntUnableTestRE) {
            if (this.state.eyeHealthIntUnableTestRE === "" || this.state.eyeHealthIntUnableTestRE === false) {
                this.setState({
                    eyeHealthIntArrayRE: [],
                    eyeHealthIntAbnormalityRE: "",
                })
            }
        }
        if (prevState.eyeHealthIntUnableTestLE !== this.state.eyeHealthIntUnableTestLE) {
            if (this.state.eyeHealthIntUnableTestLE === "" || this.state.eyeHealthIntUnableTestLE === false) {
                this.setState({
                    eyeHealthIntArrayLE: [],
                    eyeHealthIntAbnormalityLE: "",
                })
            }
        }
        if (prevState.pupilsUnableTest !== this.state.pupilsUnableTest) {
            if (this.state.pupilsUnableTest === "" || this.state.pupilsUnableTest === false) {
                this.setState({
                    pupilsValue: "",
                    pupilsAbnormalReason: "",
                })
            }
        }
        if (prevState.pupilsValue !== this.state.pupilsValue) {
            if (this.state.pupilsValue !== "Abnormal") {
                this.setState({
                    pupilsAbnormalReason: "",
                })
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleChangeCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name_value = target.value;
        const name = target.name;
        let options = []
        if (name === 'eyeHealthExtArrayRE') {
            options = this.state.eyeHealthExtArrayRE;
        }
        if (name === 'eyeHealthExtArrayLE') {
            options = this.state.eyeHealthExtArrayLE;
        }
        if (name === 'eyeHealthIntArrayRE') {
            options = this.state.eyeHealthIntArrayRE;
        }
        if (name === 'eyeHealthIntArrayLE') {
            options = this.state.eyeHealthIntArrayLE;
        }
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        this.setState({
            [name]: options,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageOne();
    }

    openingEyePageOne() {
       // console.log("I called");
        let dataToSend = {
            user_id: this.state.sob_scanId,
        };

        // console.log(this.state);

       // let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        let eyeHealthExtUnableTestRE_1 = this.state.eyeHealthExtUnableTestRE != "" ? dataToSend['eyeHealthExtUnableTestRE'] = this.state.eyeHealthExtUnableTestRE : null;
        if (!this.state.eyeHealthExtUnableTestRE) {
            let eyeHealthExtArrayRE_1 = (this.state.eyeHealthExtArrayRE != "" && this.state.eyeHealthExtArrayRE.length > 0) ? dataToSend['eyeHealthExtArrayRE'] = this.state.eyeHealthExtArrayRE : null;
            let eyeHealthExtAbnormalityRE_1 = this.state.eyeHealthExtAbnormalityRE != "" ? dataToSend['eyeHealthExtAbnormalityRE'] = this.state.eyeHealthExtAbnormalityRE : null;
        }
        let eyeHealthExtUnableTestLE_1 = this.state.eyeHealthExtUnableTestLE != "" ? dataToSend['eyeHealthExtUnableTestLE'] = this.state.eyeHealthExtUnableTestLE : null;
        if (!this.state.eyeHealthExtUnableTestLE) {
            let eyeHealthExtArrayLE_1 = (this.state.eyeHealthExtArrayLE != "" && this.state.eyeHealthExtArrayLE.length > 0) ? dataToSend['eyeHealthExtArrayLE'] = this.state.eyeHealthExtArrayLE : null;
            let eyeHealthExtAbnormalityLE_1 = this.state.eyeHealthExtAbnormalityLE != "" ? dataToSend['eyeHealthExtAbnormalityLE'] = this.state.eyeHealthExtAbnormalityLE : null;
        }
        let eyeHealthIntUnableTestRE_1 = this.state.eyeHealthIntUnableTestRE != "" ? dataToSend['eyeHealthIntUnableTestRE'] = this.state.eyeHealthIntUnableTestRE : null;
        if (!this.state.eyeHealthIntUnableTestRE) {
            let eyeHealthIntArrayRE_1 = (this.state.eyeHealthIntArrayRE != "" && this.state.eyeHealthIntArrayRE.length > 0) ? dataToSend['eyeHealthIntArrayRE'] = this.state.eyeHealthIntArrayRE : null;
            let eyeHealthIntAbnormalityRE_1 = this.state.eyeHealthIntAbnormalityRE != "" ? dataToSend['eyeHealthIntAbnormalityRE'] = this.state.eyeHealthIntAbnormalityRE : null;
        }
        let eyeHealthIntUnableTestLE_1 = this.state.eyeHealthIntUnableTestLE != "" ? dataToSend['eyeHealthIntUnableTestLE'] = this.state.eyeHealthIntUnableTestLE : null;
        if (!this.state.eyeHealthIntUnableTestLE) {
            let eyeHealthIntArrayLE_1 = (this.state.eyeHealthIntArrayLE != "" && this.state.eyeHealthIntArrayLE.length > 0) ? dataToSend['eyeHealthIntArrayLE'] = this.state.eyeHealthIntArrayLE : null;
            let eyeHealthIntAbnormalityLE_1 = this.state.eyeHealthIntAbnormalityLE != "" ? dataToSend['eyeHealthIntAbnormalityLE'] = this.state.eyeHealthIntAbnormalityLE : null;
        }
        let pupilsUnableTest_1 = this.state.pupilsUnableTest != "" ? dataToSend['pupilsUnableTest'] = this.state.pupilsUnableTest : null;
        if (!this.state.pupilsUnableTest) {
            let pupilsValue_1 = this.state.pupilsValue != "" ? dataToSend['pupilsValue'] = this.state.pupilsValue : null;
            if (this.state.pupilsValue !== "Abnormal") {
                let pupilsAbnormalReason_1 = this.state.pupilsAbnormalReason != "" ? dataToSend['pupilsAbnormalReason'] = this.state.pupilsAbnormalReason : null;
            }
        }

        //console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageSix', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                        
                                    <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div class="compositionBg">


                                            <h4>Eye Health External:</h4>
                                            <div class="ehExternal frmStepbPdn">
                                                <div class="ehLeft">
                                                    <h4 class="coverTestBordr">Right Eye &nbsp;&nbsp; <span class="autoRe noPdnBtm">
                                                        <input type="checkbox" name="eyeHealthExtUnableTestRE" onChange={this.handleChange} />&nbsp; Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.eyeHealthExtUnableTestRE &&
                                                        <>
                                                            <div class="eheLft">
                                                                {this.state.eyeHealthExt.map((illness, i) => (
                                                                    // defaultChecked={illness.value == "Age Under 20"} 
                                                                    <span key={i}><input type="checkbox" name="eyeHealthExtArrayRE" value={illness.id} onChange={this.handleChangeCheckbox} />{illness.value}</span>
                                                                ))
                                                                }
                                                                <div class="ehTextarea">
                                                                    <h4>Abnormality:</h4>
                                                                    <textarea class="form-control" name="eyeHealthExtAbnormalityRE" onChange={this.handleChange} placeholder="Enter"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div class="ehRight">
                                                    <h4 class="coverTestBordr">Left Eye &nbsp;&nbsp; <span class="autoRe noPdnBtm">
                                                        <input type="checkbox" name="eyeHealthExtUnableTestLE" onChange={this.handleChange} />&nbsp; Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.eyeHealthExtUnableTestLE &&
                                                        <>
                                                            <div class="eheRight">
                                                                {this.state.eyeHealthExt.map((illness, i) => (
                                                                    // defaultChecked={illness.value == "Age Under 20"} 
                                                                    <span key={i}><input type="checkbox" name="eyeHealthExtArrayLE" value={illness.id} onChange={this.handleChangeCheckbox} />{illness.value}</span>
                                                                ))
                                                                }
                                                                <div class="ehTextarea">
                                                                    <h4>Abnormality:</h4>
                                                                    <textarea class="form-control" name="eyeHealthExtAbnormalityLE" onChange={this.handleChange} placeholder="Enter"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>



                                            <h4>Eye Health Internal:</h4>
                                            <div class="ehExternal frmStepbPdn">
                                                <div class="ehLeft">
                                                    <h4 class="coverTestBordr">Right Eye &nbsp;&nbsp; <span class="autoRe noPdnBtm">
                                                        <input type="checkbox" name="eyeHealthIntUnableTestRE" onChange={this.handleChange} />&nbsp; Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.eyeHealthIntUnableTestRE &&
                                                        <>
                                                            <div class="eheLft">
                                                                {this.state.eyeHealthInt.map((illness, i) => (
                                                                    // defaultChecked={illness.value == "Age Under 20"} 
                                                                    <span key={i}><input type="checkbox" name="eyeHealthIntArrayRE" value={illness.id} onChange={this.handleChangeCheckbox} />{illness.value}</span>
                                                                ))
                                                                }
                                                                <div class="ehTextarea">
                                                                    <h4>Abnormality:</h4>
                                                                    <textarea class="form-control" name="eyeHealthIntAbnormalityRE" onChange={this.handleChange} placeholder="Enter"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                                <div class="ehRight">
                                                    <h4 class="coverTestBordr">Left Eye &nbsp;&nbsp; <span class="autoRe noPdnBtm">
                                                        <input type="checkbox" name="eyeHealthIntUnableTestLE" onChange={this.handleChange} />&nbsp; Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.eyeHealthIntUnableTestLE &&
                                                        <>
                                                            <div class="eheRight">
                                                                {this.state.eyeHealthInt.map((illness, i) => (
                                                                    // defaultChecked={illness.value == "Age Under 20"} 
                                                                    <span key={i}><input type="checkbox" name="eyeHealthIntArrayLE" value={illness.id} onChange={this.handleChangeCheckbox} />{illness.value}</span>
                                                                ))
                                                                }
                                                                <div class="ehTextarea">
                                                                    <h4>Abnormality:</h4>
                                                                    <textarea class="form-control" name="eyeHealthIntAbnormalityLE" onChange={this.handleChange} placeholder="Enter"></textarea>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <h4 class="">Pupils: &nbsp;&nbsp; <span class="autoRe noPdnBtm">
                                                <input type="checkbox" name="pupilsUnableTest" onChange={this.handleChange} />&nbsp; Unable to test
                                            </span></h4>
                                            {
                                                !this.state.pupilsUnableTest &&
                                                <>
                                                    <div class="pupilsBg coverTestBordr">
                                                        <div class="newSelect">
                                                            <select name="pupilsValue" onChange={this.handleChange}>
                                                                <option class="slRedbg" value="">Select Option</option>
                                                                <option value="Normal">Normal</option>
                                                                <option value="Abnormal">Abnormal</option>
                                                            </select>
                                                        </div>
                                                        <input type="text"
                                                            disabled={this.state.pupilsValue !== "Abnormal"}
                                                            name="pupilsAbnormalReason"
                                                            onChange={this.handleChange}
                                                            value={this.state.pupilsAbnormalReason}
                                                            class="form-control"
                                                            placeholder="Enter note"
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Eyes_Form6;