import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Nutrition extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            nextRouteLink:""
        }
        this.getNextRoute = this.getNextRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }

    async componentDidMount() {
        this.saveCurrentPos(0);
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        //console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
        //console.log(currentPosition)
        let nextPosition = parseInt(currentPosition)+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        //console.log(nextPosition)
        this.saveCurrentPos(nextPosition);
        let elemIdForRoute = routeOrderArray[nextPosition]
        //console.log(elemIdForRoute)
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        //console.log(nextRouteLink)        
        this.setState({
            nextRouteLink:nextRouteLink
        })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
        
    }

    //This function can be called from every sub pages for  next one
    async getNextRoute(){
    
        let nextRouteLink=this.state.nextRouteLink.toString();
        //Navigate to routeLink    
        this.props.history.push({
            pathname: `${nextRouteLink}`           
        })

        //same methodology can be used in case of previous route link (use -1 instead of +1)
    }

    saveCurrentPos(pos){
        // save currentPos variable to localstorage and set it to 0
    localStorage.setItem('currentPos',pos)
    }

    render() {
        
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop nutritionBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Nutrition</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
        
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>I choose to eat more fruits &amp; vegetables</h4>
                                <div className="nutritionBlog">
                                        <h3>Why?</h3>
                                        <ul>
                                            <li>They give your body important vitamins, minerals and energy needed for good health.</li>
                                            <li>Provide energy for your sports performance</li>
                                        </ul>
                                    </div>
                                    
                                    <div className="nutritionPingbg">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/plate.png'} alt=""/>
                                        <div className="tipBg">
                                            <h3>Tip</h3>
                                            <p>Fresh, local and in season fruits and<br/> vegetable are the best!</p>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="nutritionBlog">
                                        <h3>How?</h3>
                                        <ul>
                                            <li>Eat a fruit a day with lunch</li>
                                            <li>Make half my plate fruits and vegetables every day. Have a salad for lunch</li>
                                            <li>When I want crunchy foods, I can eat apple slices, little carrots, celery sticks and snap peas</li>
                                            <li>Make a fruit smoothie with low fat milk or low fat plain yoghurt for dessert</li>
                                        </ul>
                                    </div>
                                    <div className="intBtn">
                                        <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                        <Link to="#" onClick={()=>this.getNextRoute()} className="intbtnb">Next</Link>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
            </div>
        )
    }
}
export default Nutrition;