import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
//import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Fit_Five extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            itemDetails_list: [],
            profilePic:"",
            trainingId:"",
            contentDetails_list: [],
            modalState: true
        };
        this.getContents = this.getContents.bind(this);
        this.saveRoute = this.saveRoute.bind(this);
        this.saveRouteOrder = this.saveRouteOrder.bind(this);
        this.getNextRoute = this.getNextRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
        this.getNutritionContents=this.getNutritionContents.bind(this);
        this.handleShow = this.handleShow.bind(this);
        

    } 
    
    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }

     async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const trainingId = await localStorage.getItem('trainingId')
        if(trainingId===null){
            localStorage.setItem('trainingId',this.props.location.state.trainingId)
        }
        const updated_trainingId = await localStorage.getItem('trainingId')
       // console.log('trainingId'+updated_trainingId);
        //if(userId && userId.hasOwnProperty('userId')){
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            trainingId: updated_trainingId
        })
        // }
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getItems();


    
        // //read routeOrderArr from localstorage
        // let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        // //console.log(routeOrderArrayString)
        // let routeOrderArray = routeOrderArrayString.split(",")
        // //console.log(routeOrderArray)
        // //read currentPos from localstorage
        // let currentPosition = await localStorage.getItem('currentPos')       
        // this.saveCurrentPos(currentPosition);
        // let elemIdForRoute = routeOrderArray[currentPosition]
        // //console.log(elemIdForRoute)
        // //get value from localStorage with elem._id
        // let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        // //console.log(nextRouteLink)        
        // this.setState({
        //     nextRouteLink:nextRouteLink
        // })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
    }

 

    getItems() {
        axios.post(path , {
            traningId: this.state.trainingId
        }, {
            headers: { 'Api-Name': 'getItems', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ItemDetails = res.response.item;

                this.setState({
                    itemDetails_list: ItemDetails
                    
                });
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }




// Below function saves route order in localstorage 
    saveRouteOrder(elemList){
        //save elem._id list as array in order in localstorage
        let routeOrderArr=[]
        elemList.forEach(elem=>{
            routeOrderArr[elem.order-1]=elem._id
        })
       // save routeOrderArr to localstorage
       //console.log(routeOrderArr);
       localStorage.setItem('routeOrderArr',routeOrderArr)
       this.saveCurrentPos();
       this.getNextRoute();
    }

    saveCurrentPos(){
         // save currentPos variable to localstorage and set it to 0
       localStorage.setItem('currentPos',0)
    }
    
    saveRoute(contentsArr){
       // take elem._id as key and save to localstorage with route as value
        contentsArr.forEach(eachContent => {
            //Check contenttype is static or dynamic
           // if(eachContent.contentType==="static"){
                //Check category is content or quiz
                if(eachContent.category==="content"){
                    //Check for particular item(for introduction to fit 5)
                    if(eachContent.itemId==="60a64a37190b917dd724c914"){
                        if(eachContent._id === "60aceab8a79d152558f2168c"){
                            localStorage.setItem(eachContent._id,"/introduction")
                        }
                        if(eachContent._id === "60aceafea79d152558f2168d"){
                            localStorage.setItem(eachContent._id,"/physical_activity")
                        }
                        if(eachContent._id === "60aceb05a79d152558f2168e"){
                            localStorage.setItem(eachContent._id,"/physical_activity_B")
                        }
                        if(eachContent._id === "60aceb09a79d152558f2168f"){
                            localStorage.setItem(eachContent._id,"/physical_activity_C")
                        }
                       
                    }else if(eachContent.itemId==="60a64a6f190b917dd724c915"){
                        //for exercise
                        if(eachContent._id === "60c9a4138877ff139467b736"){
                            localStorage.setItem(eachContent._id,"/exercise")
                            localStorage.setItem("current_page_id",eachContent._id)
                        }
                    }else if(eachContent.itemId==="60a64a9e190b917dd724c916"){
                        //for nutrition
                        if(eachContent._id === "60b9cb2f5347f91aa8572b3d"){
                            localStorage.setItem(eachContent._id,"/nutrition")
                        }
                        if(eachContent._id === "60b9cb515347f91aa8572b3e"){
                            localStorage.setItem(eachContent._id,"/nutrition2")
                        }
                    }else if(eachContent.itemId==="60a64ad9190b917dd724c917"){
                        //for hydration
                        if(eachContent._id === "614b4e04e8904d05ce4b7326"){
                            localStorage.setItem(eachContent._id,"/hydration")
                        }
                        if(eachContent._id === "614b4e28e8904d05ce4b7331"){
                            localStorage.setItem(eachContent._id,"/hydration2")
                        }
                        if(eachContent._id === "614b4e2fe8904d05ce4b7334"){
                            localStorage.setItem(eachContent._id,"/hydration3")
                        }
                        if(eachContent._id === "614b4e39e8904d05ce4b7337"){
                            localStorage.setItem(eachContent._id,"/hydration4")
                        }
                    }else if(eachContent.itemId==="60a64b10190b917dd724c918"){
                        //for covid_awarness
                        if(eachContent._id === "614b4eaee8904d05ce4b7346"){
                            localStorage.setItem(eachContent._id,"/covid_awarness")
                        }
                        if(eachContent._id === "614b4eb4e8904d05ce4b7349"){
                            localStorage.setItem(eachContent._id,"/covid_awarness2")
                        }
                        if(eachContent._id === "614b4eb9e8904d05ce4b734c"){
                            localStorage.setItem(eachContent._id,"/covid_awarness3")
                        }
                        if(eachContent._id === "614b4ebde8904d05ce4b734f"){
                            localStorage.setItem(eachContent._id,"/covid_awarness4")
                        }
                        if(eachContent._id === "614b4ec2e8904d05ce4b7352"){
                            localStorage.setItem(eachContent._id,"/covid_awarness5")
                        }
                        if(eachContent._id === "614b4ec8e8904d05ce4b7355"){
                            localStorage.setItem(eachContent._id,"/covid_awarness6")
                        }
                        
                    }          
                }else{
                     if(eachContent.itemId==="60bf1fd27dd909133f765dc1"){
                        //for takequiz
                        if(eachContent._id === "60dac86dff11142a9bf9ca3c"){
                            localStorage.setItem(eachContent._id,"/test_yourself")
                        }
                    }      
                }
            //}
            //May use switch as well
            // full list as look up table          
        });       
    }
    
    //This function can be called from every sub pages for  next one
    async getNextRoute(){
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
       // console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
       // let nextPosition = currentPosition+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        let elemIdForRoute = routeOrderArray[currentPosition]
        //console.log('nextRouteLink-'+elemIdForRoute);
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        //Navigate to routeLink
        //console.log('nextRouteLink-'+nextRouteLink);
        //  return <Redirect to='/introduction' />
       
        this.props.history.push({
            pathname: `${nextRouteLink}`          
        })

        //same methodology can be used in case of previous route link (use -1 instead of +1)
    }

    async getContents(item_id) {
        axios.post(path, {
            itemId: item_id
        }, {
            headers: { 'Api-Name': 'getContents', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            localStorage.setItem('itemId',item_id)
            //console.log(item_id);
            if (res.status === true) {
               // let urllink='';
              // console.log('contents');
               // console.log(res);
                const ContentDetails = res.response.contents;
                this.saveRoute(ContentDetails);
                this.saveRouteOrder(ContentDetails);

            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }


    async getNutritionContents(item_id) {
        axios.post(path, {
            itemId: item_id
        }, {
            headers: { 'Api-Name': 'getNutritionContents', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            localStorage.setItem('itemId',item_id)
            //console.log(item_id);
            if (res.status === true) {
               // let urllink='';
               //console.log('nutrition-contents');
               // console.log(res);
                const NutritionDetails = res.response.contents;
                this.saveRoute(NutritionDetails);
                this.saveRouteOrder(NutritionDetails);

            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
  


    render() {
        //let urllink='';
        return (
            <div className="fullHeight">
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/fitb.png'} alt=""/>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
                                    <div className="fitTopImg">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit1.png'} alt=""/>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit2.png'} alt=""/>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit3.png'} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <div className="leaderNew">

                                 {this.state.itemDetails_list.map((eachitemDetails, i) => (
                                    <div   className="leadInn leadInnB active" key={i} style={{backgroundColor:eachitemDetails.color}}>

                                    <Link  to="#" onClick={()=>eachitemDetails.isFitfivelock === false ?(eachitemDetails._id === "60a64a9e190b917dd724c916" ?this.getNutritionContents(eachitemDetails._id):this.getContents(eachitemDetails._id)):false} style={{"textDecoration":"none"}}>

                                    {/* <Link  to="#" onClick={()=>eachitemDetails._id === "60a64a9e190b917dd724c916" ?this.getNutritionContents(eachitemDetails._id):this.getContents(eachitemDetails._id)} style={{"textDecoration":"none"}}> */}
                                
                                        <p>{eachitemDetails.order}. {eachitemDetails.itemName}</p>
                                        <div className="rangeBg">
                                            <div className="rangeLft" style={{backgroundColor:eachitemDetails.color}}><span className="rangewdths_e">&nbsp;</span></div>
                                            <div className="leadIcon"  style={{boxShadow: `0 3px 1px 3px ${eachitemDetails.color}`}}>{eachitemDetails.isFitfivelock === false ?<i className="fas fa-play-circle"></i>:<i className="fas fa-lock"></i>}</div>
                                        </div>
                                        </Link>


                                      
                                    </div>
                                 ))}
                               
                                </div>
                                <div className="intBtnBx"><Link to="/home" className="intbtnax">Back to Home</Link> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    
                    {/* Add-Modal */}
                    <div className={"modal addModal fade"+ (this.state.modalState ? " show d-block" : " d-none")} id="myModal1" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleShow}><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                <h4>Thank You to our sponsors:</h4>
                                <div className="specialImage"><img src={process.env.PUBLIC_URL + '/assets/images/specialPopicon.png'} alt=""/></div>
                                <div className="healthIcon"><img src={process.env.PUBLIC_URL + '/assets/images/healthicon.png'} alt=""/></div>
                                </div>
                                
                            </div>
                        </div>
                        </div>

            </div>
        )
    }
}
export default Fit_Five;