import React from "react";
import { Link } from "react-router-dom";
import { PDFReader } from 'reactjs-pdf-reader';
import { saveAs } from "file-saver";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_Show_ID extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            profilePic: "",
            participant_details: [],
            participant_pdf: ""

        };

    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')


        this.setState({
            userId: userId,
            AuthToken: AuthToken,


        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getRegistrationDetails();
        this.getProfile();


    }
    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    getRegistrationDetails() {
        //console.log(this.state.userId)
        axios.post(path, {
            participantType: "",
            eventId: "61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(res);
            const ParticipantDetails = res.response.data[0];
            if (res.status === true) {
                this.setState({
                    participant_details: ParticipantDetails,
                    participant_pdf: ParticipantDetails['AMgeneratedId']
                });

            }
        });
    }
    saveFile() {
        saveAs(
            this.state.participant_pdf,
            this.state.userId
        );
    };

    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li><Link to="/amrit_medical_professional"><span><img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete</Link></li>
                                        <li className="active"><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Show ID</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="showId">
                                    {/* <img src={process.env.PUBLIC_URL + '/assets/images/id.jpg'} alt="" /> */}
                                    {this.state.participant_pdf !== "" ? (<PDFReader
                                        // width="400"
                                        scale="0.8"
                                        url={this.state.participant_pdf} />) : null}


                                </div>
                                <div className="filterModalSubmit"><button type="submit" style={{ cursor: "pointer" }} onClick={(e) => this.saveFile()}>Download</button></div>
                                <div className="intBtn">
                                    <Link to="/amrit_after_registration" className="intbtnb">Back</Link>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Show_ID;