import React from "react";
import Header from "../components/header";
import ButtonComponent from "../components/buttonComponent";
class PhysicalActivity_B extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Introduction to FIT 5 </h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>

                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>Physical Activity</h4>
                                <ul className="physicalBullet">
                                    <li>Practice sports drills by yourself or with family</li>
                                    <li>Turn on some music and dance!</li>
                                    <li>Clean your house or do yard work</li>
                                    <li>Walk or jog around your home or neighborhood</li>
                                    <li>Practice some of your favorite exercises - the next few cards can give you a great workout</li>
                                </ul>
                                <div className="distanceBox">
                                        <div className="distncLft"><img src={process.env.PUBLIC_URL + '/assets/images/pi1.jpg'} alt=""/></div>
                                        <div className="distncRgt">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/pi2.jpg'} alt=""/>
                                        </div>
                                    </div>
                                    <ButtonComponent {...this.props}/>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default PhysicalActivity_B;