import React from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
// Load the full build.
//var _ = require('lodash');
class Amrit_Participants_Resubmit extends React.Component {
    fileObj = [];
    fileArray = [];
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_type: "",
            registration_ques_list: [],
            imageNameArr: [],
            imgArr: [],
            index: "",
            district_options: [],
            participant_details: [],
            divvalue: "hide",
            dropdownvalue: "show",
            reqvalue: false,
            new_quesArray: [],
            fileUploadState: "",
            img_org_height: "",
            img_org_width: "",
           // imgSrc: process.env.PUBLIC_URL + '/assets/images/upload.png',
            oldImgArr: [],
            dummyimgnameArray: [],
            dummyImgArr: [],
            stateIndexval: "",
            state_array:
                [
                    ["Anantapur", "Chittoor", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari", "YSR Kadapa"],
                    ["Nicobar", "North and Middle Andaman", "South Andaman"],
                    ["Tawang", "West Kameng", "East Kameng", "Papum Pare", "Kurung Kumey", "Kra Daadi", "Lower Subansiri", "Upper Subansiri", "West Siang", "East Siang", "Siang", "Upper Siang", "Lower Siang", "Lower Dibang Valley", "Dibang Valley", "Anjaw", "Lohit", "Namsai", "Changlang", "Tirap", "Longding"],
                    ["Baksa", "Barpeta", "Biswanath", "Bongaigaon", "Cachar", "Charaideo", "Chirang", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Hojai", "Jorhat", "Kamrup Metropolitan", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Majuli", "Morigaon", "Nagaon", "Nalbari", "Dima Hasao", "Sivasagar", "Sonitpur", "South Salmara-Mankachar", "Tinsukia", "Udalguri", "West Karbi Anglong"],
                    ["Araria", "Arwal", "Aurangabad", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran (Motihari)", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger (Monghyr)", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia (Purnea)", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"],
                    ["Chandigarh"], ["Balod", "Baloda Bazar", "Balrampur", "Bastar", "Bemetara", "Bijapur", "Bilaspur", "Dantewada (South Bastar)", "Dhamtari", "Durg", "Gariyaband", "Janjgir-Champa", "Jashpur", "Kabirdham (Kawardha)", "Kanker (North Bastar)", "Kondagaon", "Korba", "Korea (Koriya)", "Mahasamund", "Mungeli", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Sukma", "Surajpur  ", "Surguja"],
                    ["Dadra & Nagar Haveli"],
                    ["Daman", "Diu"],
                    ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East  Delhi", "North West  Delhi", "Shahdara", "South Delhi", "South East Delhi", "South West  Delhi", "West Delhi"],
                    ["North Goa", "South Goa"],
                    ["Ahmedabad", "Amreli", "Anand", "Aravalli", "Banaskantha (Palanpur)", "Bharuch", "Bhavnagar", "Botad", "Chhota Udepur", "Dahod", "Dangs (Ahwa)", "Devbhoomi Dwarka", "Gandhinagar", "Gir Somnath", "Jamnagar", "Junagadh", "Kachchh", "Kheda (Nadiad)", "Mahisagar", "Mehsana", "Morbi", "Narmada (Rajpipla)", "Navsari", "Panchmahal (Godhra)", "Patan", "Porbandar", "Rajkot", "Sabarkantha (Himmatnagar)", "Surat", "Surendranagar", "Tapi (Vyara)", "Vadodara", "Valsad"],
                    ["Ambala", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gurgaon", "Hisar", "Jhajjar", "Jind", "Kaithal", "Karnal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Yamunanagar"], ["Bilaspur", "Chamba", "Hamirpur", "Kangra", "Kinnaur", "Kullu", "Lahaul &amp; Spiti", "Mandi", "Shimla", "Sirmaur (Sirmour)", "Solan", "Una"],
                    ["Anantnag", "Bandipore", "Baramulla", "Budgam", "Doda", "Ganderbal", "Jammu", "Kargil", "Kathua", "Kishtwar", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajouri", "Ramban", "Reasi", "Samba", "Shopian", "Srinagar", "Udhampur"], ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridih", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela-Kharsawan", "Simdega", "West Singhbhum"],
                    ["Bagalkot", "Ballari (Bellary)", "Belagavi (Belgaum)", "Bengaluru (Bangalore) Rural", "Bengaluru (Bangalore) Urban", "Bidar", "Chamarajanagar", "Chikballapur", "Chikkamagaluru (Chikmagalur)", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Hassan", "Haveri", "Kalaburagi (Gulbarga)", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysuru (Mysore)", "Raichur", "Ramanagara", "Shivamogga (Shimoga)", "Tumakuru (Tumkur)", "Udupi", "Uttara Kannada (Karwar)", "Vijayapura (Bijapur)", "Yadgir"],
                    ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasaragod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"],
                    ["Agatti", "Amini", "Androth", "Bithra", "Chethlath", "Kavaratti", "Kadmath", "Kalpeni", "Kilthan", "Minicoy"],
                    ["Agar Malwa", "Alirajpur", "Anuppur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Chhindwara", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha"],
                    ["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"],
                    ["Bishnupur", "Chandel", "Churachandpur", "Imphal East", "Imphal West", "Jiribam", "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal", "Thoubal", "Ukhrul"],
                    ["East Garo Hills", "East Jaintia Hills", "East Khasi Hills", "North Garo Hills", "Ri Bhoi", "South Garo Hills", "South West Garo Hills ", "South West Khasi Hills", "West Garo Hills", "West Jaintia Hills", "West Khasi Hills"],
                    ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip"],
                    ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunheboto"],
                    ["Angul", "Balangir", "Balasore", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Deogarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajpur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar (Keonjhar)", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonepur", "Sundargarh"],
                    ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
                    ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Ferozepur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Muktsar", "Nawanshahr (Shahid Bhagat Singh Nagar)", "Pathankot", "Patiala", "Rupnagar", "Sahibzada Ajit Singh Nagar (Mohali)", "Sangrur", "Tarn Taran"],
                    ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalore", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"],
                    ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
                    ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Thoothukudi (Tuticorin)", "Tiruchirappalli", "Tirunelveli", "Tiruppur", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Vellore", "Viluppuram", "Virudhunagar"],
                    ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar Bhoopalpally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem Asifabad", "Mahabubabad", "Mahabubnagar", "Mancherial", "Medak", "Medchal", "Nagarkurnool", "Nalgonda", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Rangareddy", "Sangareddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal (Rural)", "Warangal (Urban)", "Yadadri Bhuvanagiri"],
                    ["Dhalai", "Gomati", "Khowai", "North Tripura", "Sepahijala", "South Tripura", "Unakoti", "West Tripura"],
                    ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"],
                    ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Amethi (Chatrapati Sahuji Mahraj Nagar)", "Amroha (J.P. Nagar)", "Auraiya", "Azamgarh", "Baghpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bhadohi", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hapur (Panchsheel Nagar)", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kanshiram Nagar (Kasganj)", "Kaushambi", "Kushinagar (Padrauna)", "Lakhimpur - Kheri", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "RaeBareli", "Rampur", "Saharanpur", "Sambhal (Bhim Nagar)", "Sant Kabir Nagar", "Shahjahanpur", "Shamali (Prabuddh Nagar)", "Shravasti", "Siddharth Nagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"],
                    ["Alipurduar", "Bankura", "Birbhum", "Burdwan (Bardhaman)", "Cooch Behar", "Dakshin Dinajpur (South Dinajpur)", "Darjeeling", "Hooghly", "Howrah", "Jalpaiguri", "Kalimpong", "Kolkata", "Malda", "Murshidabad", "Nadia", "North 24 Parganas", "Paschim Medinipur (West Medinipur)", "Purba Medinipur (East Medinipur)", "Purulia", "South 24 Parganas", "Uttar Dinajpur (North Dinajpur)"]
                ]


        };
        this.getRegistrationDetails = this.getRegistrationDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleChangeDropdown = this.handleChangeDropdown.bind(this);
        this.handleChangeVaccineDropdown = this.handleChangeVaccineDropdown.bind(this);
        this.handleChangeImage = this.handleChangeImage.bind(this);
    }

    async componentDidMount() {

        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_type = await localStorage.getItem('participant_type')
        if (participant_type === null) {
            localStorage.setItem('participant_type', this.props.location.state.participant_type)
        }
        const updated_participant_type = await localStorage.getItem('participant_type')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participant_type: updated_participant_type
        })

        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getRegistrationDetails();
        this.getRegistrationDetailsData();


    }
    getRegistrationDetailsData() {
        axios.post(path, {
            participantType: "",
            eventId: "61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(res);
            const ParticipantDetails = res.response.data[0];
            if (res.status === true) {
                this.setState({
                    participant_details: ParticipantDetails
                });

            }
        });
    }




    handleChange(event, answrFldName) {

        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let updated_val = this.state.participant_details;
        updated_val[answrFldName] = value;
        //console.log(value); console.log(name); console.log(answrFldName);
        this.setState({
            [name]: value,
            participant_details: updated_val
        });
    }

    handleChangeImage(event, quesOrder, answrFldName) {
        //console.log(event);
        const target = event.target;
        //const value = target.value;
        const name = target.name;

        //console.log(value); console.log(name);
       // console.log(event.target.files);
        let totalFiles = event.target.files ? event.target.files.length : 0;
        let keyquesOrder = quesOrder + "_order";
        let keyimgName = name + "_img";
        let keyimgFile = name + "_file";
        let keyimgpreviewFile = name + "_preview";
        // console.log(totalFiles);
        var fileInput = false;
        if (event.target.files && totalFiles > 0) {
            //Part 1
            let imgNameArr = this.state.imageNameArr;
            imgNameArr.push(event.target.files[totalFiles - 1].name.split('.').slice(0, -1).join('.'));
            //Part 2
            //let imgNameArr1 = event.target.files[totalFiles - 1].name.split('.').slice(0, -1).join('.');
            //Part 3
            let imgNameArr1 = "changed";



            let img_extension = event.target.files[totalFiles - 1].name.split('.').pop();;
            // console.log(img_extension);

            const files = [...this.state.imgArr]; // Spread syntax creates a shallow copy
            //console.log(imgNameArr);
            // console.log(imgNameArr1);
            files.push(event.target.files[totalFiles - 1]);

            let files1 = event.target.files[totalFiles - 1];
            //console.log(files);
            //console.log(files1);




            //compress
            if (img_extension === 'png' || img_extension === 'jpg' || img_extension === 'jpeg' || img_extension === 'webp' || img_extension === 'gif' ||
                img_extension === 'PNG' || img_extension === 'JPG' || img_extension === 'JPEG' || img_extension === 'WEBP' || img_extension === 'GIF') {
                fileInput = true;
            } else {
                fileInput = false;
            }





            if (fileInput) {
                //console.log(this.state.img_org_height,this.state.img_org_width);
                try {
                    Resizer.imageFileResizer(
                        files1,
                        600, 600,
                        "JPEG",
                        90,
                        0,
                        (uri) => {
                            // console.log(uri);
                            // this.setState({ newImage: uri });
                            let imgDetailsArr = [];
                            let imgDetails = [
                                {
                                    "imgName": imgNameArr1,
                                    "imgOrder": quesOrder,
                                    "imgFile": uri,
                                }
                            ]
                            imgDetailsArr.push(imgDetails);
                            //console.log(imgDetailsArr)
                            this.setState({
                                [keyquesOrder]: quesOrder,
                                [keyimgName]: imgNameArr1,
                                [keyimgFile]: uri,
                                [name]: true,
                                imageNameArr: imgNameArr,
                                imgArr: files,
                                [keyimgpreviewFile]: URL.createObjectURL(event.target.files[totalFiles - 1])
                            })
                        },
                        "file"
                    );
                } catch (err) {
                    //console.log(err);
                }
            } else {
                this.setState({
                    [keyquesOrder]: quesOrder,
                    [keyimgName]: imgNameArr1,
                    [keyimgFile]: files1,
                    [name]: true,
                    imageNameArr: imgNameArr,
                    imgArr: files,
                    [keyimgpreviewFile]: process.env.PUBLIC_URL + '/assets/images/pdf1.png'
                })
                // console.log(this.state);

            }

            //compress




        } else if (event.target.files && totalFiles === 0) {
            let keyimgName = name + "_img";
            let keyimgFile = name + "_file";
            this.setState({
                //[keyimgName]: "",
                [keyimgName]: "remove",
                [keyquesOrder]: "",
                [keyimgFile]: null,
                [name]: false,
                imageNameArr: [],
                [keyimgpreviewFile]: ""
            })
            // console.log(this.state);
        }
    }



    generateDistrictDropdown(index) {
        const stateArr = this.state.state_array;
        let slectedDistricts = stateArr[index];
        this.setState({
            district_options: slectedDistricts
        });
    }


    handleChangeDropdown(event, answrFldName) {
       // console.log(answrFldName);
        const target = event.target;
        const value = target.value;
        const name = target.name;
        // console.log(value);console.log(name);
        const json_value = JSON.parse(value);
        let updated_val = this.state.participant_details;
        updated_val[answrFldName] = value;
       // console.log(value); console.log(name); console.log(answrFldName);
        // console.log(this.state.state_array);
        // console.log(json_value);
        this.setState({
            [name]: json_value.state,
            index: json_value.index,
            participant_details: updated_val
        });
        this.generateDistrictDropdown(json_value.index);

    }

    handleChangeVaccineDropdown(event, answrFldName) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let updated_val = this.state.participant_details;
        updated_val[answrFldName] = value;
        //console.log(value); console.log(name); console.log(answrFldName);
        this.setState({
            [name]: value,
            participant_details: updated_val
        });


        let new_quesArray = [];
        let qList = this.state.registration_ques_list;
        if (value !== "Not Vaccinated") {
            new_quesArray = qList.map((eachyaquestionDetails, i) => {
                if (eachyaquestionDetails.fieldName === "Upload Vaccination Certificate (image/PDF,max file size 1 mb)") {
                    eachyaquestionDetails.questionMandatory = "true";
                    //console.log(eachyaquestionDetails);
                    return eachyaquestionDetails;
                }
            })
        } else {
            new_quesArray = qList.map((eachyaquestionDetails, i) => {
                if (eachyaquestionDetails.fieldName === "Upload Vaccination Certificate (image/PDF,max file size 1 mb)") {
                    eachyaquestionDetails.questionMandatory = "false";
                    //console.log(eachyaquestionDetails);
                    return eachyaquestionDetails;
                }
            })
        }

        //console.log(qList);
        this.setState({
            new_quesArray: qList
        });


        if (value === "Not Vaccinated") {
            this.setState({ reqvalue: false })
        } else {
            this.setState({ reqvalue: true })
        }

        if (value === "Not Vaccinated") { this.setState({ dropdownvalue: "hide" }) } else { this.setState({ dropdownvalue: "show" }) }
    }



    handleChangeRadio(event, i, j, length, answrFldName) {
        //console.log((i,j,length));
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let updated_val = this.state.participant_details;
        updated_val[answrFldName] = value;
       // console.log(value); console.log(name); console.log(answrFldName);
        this.setState({
            [name]: value,
            participant_details: updated_val
        });

        if (j === length - 1) {
            this.setState({ divvalue: "show", changeIndex: i })
        } else {
            this.setState({ divvalue: "hide", changeIndex: null })
        }

        // if(value==="Other" || value==="Yes"){this.setState({divvalue:"show"})}else{this.setState({divvalue:"hide"})}
    }

    handleSubmit(event) {
        // console.log(this.state);
        event.preventDefault();
        document.getElementById("exampleModalCenter").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
            .forEach(el => el.classList.remove("modal-backdrop"));
        // event.stopPropagation();
        this.saveRegistrationDetails();
    }



    validationCheck(answerArray) {
        //console.log("Inside validationCheck");
        //console.log(this.state.answerArray);
        let isValidate = true;
        // console.log(answerArray);

        answerArray.map((eachqs, i) => {
            // console.log(eachqs.questionMandatory);
            if (eachqs.questionMandatory === "true") {
                // console.log("Inside true");
                if (eachqs.answer === null || eachqs.answer === false) {
                    //console.log("Inside true null");

                    // console.log(eachqs.questionId);
                    isValidate = false;
                    return isValidate;
                }
            }

            // console.log("Inside validationCheck end");


        })
        return isValidate;
    }

    saveRegistrationDetails() {
        // let quesArray = [];
        // this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
        //     // console.log(this.state[eachyaquestionDetails.questionId])
        //     let eachObject = {
        //         "questionId": eachyaquestionDetails.questionId,
        //         "answer": this.state[eachyaquestionDetails.questionId] ?
        //             this.state[eachyaquestionDetails.questionId] : (eachyaquestionDetails.questionType === "upload image" ? false : "")
        //     }
        //     quesArray.push(eachObject);
        //     return eachObject;

        // })

        // console.log(quesArray);

        //for resubmit prevalue array
        let prequesArray = [];
        this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
            let answerObj = {
                "questionId": eachyaquestionDetails["questionId"],
                "answer": this.state[eachyaquestionDetails.questionId] ?
                    this.state[eachyaquestionDetails.questionId]
                    :
                    (eachyaquestionDetails.questionType === "upload image" ?
                        (this.state.participant_details[eachyaquestionDetails["fieldName"]][0] ? true : false)
                        :
                        (this.state.participant_details[eachyaquestionDetails["fieldName"]] ?
                            this.state.participant_details[eachyaquestionDetails["fieldName"]] : ""))
            }
            prequesArray.push(answerObj);

        })

        //console.log(prequesArray)





        //for imgName
        let dummyimgnameArray = [], dimgname;
        this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
            if (this.state[eachyaquestionDetails.questionId]) {
                if (eachyaquestionDetails.questionType === "upload image") {
                    dimgname = "changed"
                    dummyimgnameArray.push(dimgname);
                }

            } else if (eachyaquestionDetails.questionType === "upload image") {
                if (this.state.participant_details[eachyaquestionDetails["fieldName"]][0]) {
                    dimgname = "yes"
                    dummyimgnameArray.push(dimgname);
                } else {
                    dimgname = "no"
                    dummyimgnameArray.push(dimgname);
                }

            }
            return dimgname;

        })
      //  console.log(dummyimgnameArray);
        this.setState({
            dummyimgnameArray: dummyimgnameArray,
        });

        let answerArray = [];
        this.state.new_quesArray.map((eachyaquestionDetails, i) => {
            let eachObject = {
                "questionId": eachyaquestionDetails.questionId,
                "questionMandatory": eachyaquestionDetails.questionMandatory,
                "answer": this.state[eachyaquestionDetails.questionId] ?
                    this.state[eachyaquestionDetails.questionId]
                    :
                    (eachyaquestionDetails.questionType === "upload image" ?
                        (this.state.participant_details[eachyaquestionDetails["fieldName"]][0] ? true : false)
                        :
                        (this.state.participant_details[eachyaquestionDetails["fieldName"]] ?
                            this.state.participant_details[eachyaquestionDetails["fieldName"]] : ""))
            }
            answerArray.push(eachObject);
            return eachObject;

        })
        //console.log(answerArray);

        let formData = new FormData();
        formData.append('eventId', "61c95e248335ae7cb8df1699");   //append the values with key, value pair
        formData.append('participantType', this.state.participant_type);
        formData.append('formData', JSON.stringify(prequesArray));
        formData.append('participantID', this.state.participant_details._id);

        //console.log(JSON.stringify(quesArray));
        // formData.append('imageName', JSON.stringify(this.state.imageNameArr));
        // this.state.imgArr.forEach((file) => formData.append('image', file));




        let keyimgNameArray = [], keyimgFileArray = [], keyimgOrderArray = [];
        let stateval = this.state;
        //for image name
        var filteredNames = Object.keys(stateval).filter((name) => /_img/.test(name));
       // console.log(filteredNames);
        filteredNames.map(name => {

            if (stateval[name] !== "") {
                keyimgNameArray.push(stateval[name]);
            }
        });
       // console.log(keyimgNameArray);

        //for image file
        var filteredFiles = Object.keys(stateval).filter((name) => /_file/.test(name));
        // console.log(filteredFiles);
        filteredFiles.map(name => {
            if (stateval[name] !== null) {
                keyimgFileArray.push(stateval[name]);
            }

        });
        //console.log(keyimgFileArray);

        //for image order
        var filteredimgOrder = Object.keys(stateval).filter((name) => /_order/.test(name));
        filteredimgOrder.map(name => {
            if (stateval[name] !== "") {
                keyimgOrderArray.push(stateval[name]);
            }

        });
        //console.log(keyimgOrderArray);

        //var response = { data: [{ abc: keyimgOrderArray }, { abc: keyimgNameArray }, { abc: keyimgFileArray }] }

        var response = { data: [{ abc: keyimgOrderArray }, { abc: keyimgFileArray }] }
        var users = response.data.map(o => o.abc).flat();

        //console.log(users);

        for (let i = 0; i < keyimgOrderArray.length - 1; i++) {
            for (let j = 0; j < keyimgOrderArray.length - i - 1; j++) {
                if (users[j] > users[j + 1]) {
                    let imgOrderSwap = users[j];
                    users[j] = users[j + 1];
                    users[j + 1] = imgOrderSwap;

                    const file_index = keyimgOrderArray.length;
                    let imgFileSwap = users[j + file_index];
                    users[j + file_index] = users[j + file_index + 1];
                    users[j + file_index + 1] = imgFileSwap;

                    // const name_index = keyimgOrderArray.length;
                    // let imgNameSwap = users[j + name_index];
                    // users[j + name_index] = users[j + name_index + 1];
                    // users[j + name_index + 1] = imgNameSwap;

                    // const file_index = keyimgOrderArray.length;
                    // let imgFileSwap = users[j + name_index + file_index];
                    // users[j + name_index + file_index] = users[j + name_index + file_index + 1];
                    // users[j + name_index + file_index + 1] = imgFileSwap;

                }
            }
        }

        //console.log(users);
        // let finalImgFileArr = users.slice(keyimgOrderArray.length * 2);
        // let finalImgNameArr = users.slice(keyimgOrderArray.length, keyimgOrderArray.length * 2);
        // console.log(finalImgNameArr)
        //  console.log(finalImgFileArr)

        let finalImgFileArr = users.slice(keyimgOrderArray.length, keyimgOrderArray.length * 2);
        //  console.log(finalImgFileArr)
       //console.log(this.state.oldImgArr);
        formData.append('oldImageName', JSON.stringify(this.state.oldImgArr));
        formData.append('imageName', JSON.stringify(dummyimgnameArray));
        finalImgFileArr.forEach((file) => formData.append('image', file));


        //console.log("before validationcheck call");

        if (this.validationCheck(answerArray)) {

            axios.post(path, formData,
                {
                    headers: {
                        'api-name': 'saveRegistrationDetails',
                        'Content-Type': 'multipart/form-data',
                        'userId': this.state.userId,
                        'AuthToken': this.state.AuthToken
                    }
                })
                .then((serverRes) => {
                    // window.scrollTo(0, 0);
                    const res = serverRes.data;

                    //console.log(serverRes);
                    //console.log(res);

                    if (res.status === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.props.history.push("/amrit_after_registration");

                    } else {
                        //console.log(res.message);
                        toast.error(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        // window.scroll({
                        //     top: 100,
                        //     left: 100,
                        //     behavior: 'smooth'
                        //   });
                        //  window.location.reload(true);
                        // this.scrollToBottom();

                    }
                });


        } else {

            toast.error("Please fill up all the fields", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

        }

    }




    getRegistrationDetails() {
        axios.post(path, {
            participantType: this.state.participant_type,
            eventId: "61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            const RegQuesList = res.response.registration;
            //console.log(RegQuesList);
            if (res.status === true) {
                this.setState({
                    registration_ques_list: RegQuesList
                });
                //Old image url
                let oldImg = [], eachObj;
                this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
                    if (eachyaquestionDetails.questionType === "upload image") {
                        if (this.state.participant_details[eachyaquestionDetails.fieldName] && this.state.participant_details[eachyaquestionDetails.fieldName][0]) {
                            eachObj = this.state.participant_details[eachyaquestionDetails.fieldName][0];
                        } else {
                            eachObj = ""
                        }
                        oldImg.push(eachObj);
                        return eachObj;

                    }

                })
               // console.log(oldImg);


                let dummyImgArr = [], dummyname;
                this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
                    if (eachyaquestionDetails.questionType === "upload image") {
                        if (this.state.participant_details[eachyaquestionDetails.fieldName] && this.state.participant_details[eachyaquestionDetails.fieldName][0]) {
                            dummyname = "yes";
                        } else {
                            dummyname = "no"
                        }
                        dummyImgArr.push(dummyname);
                        return dummyname;

                    }

                })
                //console.log(dummyImgArr);
                this.setState({
                    oldImgArr: oldImg,
                    dummyImgArr: dummyImgArr
                });

                //Old image url


                this.state.registration_ques_list.map((eachyaquestionDetails, i) => {
                    if (eachyaquestionDetails.fieldName === "Participation State Preference") {
                        eachyaquestionDetails.options.map((eachoption, j) => {
                            if (this.state.participant_details[eachyaquestionDetails.fieldName] === eachoption) {
                               // console.log("print j" + j);
                                this.setState({
                                    stateIndexval: j
                                })
                                this.generateDistrictDropdown(j);
                            }

                        })




                    }


                })
                //console.log(oldImg);

            }


        });
    }

    getExtension(url){
       // let url="https://sobharatbucket.s3.ap-south-1.amazonaws.com/ProfilePics/profilePic-610a2ca8eff7014ce19a2edd.pdf";
        return url. substring(url. lastIndexOf(".") + 1);
        
    }
    // deleteImage(imgId){
    //     console.log(imgId);
    //     let imgField=this.state.participant_details;
    //     imgField[imgId]=""
    //     this.setState({
    //         participant_details : imgField,
    //         isDeleteBtnActive : false
    //     })
    // }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                <section className="amritRegd">
                    <div className="amritRegdInn officialBase">
                        <div className="amritRegdBlock amritAfterregd officials">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt="" /></Link>
                            <h3 className="partPdn">Participants</h3>
                            <form>
                                <div className="newSelect" >
                                    <select name="Country" id="country" >
                                        <option className="slRedbg" value="">{this.state.participant_type}</option>

                                    </select>
                                </div>
                                <div className="officialInn">
                                    <div className="uploadCertificate">

                                        {this.state.registration_ques_list.map((eachamritquestionDetails, i) => (

                                            <div key={i}>
                                                {/* {(i+1) % 3 === 0 || i% 3 === 0? this.state.preDiv:""} */}
                                                {eachamritquestionDetails.questionType === "dropdown" ?

                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>{eachamritquestionDetails.questionDisplay}</label>

                                                            {/* State */}
                                                            {eachamritquestionDetails.fieldName === "Participation State Preference" ?
                                                                <div className="newSelect" >
                                                                    <select name={eachamritquestionDetails.questionId} id={eachamritquestionDetails.questionId} value={JSON.stringify({ "state": this.state.participant_details[eachamritquestionDetails.fieldName], "index": this.state.stateIndexval })} onChange={event => this.handleChangeDropdown(event, eachamritquestionDetails.fieldName)}>
                                                                        <option className="slRedbg" value="">Select Option</option>

                                                                        {eachamritquestionDetails.options.map((eachoption, j) => (
                                                                            <option className="slRedbg" value={JSON.stringify({ "state": eachoption, "index": j })}  key={j}>{eachoption}</option>

                                                                        ))}

                                                                    </select>
                                                                </div>
                                                                : (eachamritquestionDetails.fieldName === "Participation District Preference" ?
                                                                    // District
                                                                    <div className="newSelect" >
                                                                        <select name={eachamritquestionDetails.questionId} id={eachamritquestionDetails.questionId} value={this.state.participant_details[eachamritquestionDetails.fieldName]} onChange={event => this.handleChange(event, eachamritquestionDetails.fieldName)}>
                                                                            <option className="slRedbg" value="">Select Option</option>

                                                                            {this.state.district_options.map((eachoption, j) => (
                                                                                <option className="slRedbg" value={eachoption} key={j}>{eachoption}</option>

                                                                            ))}

                                                                        </select>
                                                                    </div>

                                                                    : (eachamritquestionDetails.fieldName === "Vaccination Details" ?
                                                                        // Vacnination details dropdown
                                                                        <div className="newSelect" >
                                                                            <select name={eachamritquestionDetails.questionId} id={eachamritquestionDetails.questionId} value={this.state.participant_details[eachamritquestionDetails.fieldName]} onChange={event => this.handleChangeVaccineDropdown(event, eachamritquestionDetails.fieldName)}>
                                                                                <option className="slRedbg" value="">Select Option</option>

                                                                                {eachamritquestionDetails.options.map((eachoption, j) => (
                                                                                    <option className="slRedbg" value={eachoption} key={j}>{eachoption}</option>

                                                                                ))}

                                                                            </select>
                                                                        </div>
                                                                        :
                                                                        // others
                                                                        <div className="newSelect" >
                                                                            <select name={eachamritquestionDetails.questionId} id={eachamritquestionDetails.questionId} value={this.state.participant_details[eachamritquestionDetails.fieldName]} onChange={event => this.handleChange(event, eachamritquestionDetails.fieldName)}>
                                                                                <option className="slRedbg" value="">Select Option</option>

                                                                                {eachamritquestionDetails.options.map((eachoption, j) => (
                                                                                    <option className="slRedbg" value={eachoption} key={j}>{eachoption}</option>

                                                                                ))}

                                                                            </select>
                                                                        </div>
                                                                    ))}
                                                        </div>
                                                    </div>
                                                    : eachamritquestionDetails.questionType === "textbox" ?
                                                        <div className='col-md-12'>
                                                            <div className="form-group volunteerTextarea">
                                                                <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                <textarea className="form-control" name={eachamritquestionDetails.questionId} value={this.state.participant_details[eachamritquestionDetails.fieldName]} onChange={event => this.handleChange(event, eachamritquestionDetails.fieldName)}></textarea>
                                                            </div>
                                                        </div>

                                                        : eachamritquestionDetails.questionType === "mcqwhe" ?

                                                            <div className='col-md-12'>
                                                                <div className="form-group">
                                                                    <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                    <br />
                                                                    <div className="page4Radio page5Radio">
                                                                        {eachamritquestionDetails.options.map((eachoption, j) => (
                                                                            <div className="form-check" key={i + j}>
                                                                                <input className="form-check-input" type="radio"
                                                                                    checked={this.state.participant_details[eachamritquestionDetails.fieldName] === eachoption}
                                                                                    name={eachamritquestionDetails.questionId} id={"radio" + i + j} value={eachoption} onChange={event => this.handleChange(event, eachamritquestionDetails.fieldName)} required />
                                                                                <label className="form-check-label" htmlFor={"radio" + i + j}>
                                                                                    {eachoption}
                                                                                </label>
                                                                            </div>
                                                                        ))}

                                                                    </div>

                                                                </div>
                                                            </div>

                                                            : eachamritquestionDetails.questionType === "mcqwe" ?

                                                                <div className='col-md-12'>
                                                                    <div className="form-group">
                                                                        <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                        <br />
                                                                        <div className="page4Radio page5Radio">
                                                                            {eachamritquestionDetails.options.map((eachoption, j) => (

                                                                                <div className="form-check" key={i + j}>
                                                                                    <input className="form-check-input" type="radio" name={eachamritquestionDetails.questionId}
                                                                                        id={"radio" + i + j} value={eachoption}
                                                                                        checked={this.state.participant_details[eachamritquestionDetails.fieldName] === eachoption}
                                                                                        onChange={event => this.handleChangeRadio(event, i, j, eachamritquestionDetails.options.length, eachamritquestionDetails.fieldName)} required />
                                                                                    <label className="form-check-label" htmlFor={"radio" + i + j}>
                                                                                        {eachoption}
                                                                                    </label>
                                                                                </div>
                                                                            ))}

                                                                        </div>

                                                                    </div>
                                                                </div>



                                                                : eachamritquestionDetails.questionType === "upload image" ?
                                                                (eachamritquestionDetails.fieldName === "Upload Vaccination Certificate (image/PDF,max file size 1 mb)" ?
                                                                    <div  className={"col-md-12 " + this.state.dropdownvalue}>
                                                                        <div className="form-group">
                                                                            <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                            <br />
                                                                         
                                                                            <label>
                                                                                <input id="fileButton" type="file" className="image-upload" name={eachamritquestionDetails.questionId}

                                                                                    onChange={event => this.handleChangeImage(event, eachamritquestionDetails.questionOrder, eachamritquestionDetails.fieldName)}
                                                                                />
                                                                               
                                                                                
                                                                            </label>

                                                                        </div>
                                                                        {this.state[`${eachamritquestionDetails.questionId}_preview`] ?
                                                                            (<div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                style={{ backgroundImage: `url(${this.state[`${eachamritquestionDetails.questionId}_preview`]})` }}
                                                                            ></div>)
                                                                             : (this.state.participant_details[eachamritquestionDetails.fieldName] && 
                                                                                this.state.participant_details[eachamritquestionDetails.fieldName].length>0?(
                                                                                this.getExtension(this.state.participant_details[eachamritquestionDetails.fieldName][0]) === "pdf" ?
                                                                                (
                                                                                    <div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/pdf1.png)` }}
                                                                                    ></div>
                                                                                ):(
                                                                                    <div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                    style={{ backgroundImage: `url(${this.state.participant_details[eachamritquestionDetails.fieldName][0]})` }}
                                                                                ></div>
                                                                                )
                                                                            )
                                                                            :(
                                                                                <div className="js--image-preview"></div>
                                                                                
                                                                            ))}

                                                                    </div>
                                                                    :
                                                                    <div className='col-md-12'>
                                                                        <div className="form-group">
                                                                            <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                            <br />
                                                                         
                                                                            <label>
                                                                                <input id="fileButton" type="file" className="image-upload" name={eachamritquestionDetails.questionId}

                                                                                    onChange={event => this.handleChangeImage(event, eachamritquestionDetails.questionOrder, eachamritquestionDetails.fieldName)}
                                                                                />
                                                                               
                                                                                
                                                                            </label>

                                                                        </div>
                                                                        {this.state[`${eachamritquestionDetails.questionId}_preview`] ?
                                                                            (<div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                style={{ backgroundImage: `url(${this.state[`${eachamritquestionDetails.questionId}_preview`]})` }}
                                                                            ></div>)
                                                                             : (this.state.participant_details[eachamritquestionDetails.fieldName] && 
                                                                                this.state.participant_details[eachamritquestionDetails.fieldName].length>0?(
                                                                                this.getExtension(this.state.participant_details[eachamritquestionDetails.fieldName][0]) === "pdf" ?
                                                                                (
                                                                                    <div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/pdf1.png)` }}
                                                                                    ></div>
                                                                                ):(
                                                                                    <div className="js--image-preview" id={eachamritquestionDetails.questionId}
                                                                                    style={{ backgroundImage: `url(${this.state.participant_details[eachamritquestionDetails.fieldName][0]})` }}
                                                                                ></div>
                                                                                )
                                                                            )
                                                                            :(
                                                                                <div className="js--image-preview"></div>
                                                                                
                                                                            ))}

                                                                    </div>
                                                                )





                                                                    : eachamritquestionDetails.questionType === "text" ?
                                                                        // <div className={((i+1) % 3 === 0 ? 'col-md-12' :'col-md-6')}>
                                                                        <div className='col-md-12'>
                                                                            <div className="form-group">
                                                                                <label>{eachamritquestionDetails.questionDisplay}</label>
                                                                                <input className="form-control"
                                                                                    type="text"
                                                                                    value={this.state.participant_details[eachamritquestionDetails.fieldName]}
                                                                                    maxLength={eachamritquestionDetails.textLength > 0 ? eachamritquestionDetails.textLength : ""}
                                                                                    name={eachamritquestionDetails.questionId} placeholder={eachamritquestionDetails.questionPlaceholder}
                                                                                    onChange={event => this.handleChange(event, eachamritquestionDetails.fieldName)} />
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        ""}
                                                {/* {(i+1) % 3 === 0 || (i+2)% 3 === 0? this.state.postDiv:""} */}
                                            </div>
                                        ))}

                                    </div>

                                    {/* <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                        <span className="form-check-label" htmlFor="defaultCheck1">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry <a href="">Click Here</a>
                                        </span>
                                    </div> */}

                                    {/* <button type="button" className="btn btn-primary" onClick={this.handleSubmit} >Submit</button> */}
                                    <button type="button" className="btn btn-primary btn-submit" data-toggle="modal" data-target="#exampleModalCenter">Submit</button>
                                </div>
                                <div className="intBtn">
                                <Link to="/amrit_after_registration"  className="intbtnb"> Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>

                {/* <!-- Modal --> */}
                <div className="modal fade cautionModal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="cautionHdn"><i className="fas fa-exclamation-triangle"></i> <span>Caution</span></div>
                                <p>Are you sure you want to submit?</p>
                                <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>
                                <button type="button" onClick={this.handleSubmit} data-dismiss="modal" className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default Amrit_Participants_Resubmit;