import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Introduction extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            nextRouteLink:""
        }
        this.getNextRoute = this.getNextRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }

    async componentDidMount() {
        this.saveCurrentPos(0);
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        //console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
        //console.log(currentPosition)
        let nextPosition = parseInt(currentPosition)+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        //console.log(nextPosition)
        this.saveCurrentPos(nextPosition);
        let elemIdForRoute = routeOrderArray[nextPosition]
        //console.log(elemIdForRoute)
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        //console.log(nextRouteLink)        
        this.setState({
            nextRouteLink:nextRouteLink
        })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
        
    }

 //This function can be called from every sub pages for  next one
 async getNextRoute(){
   
    let nextRouteLink=this.state.nextRouteLink.toString();
    //Navigate to routeLink
    //console.log('newnextnextRouteLink-'+nextRouteLink);
    //  return <Redirect to={nextRouteLink} />
   
    this.props.history.push({
        pathname: `${nextRouteLink}`           
    })

    //same methodology can be used in case of previous route link (use -1 instead of +1)
}

saveCurrentPos(pos){
    // save currentPos variable to localstorage and set it to 0
  localStorage.setItem('currentPos',pos)
}

    render() {
        return (
            <div>
                 <Header {...this.props} />
                
                <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                        <h3>Introduction to FIT 5 </h3>
                                        <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
                
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                    <h4>What is FIT 5?</h4>
                                    <ul>
                                        <li>Athletes want to perform their best at every competition. You can do this by being fit. </li>
                                        <li>Fit 5 is a plan for physical activity, nutrition, and hydration. </li>
                                        <li>It can improve your health and fitness to make you the best athlete you can be!</li>
                                    </ul>
                                    <h4>Goals of FIT 5</h4>
                                    <div className="introFit if1">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/intro1.png'} alt=""/>
                                        <span>Exercise 5 Days a Week</span>
                                    </div>
                                    
                                    <div className="introFit if2">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/intro2.png'} alt=""/>
                                        <span>Eats Fruits &amp; Vegitables Every Day</span>
                                    </div>
                                    
                                    <div className="introFit if3">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/intro3.png'} alt=""/>
                                        <span>Drink 5 Waterbottles Every Day</span>
                                    </div>
                                    <div className="intBtn"><Link to="/fit_five" className="intbtna">Back to Main Menu</Link> <Link to="#" onClick={()=>this.getNextRoute()} className="intbtnb">Next</Link></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    


            </div>
        )
    }
}
export default Introduction;