import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_After_Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            name: "",
            participant_details: [],
        };
    }
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.getRegistrationDetails();
        localStorage.removeItem("participant_type");
    }



    removeAmDetails(participant_id) {
        document.getElementById("exampleModalCenter").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
            .forEach(el => el.classList.remove("modal-backdrop"));
        axios.post(path, {
            participant_id: participant_id,
        }, {
            headers: { 'api-name': 'removeAmDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {

                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.props.history.push("/amrit_registration");
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }



    getRegistrationDetails() {
        axios.post(path, {
            participantType: "",
            eventId: "61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res);
            const ParticipantDetails = res.response.data[0];
            if (res.status === true) {
                this.setState({
                    participant_details: ParticipantDetails,

                });

                // localStorage.setItem('medical_type', ParticipantDetails['medical program selection'])

            }
        });
    }

    getProfile() {
        axios.post(path + "getProfile", {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                });
            }
        });
    }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock amritAfterregd">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt="" /></Link>
                            {/* <h3>You have registered and pending for approval</h3> */}
                            <h3></h3>
                            <div className="afterRegdamrit">
                                <div className="aftRinner">
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Participant's Name:</span>
                                        <span className="afterAmrTxtb">{this.state.name !== "" ? this.state.name : "---"}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Participant's Type:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details.participantType !== "" ? this.state.participant_details.participantType : "---"}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Status:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details.isRejected === true ? "Not Approved" : (this.state.participant_details.isApproved === true ? "Approved" : "Pending for Approval")}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Preferred State:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Participation State Preference"] !== "" ? this.state.participant_details["Participation State Preference"] : "---"}</span>
                                    </div>

                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Preferred City:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Participation District Preference"] !== "" ? this.state.participant_details["Participation District Preference"] : "---"}</span>
                                    </div>
                                    {this.state.participant_details.isApproved === true ?
                                        <div className="afterAmr">
                                            <span className="afterAmrTxta">Event Date:</span>
                                            <span className="afterAmrTxtb">7th April,2022</span>
                                        </div> : ""}
                                    {this.state.participant_details.isApproved === true ?
                                        <div className="afterAmr">
                                            <span className="afterAmrTxta">Event Venue:</span>
                                            <span className="afterAmrTxtb">{this.state.participant_details.eventVenue !== "" ? this.state.participant_details.eventVenue : "---"}</span>
                                        </div> : ""}
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Vacination Details:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Vaccination Details"] !== "" ? this.state.participant_details["Vaccination Details"] : "---"}</span>
                                    </div>
                                </div>



                            </div>

                            <div className="intBtn">
                                {this.state.participant_details.isRejected === true ?
                                    <div style={{ marginBottom: 20 }}><span style={{ fontStyle: "italic", color: "#fc6161", fontSize: 17 }}><strong>Rejected Reason:  </strong>{this.state.participant_details.rejectComment}</span></div>
                                    : ""}


                                {this.state.participant_details.isApproved === true && this.state.participant_details.participantType === "medical professionals" ?
                                    <Link to={

                                        {
                                            pathname: '/amrit_medical_professional',
                                            state: {
                                                participant_id: this.state.participant_details._id
                                            }
                                        }
                                    } className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}>  Dashboard</Link>
                                    : (this.state.participant_details.isRejected === true ?
                                        <Link to={

                                            {
                                                pathname: '/amrit_participants_resubmit',
                                                state: {
                                                    participant_type: this.state.participant_details.participantType
                                                }
                                            }
                                        }
                                            className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}> Resubmit</Link>
                                        : "")
                                }




                                {this.state.participant_details.isApproved === true && this.state.participant_details.participantType === "officials" ?
                                    <Link to={

                                        {
                                            pathname: '/amrit_officials_dashboard',
                                            state: {
                                                participant_id: this.state.participant_details._id
                                            }
                                        }
                                    } className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}>  Dashboard</Link>
                                    : (this.state.participant_details.isApproved === true && this.state.participant_details.participantType === "athletes" ?
                                        <Link to={

                                            {
                                                pathname: '/amrit_athletes_show_id',
                                                state: {
                                                    participant_id: this.state.participant_details._id
                                                }
                                            }
                                        } className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}>  Dashboard</Link>
                                        : (this.state.participant_details.isApproved === true && this.state.participant_details.participantType === "volunteers" ?
                                            <Link to={

                                                {
                                                    pathname: '/amrit_checkin_checkout_status',
                                                    state: {
                                                        participant_id: this.state.participant_details._id
                                                    }
                                                }
                                            } className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}>  Dashboard</Link>
                                            : ""
                                        ))}









                                <Link to="/home" className="intbtnb"> Back to Home</Link>
                            </div>

                            {this.state.participant_details.isRejected === true ?
                                <div className="intBtn">
                                    <div style={{ marginBottom: 20 }}><span style={{ fontStyle: "bold", fontSize: 17 }}><strong>Note:  </strong>To change Participant Type, please delete the previous application and reapply.</span></div>

                                    <Link to="#" data-toggle="modal" data-target="#exampleModalCenter" className="backtohome" style={{ color: "white", borderColor: "#A18B64", textDecoration: "none" }}> Delete & Reapply</Link>
                                    {/* <button type="button" className="btn btn-primary btn-submit" data-toggle="modal" data-target="#exampleModalCenter">Submit</button> */}

                                </div>
                                : ""}


                        </div>
                    </div>
                </section>

                {/* <!-- Modal --> */}
                <div className="modal fade cautionModal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="cautionHdn"><i className="fas fa-exclamation-triangle"></i> <span>Caution</span></div>
                                <p>Information once deleted can't be reverted, please confirm before proceeding.</p>
                                <button type="button" onClick={() => this.removeAmDetails(this.state.participant_details._id)} data-dismiss="modal" className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Amrit_After_Registration;