import React from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { requestForToken } from "../components/firebase";
import apiPath from '../constant';
var path = apiPath.path;
//var path1 = apiPath.path1;
var sha1 = require('sha1');
// const validEmailRegex = RegExp(
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   );
// const regexPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
const regexPhone = /^(0|[1-9][0-9]*)$/;
class Login extends React.Component {
  constructor(props) {
    super(props);
    var deviceToken_val = Math.floor(1000 + Math.random() * 90000000);
    //console.log(val);
    this.state = {
      email: "",
      password: "",
      phone: "",
      countryCode: "",
      divvalue: "hide",
      deviceInfo: {
        deviceName: "lenevo",
        deviceType: "laptop",
        deviceModel: "L",
        deviceUniqueIdentifier: "sgasgsg1234"
      },
      deviceToken: deviceToken_val,
      country_details:
        [
          {
            name: "India",
            code: "IN",
            dial_code: "+91"
          },
          {
            name: "Afghanistan",
            code: "AF",
            dial_code: "+93"
          },
          {
            name: "Åland",
            code: "AX",
            dial_code: "+358"
          },
          {
            name: "Shqipëria",
            code: "AL",
            dial_code: "+355"
          },
          {
            name: "Algeria",
            code: "DZ",
            dial_code: "+213"
          },
          {
            name: "American Samoa",
            code: "AS",
            dial_code: "+1684"
          },
          {
            name: "Andorra",
            code: "AD",
            dial_code: "+376"
          },
          {
            name: "Angola",
            code: "AO",
            dial_code: "+244"
          },
          {
            name: "Anguilla",
            code: "AI",
            dial_code: "+1264"
          },
          {
            name: "Antarctica",
            code: "AQ",
            dial_code: "+672"
          },
          {
            name: "Antigua and Barbuda",
            code: "AG",
            dial_code: "+1268"
          },
          {
            name: "Argentina",
            code: "AR",
            dial_code: "+54"
          },
          {
            name: "Armenia",
            code: "AM",
            dial_code: "+374"
          },
          {
            name: "Aruba",
            code: "AW",
            dial_code: "+297"
          },
          {
            name: "Australia",
            code: "AU",
            dial_code: "+61"
          },
          {
            name: "Österreich",
            code: "AT",
            dial_code: "+43"
          },
          {
            name: "Azerbaijan",
            code: "AZ",
            dial_code: "+994"
          },
          {
            name: "Bahamas",
            code: "BS",
            dial_code: "+1242"
          },
          {
            name: "Bahrain",
            code: "BH",
            dial_code: "+973"
          },
          {
            name: "Bangladesh",
            code: "BD",
            dial_code: "+880"
          },
          {
            name: "Barbados",
            code: "BB",
            dial_code: "+1246"
          },
          {
            name: "Belarus",
            code: "BY",
            dial_code: "+375"
          },
          {
            name: "België",
            code: "BE",
            dial_code: "+32"
          },
          {
            name: "Belize",
            code: "BZ",
            dial_code: "+501"
          },
          {
            name: "Benign",
            code: "BJ",
            dial_code: "+229"
          },
          {
            name: "Bermuda",
            code: "BM",
            dial_code: "+1441"
          },
          {
            name: "ʼbrug-yul",
            code: "BT",
            dial_code: "+975"
          },
          {
            name: "Bolivia",
            code: "BO",
            dial_code: "+591"
          },
          {
            name: "Bosna i Hercegovina",
            code: "BA",
            dial_code: "+387"
          },
          {
            name: "Botswana",
            code: "BW",
            dial_code: "+267"
          },
          {
            name: "Bouvetøya",
            code: "BV",
            dial_code: "+47"
          },
          {
            name: "Brasil",
            code: "BR",
            dial_code: "+55"
          },
          {
            name: "British Indian Ocean Territory",
            code: "IO",
            dial_code: "+246"
          },
          {
            name: "Negara Brunei Darussalam",
            code: "BN",
            dial_code: "+673"
          },
          {
            name: "Bulgaria",
            code: "BG",
            dial_code: "+359"
          },
          {
            name: "Burkina Faso",
            code: "BF",
            dial_code: "+226"
          },
          {
            name: "Burundi",
            code: "BI",
            dial_code: "+257"
          },
          {
            name: "Cambodia",
            code: "KH",
            dial_code: "+855"
          },
          {
            name: "Cameroon",
            code: "CM",
            dial_code: "+237"
          },
          {
            name: "Canada",
            code: "CA",
            dial_code: "+1"
          },
          {
            name: "Cabo Verde",
            code: "CV",
            dial_code: "+238"
          },
          {
            name: "Cayman Islands",
            code: "KY",
            dial_code: "+1345"
          },
          {
            name: "Ködörösêse tî Bêafrîka",
            code: "CF",
            dial_code: "+236"
          },
          {
            name: "Tchad",
            code: "TD",
            dial_code: "+235"
          },
          {
            name: "Chile",
            code: "CL",
            dial_code: "+56"
          },
          {
            name: "China",
            code: "CN",
            dial_code: "+86"
          },
          {
            name: "Christmas Island",
            code: "CX",
            dial_code: "+61"
          },
          {
            name: "Cocos (Keeling) Islands",
            code: "CC",
            dial_code: "+61"
          },
          {
            name: "Colombia",
            code: "CO",
            dial_code: "+57"
          },
          {
            name: "Komori",
            code: "KM",
            dial_code: "+269"
          },
          {
            name: "Republic of Congo",
            code: "CG",
            dial_code: "+242"
          },
          {
            name: "Democratic Republic of Congo",
            code: "CD",
            dial_code: "+243"
          },
          {
            name: "Cook Islands",
            code: "CK",
            dial_code: "+682"
          },
          {
            name: "Costa Rica",
            code: "CR",
            dial_code: "+506"
          },
          {
            name: "Ivory Coast",
            code: "CI",
            dial_code: "+225"
          },
          {
            name: "Hrvatska",
            code: "HR",
            dial_code: "+385"
          },
          {
            name: "Cuba",
            code: "CU",
            dial_code: "+53"
          },
          {
            name: "Cyprus",
            code: "CY",
            dial_code: "+357"
          },
          {
            name: "Czech Republic",
            code: "CZ",
            dial_code: "+420"
          },
          {
            name: "Danmark",
            code: "DK",
            dial_code: "+45"
          },
          {
            name: "Djibouti",
            code: "DJ",
            dial_code: "+253"
          },
          {
            name: "Dominica",
            code: "DM",
            dial_code: "+1767"
          },
          {
            name: "República Dominicana",
            code: "DO",
            dial_code: "+1"
          },
          {
            name: "Ecuador",
            code: "EC",
            dial_code: "+593"
          },
          {
            name: "Egypt",
            code: "EG",
            dial_code: "+20"
          },
          {
            name: "El Salvador",
            code: "SV",
            dial_code: "+503"
          },
          {
            name: "Guinea Ecuatorial",
            code: "GQ",
            dial_code: "+240"
          },
          {
            name: "Eritrea",
            code: "ER",
            dial_code: "+291"
          },
          {
            name: "Eesti",
            code: "EE",
            dial_code: "+372"
          },
          {
            name: "Ethiopia",
            code: "ET",
            dial_code: "+251"
          },
          {
            name: "Falkland Islands",
            code: "FK",
            dial_code: "+500"
          },
          {
            name: "Faroe Islands",
            code: "FO",
            dial_code: "+298"
          },
          {
            name: "Fiji",
            code: "FJ",
            dial_code: "+679"
          },
          {
            name: "Suomi",
            code: "FI",
            dial_code: "+358"
          },
          {
            name: "France",
            code: "FR",
            dial_code: "+33"
          },
          {
            name: "Guyane française",
            code: "GF",
            dial_code: "+594"
          },
          {
            name: "Polynésie française",
            code: "PF",
            dial_code: "+689"
          },
          {
            name: "Territory of the Southern and Antarctic Lands fr",
            code: "TF",
            dial_code: "+262"
          },
          {
            name: "Gabon",
            code: "GA",
            dial_code: "+241"
          },
          {
            name: "Gambia",
            code: "GM",
            dial_code: "+220"
          },
          {
            name: "Georgia",
            code: "GE",
            dial_code: "+995"
          },
          {
            name: "Deutschland",
            code: "DE",
            dial_code: "+49"
          },
          {
            name: "Ghana",
            code: "GH",
            dial_code: "+233"
          },
          {
            name: "Gibraltar",
            code: "GI",
            dial_code: "+350"
          },
          {
            name: "Hellas",
            code: "GR",
            dial_code: "+30"
          },
          {
            name: "Kalaallit Nunaat",
            code: "GL",
            dial_code: "+299"
          },
          {
            name: "Grenada",
            code: "GD",
            dial_code: "+1473"
          },
          {
            name: "Guadeloupe",
            code: "GP",
            dial_code: "+590"
          },
          {
            name: "Guam",
            code: "GU",
            dial_code: "+1671"
          },
          {
            name: "Guatemala",
            code: "GT",
            dial_code: "+502"
          },
          {
            name: "Guernsey",
            code: "GG",
            dial_code: "+44"
          },
          {
            name: "Guinée",
            code: "GN",
            dial_code: "+224"
          },
          {
            name: "Guiné-Bissau",
            code: "GW",
            dial_code: "+245"
          },
          {
            name: "Guyana",
            code: "GY",
            dial_code: "+592"
          },
          {
            name: "Haïti",
            code: "HT",
            dial_code: "+509"
          },
          {
            name: "Heard Island and McDonald Islands",
            code: "HM",
            dial_code: "+0"
          },
          {
            name: "Vaticano",
            code: "VA",
            dial_code: "+379"
          },
          {
            name: "Honduras",
            code: "HN",
            dial_code: "+504"
          },
          {
            name: "Hongkong",
            code: "HK",
            dial_code: "+852"
          },
          {
            name: "Hungary",
            code: "HU",
            dial_code: "+36"
          },
          {
            name: "Ísland",
            code: "IS",
            dial_code: "+354"
          },

          {
            name: "Indonesia",
            code: "ID",
            dial_code: "+62"
          },
          {
            name: "Iran",
            code: "IR",
            dial_code: "+98"
          },
          {
            name: "Iraq",
            code: "IQ",
            dial_code: "+964"
          },
          {
            name: "Ireland",
            code: "IE",
            dial_code: "+353"
          },
          {
            name: "Isle of Man",
            code: "IM",
            dial_code: "+44"
          },
          {
            name: "Israel",
            code: "IL",
            dial_code: "+972"
          },
          {
            name: "Italia",
            code: "IT",
            dial_code: "+39"
          },
          {
            name: "Jamaica",
            code: "JM",
            dial_code: "+1876"
          },
          {
            name: "Japan",
            code: "JP",
            dial_code: "+81"
          },
          {
            name: "Jersey",
            code: "JE",
            dial_code: "+44"
          },
          {
            name: "Jordan",
            code: "JO",
            dial_code: "+962"
          },
          {
            name: "Kazakhstan",
            code: "KZ",
            dial_code: "+7"
          },
          {
            name: "Kenya",
            code: "KE",
            dial_code: "+254"
          },
          {
            name: "Kiribati",
            code: "KI",
            dial_code: "+686"
          },
          {
            name: "North Korea",
            code: "KP",
            dial_code: "+850"
          },
          {
            name: "Korea",
            code: "KR",
            dial_code: "+82"
          },
          {
            name: "The Republic of Kosovo",
            code: "XK",
            dial_code: "+383"
          },
          {
            name: "Kuwait",
            code: "KW",
            dial_code: "+965"
          },
          {
            name: "Kyrgyzstan",
            code: "KG",
            dial_code: "+996"
          },
          {
            name: "Lao PDR",
            code: "LA",
            dial_code: "+856"
          },
          {
            name: "Latvija",
            code: "LV",
            dial_code: "+371"
          },
          {
            name: "Lebanon",
            code: "LB",
            dial_code: "+961"
          },
          {
            name: "Lesotho",
            code: "LS",
            dial_code: "+266"
          },
          {
            name: "Liberia",
            code: "LR",
            dial_code: "+231"
          },
          {
            name: "Libya",
            code: "LY",
            dial_code: "+218"
          },
          {
            name: "Liechtenstein",
            code: "LI",
            dial_code: "+423"
          },
          {
            name: "Lietuva",
            code: "LT",
            dial_code: "+370"
          },
          {
            name: "Luxembourg",
            code: "LU",
            dial_code: "+352"
          },
          {
            name: "Macao",
            code: "MO",
            dial_code: "+853"
          },
          {
            name: "Macedonia",
            code: "MK",
            dial_code: "+389"
          },
          {
            name: "Madagasikara",
            code: "MG",
            dial_code: "+261"
          },
          {
            name: "Malawi",
            code: "MW",
            dial_code: "+265"
          },
          {
            name: "Malaysia",
            code: "MY",
            dial_code: "+60"
          },
          {
            name: "Maldives",
            code: "MV",
            dial_code: "+960"
          },
          {
            name: "Mali",
            code: "ML",
            dial_code: "+223"
          },
          {
            name: "Malta",
            code: "MT",
            dial_code: "+356"
          },
          {
            name: "M̧ajeļ",
            code: "MH",
            dial_code: "+692"
          },
          {
            name: "Martinique",
            code: "MQ",
            dial_code: "+596"
          },
          {
            name: "Mauritania",
            code: "MR",
            dial_code: "+222"
          },
          {
            name: "Maurice",
            code: "MU",
            dial_code: "+230"
          },
          {
            name: "Mayotte",
            code: "YT",
            dial_code: "+262"
          },
          {
            name: "México",
            code: "MX",
            dial_code: "+52"
          },
          {
            name: "Micronesia",
            code: "FM",
            dial_code: "+691"
          },
          {
            name: "Moldova",
            code: "MD",
            dial_code: "+373"
          },
          {
            name: "Monaco",
            code: "MC",
            dial_code: "+377"
          },
          {
            name: "Mongolia",
            code: "MN",
            dial_code: "+976"
          },
          {
            name: "Montenegro",
            code: "ME",
            dial_code: "+382"
          },
          {
            name: "Montserrat",
            code: "MS",
            dial_code: "+1664"
          },
          {
            name: "Morocco",
            code: "MA",
            dial_code: "+212"
          },
          {
            name: "Moçambique",
            code: "MZ",
            dial_code: "+258"
          },
          {
            name: "Myanmar",
            code: "MM",
            dial_code: "+95"
          },
          {
            name: "Namibia",
            code: "NA",
            dial_code: "+264"
          },
          {
            name: "Nauru",
            code: "NR",
            dial_code: "+674"
          },
          {
            name: "Nepal",
            code: "NP",
            dial_code: "+977"
          },
          {
            name: "Nederland",
            code: "NL",
            dial_code: "+31"
          },
          {
            name: "Netherlands Antilles",
            code: "AN",
            dial_code: "+599"
          },
          {
            name: "Nouvelle-Calédonie",
            code: "NC",
            dial_code: "+687"
          },
          {
            name: "New Zealand",
            code: "NZ",
            dial_code: "+64"
          },
          {
            name: "Nicaragua",
            code: "NI",
            dial_code: "+505"
          },
          {
            name: "Niger",
            code: "NE",
            dial_code: "+227"
          },
          {
            name: "Nigeria",
            code: "NG",
            dial_code: "+234"
          },
          {
            name: "Niuē",
            code: "NU",
            dial_code: "+683"
          },
          {
            name: "Norfolk Island",
            code: "NF",
            dial_code: "+672"
          },
          {
            name: "Northern Mariana Islands",
            code: "MP",
            dial_code: "+1670"
          },
          {
            name: "Norge",
            code: "NO",
            dial_code: "+47"
          },
          {
            name: "Oman",
            code: "OM",
            dial_code: "+968"
          },
          {
            name: "Pakistan",
            code: "PK",
            dial_code: "+92"
          },
          {
            name: "Palau",
            code: "PW",
            dial_code: "+680"
          },
          {
            name: "Palestine",
            code: "PS",
            dial_code: "+970"
          },
          {
            name: "Panamá",
            code: "PA",
            dial_code: "+507"
          },
          {
            name: "Papua Niugini",
            code: "PG",
            dial_code: "+675"
          },
          {
            name: "Paraguay",
            code: "PY",
            dial_code: "+595"
          },
          {
            name: "Perú",
            code: "PE",
            dial_code: "+51"
          },
          {
            name: "Pilipinas",
            code: "PH",
            dial_code: "+63"
          },
          {
            name: "Pitcairn Islands",
            code: "PN",
            dial_code: "+64"
          },
          {
            name: "Polska",
            code: "PL",
            dial_code: "+48"
          },
          {
            name: "Portugal",
            code: "PT",
            dial_code: "+351"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1939"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1787"
          },
          {
            name: "Diameter",
            code: "QA",
            dial_code: "+974"
          },
          {
            name: "România",
            code: "RO",
            dial_code: "+40"
          },
          {
            name: "Russia",
            code: "RU",
            dial_code: "+7"
          },
          {
            name: "Rwanda",
            code: "RW",
            dial_code: "+250"
          },
          {
            name: "La Réunion",
            code: "RE",
            dial_code: "+262"
          },
          {
            name: "Saint-Barthélemy",
            code: "BL",
            dial_code: "+590"
          },
          {
            name: "Saint Helena",
            code: "SH",
            dial_code: "+290"
          },
          {
            name: "Saint Kitts and Nevis",
            code: "KN",
            dial_code: "+1869"
          },
          {
            name: "Saint Lucia",
            code: "LC",
            dial_code: "+1758"
          },
          {
            name: "Saint-Martin",
            code: "MF",
            dial_code: "+590"
          },
          {
            name: "Saint-Pierre-et-Miquelon",
            code: "PM",
            dial_code: "+508"
          },
          {
            name: "Saint Vincent and the Grenadines",
            code: "VC",
            dial_code: "+1784"
          },
          {
            name: "Samoa",
            code: "WS",
            dial_code: "+685"
          },
          {
            name: "San Marino",
            code: "SM",
            dial_code: "+378"
          },
          {
            name: "São Tomé e Príncipe",
            code: "ST",
            dial_code: "+239"
          },
          {
            name: "Saudi Arab",
            code: "SA",
            dial_code: "+966"
          },
          {
            name: "Sénégal",
            code: "SN",
            dial_code: "+221"
          },
          {
            name: "Serbia",
            code: "RS",
            dial_code: "+381"
          },
          {
            name: "Seychelles",
            code: "SC",
            dial_code: "+248"
          },
          {
            name: "Sierra Leone",
            code: "SL",
            dial_code: "+232"
          },
          {
            name: "Singapore",
            code: "SG",
            dial_code: "+65"
          },
          {
            name: "Slovensko",
            code: "SK",
            dial_code: "+421"
          },
          {
            name: "Slovenija",
            code: "SI",
            dial_code: "+386"
          },
          {
            name: "Solomon Islands",
            code: "SB",
            dial_code: "+677"
          },
          {
            name: "Soomaaliya",
            code: "SO",
            dial_code: "+252"
          },
          {
            name: "South Africa",
            code: "ZA",
            dial_code: "+27"
          },
          {
            name: "South Sudan",
            code: "SS",
            dial_code: "+211"
          },
          {
            name: "South Georgia",
            code: "GS",
            dial_code: "+500"
          },
          {
            name: "Spain",
            code: "ES",
            dial_code: "+34"
          },
          {
            name: "Sri Lanka",
            code: "LK",
            dial_code: "+94"
          },
          {
            name: "Sudan",
            code: "SD",
            dial_code: "+249"
          },
          {
            name: "Suriname",
            code: "SR",
            dial_code: "+597"
          },
          {
            name: "Svalbard og Jan Mayen",
            code: "SJ",
            dial_code: "+47"
          },
          {
            name: "Swaziland",
            code: "SZ",
            dial_code: "+268"
          },
          {
            name: "Sverige",
            code: "SE",
            dial_code: "+46"
          },
          {
            name: "Schweiz",
            code: "CH",
            dial_code: "+41"
          },
          {
            name: "Syrian Arab Republic",
            code: "SY",
            dial_code: "+963"
          },
          {
            name: "Taiwan",
            code: "TW",
            dial_code: "+886"
          },
          {
            name: "Tajikistan",
            code: "TJ",
            dial_code: "+992"
          },
          {
            name: "Tanzania",
            code: "TZ",
            dial_code: "+255"
          },
          {
            name: "Thailand",
            code: "TH",
            dial_code: "+66"
          },
          {
            name: "Timor-Leste",
            code: "TL",
            dial_code: "+670"
          },
          {
            name: "Togo",
            code: "TG",
            dial_code: "+228"
          },
          {
            name: "Tokelau",
            code: "TK",
            dial_code: "+690"
          },
          {
            name: "Tonga",
            code: "TO",
            dial_code: "+676"
          },
          {
            name: "Trinidad and Tobago",
            code: "TT",
            dial_code: "+1868"
          },
          {
            name: "Tunisia",
            code: "TN",
            dial_code: "+216"
          },
          {
            name: "Turkey",
            code: "TR",
            dial_code: "+90"
          },
          {
            name: "Türkmenistan",
            code: "TM",
            dial_code: "+993"
          },
          {
            name: "Turks and Caicos Islands",
            code: "TC",
            dial_code: "+1649"
          },
          {
            name: "Tuvalu",
            code: "TV",
            dial_code: "+688"
          },
          {
            name: "Uganda",
            code: "UG",
            dial_code: "+256"
          },
          {
            name: "Ukraine",
            code: "UA",
            dial_code: "+380"
          },
          {
            name: "United Arab Emirates",
            code: "AE",
            dial_code: "+971"
          },
          {
            name: "United Kingdom",
            code: "GB",
            dial_code: "+44"
          },
          {
            name: "United States",
            code: "US",
            dial_code: "+1"
          },
          {
            name: "Uruguay",
            code: "UY",
            dial_code: "+598"
          },
          {
            name: "Uzbekistan",
            code: "UZ",
            dial_code: "+998"
          },
          {
            name: "Vanuatu",
            code: "VU",
            dial_code: "+678"
          },
          {
            name: "Venezuela",
            code: "VE",
            dial_code: "+58"
          },
          {
            name: "Vietnam",
            code: "VN",
            dial_code: "+84"
          },
          {
            name: "British Virgin Islands",
            code: "VG",
            dial_code: "+1284"
          },
          {
            name: "United States Virgin Islands",
            code: "VI",
            dial_code: "+1340"
          },
          {
            name: "Wallis et Futuna",
            code: "WF",
            dial_code: "+681"
          },
          {
            name: "Yemen",
            code: "YE",
            dial_code: "+967"
          },
          {
            name: "Zambia",
            code: "ZM",
            dial_code: "+260"
          },
          {
            name: "Zimbabwe",
            code: "ZW",
            dial_code: "+263"
          }
        ],
      errors: {
        email: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMethod = this.handleChangeMethod.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
  }
  async componentDidMount() {
    //call firebase token

    await requestForToken();
    const deviceToken = await localStorage.getItem('SOB_currentToken')
    //console.log("deviceToken for login---------"+deviceToken);
    this.setState({
      deviceToken: deviceToken,
    })
  }

  handleChange(event) {

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //let errors = this.state.errors;
    // switch (name) {
    //     case "email":
    //       errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
    //       break;
    //     default:
    //         break;
    //     }
    this.setState({
      [name]: value
    });
    //console.log(value.length);
    if (regexPhone.test(value)) { this.setState({ divvalue: "show" }) } else { this.setState({ divvalue: "hide" }) }
  }

  handleChangeMethod(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  handleSubmit(event) {
    //console.log(this.state);
   
    this.logIn(this.state.email, sha1(this.state.password), this.state.deviceInfo, this.state.deviceToken, this.state.countryCode);
    event.preventDefault();
  }

  logIn(...params) {
    var email_val, phone_val;
    if (params[4] === "") {
      email_val = params[0]
      phone_val = ""
    } else {
      email_val = ""
      phone_val = params[0]
    }
    axios
      .post(path, {
        email: email_val,
        password: params[1],
        deviceInfo: params[2],
        deviceToken: params[3],
        phone: phone_val,
        countryCode: params[4],

      }, {
        headers: { 'Api-Name': 'login' }
      })
      .then((serverRes) => {
        const res = serverRes.data;
        //console.log(res);

        if (res.status === true) {
          localStorage.setItem("SOB_userId", res.response.userId);
          localStorage.setItem("SOB_authToken", res.response.authToken);
          localStorage.setItem("SOB_refreshToken", res.response.refreshToken);
          localStorage.setItem("SOB_idType", res.response.idType);

          if (res.response.isVerified === true) {
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.history.push("/home");
          } else if (res.response.idType === "I have no ID") {
            this.props.history.push("/otp_verification");
          } else if (res.response.isAadharUploaded === false) {
            this.props.history.push("/id_upload");
          } else {
            this.props.history.push("/otp_verification");
          }
        } else {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }

  forgotPasswordSubmit(event) {
    // console.log(this.state);
    this.forgotPasssword(this.state.email, this.state.countryCode);
    event.preventDefault();
  }

  forgotPasssword(...params) {
    var email_val, phone_val;
    if (params[1] === "") {
      email_val = params[0]
      phone_val = ""
    } else {
      email_val = ""
      phone_val = params[0]
    }
    // console.log('check');
    axios.post(path, {
      email: email_val,
      phone: phone_val,
      countryCode: params[1],

    }, {
      headers: { 'api-name': 'forgotPassword' }
    }).then(serverResponse => {
      const res = serverResponse.data;
      // console.log(res);
      if (res.status === true) {
        document.getElementById("myFPModal").classList.remove("show", "d-block");
        document.querySelectorAll(".modal-backdrop")
          .forEach(el => el.classList.remove("modal-backdrop"));
        // console.log(res);
        toast.success(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        localStorage.setItem("SOB_userId", res.response.userId);
        this.props.history.push("/forgotp_otp_verification");
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  }

  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    });
    //const { errors } = this.state;
    return (
      <div className="fullHeight">

        <section className="loginBase">
          <h2>Sign In</h2>
          <div className="loginPannel">
            <div className="loginLft"><img src={process.env.PUBLIC_URL + '/assets/images/loginlogo.png'} alt="" /></div>
            <div className="loginFrm">
              <form>
                <div className="form-group loginSelect ">
                  <div className={"newSelect " + this.state.divvalue} >
                    <select name="countryCode" onChange={this.handleChangeMethod}>
                      <option value="0" className="dropdown-item active">ISD</option>
                      {this.state.country_details.map((eachcountryDetails, i) => (
                        <option value={eachcountryDetails.dial_code} key={i} className="dropdown-item">{eachcountryDetails.dial_code} ({eachcountryDetails.name})</option>

                      ))}
                    </select>
                  </div>
                  <input type="text" id="email" name="email" onChange={this.handleChange} className="form-control" placeholder="Email / Phone No" />
                  {/* <span style={{color:"white"}}>{errors.email}</span> */}
                </div>



                <div className="form-group">
                  <input type="password" className="form-control" id="password" name="password" onChange={this.handleChangeMethod} placeholder="Password" />
                </div>
                <p><Link to="#" className="forgotPass" data-toggle="modal" data-target="#myFPModal">Forgot Password</Link></p>
                <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Login</button>
                <p><Link to="/registration" className="forgotPass">Don't have an account? Register</Link></p>
              </form>
            </div>
          </div>
        </section>


        {/* changepassword-Modal  */}
        <div className="modal feedbackModal fade modal-center" id="myFPModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              {/* <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
              <form useref="form" onSubmit={this.forgotPasswordSubmit}>
                <div className="modal-body">
                  <img src={process.env.PUBLIC_URL + '/assets/images/changepassword.png'} alt="" />
                  <h3>FORGOT PASSWORD</h3>
                  <div className="form-group">
                    <input type="text" className="form-control" name="email" placeholder="Email/Phone" onChange={this.handleChange} />
                  </div>

                  <div className={"form-group " + this.state.divvalue}>
                    <select name="countryCode" onChange={this.handleChangeMethod} className="forgotP">
                      <option value="0" className="dropdown-item active">ISD</option>
                      {this.state.country_details.map((eachcountryDetails, i) => (
                        <option value={eachcountryDetails.dial_code} key={i} className="dropdown-item">{eachcountryDetails.dial_code} ({eachcountryDetails.name})</option>

                      ))}
                    </select>
                  </div>


                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn">Submit</button>
                  <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Login;