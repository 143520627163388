import React from 'react';
import { withRouter } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
class countDownComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state =
        {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            future: 0
        };


    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')

        //if(userId && userId.hasOwnProperty('userId')){
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        // }
        // console.log("user"+this.state.userId);
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getTimer();

    }

    getTimer() {
        this.interval = setInterval(() => {
        var future = Date.parse(this.props.futureDate);
        var now = new Date();
        var diff = future - now;

       // console.log(diff);
        var days = Math.floor(diff / (1000 * 60 * 60 * 24));
        var hours = Math.floor(diff / (1000 * 60 * 60));
        var mins = Math.floor(diff / (1000 * 60));
        var secs = Math.floor(diff / 1000);

        var d = days;
        var h = hours - days * 24;
        var m = mins - hours * 60;
        var s = secs - mins * 60;
        this.setState({
            days: d,
            hours: h,
            minutes: m,
            seconds: s,
            future: diff
        });
    }, 1000);
    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    render() {
        return (
            <div>
                {this.props.isVisible === true ?
                    <div style={{marginTop:18,color:"#A18B64",fontSize:18,fontStyle:"bold"}}>
                        <span>{this.state.days} days </span>
                        <span>{this.state.hours}h:</span>
                        <span>{this.state.minutes}m:</span>
                        <span>{this.state.seconds}s</span>
                        {/* <Countdown  date={Date.now() + (Date.parse(this.props.futureDate) - new Date())} /> */}
                       
                    </div>
                    : ""}
            </div>
        );
    }
}


export default withRouter(countDownComponent);
