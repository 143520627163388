import React from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Aadhar_Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            imgArr: [],
            aadhar_front: "",
            aadhar_back: "",
            imgPreview1: process.env.PUBLIC_URL + '/assets/images/scan.png',
            imgPreview: [],
            idType: ""

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // Get userdetails from localstorage
      
        const userId = await localStorage.getItem('SOB_userId')
        const idType = await localStorage.getItem('SOB_idType')
        const sob_name = await localStorage.getItem('SOB_name')
       // console.log(idType)
        if(userId===null || userId==="undefined"){
            localStorage.setItem("SOB_userId", this.props.location.state.SOB_userId);
        }
        if(idType===null || idType==="undefined"){
            localStorage.setItem("SOB_idType", this.props.location.state.SOB_idType);
        }

        if(sob_name===null || sob_name==="undefined"){
            localStorage.setItem("SOB_name", this.props.location.state.SOB_name);
        }
        const updated_userId = await localStorage.getItem('SOB_userId')
        const updated_idType = await localStorage.getItem('SOB_idType')
        this.setState({
            userId: updated_userId,
            idType: updated_idType,
           // sob_name:sob_name
        })
        if (this.state.userId === null) {
            this.props.history.push("/offline_login");
        }
    }


    handleChange(event) {
        const target = event.target;
       // const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        //console.log(value); console.log(name);
        // console.log(event.target.files)

        let totalFiles = event.target.files ? event.target.files.length : 0;
        let keyimgFile = name + "_file";
        let keyimgpreviewFile = name + "_preview";
       
        var fileInput = false;
        if (event.target.files && totalFiles > 0) {
            let img_extension = event.target.files[totalFiles - 1].name.split('.').pop();
            const files = [...this.state.imgArr]; // Spread syntax creates a shallow copy
            files.push(event.target.files[totalFiles - 1]);
            let files1 = event.target.files[totalFiles - 1];


            if (img_extension === 'png' || img_extension === 'jpg' || img_extension === 'jpeg' || img_extension === 'webp' || img_extension === 'gif' ||
                img_extension === 'PNG' || img_extension === 'JPG' || img_extension === 'JPEG' || img_extension === 'WEBP' || img_extension === 'GIF') {
                fileInput = true;
            } else {
                fileInput = false;
            }

            if (fileInput) {
                try {
                    Resizer.imageFileResizer(
                        files1,
                        600, 600,
                        "JPEG",
                        90,
                        0,
                        (uri) => {
                            // console.log(uri);
                            // this.setState({ newImage: uri });
                            this.setState({
                                imgArr: files,
                                [keyimgFile]: uri,
                                [keyimgpreviewFile]: URL.createObjectURL(event.target.files[totalFiles - 1])
                            })
                        },
                        "file",
                    );
                } catch (err) {
                    //console.log(err);
                }
            } else {

                //console.log(files);
                this.setState({
                    imgArr: files,
                    [keyimgFile]: files1,
                    [keyimgpreviewFile]: process.env.PUBLIC_URL + '/assets/images/pdf.png'
                })
            }


        } else if (event.target.files && totalFiles === 0) {
            this.setState({
                [keyimgFile]: null,
                imgArr: [],
                [keyimgpreviewFile]: ""
            })
            // console.log(this.state);
        }
    }


    handleSubmit(event) {
        this.uploadAadhar();
        event.preventDefault();
    }

    uploadAadhar() {
        //console.log(this.state.userId);
        // console.log(this.state.imgArr);
        let formData = new FormData();

        let keyimgFileArray = [];
        let stateval = this.state;
        //for image file
        var filteredFiles = Object.keys(stateval).filter((name) => /_file/.test(name));
        filteredFiles.map(name => {
            if (stateval[name] != null) {
                keyimgFileArray.push(stateval[name]);
            }
        });


        // this.state.imgArr.forEach((file) => formData.append('image', file));

        keyimgFileArray.forEach((file) => formData.append('image', file));


        axios.post(path, formData,
            {
                headers: {
                    'api-name': 'uploadAadhar',
                    'Content-Type': 'multipart/form-data',
                    'userId': this.state.userId,
                }
            })
            .then((serverRes) => {
                const res = serverRes.data;
                // console.log(res);

                if (res.status === true) {
                    localStorage.setItem("SOB_userId", res.response.userId);
                    if (res.response.isAadharUploaded === true && res.response.isVerified === true) {
                        toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        this.props.history.push("/amrit_registration_sob");
                    }

                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                }
            });



    }




    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });

        return (
            <div className="fullHeight">
                <section className="loginBase regdBase">
                    <div className="signuplogo"><img src={process.env.PUBLIC_URL + '/assets/images/loginlogo.png'} alt="" /></div>
                    <h2>Upload {this.state.idType}</h2>
                    <div className="loginPannel">
                        <div className="loginFrm">
                            <form>
                                <div className="formField">
                                    <div className="form-group">
                                        <h4 style={{ color: "white" }}>Upload {this.state.idType} Front Page (Max File size 3 MB):</h4>
                                    </div>

                                    <div className="form-group">
                                        <div className="scanAdhaar">
                                            <div className="scanInn">
                                                <input type="file" className="image-upload" name="aadhar_front" onChange={this.handleChange} required />

                                                {this.state.aadhar_front_preview ?
                                                    <button><img height={100} width={100}  src={this.state.aadhar_front_preview} alt="" /></button>

                                                    : <button><img src={this.state.imgPreview1} alt="" /></button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.idType === "Aadhar" || this.state.idType === "UDID Card" || this.state.idType === "Passport" ?
                                    <div className="formField">
                                        <div className="form-group">
                                            <h4 style={{ color: "white" }}>Upload {this.state.idType} Back Page (Max File size 3 MB):</h4>
                                        </div>

                                        <div className="form-group">
                                            <div className="scanAdhaar">
                                                <div className="scanInn">
                                                    <input type="file" className="image-upload" name="aadhar_back" onChange={this.handleChange} required />

                                                    {this.state.aadhar_back_preview ?
                                                        <button><img height={100} width={100} src={this.state.aadhar_back_preview} alt="" /></button>

                                                        : <button><img src={this.state.imgPreview1} alt="" /></button>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}


                                <div className="formBtm"><button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Upload</button></div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Aadhar_Upload;