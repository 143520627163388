import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",

            sport: "",
            autorefracUnableTestRE: "",
            autorefracSphereRE: "",
            autorefracCylinderRE: "",
            autorefracAxisRE: "",
            autorefracUnableTestLE: "",
            autorefracSphereLE: "",
            autorefracCylinderLE: "",
            autorefracAxisLE: "",
            iopUnableTest: "",
            iopRE: "",
            iopLE: "",
            iopValue: "",

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.autorefracUnableTestRE !== this.state.autorefracUnableTestRE) {
            if (this.state.autorefracUnableTestRE === "" || this.state.autorefracUnableTestRE === false) {
               // console.log("i am in update")
                this.setState({
                    autorefracSphereRE: "",
                    autorefracCylinderRE: "",
                    autorefracAxisRE: "",
                })
            }
        }
        if (prevState.autorefracUnableTestLE !== this.state.autorefracUnableTestLE) {
            if (this.state.autorefracUnableTestLE === "" || this.state.autorefracUnableTestLE === false) {
               // console.log("i am in update")
                this.setState({
                    autorefracSphereLE: "",
                    autorefracCylinderLE: "",
                    autorefracAxisLE: "",
                })
            }
        }
        if (prevState.iopUnableTest !== this.state.iopUnableTest) {
            if (this.state.iopUnableTest === "" || this.state.iopUnableTest === false) {
               // console.log("i am in update")
                this.setState({
                    iopRE: "",
                    iopLE: "",
                    iopValue: "",
                })
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageOne();
    }

    openingEyePageOne() {
       // console.log("I called");
        let dataToSend = {
            user_id: this.state.sob_scanId,
        
        };

        // console.log(this.state);

        //let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        let autorefracUnableTestRE_1 = this.state.autorefracUnableTestRE != "" ? dataToSend['autorefracUnableTestRE'] = this.state.autorefracUnableTestRE : null;
        if (!this.state.autorefracUnableTestRE) {
            let autorefracSphereRE_1 = this.state.autorefracSphereRE != "" ? dataToSend['autorefracSphereRE'] = this.state.autorefracSphereRE : null;
            let autorefracCylinderRE_1 = this.state.autorefracCylinderRE != "" ? dataToSend['autorefracCylinderRE'] = this.state.autorefracCylinderRE : null;
            let autorefracAxisRE_1 = this.state.autorefracAxisRE != "" ? dataToSend['autorefracAxisRE'] = this.state.autorefracAxisRE : null;
        }
        let autorefracUnableTestLE_1 = this.state.autorefracUnableTestLE != "" ? dataToSend['autorefracUnableTestLE'] = this.state.autorefracUnableTestLE : null;
        if (!this.state.autorefracUnableTestLE) {
            let autorefracSphereLE_1 = this.state.autorefracSphereLE != "" ? dataToSend['autorefracSphereLE'] = this.state.autorefracSphereLE : null;
            let autorefracCylinderLE_1 = this.state.autorefracCylinderLE != "" ? dataToSend['autorefracCylinderLE'] = this.state.autorefracCylinderLE : null;
            let autorefracAxisLE_1 = this.state.autorefracAxisLE != "" ? dataToSend['autorefracAxisLE'] = this.state.autorefracAxisLE : null;
        }
        let iopUnableTest_1 = this.state.iopUnableTest != "" ? dataToSend['iopUnableTest'] = this.state.iopUnableTest : null;
        if (!this.state.iopUnableTest) {
            let iopRE_1 = this.state.iopRE != "" ? dataToSend['iopRE'] = this.state.iopRE : null;
            let iopLE_1 = this.state.iopLE != "" ? dataToSend['iopLE'] = this.state.iopLE : null;
            let iopValue_1 = this.state.iopValue != "" ? dataToSend['iopValue'] = this.state.iopValue : null;
        }

        //console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'openingEyePageFive', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
               
            })
            .then((serverRes) => {
                const res = serverRes;
                //console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd amritRegdRemoveNumberSign">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                    <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div class="compositionBg">
                                            <h4>Autorefraction:</h4><br />
                                            <div class="row frmStepbPdn">
                                                <div class="col-md-6">
                                                    <h4>RIGHT EYE &nbsp;&nbsp; <span class="autoRe minWdt noPdnBtm">
                                                        <input type="checkbox" name="autorefracUnableTestRE" onChange={this.handleChange} />&nbsp; Unable to test
                                                    </span></h4>
                                                    {
                                                        !this.state.autorefracUnableTestRE &&
                                                        <>
                                                            <h5>Sphere</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracSphereRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Cylinder</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracCylinderRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Axis</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracAxisRE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div class="col-md-6">
                                                    <h4>LEFT EYE &nbsp;&nbsp; <span class="autoRe minWdt noPdnBtm"> <input type="checkbox" name="autorefracUnableTestLE" onChange={this.handleChange} />&nbsp; Unable to test</span></h4>
                                                    {
                                                        !this.state.autorefracUnableTestLE &&
                                                        <>
                                                            <h5>Sphere</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracSphereLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Cylinder</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracCylinderLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                            <h5>Axis</h5>
                                                            <div class="heightFlex">
                                                                <input type="number" name="autorefracAxisLE" onChange={this.handleChange} class="form-control" placeholder="Enter" />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <h4 class="coverTestBordr respCol">IOP: &nbsp;&nbsp; <span class="autoRe minWdt noPdnBtm"> <input type="checkbox" name="iopUnableTest" onChange={this.handleChange} />&nbsp; Unable to test</span></h4>
                                            {
                                                !this.state.iopUnableTest &&

                                                <>
                                                    <div class="coverTestBordr iopBg">
                                                        <div class="iopField">
                                                            <h5>Right Eye</h5>
                                                            <input type="number" name="iopRE" onChange={this.handleChange} class="form-control" placeholder="Value" />
                                                        </div>
                                                        <div class="iopField">
                                                            <h5>Left Eye</h5>
                                                            <input type="number" name="iopLE" onChange={this.handleChange} class="form-control" placeholder="Value" />
                                                        </div>
                                                        <div class="newSelect">
                                                            <select name="iopValue" onChange={this.handleChange}>
                                                                <option className="slRedbg" value="">Select Option</option>
                                                                <option value="Icare">Icare</option>
                                                                <option value="Noncontact">Noncontact</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Eyes_Form5;