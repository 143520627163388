import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class YA_Page7 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                
                <section className="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/yabg2.png'})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 introsx">
                                    <h3>Athletes</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
            
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="ya4Top">
                                    <span className="preAct"></span>
                                    <span className="preAct"></span>
                                    <span className="preAct"></span>
                                    <span className="current"></span>
                                </div>
                                
                                                        
                                    
                                <div className="page4borderbase page7Base">
                                    <h5>10. How does your child primarily communicate? <em>(Check all that apply)</em></h5>
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio1" value="option1" checked/>
                                            <label className="form-check-label" for="radio1">
                                                Full sentences
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio2" value="option1"/>
                                            <label className="form-check-label" for="radio2">
                                                Single words
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio3" value="option1"/>
                                            <label className="form-check-label" for="radio3">
                                                Short phrases
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio4" value="option1"/>
                                            <label className="form-check-label" for="radio4">
                                                Signs or AAC
                                            </label>
                                        </div>
                                    </div>
                                    <div className="page4Radio page6Quarter">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio5" value="option5"/>
                                            <label className="form-check-label" for="radio5">
                                                Gestures or sounds
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio6" value="option6"/>
                                            <label className="form-check-label" for="radio6">
                                                Other
                                            </label>
                                        </div>
                                            <input type="text" className="form-control" id="" placeholder="Specify Please..."/>                               
                                    </div><br/><br/>
                                    <h5>11. Does your child have any behaivours (like aggression, hitting, biting) that make everyday  like challenging?  </h5>
                                    <div className="page4Radio page6Quarter">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioh" id="radio7" value="option7"/>
                                            <label className="form-check-label" for="radio7">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioh" id="radio8" value="option8"/>
                                            <label className="form-check-label" for="radio8">
                                                No
                                            </label>
                                        </div>
                                            <input type="text" className="form-control" id="" placeholder="If yes, please describe "/>                               
                                    </div>
                                    
                                </div>
                                <div className="intBtnBx intautowidth"><Link to="/ya_page1" className="intbtnax">Back to Main Menu</Link> <Link to="#" className="intbtnbx">Save</Link> <Link to="/ya_page8" className="intbtnbx">Next</Link></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
        )
    }
}
export default YA_Page7;