import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;


class Amrit_Special_Smile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",
            divChekboxvalue: "hide",
            divvalue: "show",

            sport: "",
            mouthCleanFreq: "",
            mouthPainString: "",
            mouthPainArea: [],
            athleteRefused: false,

            edentulous: "",

            untreatedDecayString: "",
            untreatedDecayArea: [],
            filledTeeth: "",
            missingTeethString: "",
            missingTeethArea: [],
            sealant: "",
            injuryString: "",
            injuryTreated: "",
            fluorosis: "",
            gingivalSigns: "",
            treatmentUrgency: "",
            mouthguardRecommended: "",
            mouthguardDelivered: false,
            fluorideVarnishRecommended: "",
            fluorideVarnishDelivered: false,

            doctorComments: "",
            sob_scanId: "",

            mouthPainAreaArray: [
                { id: 'Teeth', value: 'Teeth' },
                { id: 'Other', value: 'Other' },
            ],

            untreatedDecayAreaArray: [
                { id: 'Anterior(s)', value: 'Anterior(s)' },
                { id: 'Premolar(s)', value: 'Premolar(s)' },
                { id: 'Molar(s)', value: 'Molar(s)' },
            ],

            missingTeethAreaArray: [
                { id: 'Anterior(s)', value: 'Anterior(s)' },
                { id: 'Molar(s)', value: 'Molar(s)' },
            ],

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAreaCheckbox = this.handleAreaCheckbox.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    componentDidUpdate(prevProps, prevState) {       
        if (prevState.athleteRefused !== this.state.athleteRefused) {
            if(this.state.athleteRefused === true){
                this.setState({
                    edentulous: "",
                    untreatedDecayString: "",
                    untreatedDecayArea: [],
                    filledTeeth: "",
                    missingTeethString: "",
                    missingTeethArea: [],
                    sealant: "",
                    injuryString: "",
                    injuryTreated: "",
                    fluorosis: "",
                    gingivalSigns: "",
                    treatmentUrgency: "",
                    mouthguardRecommended: "",
                    mouthguardDelivered: false,
                    fluorideVarnishRecommended: "",
                    fluorideVarnishDelivered: false,
                })
            }
        }
        if (prevState.edentulous !== this.state.edentulous) {
            if (this.state.edentulous === "Yes") {
                this.setState({
                    untreatedDecayString: "",
                    untreatedDecayArea: [],
                    filledTeeth: "",
                    missingTeethString: "",
                    missingTeethArea: [],
                    sealant: "",
                    injuryString: "",
                    injuryTreated: "",
                    fluorosis: "",
                    gingivalSigns: "",
                    treatmentUrgency: "",
                    mouthguardRecommended: "",
                    mouthguardDelivered: false,
                    fluorideVarnishRecommended: "",
                    fluorideVarnishDelivered: false,
                })
            }
        }
        if (prevState.mouthPainString !== this.state.mouthPainString) {
            if(this.state.mouthPainString === "No") {
                this.setState({ mouthPainArea: [] });
            }
        }
        if (prevState.untreatedDecayString !== this.state.untreatedDecayString) {
            if(this.state.untreatedDecayString === "No") {
                this.setState({ untreatedDecayArea: [] });
            }
        }
        if (prevState.missingTeethString !== this.state.missingTeethString) {
            if(this.state.missingTeethString === "No"){
                this.setState({ missingTeethArea: [] });
            }
        }
        if (prevState.injuryString !== this.state.injuryString){
            if(this.state.injuryString === "No"){
                this.setState({ injuryTreated: "" });
            }
        }
        if (prevState.mouthguardRecommended !== this.state.mouthguardRecommended){
            if(this.state.mouthguardRecommended === "No"){
                this.setState({ mouthguardDelivered: false});
            }
        }
        if (prevState.fluorideVarnishRecommended !== this.state.fluorideVarnishRecommended){
            if(this.state.fluorideVarnishRecommended === "No"){
                this.setState({ fluorideVarnishDelivered: false});
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleAreaCheckbox(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name_value = target.value;
        const name = target.name;
        let options = []
        if(name === 'mouthPainArea') {
            options = this.state.mouthPainArea;
        }
        if(name === 'untreatedDecayArea') {
            options = this.state.untreatedDecayArea;
        }
        if(name === 'missingTeethArea'){
            options = this.state.missingTeethArea;
        }
        let index;

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        this.setState({
            [name]: options,
        });
    }

    handleSubmit(event) {
        // console.log(this.state);
        this.specialSmilePage();
        event.preventDefault();
    }

    specialSmilePage() {

        let dataToSend = {
            user_id: this.state.sob_scanId,
            
        };

        let sport_1 = this.state.sport !== "" ? dataToSend["sport"] = this.state.sport : null;
        let mouthCleanFreq_1 = this.state.mouthCleanFreq !== "" ? dataToSend["mouthCleanFreq"] = this.state.mouthCleanFreq : null;
        let mouthPainString_1 = this.state.mouthPainString !== "" ? dataToSend['mouthPainString'] = this.state.mouthPainString : null;
        let mouthPainArea_1 = (this.state.mouthPainArea !== "" && this.state.mouthPainArea.length > 0) ? dataToSend['mouthPainArea'] = this.state.mouthPainArea : null;

        // let athleteRefused_1 = this.state.athleteRefused != "" ? dataToSend['athleteRefused'] = this.state.athleteRefused : null;

        if (this.state.athleteRefused === false) {
            if (this.state.edentulous === "No") {
                let untreatedDecayString_1 = this.state.untreatedDecayString !== "" ? dataToSend['untreatedDecayString'] = this.state.untreatedDecayString : null;
                let untreatedDecayArea_1 = (this.state.untreatedDecayArea !== "" && this.state.untreatedDecayArea.length > 0) ? dataToSend['untreatedDecayArea'] = this.state.untreatedDecayArea : null;
                let filledTeeth_1 = this.state.filledTeeth !== "" ? dataToSend['filledTeeth'] = this.state.filledTeeth : null;
                let missingTeethString_1 = this.state.missingTeethString !== "" ? dataToSend['missingTeethString'] = this.state.missingTeethString : null;
                let missingTeethArea_1 = (this.state.missingTeethArea !== "" && this.state.missingTeethArea.length > 0) ? dataToSend['missingTeethArea'] = this.state.missingTeethArea : null;
                let sealant_1 = this.state.sealant !== "" ? dataToSend['sealant'] = this.state.sealant : null;
                let injuryString_1 = this.state.injuryString !== "" ? dataToSend['injuryString'] = this.state.injuryString : null;
                let injuryTreated_1 = this.state.injuryTreated !== "" ? dataToSend['injuryTreated'] = this.state.injuryTreated : null;
                let fluorosis_1 = this.state.fluorosis !== "" ? dataToSend['fluorosis'] = this.state.fluorosis : null;
                let gingivalSigns_1 = this.state.gingivalSigns !== "" ? dataToSend['gingivalSigns'] = this.state.gingivalSigns : null;
                let treatmentUrgency_1 = this.state.treatmentUrgency !== "" ? dataToSend['treatmentUrgency'] = this.state.treatmentUrgency : null;
                let mouthguardRecommended_1 = this.state.mouthguardRecommended !== "" ? dataToSend['mouthguardRecommended'] = this.state.mouthguardRecommended : null;
                let mouthguardDelivered_1 = this.state.mouthguardDelivered !== "" ? dataToSend['mouthguardDelivered'] = this.state.mouthguardDelivered : null;
                let fluorideVarnishRecommended_1 = this.state.fluorideVarnishRecommended !== "" ? dataToSend['fluorideVarnishRecommended'] = this.state.fluorideVarnishRecommended : null;
                let fluorideVarnishDelivered_1 = this.state.fluorideVarnishDelivered !== "" ? dataToSend['fluorideVarnishDelivered'] = this.state.fluorideVarnishDelivered : null;
            } else {
                let edentulous_1 = this.state.edentulous !== "" ? dataToSend['edentulous'] = this.state.edentulous : null;
            }
        } else {
            let athleteRefused_1 = this.state.athleteRefused !== "" ? dataToSend['athleteRefused'] = this.state.athleteRefused : null;
        }

        let doctorComments = this.state.doctorComments !== "" ? dataToSend["doctorComments"] = this.state.doctorComments : null;

         console.log(dataToSend);


        axios
            .post(path, dataToSend, {
             
                headers: { 'api-name': 'specialSmile', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes;
                console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    // useEffect(() => {
    //     if(mouthPainString === "No") {
    //         mouthPainArea = []
    //     }
    // }, [mouthPainString]);

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                        <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"><Link to="/amrit_medical_professional"><span><img src={process.env.PUBLIC_URL + '/assets/images/dbicon.png'} alt="" /></span> Scan ID</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                            <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/smile.png'} alt="" />
                                    <form>
                                    <div className="hpTop">
                                            <div className="hpTxt hpTxtB">

                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong>{this.state.participant_list.contactNo} <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                                                {/* <p><strong>DOB:</strong> <span>{this.state.participant_list.dob}</span></p> */}
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div>
                                        <div className="compositionBg">

                                            {/* DENTAL HISTORY */}
                                            <h3>Dental History</h3>
                                            <h5>How often do you clean your mouth?</h5>
                                            <div className="newSelect mrgbtm30">
                                                <select name="mouthCleanFreq" onChange={this.handleChange} defaultValue="">
                                                    <option className="slRedbg" value="">Select Option</option>
                                                    <option value="Once or more a day">Once or more a day</option>
                                                    <option value="2 to 6 times per week">2 to 6 times per week</option>
                                                    <option value="Once per week">Once per week</option>
                                                    <option value="Less than once per week">Less than once per week</option>
                                                    <option value="Not sure">Not sure</option>
                                                </select>
                                            </div>
                                            <h5>Pain inside mouth</h5>
                                            <div className="newSelect">
                                                <select name="mouthPainString" onChange={this.handleChange}>
                                                    <option className="slRedbg" value="">Select Option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.mouthPainString === "Yes" && <>
                                                    <div className="unabletoTest stpBcheck">
                                                        {this.state.mouthPainAreaArray.map((area, i) => (
                                                            <span key={i}>
                                                                <input
                                                                    type="checkbox"
                                                                    name="mouthPainArea"
                                                                    value={area.id}
                                                                    onChange={this.handleAreaCheckbox}
                                                                    checked={this.state.mouthPainArea.includes(area.value)}
                                                                />
                                                                {area.value}
                                                            </span>
                                                        ))
                                                        }
                                                    </div>
                                                </>
                                            }
                                            <div className="bordrBtmfrm1"></div>
                                            <div className="unabletoTest stpBcheck">
                                                <span><input
                                                    name="athleteRefused"
                                                    type="checkbox"
                                                    onChange={this.handleChange}
                                                    value={this.state.athleteRefused}
                                                    checked={this.state.athleteRefused}
                                                /> Athlete refused/could not screen
                                                </span>
                                            </div>

                                            {/* SCREENING */}
                                            {
                                                this.state.athleteRefused === false && <>
                                                    <h3>Screening</h3>
                                                    <h5>Edentulous</h5>
                                                    <div className="newSelect mrgbtm30">
                                                        <select name="edentulous" onChange={this.handleChange} >
                                                            <option className="slRedbg" value="">Select Option</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                    {/* <h5 className="mrgbtm30">If “No” then please answer all this questions below</h5> */}
                                                    {
                                                        this.state.edentulous === "No" && <>

                                                            <h5>Untreated decay</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="untreatedDecayString" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.untreatedDecayString === "Yes" && <>
                                                                    <div className="unabletoTest stpBcheck">
                                                                        {this.state.untreatedDecayAreaArray.map((area, i) => (
                                                                            <span key={i}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="untreatedDecayArea"
                                                                                    value={area.id}
                                                                                    onChange={this.handleAreaCheckbox}
                                                                                    checked={this.state.untreatedDecayArea.includes(area.value)}
                                                                                />{area.value}
                                                                            </span>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </>
                                                            }

                                                            <h5>Filled teeth</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="filledTeeth" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </select>
                                                            </div>

                                                            <h5>Missing teeth</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="missingTeethString" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.missingTeethString === "Yes" && <>
                                                                    <div className="unabletoTest stpBcheck">
                                                                        {this.state.missingTeethAreaArray.map((area, i) => (
                                                                            <span key={i}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="missingTeethArea"
                                                                                    value={area.id}
                                                                                    onChange={this.handleAreaCheckbox}
                                                                                    checked={this.state.missingTeethArea.includes(area.value)}
                                                                                />{area.value}
                                                                            </span>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </>
                                                            }

                                                            <h5>Sealant(s)</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="sealant" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </select>
                                                            </div>

                                                            <h5>Injury</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="injuryString" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.injuryString === "Yes" && <>
                                                                    <div className="injTreat">
                                                                        <h5>Injury Treated</h5>
                                                                        <div className="newSelect mrgbtm30">
                                                                            <select name="injuryTreated" onChange={this.handleChange} >
                                                                                <option className="slRedbg" value="">Select Option</option>
                                                                                <option value="Yes">Yes</option>
                                                                                <option value="No">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }

                                                            <h5>Fluorosis</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="fluorosis" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>

                                                            <h5>Gingival signs</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="gingivalSigns" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>

                                                                </select>
                                                            </div>
                                                            <h5>Treatment urgency</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="treatmentUrgency" onChange={this.handleChange} defaultValue="Maintenance">
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Maintenance">Maintenance</option>
                                                                    <option value="Non-urgent">Non-urgent</option>
                                                                    <option value="Urgent">Urgent</option>
                                                                </select>
                                                            </div>
                                                            <h5>Mouthguard recommended</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="mouthguardRecommended" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.mouthguardRecommended === "Yes" && <>
                                                                    <div className="unabletoTest stpBcheck">
                                                                        <span>
                                                                            <input
                                                                                name="mouthguardDelivered"
                                                                                type="checkbox"
                                                                                onChange={this.handleChange}
                                                                                value={this.state.mouthguardDelivered}
                                                                                checked={this.state.mouthguardDelivered}
                                                                            /> Mouthguard delivered
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            }

                                                            <h5>Fluoride Varnish recommended</h5>
                                                            <div className="newSelect mrgbtm30">
                                                                <select name="fluorideVarnishRecommended" onChange={this.handleChange} >
                                                                    <option className="slRedbg" value="">Select Option</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                this.state.fluorideVarnishRecommended === "Yes" && <>
                                                                    <div className="unabletoTest stpBcheck">
                                                                        <span>
                                                                            <input
                                                                                name="fluorideVarnishDelivered"
                                                                                type="checkbox"
                                                                                onChange={this.handleChange}
                                                                                value={this.state.fluorideVarnishDelivered}
                                                                                checked={this.state.fluorideVarnishDelivered}
                                                                            /> Fluoride Varnish delivered
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            }

                                                        </>
                                                    }

                                                </>
                                            }

                                            <div className="addlComment">
                                                <h3>Additional comments:</h3>
                                                <textarea className="form-control" placeholder="Comment" name="doctorComments" onChange={this.handleChange}></textarea>
                                            </div>


                                        </div>

                                        <div className="intBtn">
                                            <Link to="/amrit_medical_professional" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Special_Smile;