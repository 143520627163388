import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class YA_View_Exercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            yaAthleteId:"",
            yaContentOrder:"", 
            ya_innercontentDetails_list:[],
            ya_innercontentDetails_list_1: [],
            ya_innercontentDetails_list_2: [],
            ya_innercontentDetails_list_0: [],
            name:"",
            name_color:"",
            inner_page_color:"",
            content_order:"",
            training_id:"",
            yaItemId:""

        };    
        this.viewExercise = this.viewExercise.bind(this); 
        //this.getTrainings = this.getTrainings.bind(this);  
        this.handleNext = this.handleNext.bind(this); 
    } 

    async componentDidMount() {
        window.scrollTo(0, 0);
      
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaAthleteId = await localStorage.getItem('yaAthleteId')
        if(yaAthleteId===null){
            localStorage.setItem('yaAthleteId',this.props.location.state.yaAthleteId)
        }
        const updated_yaAthleteId = await localStorage.getItem('yaAthleteId')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaAthleteId:updated_yaAthleteId
        })

        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getTrainings();
        //this.viewExercise();
    }

    getTrainings() {
        axios.post(path, {
        }, {
            headers: { 'Api-Name': 'getTrainings', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const TrainingDetails = res.response.traning;
               // console.log(TrainingDetails);
                TrainingDetails.map((eachtraningDetails, i) => {
                    if(eachtraningDetails.traningName==="Young Athletes"){
                    this.setState({
                             training_id: eachtraningDetails._id
                    });
                    }
                })
                this.getYaItemsApp();
              
                
            }
        });
    }


    getYaItemsApp() {
        //console.log(this.state.training_id)
        axios.post(path, {
            yaTraningId: this.state.training_id
        }, {
            headers: { 'api-name': 'getYaItemsApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ItemDetails = res.response.items;
                //console.log(ItemDetails);
                ItemDetails.map((eachitemDetails, i) => {
                    if(eachitemDetails.yaItemType==="multi"){
                    //  console.log( eachitemDetails.yaItemId);

                    this.setState({
                        yaItemId: eachitemDetails.yaItemId
                    });
                    this.viewExercise();
                    }
                })
            }
        });
    }
   
   
    viewExercise() {
        //  console.log('test--'+this.state.yaItemId);
         axios.post(path, {
             yaAthleteId: this.state.yaAthleteId,
             yaItemId:this.state.yaItemId,
             yaContentOrder:this.state.content_order
         }, {
             headers: { 'api-name': 'viewExercise', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
         }).then(serverResponse => {
             const res = serverResponse.data;
            //  console.log(res);
             if (res.status === true) {
                
                 const ViewDetails = res.response.contents[0];
                 const ViewInnerContentDetails = res.response.contents[0].content_array[0].innerContents;
                 const ViewContentDetails = res.response.contents[0].content_array[0];
                //  console.log(ViewDetails);
             
                 this.setState({
                    ya_innercontentDetails_list:ViewDetails,
                    ya_innercontentDetails_list_0: ViewInnerContentDetails[0],
                    ya_innercontentDetails_list_1: ViewInnerContentDetails[1],
                    ya_innercontentDetails_list_2: ViewInnerContentDetails[2],
                    name:ViewContentDetails.name,
                    name_color:ViewContentDetails.name_color,
                    inner_page_color:ViewDetails.inner_page_color,
                    content_order:ViewDetails.order
                     
                 });
               
             }
             else {
                  toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                this.props.history.push("/ya_program");
             }
         });
     }

     handleNext(){
        window.scrollTo(0, 0);
        this.viewExercise();
        
    }
    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
            <Header {...this.props} />
                <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.inner_page_color}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:"#a9d6ee"}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3 style={{color:this.state.name_color}}>
                                                {this.state.name}</h3>
                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ya10pdn pdnCurve">
                                    <div className="ya11top">
                                        <div className="curveMain" style={{backgroundColor:this.state.ya_innercontentDetails_list_0.yaContentBottomColor}}>
                    
                                            <div className="slideshow">
                                            <div className="huge">
                                                <div className="golding" style={{borderColor:this.state.ya_innercontentDetails_list_0.yaContentBottomStripColor}}>
                                              
                                                    <div className="aslide carfImg">
                                                        <img src={this.state.ya_innercontentDetails_list_0.yaContentPageMultimedia} alt=""/>                                         
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="curvMnBtm"><img src={process.env.PUBLIC_URL + '/assets/images/whiteLogo.png'} alt=""/> </div>
                                        </div>
                                        
                                        <div className="curveMain curveB" style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                       
                                            <div className="slideshow">
                                            <div className="huge goldBordr">
                                                <div className="golding goldBordr">
                                                    <div className="aslide">
                                                        <div className="curveTP">
                                                            <span className="curveIcon" style={{backgroundColor:this.state.name_color}}>
                                                       
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/curveicon.png'} alt=""/>
                                                            </span>  
                                                            <div className="curveIconright" style={{color:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                                           
                                                                {this.state.ya_innercontentDetails_list_1.yaRightSideTopHeading}
                                                                <span style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                    
                                                                &nbsp;</span>
                                                            </div>
                                                        </div> 
                                                        <h4 style={{color:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>{this.state.ya_innercontentDetails_list_1.yaContentPageHeading_1}</h4>     
                                                        
                                                        <p>{this.state.ya_innercontentDetails_list_1.yaContentPageDesc_1}</p>                                  
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="curvMnTxt" style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                                <h4>{this.state.ya_innercontentDetails_list_1.yaContentPageHeading_2}</h4>
                                                <p>{this.state.ya_innercontentDetails_list_1.yaContentPageDesc_2}</p>
                                            </div>
                                        </div>
                                    </div>
                                                    
                                    <div className="page11Video">
                                        <div className="page11videoBg" style={{backgroundColor:this.state.ya_innercontentDetails_list_2.yaContentBottomColor}}>
                                       
                                        <div className="page11videoInn">
                                            
                                          
                                             <video width="" src={this.state.ya_innercontentDetails_list_2.yaContentPageMultimedia} type="video/mp4" height="415" controls controlsList="nodownload" onContextMenu={e => e.preventDefault()}>                                            
                                              
                                            </video>
                                            
                                        </div>                                
                                        </div>
                                    </div>
                                                        
                                <div className="intBtnBx">
                                    <Link to="ya_program" className="intbtnax">Back to Main Menu</Link>
                                    <Link to="#" onClick={()=>this.handleNext()} className="intbtnax">Next</Link> 
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
    

        </div>
        )
    }
}
export default YA_View_Exercise;