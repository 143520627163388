import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;

class Ya_Equipments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_contentDetails_list: [],
            yaItem_Id:"",
            yaItemDescription:"",
            inner_page_color:""
        };    
        this.getYaContentApp = this.getYaContentApp.bind(this);  

    } 

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaItem_Id = await localStorage.getItem('yaItem_Id')
        const yaItemDescription = await localStorage.getItem('yaItemDescription')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaItem_Id: yaItem_Id,
            yaItemDescription:yaItemDescription
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getYaContentApp();
    }
    getYaContentApp() {
        axios.post(path, {
            yaItemId: this.state.yaItem_Id
        }, {
            headers: { 'api-name': 'getYaContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ContentDetails = res.response.contents;
               // console.log(ContentDetails);


                var size = 4; var arrayOfArrays = [];
                for (var i=0; i<ContentDetails.length; i+=size) {
                    arrayOfArrays.push(ContentDetails.slice(i,i+size));
                }
                //console.log(arrayOfArrays);

                this.setState({
                    ya_contentDetails_list: arrayOfArrays,
                    inner_page_color:ContentDetails[0].inner_page_color
                    
                });

            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.inner_page_color}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:this.state.inner_page_color}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3>{this.state.yaItemDescription}</h3>
                                            <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="yaSlidebg yaCarosB">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                        {this.state.ya_contentDetails_list.map((eachyapage, i) => (
                                            <li data-target="#carouselExampleIndicators" key={i}  className={(i===0 ? "active" : "")} data-slide-to={i}></li>
                                          
                                            ))} 
                                      
                                        </ol>
                                        <div className="carousel-inner">
                                        {this.state.ya_contentDetails_list.map((eachyapage, i) => (
                                            <div className={"carousel-item"+ (i===0 ? " active" : "")} key={i}>
                                            <div className="yaUse">
                                            {eachyapage.map((eachyainnerpage, j) => (
                                                <div className="yaUseBlock" key={j}>
                                                    <div className="useBlockInn">
                                                        <span className="useBlockShadow"><img src={eachyainnerpage.content_array[0].multimedia} alt="" width="60%" height="60%"/></span>
                                                        {eachyainnerpage.content_array[0].name}
                                                    </div>
                                                    <div className="useOr">OR</div>
                                                    <div className="useBlockInn">
                                                        <span className="useBlockShadow"><img src={eachyainnerpage.content_array[1].multimedia} alt="" width="60%" height="60%"/></span>
                                                        {eachyainnerpage.content_array[1].name}
                                                    </div>
                                                </div>
                                               
                                               ))} 
                                            </div>
                                            </div>
                                            ))} 
                                          
                                          
                                        </div>
                                        </div>
                                </div>
                                
                                <div className="intBtnBx"><Link to="/ya_home" className="intbtnax">Back to Main Menu</Link> 
                                <a href="#carouselExampleIndicators" data-slide="prev" className="carousel-control left intbtnbx">Previous</a>
                                <a href="#carouselExampleIndicators" data-slide="next" className="carousel-control right intbtnbx">Next</a>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        )
    }
}
export default Ya_Equipments;