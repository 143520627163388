import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Nutrition2 extends React.Component {
   
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop nutritionBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Nutrition</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
        
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
          
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                    {/* <h4>I choose to eat more fruits &amp; vegetables</h4> */}
                                <div className="nutritionBlog nutritionB">
                                        {/* <h3>Why?</h3> */}
                                        <ul>
                                            <li>TYou know fruits and vegetables are important for your health, but sometimes it can be difficult to know what other foods you should be eating. </li>
                                            <li>This plate has all the food groups, with some great choices in each group!</li>
                                        </ul>
                                        <Link to="/nutrition3" className="takeQuiz">Take The Quiz</Link>
                                    </div>
                                    <div className="row nutriVariety">
                                        <div className="col-md-4">
                                            <div className="nutriThali"  style={{backgroundColor:'#F9A01B'}}>
                                                <span className="dishMain" style={{backgroundColor:'#CD7D05'}}>
                                                    <span className="dishBg"><img src={process.env.PUBLIC_URL + '/assets/images/dish1.png'} alt=""/></span>
                                                </span>
                                                <p>Include fruit at<br/> breakfast</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="nutriThali"  style={{backgroundColor:'#1BA6D6'}}>
                                                <span className="dishMain"  style={{backgroundColor:'#0A7FA8'}}>
                                                    <span className="dishBg"><img src={process.env.PUBLIC_URL + '/assets/images/dish2.png'} alt=""/></span>
                                                </span>
                                                <p>Plant a vegetable or fruit garden at home or in your community</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="nutriThali"  style={{backgroundColor:'#4DBA6D'}}>
                                                <span className="dishMain" style={{backgroundColor:'#249846'}}>
                                                    <span className="dishBg"><img src={process.env.PUBLIC_URL + '/assets/images/dish3.png'} alt=""/></span>
                                                </span>
                                                <p>Add vegetable tosoups, broth &amp; sandwiches &amp; other foods</p>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <div className="intBtn">
                                        <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                        <Link to="/nutrition3"  className="intbtnb">Next</Link>
                                    </div>
                                    
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        )
    }
}
export default Nutrition2;