import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class YA_Form2 extends React.Component {
   
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/yabg2.png'})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 introsx">
                                    <h3>Pre-Evaluation</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
            
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
            
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="ya4Top">
                                    <span className="preAct"></span>
                                    <span className="current"></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                
                                                        
                                    
                                <div className="page4borderbase page5Base">
                                    <h5>05. Has your child participated in Young Athletes before ?</h5>
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio6" value="option6" checked/>
                                            <label className="form-check-label" for="radio6">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="radio7" value="option7"/>
                                            <label className="form-check-label" for="radio7">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                  
                                    
                                    <h5>07. If Yes, please specify the diagnosis?</h5>
                                    <div className="page4Radio page5Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioc" id="radio10" value="option10" checked/>
                                            <label className="form-check-label" for="radio10">
                                                Autism or Autism Spectrum Disorder (ASD)
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioc" id="radio11" value="option11"/>
                                            <label className="form-check-label" for="radio11">
                                                Intellectual Disabilty (ID)
                                            </label>
                                        </div>
                                    
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioc" id="radio12" value="option12"/>
                                            <label className="form-check-label" for="radio12">
                                                Global Development Delay (GDD)
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioc" id="radio13" value="option13"/>
                                            <label className="form-check-label" for="radio13">
                                                Down Syndrome (DS)
                                            </label>
                                        </div>
                                    </div>
                                                                
                                    
                                    <div className="page4Radio">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioc" id="radio14" value="option14"/>
                                            <label className="form-check-label" for="radio14">
                                                Other
                                            </label>
                                        </div>
                                            <input type="text" className="form-control" id="" placeholder="Specify Please..."/>                               
                                    </div>
                                    
                                </div>
                             
                            <div className="intBtnBx intautowidth"><Link to="/ya_page1" className="intbtnax">Back to Main Menu</Link> <Link to="#" className="intbtnbx">Save</Link> <Link to="/ya_page6" className="intbtnbx">Next</Link></div>
                        </div>
                </div>
            </div>
            
        </div>
    </section>
    
            </div>
        )
    }
}
export default YA_Form2;