import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class YA_Form1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_athletes_add_question: [],
            ya_athletes_question:[],
            yaPageId:"",
            yaPageOrder:"",
            yaAthleteId:"",
            image: "",
            divvalue:"hide",
            preDiv:null,
            yaPageColor:"",
            imgSrc : process.env.PUBLIC_URL + '/assets/images/upload.png'
        };    
        this.addResumeAthlete = this.addResumeAthlete.bind(this);  
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        

    } 

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaAthleteId = await localStorage.getItem('yaAthleteId')
        
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaAthleteId:yaAthleteId
        })
        if(yaAthleteId===null){
            if(this.props.location.state && this.props.location.state.yaAthleteId){
            this.setState({yaAthleteId:this.props.location.state.yaAthleteId})
            }
            else{                
                this.setState({yaAthleteId:''})
            }
         }
         
         if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.addResumeAthlete();
    }

    handleChange(event) {
        //console.log("event");
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        //console.log(value);console.log(name);
        if (event.target.files && event.target.files[0]) {
            this.setState({
                [name]:event.target.files[0]
            })
            //updating image preview                        
            this.setState({
                imgSrc: URL.createObjectURL(event.target.files[0])
            })
        }
        else{
        this.setState({
            [name]: value
        });
     }
    }

       handleChangeRadio(event,i,j,length) {
       //console.log((i,j,length));
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

       if(j===length-1){
        this.setState({divvalue:"show",changeIndex:i})}else{this.setState({divvalue:"hide",changeIndex:null})
       }
  
       // if(value==="Other" || value==="Yes"){this.setState({divvalue:"show"})}else{this.setState({divvalue:"hide"})}
    }
  
  
  
      handleSubmit(event) {
         //console.log(this.state);
         document.getElementById("exampleModalCenter").classList.remove("show", "d-block");
         document.querySelectorAll(".modal-backdrop")
         .forEach(el => el.classList.remove("modal-backdrop"));
          //this.saveYaAthlete(this.state.yaPageId, this.state.yaAthleteId,this.state.yaPageOrder,this.state.image);
          this.saveYaAthlete();
          event.preventDefault();
      }


      validationCheck(quesArr){
        //console.log("Inside validationCheck");
        let isValidate=true;
        quesArr.map((eachqs, i) => {
        if(eachqs.yaAnswer==null){
            //console.log("Inside if");
            isValidate = false;
        }
       })       
        return isValidate;
      }


      saveYaAthlete() {
        let quesArray=[];
        this.state.ya_athletes_question.map((eachyaquestionDetails, i) => {
            let eachObject={
                "yaQuestion":eachyaquestionDetails.yaQuestionDescription,
                "yaQuestionType":eachyaquestionDetails.yaQuestionType,
                "showInList":eachyaquestionDetails.showInList,
                "yaAnswer":this.state[eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder]?
                this.state[eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder]:null
            }
            quesArray.push(eachObject);
            return eachObject;
            
        })
        //console.log(quesArray);
        //console.log(quesArray[0].yaAnswer);
        let formData = new FormData(); 
        formData.append('yaPageId', this.state.yaPageId);   //append the values with key, value pair
        formData.append('yaQuestion', JSON.stringify(quesArray));
        formData.append('yaAthleteId', this.state.yaAthleteId); 
        formData.append('yaPageOrder', parseInt(this.state.yaPageOrder)); 
        formData.append('image', quesArray[0].yaAnswer);
        //console.log(formData);

        if(this.validationCheck(quesArray)){

        axios.post(path,formData,         
            {
                headers: { 
                'api-name': 'saveYaAthlete',
                'Content-Type': 'multipart/form-data',
                'userId': this.state.userId,
                'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes.data;
                //console.log(serverRes);
                console.log(res);

                if (res.status === true) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  
                    localStorage.setItem('yaAthleteId',res.response.yaAthleteId)
                  
                    if(res.response.evaluationDone===false){
                    
                        this.addResumeAthlete();
                        window.location.reload(false);
                    }else{
                        this.props.history.push("/ya_view_exercise");  
                    }
                   
                    //this.props.history.push("/ya_form1");
                } else {
                    toast.error("Some error occured", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    //console.log(res.message);

                }
            });
        }else{
            toast.error("Please fill up all the fields", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    addResumeAthlete() {
        window.scrollTo(0, 0);
       // console.log(this.state.yaAthleteId);
        axios.post(path, {
            yaAthleteId: this.state.yaAthleteId
        }, {
            headers: { 'api-name': 'addResumeAthlete', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               
                const QuestionDetails = res.response.pages;
                const YAQuestion = res.response.pages.question;
                const YAPageId = QuestionDetails.pageId;
                const YAPageOrder = QuestionDetails.pageOrder;
                const yaPageColor = QuestionDetails.pageColor;
                
                console.log(QuestionDetails);
                //console.log(YAPageOrder);
            
                this.setState({
                    ya_athletes_add_question: QuestionDetails,
                    ya_athletes_question: YAQuestion,
                    yaPageId: YAPageId,
                    yaPageOrder: YAPageOrder,
                    yaPageColor: yaPageColor,
                    
                });

                let total_pages=11;
                let current_yaPageOrder = this.state.yaPageOrder, preDiv = [];
                for (let cnt = 1; cnt < current_yaPageOrder; cnt++) {
                        let progress_bar = (<span className="preAct" key={cnt}></span>);
                        preDiv.push(progress_bar);
                }
                preDiv.push(<span className="current"></span>);
                let upcoming_pages= parseInt(total_pages-current_yaPageOrder)
                for (let cnt2 = 0; cnt2 < upcoming_pages; cnt2++) {
                    let progress_bar2 = (<span key={cnt2}></span>);
                    preDiv.push(progress_bar2);
            }
            this.setState({
                preDiv:preDiv
            })
               // console.log(this.state.yaPageId);
            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

   

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                 <Header {...this.props} />
                 <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.yaPageColor}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:"#a9d6ee"}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3>Pre-Evaluation</h3>
                                            <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                 {/* <section className="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/yabg2.png'})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 introsx">
                                    <h3>Pre-Evaluation</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="fit_intro ya_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                <div className="ya4Top">
                                    {this.state.preDiv}                            
                                    {/* <span className="current"></span>   
                                    <span></span>                                 */}
                                </div>

                                {this.state.ya_athletes_question.map((eachyaquestionDetails, i) => (
                                    <div key={i} >
                            {eachyaquestionDetails.yaQuestionType === "upload" ?
                                <div className="ya4Browse" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/linear.png'})`}}>
                                <div className="box">
                                    <div className="browseBx">
                                        <div className="js--image-preview" 
                                        style={{backgroundImage:`url(${this.state.imgSrc})`}}></div>
                                        <h4>{eachyaquestionDetails.questionOrder}. {eachyaquestionDetails.yaQuestionDescription}</h4>
                                    </div>
                                    <div className="uploadBtn">
                                    <label>
                                        <input type="file" className="image-upload" name={eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} onChange={this.handleChange} required/>
                                    </label>
                                    {/* {let img_name = eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} */}
                                    {/* <img src={this.state.img_name?this.state.img_name:''}></img> */}
                                    
                                    </div>
                                </div>
                                </div>

                               : eachyaquestionDetails.yaQuestionType === "mcqwe" ?

                                <div className="page4borderbase">
                                    <h5>{eachyaquestionDetails.questionOrder}. {eachyaquestionDetails.yaQuestionDescription}</h5>
                                    <div className="page4Radio page5Radio">
                                    {eachyaquestionDetails.options.map((eachoption, j) => (
                                        
                                        <div className="form-check" key={i+j}>
                                            <input className="form-check-input" type="radio" name={eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} 
                                            id={"radio"+i+j} value={eachoption}
                                             onChange={event=>this.handleChangeRadio(event,i,j,eachyaquestionDetails.options.length)} required />
                                            <label className="form-check-label" htmlFor={"radio"+i+j}>
                                                {eachoption}
                                            </label>
                                        </div>
                                      ))}
                                   
                                    </div>
                                    <div className={i===this.state.changeIndex?"page4Radio show":"page4Radio hide"}>
                                        <input type="text" 
                                        className={i===this.state.changeIndex?"form-control show":"form-control hide"} id="" name={eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} placeholder={eachyaquestionDetails.yaQuestionPlaceholder} onChange={this.handleChange} required/>
                                        
                                    </div>
                                </div>     
                                :eachyaquestionDetails.yaQuestionType === "text" ?
                                <div className="page4borderbase page4B">
                                    <h5>{eachyaquestionDetails.questionOrder}. {eachyaquestionDetails.yaQuestionDescription}</h5>                          
                                    <input type="text" name={eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} className="form-control" id="" onChange={this.handleChange} required/>
                                </div>
                                :eachyaquestionDetails.yaQuestionType === "mcqwhe" ?
                                <div className="page4borderbase">
                                    <h5>{eachyaquestionDetails.questionOrder}. {eachyaquestionDetails.yaQuestionDescription}</h5>
                                    <div className="page4Radio page5Radio">
                                    {eachyaquestionDetails.options.map((eachoption, j) => (
                                        <div className="form-check" key={i+j}>
                                            <input className="form-check-input" type="radio" name={eachyaquestionDetails.yaQuestionType+eachyaquestionDetails.questionOrder} id={"radio"+i+j} value={eachoption} onChange={this.handleChange} required/>
                                            <label className="form-check-label" htmlFor={"radio"+i+j}>
                                                {eachoption}
                                            </label>
                                        </div>
                                      ))}
                                   
                                    </div>
                                   
                                </div>     
                                :""}
                               </div>
                                ))}            
                               
                                <div className="intBtnBx intautowidth"><Link to="/ya_home" className="intbtnax">Back to Main Menu</Link>
                                 <Link to="#" className="intbtnbx" data-toggle="modal" data-target="#exampleModalCenter">Save and Next</Link> 
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>

                {/* <!-- Modal --> */}
                <div className="modal fade cautionModal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="cautionHdn"><i className="fas fa-exclamation-triangle"></i> <span>Caution</span></div>
                            <p>Information once saved cannot be edited, please confirm before proceeding.</p>
                            <button type="button" onClick={this.handleSubmit}  className="btn btn-primary">Confirm</button>
                          </div>
                        </div>
                      </div>
                    </div>
                
            </div>
        )
    }
}
export default YA_Form1;