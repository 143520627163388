import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class YA_Exercise_Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_innercontentDetails_list_1: [],
            ya_innercontentDetails_list_2: [],
            ya_innercontentDetails_list_0: [],
            name:"",
            name_color:"",
            exercise_id:"",
        };    
        this.getYaInnerContentApp = this.getYaInnerContentApp.bind(this);  

    } 

    async componentDidMount() {
        window.scrollTo(0, 0);
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const exercise_id = await localStorage.getItem('exercise_id')
        const name = await localStorage.getItem('name')
        const name_color = await localStorage.getItem('name_color')
        const inner_page_color = await localStorage.getItem('exercise_inner_page_color')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            exercise_id: exercise_id,
            name:name,
            name_color:name_color,
            inner_page_color:inner_page_color,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getYaInnerContentApp();
    }
   
    getYaInnerContentApp() {
        axios.post(path, {
            yaContentId: this.state.exercise_id
        }, {
            headers: { 'api-name': 'getYaInnerContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(serverResponse);
            if (res.status === true) {
                const Inner_ContentDetails = res.response.innerContents;
                this.setState({
                    ya_innercontentDetails_list_0: Inner_ContentDetails[0],
                    ya_innercontentDetails_list_1: Inner_ContentDetails[1],
                    ya_innercontentDetails_list_2: Inner_ContentDetails[2]
                    
                });
               // console.log(Inner_ContentDetails);

            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
    render() {
        return (
            <div>
            <Header {...this.props} />
                <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.inner_page_color}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:"#a9d6ee"}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3 style={{color:this.state.name_color}}>
                                                {this.state.name}</h3>
                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ya10pdn pdnCurve">
                                    <div className="ya11top">
                                        <div className="curveMain" style={{backgroundColor:this.state.ya_innercontentDetails_list_0.yaContentBottomColor}}>
                    
                                            <div className="slideshow">
                                            <div className="huge">
                                                <div className="golding" style={{borderColor:this.state.ya_innercontentDetails_list_0.yaContentBottomStripColor}}>
                                              
                                                    <div className="aslide carfImg">
                                                        <img src={this.state.ya_innercontentDetails_list_0.yaContentPageMultimedia} alt=""/>                                         
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="curvMnBtm"><img src={process.env.PUBLIC_URL + '/assets/images/whiteLogo.png'} alt=""/> </div>
                                        </div>
                                        
                                        <div className="curveMain curveB" style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                        {/* style="background: #EB2227;" */}
                                            <div className="slideshow">
                                            <div className="huge goldBordr">
                                                <div className="golding goldBordr">
                                                    <div className="aslide">
                                                        <div className="curveTP">
                                                            <span className="curveIcon" style={{backgroundColor:this.state.name_color}}>
                                                            {/* style="background: #EB2227;" */}
                                                                <img src={process.env.PUBLIC_URL + '/assets/images/curveicon.png'} alt=""/>
                                                            </span>  
                                                            <div className="curveIconright" style={{color:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                                           
                                                                {this.state.ya_innercontentDetails_list_1.yaRightSideTopHeading}
                                                                <span style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                    
                                                                &nbsp;</span>
                                                            </div>
                                                        </div> 
                                                        <h4 style={{color:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>{this.state.ya_innercontentDetails_list_1.yaContentPageHeading_1}</h4>     
                                                        {/* {this.state.ya_innercontentDetails_list_1.yaContentPageDesc_1.split('\n').map(str => <p>{str}</p>)} */}
                                                        <p>{this.state.ya_innercontentDetails_list_1.yaContentPageDesc_1}</p>                                  
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="curvMnTxt" style={{backgroundColor:this.state.ya_innercontentDetails_list_1.yaContentBottomColor}}>
                                                <h4>{this.state.ya_innercontentDetails_list_1.yaContentPageHeading_2}</h4>
                                                <p>{this.state.ya_innercontentDetails_list_1.yaContentPageDesc_2}</p>
                                            </div>
                                        </div>
                                    </div>
                                                    
                                    <div className="page11Video">
                                        <div className="page11videoBg" style={{backgroundColor:this.state.ya_innercontentDetails_list_2.yaContentBottomColor}}>
                                        {/* style="background: #EB2227;" */}
                                        <div className="page11videoInn">
                                            
                                             {/* <iframe width="" height="415" src={this.state.ya_innercontentDetails_list_2.yaContentPageMultimedia} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                             <video width="" src={this.state.ya_innercontentDetails_list_2.yaContentPageMultimedia} type="video/mp4" height="415" controls controlsList="nodownload" onContextMenu={e => e.preventDefault()}>                                            
                                                {/* <source src={this.state.ya_innercontentDetails_list_2.yaContentPageMultimedia} type="video/mp4"/> */}
                                            </video>
                                            
                                        </div>                                
                                        </div>
                                    </div>
                                                        
                                <div className="intBtnBx"><Link to="ya_exercise" className="intbtnax">Back to Main Menu</Link> 
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
    

        </div>
        )
    }
}
export default YA_Exercise_Details;