import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_After_Registration_Offline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            name: "",
            participant_details: [],
        };
    }
    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const adminId = await localStorage.getItem('SOB_adminId')
        const SOB_name = await localStorage.getItem('SOB_name')
        console.log(userId);
        if(userId===null || userId==="undefined"){
            localStorage.setItem("SOB_userId", this.props.location.state.SOB_userId);
        }
        if(SOB_name===null || SOB_name==="undefined"){
            localStorage.setItem("SOB_name", this.props.location.state.SOB_name);
        }
       
        const updated_userId = await localStorage.getItem('SOB_userId')
        const updated_SOB_name = await localStorage.getItem('SOB_name')
        //console.log(updated_userId);
        this.setState({
            userId: updated_userId, 
            name: updated_SOB_name,
            adminId: adminId,

        })

        if (this.state.userId === null) {
            this.props.history.push("/offline_login");
        }
        this.getRegistrationDetailsAdmin();
    }

    getRegistrationDetailsAdmin() {
        axios.post(path, {
            participantType:"",
            eventId:"61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetailsAdmin', 'userId': this.state.userId, 'AdminId': this.state.adminId }
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(res);
            const ParticipantTypeList = res.response.data[0];
            if (res.status === true) {
                this.setState({
                    participant_details:ParticipantTypeList
                });
                
                //console.log(ContentDetails);
              
               
            }
        });
    }



    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock amritAfterregd">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt="" /></Link>
                            {/* <h3>You have registered and pending for approval</h3> */}
                            <h3></h3>
                            <div className="afterRegdamrit">
                                <div className="aftRinner">
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Participant's Name:</span>
                                        <span className="afterAmrTxtb">{this.state.name !== "" ? this.state.name : "---"}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Participant's Type:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details.participantType !== "" ? this.state.participant_details.participantType : "---"}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Status:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details.isRejected === true ? "Not Approved" : (this.state.participant_details.isApproved === true ? "Approved" : "Pending for Approval")}</span>
                                    </div>
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Preferred State:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Participation State Preference"] !== "" ? this.state.participant_details["Participation State Preference"] : "---"}</span>
                                    </div>

                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Preferred City:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Participation District Preference"] !== "" ? this.state.participant_details["Participation District Preference"] : "---"}</span>
                                    </div>
                                    {this.state.participant_details.isApproved === true ?
                                        <div className="afterAmr">
                                            <span className="afterAmrTxta">Event Date:</span>
                                            <span className="afterAmrTxtb">7th April,2022</span>
                                        </div> : ""}
                                    {this.state.participant_details.isApproved === true ?
                                        <div className="afterAmr">
                                            <span className="afterAmrTxta">Event Venue:</span>
                                            <span className="afterAmrTxtb">{this.state.participant_details.eventVenue !== "" ? this.state.participant_details.eventVenue : "---"}</span>
                                        </div> : ""}
                                    <div className="afterAmr">
                                        <span className="afterAmrTxta">Vacination Details:</span>
                                        <span className="afterAmrTxtb">{this.state.participant_details["Vaccination Details"] !== "" ? this.state.participant_details["Vaccination Details"] : "---"}</span>
                                    </div>
                                </div>



                            </div>
                            
                            <div className="intBtn">
                            {this.state.participant_details.isRejected === true ?
                                <div style={{marginBottom:20}}><span style={{ fontStyle: "italic", color: "#fc6161", fontSize: 17 }}><strong>Rejected Reason:  </strong>{this.state.participant_details.rejectComment}</span></div>
                                : ""}


                               
                                {this.state.participant_details.isRejected === true?
                                <Link to={

                                    {
                                        pathname: '/amrit_participants_resubmit',
                                        state: {
                                            participant_type: this.state.participant_details.participantType
                                        }
                                    }
                                } 
                                className="backtohome" style={{color:"white",borderColor:"#A18B64",textDecoration:"none"}}> Resubmit</Link>
                                :""}

                           
                            
                            <Link to="/offline_registration"  className="intbtnb"> New Entry</Link>
                            <Link to="/offline_listing"  className="intbtnc" style={{textDecoration:"none"}}> Back to Listing</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_After_Registration_Offline;