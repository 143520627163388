import React from "react";
import axios from "axios";
//import DatePicker from 'react-datepicker';
import { DatePicker } from "react-rainbow-components";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import moment from 'moment'
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
var sha1 = require('sha1');

const validEmailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const regexPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
//const regexAadhar = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
const regex_password_complex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      email: "",
      password: "",
      name: "",
      country: "",
      idType: "",
      countryCode: "",
      userType: "",
      gender: "",
      dob: "",
      aadhaarNo: "",
      selected: new Date(),
      isDisabled: false,
      divvalue: "hide",
      confirm_password: "",
      placeholderval: "Enter ID Number",
      type: 'text',
      errors: {
        password: "",
        confirm_password: "",
        email: "",
        phone: "",
        aadhaarNo: "",
        idType: "",

      },

      country_details:
        [
          {
            name: "India",
            code: "IN",
            dial_code: "+91"
          },
          {
            name: "Afghanistan",
            code: "AF",
            dial_code: "+93"
          },
          {
            name: "Åland",
            code: "AX",
            dial_code: "+358"
          },
          {
            name: "Shqipëria",
            code: "AL",
            dial_code: "+355"
          },
          {
            name: "Algeria",
            code: "DZ",
            dial_code: "+213"
          },
          {
            name: "American Samoa",
            code: "AS",
            dial_code: "+1684"
          },
          {
            name: "Andorra",
            code: "AD",
            dial_code: "+376"
          },
          {
            name: "Angola",
            code: "AO",
            dial_code: "+244"
          },
          {
            name: "Anguilla",
            code: "AI",
            dial_code: "+1264"
          },
          {
            name: "Antarctica",
            code: "AQ",
            dial_code: "+672"
          },
          {
            name: "Antigua and Barbuda",
            code: "AG",
            dial_code: "+1268"
          },
          {
            name: "Argentina",
            code: "AR",
            dial_code: "+54"
          },
          {
            name: "Armenia",
            code: "AM",
            dial_code: "+374"
          },
          {
            name: "Aruba",
            code: "AW",
            dial_code: "+297"
          },
          {
            name: "Australia",
            code: "AU",
            dial_code: "+61"
          },
          {
            name: "Österreich",
            code: "AT",
            dial_code: "+43"
          },
          {
            name: "Azerbaijan",
            code: "AZ",
            dial_code: "+994"
          },
          {
            name: "Bahamas",
            code: "BS",
            dial_code: "+1242"
          },
          {
            name: "Bahrain",
            code: "BH",
            dial_code: "+973"
          },
          {
            name: "Bangladesh",
            code: "BD",
            dial_code: "+880"
          },
          {
            name: "Barbados",
            code: "BB",
            dial_code: "+1246"
          },
          {
            name: "Belarus",
            code: "BY",
            dial_code: "+375"
          },
          {
            name: "België",
            code: "BE",
            dial_code: "+32"
          },
          {
            name: "Belize",
            code: "BZ",
            dial_code: "+501"
          },
          {
            name: "Benign",
            code: "BJ",
            dial_code: "+229"
          },
          {
            name: "Bermuda",
            code: "BM",
            dial_code: "+1441"
          },
          {
            name: "ʼbrug-yul",
            code: "BT",
            dial_code: "+975"
          },
          {
            name: "Bolivia",
            code: "BO",
            dial_code: "+591"
          },
          {
            name: "Bosna i Hercegovina",
            code: "BA",
            dial_code: "+387"
          },
          {
            name: "Botswana",
            code: "BW",
            dial_code: "+267"
          },
          {
            name: "Bouvetøya",
            code: "BV",
            dial_code: "+47"
          },
          {
            name: "Brasil",
            code: "BR",
            dial_code: "+55"
          },
          {
            name: "British Indian Ocean Territory",
            code: "IO",
            dial_code: "+246"
          },
          {
            name: "Negara Brunei Darussalam",
            code: "BN",
            dial_code: "+673"
          },
          {
            name: "Bulgaria",
            code: "BG",
            dial_code: "+359"
          },
          {
            name: "Burkina Faso",
            code: "BF",
            dial_code: "+226"
          },
          {
            name: "Burundi",
            code: "BI",
            dial_code: "+257"
          },
          {
            name: "Cambodia",
            code: "KH",
            dial_code: "+855"
          },
          {
            name: "Cameroon",
            code: "CM",
            dial_code: "+237"
          },
          {
            name: "Canada",
            code: "CA",
            dial_code: "+1"
          },
          {
            name: "Cabo Verde",
            code: "CV",
            dial_code: "+238"
          },
          {
            name: "Cayman Islands",
            code: "KY",
            dial_code: "+1345"
          },
          {
            name: "Ködörösêse tî Bêafrîka",
            code: "CF",
            dial_code: "+236"
          },
          {
            name: "Tchad",
            code: "TD",
            dial_code: "+235"
          },
          {
            name: "Chile",
            code: "CL",
            dial_code: "+56"
          },
          {
            name: "China",
            code: "CN",
            dial_code: "+86"
          },
          {
            name: "Christmas Island",
            code: "CX",
            dial_code: "+61"
          },
          {
            name: "Cocos (Keeling) Islands",
            code: "CC",
            dial_code: "+61"
          },
          {
            name: "Colombia",
            code: "CO",
            dial_code: "+57"
          },
          {
            name: "Komori",
            code: "KM",
            dial_code: "+269"
          },
          {
            name: "Republic of Congo",
            code: "CG",
            dial_code: "+242"
          },
          {
            name: "Democratic Republic of Congo",
            code: "CD",
            dial_code: "+243"
          },
          {
            name: "Cook Islands",
            code: "CK",
            dial_code: "+682"
          },
          {
            name: "Costa Rica",
            code: "CR",
            dial_code: "+506"
          },
          {
            name: "Ivory Coast",
            code: "CI",
            dial_code: "+225"
          },
          {
            name: "Hrvatska",
            code: "HR",
            dial_code: "+385"
          },
          {
            name: "Cuba",
            code: "CU",
            dial_code: "+53"
          },
          {
            name: "Cyprus",
            code: "CY",
            dial_code: "+357"
          },
          {
            name: "Czech Republic",
            code: "CZ",
            dial_code: "+420"
          },
          {
            name: "Danmark",
            code: "DK",
            dial_code: "+45"
          },
          {
            name: "Djibouti",
            code: "DJ",
            dial_code: "+253"
          },
          {
            name: "Dominica",
            code: "DM",
            dial_code: "+1767"
          },
          {
            name: "República Dominicana",
            code: "DO",
            dial_code: "+1"
          },
          {
            name: "Ecuador",
            code: "EC",
            dial_code: "+593"
          },
          {
            name: "Egypt",
            code: "EG",
            dial_code: "+20"
          },
          {
            name: "El Salvador",
            code: "SV",
            dial_code: "+503"
          },
          {
            name: "Guinea Ecuatorial",
            code: "GQ",
            dial_code: "+240"
          },
          {
            name: "Eritrea",
            code: "ER",
            dial_code: "+291"
          },
          {
            name: "Eesti",
            code: "EE",
            dial_code: "+372"
          },
          {
            name: "Ethiopia",
            code: "ET",
            dial_code: "+251"
          },
          {
            name: "Falkland Islands",
            code: "FK",
            dial_code: "+500"
          },
          {
            name: "Faroe Islands",
            code: "FO",
            dial_code: "+298"
          },
          {
            name: "Fiji",
            code: "FJ",
            dial_code: "+679"
          },
          {
            name: "Suomi",
            code: "FI",
            dial_code: "+358"
          },
          {
            name: "France",
            code: "FR",
            dial_code: "+33"
          },
          {
            name: "Guyane française",
            code: "GF",
            dial_code: "+594"
          },
          {
            name: "Polynésie française",
            code: "PF",
            dial_code: "+689"
          },
          {
            name: "Territory of the Southern and Antarctic Lands fr",
            code: "TF",
            dial_code: "+262"
          },
          {
            name: "Gabon",
            code: "GA",
            dial_code: "+241"
          },
          {
            name: "Gambia",
            code: "GM",
            dial_code: "+220"
          },
          {
            name: "Georgia",
            code: "GE",
            dial_code: "+995"
          },
          {
            name: "Deutschland",
            code: "DE",
            dial_code: "+49"
          },
          {
            name: "Ghana",
            code: "GH",
            dial_code: "+233"
          },
          {
            name: "Gibraltar",
            code: "GI",
            dial_code: "+350"
          },
          {
            name: "Hellas",
            code: "GR",
            dial_code: "+30"
          },
          {
            name: "Kalaallit Nunaat",
            code: "GL",
            dial_code: "+299"
          },
          {
            name: "Grenada",
            code: "GD",
            dial_code: "+1473"
          },
          {
            name: "Guadeloupe",
            code: "GP",
            dial_code: "+590"
          },
          {
            name: "Guam",
            code: "GU",
            dial_code: "+1671"
          },
          {
            name: "Guatemala",
            code: "GT",
            dial_code: "+502"
          },
          {
            name: "Guernsey",
            code: "GG",
            dial_code: "+44"
          },
          {
            name: "Guinée",
            code: "GN",
            dial_code: "+224"
          },
          {
            name: "Guiné-Bissau",
            code: "GW",
            dial_code: "+245"
          },
          {
            name: "Guyana",
            code: "GY",
            dial_code: "+592"
          },
          {
            name: "Haïti",
            code: "HT",
            dial_code: "+509"
          },
          {
            name: "Heard Island and McDonald Islands",
            code: "HM",
            dial_code: "+0"
          },
          {
            name: "Vaticano",
            code: "VA",
            dial_code: "+379"
          },
          {
            name: "Honduras",
            code: "HN",
            dial_code: "+504"
          },
          {
            name: "Hongkong",
            code: "HK",
            dial_code: "+852"
          },
          {
            name: "Hungary",
            code: "HU",
            dial_code: "+36"
          },
          {
            name: "Ísland",
            code: "IS",
            dial_code: "+354"
          },

          {
            name: "Indonesia",
            code: "ID",
            dial_code: "+62"
          },
          {
            name: "Iran",
            code: "IR",
            dial_code: "+98"
          },
          {
            name: "Iraq",
            code: "IQ",
            dial_code: "+964"
          },
          {
            name: "Ireland",
            code: "IE",
            dial_code: "+353"
          },
          {
            name: "Isle of Man",
            code: "IM",
            dial_code: "+44"
          },
          {
            name: "Israel",
            code: "IL",
            dial_code: "+972"
          },
          {
            name: "Italia",
            code: "IT",
            dial_code: "+39"
          },
          {
            name: "Jamaica",
            code: "JM",
            dial_code: "+1876"
          },
          {
            name: "Japan",
            code: "JP",
            dial_code: "+81"
          },
          {
            name: "Jersey",
            code: "JE",
            dial_code: "+44"
          },
          {
            name: "Jordan",
            code: "JO",
            dial_code: "+962"
          },
          {
            name: "Kazakhstan",
            code: "KZ",
            dial_code: "+7"
          },
          {
            name: "Kenya",
            code: "KE",
            dial_code: "+254"
          },
          {
            name: "Kiribati",
            code: "KI",
            dial_code: "+686"
          },
          {
            name: "North Korea",
            code: "KP",
            dial_code: "+850"
          },
          {
            name: "Korea",
            code: "KR",
            dial_code: "+82"
          },
          {
            name: "The Republic of Kosovo",
            code: "XK",
            dial_code: "+383"
          },
          {
            name: "Kuwait",
            code: "KW",
            dial_code: "+965"
          },
          {
            name: "Kyrgyzstan",
            code: "KG",
            dial_code: "+996"
          },
          {
            name: "Lao PDR",
            code: "LA",
            dial_code: "+856"
          },
          {
            name: "Latvija",
            code: "LV",
            dial_code: "+371"
          },
          {
            name: "Lebanon",
            code: "LB",
            dial_code: "+961"
          },
          {
            name: "Lesotho",
            code: "LS",
            dial_code: "+266"
          },
          {
            name: "Liberia",
            code: "LR",
            dial_code: "+231"
          },
          {
            name: "Libya",
            code: "LY",
            dial_code: "+218"
          },
          {
            name: "Liechtenstein",
            code: "LI",
            dial_code: "+423"
          },
          {
            name: "Lietuva",
            code: "LT",
            dial_code: "+370"
          },
          {
            name: "Luxembourg",
            code: "LU",
            dial_code: "+352"
          },
          {
            name: "Macao",
            code: "MO",
            dial_code: "+853"
          },
          {
            name: "Macedonia",
            code: "MK",
            dial_code: "+389"
          },
          {
            name: "Madagasikara",
            code: "MG",
            dial_code: "+261"
          },
          {
            name: "Malawi",
            code: "MW",
            dial_code: "+265"
          },
          {
            name: "Malaysia",
            code: "MY",
            dial_code: "+60"
          },
          {
            name: "Maldives",
            code: "MV",
            dial_code: "+960"
          },
          {
            name: "Mali",
            code: "ML",
            dial_code: "+223"
          },
          {
            name: "Malta",
            code: "MT",
            dial_code: "+356"
          },
          {
            name: "M̧ajeļ",
            code: "MH",
            dial_code: "+692"
          },
          {
            name: "Martinique",
            code: "MQ",
            dial_code: "+596"
          },
          {
            name: "Mauritania",
            code: "MR",
            dial_code: "+222"
          },
          {
            name: "Maurice",
            code: "MU",
            dial_code: "+230"
          },
          {
            name: "Mayotte",
            code: "YT",
            dial_code: "+262"
          },
          {
            name: "México",
            code: "MX",
            dial_code: "+52"
          },
          {
            name: "Micronesia",
            code: "FM",
            dial_code: "+691"
          },
          {
            name: "Moldova",
            code: "MD",
            dial_code: "+373"
          },
          {
            name: "Monaco",
            code: "MC",
            dial_code: "+377"
          },
          {
            name: "Mongolia",
            code: "MN",
            dial_code: "+976"
          },
          {
            name: "Montenegro",
            code: "ME",
            dial_code: "+382"
          },
          {
            name: "Montserrat",
            code: "MS",
            dial_code: "+1664"
          },
          {
            name: "Morocco",
            code: "MA",
            dial_code: "+212"
          },
          {
            name: "Moçambique",
            code: "MZ",
            dial_code: "+258"
          },
          {
            name: "Myanmar",
            code: "MM",
            dial_code: "+95"
          },
          {
            name: "Namibia",
            code: "NA",
            dial_code: "+264"
          },
          {
            name: "Nauru",
            code: "NR",
            dial_code: "+674"
          },
          {
            name: "Nepal",
            code: "NP",
            dial_code: "+977"
          },
          {
            name: "Nederland",
            code: "NL",
            dial_code: "+31"
          },
          {
            name: "Netherlands Antilles",
            code: "AN",
            dial_code: "+599"
          },
          {
            name: "Nouvelle-Calédonie",
            code: "NC",
            dial_code: "+687"
          },
          {
            name: "New Zealand",
            code: "NZ",
            dial_code: "+64"
          },
          {
            name: "Nicaragua",
            code: "NI",
            dial_code: "+505"
          },
          {
            name: "Niger",
            code: "NE",
            dial_code: "+227"
          },
          {
            name: "Nigeria",
            code: "NG",
            dial_code: "+234"
          },
          {
            name: "Niuē",
            code: "NU",
            dial_code: "+683"
          },
          {
            name: "Norfolk Island",
            code: "NF",
            dial_code: "+672"
          },
          {
            name: "Northern Mariana Islands",
            code: "MP",
            dial_code: "+1670"
          },
          {
            name: "Norge",
            code: "NO",
            dial_code: "+47"
          },
          {
            name: "Oman",
            code: "OM",
            dial_code: "+968"
          },
          {
            name: "Pakistan",
            code: "PK",
            dial_code: "+92"
          },
          {
            name: "Palau",
            code: "PW",
            dial_code: "+680"
          },
          {
            name: "Palestine",
            code: "PS",
            dial_code: "+970"
          },
          {
            name: "Panamá",
            code: "PA",
            dial_code: "+507"
          },
          {
            name: "Papua Niugini",
            code: "PG",
            dial_code: "+675"
          },
          {
            name: "Paraguay",
            code: "PY",
            dial_code: "+595"
          },
          {
            name: "Perú",
            code: "PE",
            dial_code: "+51"
          },
          {
            name: "Pilipinas",
            code: "PH",
            dial_code: "+63"
          },
          {
            name: "Pitcairn Islands",
            code: "PN",
            dial_code: "+64"
          },
          {
            name: "Polska",
            code: "PL",
            dial_code: "+48"
          },
          {
            name: "Portugal",
            code: "PT",
            dial_code: "+351"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1939"
          },
          {
            name: "Puerto Rico",
            code: "PR",
            dial_code: "+1787"
          },
          {
            name: "Diameter",
            code: "QA",
            dial_code: "+974"
          },
          {
            name: "România",
            code: "RO",
            dial_code: "+40"
          },
          {
            name: "Russia",
            code: "RU",
            dial_code: "+7"
          },
          {
            name: "Rwanda",
            code: "RW",
            dial_code: "+250"
          },
          {
            name: "La Réunion",
            code: "RE",
            dial_code: "+262"
          },
          {
            name: "Saint-Barthélemy",
            code: "BL",
            dial_code: "+590"
          },
          {
            name: "Saint Helena",
            code: "SH",
            dial_code: "+290"
          },
          {
            name: "Saint Kitts and Nevis",
            code: "KN",
            dial_code: "+1869"
          },
          {
            name: "Saint Lucia",
            code: "LC",
            dial_code: "+1758"
          },
          {
            name: "Saint-Martin",
            code: "MF",
            dial_code: "+590"
          },
          {
            name: "Saint-Pierre-et-Miquelon",
            code: "PM",
            dial_code: "+508"
          },
          {
            name: "Saint Vincent and the Grenadines",
            code: "VC",
            dial_code: "+1784"
          },
          {
            name: "Samoa",
            code: "WS",
            dial_code: "+685"
          },
          {
            name: "San Marino",
            code: "SM",
            dial_code: "+378"
          },
          {
            name: "São Tomé e Príncipe",
            code: "ST",
            dial_code: "+239"
          },
          {
            name: "Saudi Arab",
            code: "SA",
            dial_code: "+966"
          },
          {
            name: "Sénégal",
            code: "SN",
            dial_code: "+221"
          },
          {
            name: "Serbia",
            code: "RS",
            dial_code: "+381"
          },
          {
            name: "Seychelles",
            code: "SC",
            dial_code: "+248"
          },
          {
            name: "Sierra Leone",
            code: "SL",
            dial_code: "+232"
          },
          {
            name: "Singapore",
            code: "SG",
            dial_code: "+65"
          },
          {
            name: "Slovensko",
            code: "SK",
            dial_code: "+421"
          },
          {
            name: "Slovenija",
            code: "SI",
            dial_code: "+386"
          },
          {
            name: "Solomon Islands",
            code: "SB",
            dial_code: "+677"
          },
          {
            name: "Soomaaliya",
            code: "SO",
            dial_code: "+252"
          },
          {
            name: "South Africa",
            code: "ZA",
            dial_code: "+27"
          },
          {
            name: "South Sudan",
            code: "SS",
            dial_code: "+211"
          },
          {
            name: "South Georgia",
            code: "GS",
            dial_code: "+500"
          },
          {
            name: "Spain",
            code: "ES",
            dial_code: "+34"
          },
          {
            name: "Sri Lanka",
            code: "LK",
            dial_code: "+94"
          },
          {
            name: "Sudan",
            code: "SD",
            dial_code: "+249"
          },
          {
            name: "Suriname",
            code: "SR",
            dial_code: "+597"
          },
          {
            name: "Svalbard og Jan Mayen",
            code: "SJ",
            dial_code: "+47"
          },
          {
            name: "Swaziland",
            code: "SZ",
            dial_code: "+268"
          },
          {
            name: "Sverige",
            code: "SE",
            dial_code: "+46"
          },
          {
            name: "Schweiz",
            code: "CH",
            dial_code: "+41"
          },
          {
            name: "Syrian Arab Republic",
            code: "SY",
            dial_code: "+963"
          },
          {
            name: "Taiwan",
            code: "TW",
            dial_code: "+886"
          },
          {
            name: "Tajikistan",
            code: "TJ",
            dial_code: "+992"
          },
          {
            name: "Tanzania",
            code: "TZ",
            dial_code: "+255"
          },
          {
            name: "Thailand",
            code: "TH",
            dial_code: "+66"
          },
          {
            name: "Timor-Leste",
            code: "TL",
            dial_code: "+670"
          },
          {
            name: "Togo",
            code: "TG",
            dial_code: "+228"
          },
          {
            name: "Tokelau",
            code: "TK",
            dial_code: "+690"
          },
          {
            name: "Tonga",
            code: "TO",
            dial_code: "+676"
          },
          {
            name: "Trinidad and Tobago",
            code: "TT",
            dial_code: "+1868"
          },
          {
            name: "Tunisia",
            code: "TN",
            dial_code: "+216"
          },
          {
            name: "Turkey",
            code: "TR",
            dial_code: "+90"
          },
          {
            name: "Türkmenistan",
            code: "TM",
            dial_code: "+993"
          },
          {
            name: "Turks and Caicos Islands",
            code: "TC",
            dial_code: "+1649"
          },
          {
            name: "Tuvalu",
            code: "TV",
            dial_code: "+688"
          },
          {
            name: "Uganda",
            code: "UG",
            dial_code: "+256"
          },
          {
            name: "Ukraine",
            code: "UA",
            dial_code: "+380"
          },
          {
            name: "United Arab Emirates",
            code: "AE",
            dial_code: "+971"
          },
          {
            name: "United Kingdom",
            code: "GB",
            dial_code: "+44"
          },
          {
            name: "United States",
            code: "US",
            dial_code: "+1"
          },
          {
            name: "Uruguay",
            code: "UY",
            dial_code: "+598"
          },
          {
            name: "Uzbekistan",
            code: "UZ",
            dial_code: "+998"
          },
          {
            name: "Vanuatu",
            code: "VU",
            dial_code: "+678"
          },
          {
            name: "Venezuela",
            code: "VE",
            dial_code: "+58"
          },
          {
            name: "Vietnam",
            code: "VN",
            dial_code: "+84"
          },
          {
            name: "British Virgin Islands",
            code: "VG",
            dial_code: "+1284"
          },
          {
            name: "United States Virgin Islands",
            code: "VI",
            dial_code: "+1340"
          },
          {
            name: "Wallis et Futuna",
            code: "WF",
            dial_code: "+681"
          },
          {
            name: "Yemen",
            code: "YE",
            dial_code: "+967"
          },
          {
            name: "Zambia",
            code: "ZM",
            dial_code: "+260"
          },
          {
            name: "Zimbabwe",
            code: "ZW",
            dial_code: "+263"
          }
        ],

      usertype_details: [
        { name: 'Athlete' },
        { name: 'Coach' },
        { name: 'Family' },
        { name: 'Official' },
        { name: 'Parent' },
        { name: 'Volunteer' },
      ],
      gender_details: [
        { name: 'Male' },
        { name: 'Female' },
        { name: 'Other' },
      ],
      id_type_details: [
        { name: 'Aadhar' },
        { name: 'UDID Card' },
        { name: 'Passport' },
        { name: 'PAN Card' },
        { name: 'Ration Card' },
        { name: 'I have no ID' },
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIDType = this.handleChangeIDType.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this); 
     this.showHide = this.showHide.bind(this);

  }
  showHide(e){
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text'
    })  
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) || value === "" ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password = regex_password_complex.test(value) || value === "" ? "" : "should contain 8 characters,at least one digit,one lower case,one upper case !";
        break;
      case 'phone':
        errors.phone =
          (value.length === 10 && regexPhone.test(value)) || value === ""
            ? '' : 'Invalid Phone Number!';
        break;
      case "confirm_password":
        errors.confirm_password =
          value !== this.state.password ? "Passwords do not match" : "";
        break;
      default:
        break;
    }

    this.setState({
      [name]: value
    });
  }


  handleChangeIDType(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = this.state.errors;
    switch (name) {

      case 'idType':
        errors.idType =
          value === "I have no ID"
            ? <span style={{ fontStyle: "italic" }}>Please Note: If you do not have any ID card please get in touch with your State Chapter for a CODE to complete registration</span> : '';
        break;

      default:
        break;
    }

    this.setState({
      [name]: value
    });

    if (value === "I have no ID") { this.setState({ placeholderval: "Enter SOB STATE ID" }) } else { this.setState({ placeholderval: "Enter ID Number" }) }
  }


  handleChangeCountry(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

    if (value === "IN") { this.setState({ divvalue: "show" }) } else { this.setState({ divvalue: "hide" }) }
  }

  //   handleChangeIDType(event) {
  //     const target = event.target;
  //     const value = target.type === "checkbox" ? target.checked : target.value;
  //     const name = target.name;
  //     this.setState({
  //         [name]: value
  //     });

  //     if(value==="IN"){this.setState({divvalue:"show"})}else{this.setState({divvalue:"hide"})}
  // }



  handleDateSelect = date => {
    console.log('date: ', date)
    this.setState({
      dob: date
    });
  }

  dateConvert(dateToBeConverted) {
    var dateString = dateToBeConverted;
    var dateObj = new Date(dateString);
    var momentObj = moment(dateObj);
    //console.log(momentObj);
    return momentObj.format('DD/MM/YYYY');;
  }

  validationCheck() {
    let isValidate = true;
    if (this.state.name === '') {
      isValidate = false;
    }
    if (this.state.password === '') {
      isValidate = false;
    }
    if (this.state.confirm_password === '') {
      isValidate = false;
    }
    if (this.state.country === '') {
      isValidate = false;
    }
    if (this.state.countryCode === '') {
      isValidate = false;
    }
    if (this.state.phone === '') {
      isValidate = false;
    }
    if (this.state.gender === '') {
      isValidate = false;
    }
    if (this.state.dob === '') {
      isValidate = false;
    }
    if (this.state.userType === '') {
      isValidate = false;
    }

    return isValidate;
  }

  handleSubmit(event) {
    if (this.validationCheck()) {
      // document.getElementById("exampleModalCenter").classList.remove("show", "d-block");
      // document.querySelectorAll(".modal-backdrop")
      // .forEach(el => el.classList.remove("modal-backdrop"));
      this.registration(this.state.phone, this.state.email, sha1(this.state.password), this.state.name, this.state.country, this.state.countryCode, this.state.userType, this.state.gender,
        this.dateConvert(this.state.dob), this.state.aadhaarNo, this.state.idType);
    } else {
      toast.error("Please fill all the details", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      //   this.setState({
      //     isDisabled:true
      //   })
    }
    event.preventDefault();
  }

  registration(...params) {
    axios
      .post(path, {
        phone: params[0],
        email: params[1],
        password: params[2],
        name: params[3],
        country: params[4],
        countryCode: params[5],
        userType: params[6],
        gender: params[7],
        dob: params[8],
        aadhaarNo: params[9],
        idType: params[10],

      }, {
        headers: { 'Api-Name': 'registration' }
      })
      .then((serverRes) => {
        const res = serverRes;
        console.log(res);

        if (res.data.status === true) {
          toast.success(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          localStorage.setItem("SOB_userId", res.data.response._id);
          localStorage.setItem("SOB_refreshToken", res.data.response.refreshToken);
          localStorage.setItem("SOB_idType", res.data.response.idType);

          if (res.data.response.isVerified === true) {
            this.props.history.push("/");
          } else if (res.data.response.idType === "I have no ID") {
            this.props.history.push("/otp_verification");
          } else if (res.data.response.isAadharUploaded === false) {
            this.props.history.push("/id_upload");
          } else {
            this.props.history.push("/otp_verification");
          }

        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }

  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    });
    const { errors } = this.state;
    return (
      <div className="fullHeightReg">
        <section className="signupBase regdBase" style={{ height: "290rm !important" }}>
          <div className="signuplogo"><img src={process.env.PUBLIC_URL + '/assets/images/loginlogo.png'} alt="" /></div>
          <h2>Sign Up</h2>
          <div className="loginPannel">
            <div className="loginFrm">
              <form>
                <div className="formField">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleChange} required />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email (Optional)" name="email" onChange={this.handleChange} />
                    <span style={{ color: "white" }}>{errors.email}</span>
                  </div>
                </div>

                <div className="formField">
                  <div className="form-group">
                  
                    <input type="password"  className="form-control" placeholder="Password" name="password" onChange={this.handleChange} required />
                    {/* <i class="far fa-eye" id="togglePassword"   style={{marginRight:-30,cursor:"pointer",color:"white"}}></i> */}
                    {/* <span className="password__show" onClick={this.showHide}>{this.state.type === 'text' ? 'Hide' : 'Show'}</span> */}
                    
                    <span style={{ color: "white" }}>{errors.password}</span>
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Confirm Password" name="confirm_password" onChange={this.handleChange} required />
                    <span style={{ color: "white" }}>{errors.confirm_password}</span>
                  </div>
                </div>

                <div className="formField">
                  <div className="form-group regdSelect1">
                    <div className="newSelect" style={{ marginRight: 10 }}>
                      <select name="countryCode" onChange={this.handleChange} required>
                        <option value="0" className="dropdown-item active">ISD</option>
                        {this.state.country_details.map((eachcountryDetails, i) => (
                          <option value={eachcountryDetails.dial_code} key={i} className="dropdown-item">{eachcountryDetails.dial_code} ({eachcountryDetails.name})</option>

                        ))}
                      </select>
                      <span style={{ color: "white" }}>{errors.phone}</span>
                    </div>
                    <input type="tel"  className="form-control" maxLength="10" placeholder="Phone No" name="phone" onChange={this.handleChange} required />


                  </div>

                  <div className="form-group">
                    <div className="newSelect">
                      <select name="country" onChange={this.handleChangeCountry} required>
                        <option value="0" className="dropdown-item active">Country</option>
                        {this.state.country_details.map((eachcountryDetails, i) => (
                          <option value={eachcountryDetails.code} key={i} className="dropdown-item">{eachcountryDetails.name}</option>

                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="formField">
                  <div className="form-group">
                    <div className="newSelect">
                      <select name="gender" onChange={this.handleChange} required>
                        <option value="0" className="dropdown-item active">Select Gender</option>
                        {this.state.gender_details.map((eachgenderDetails, i) => (
                          <option value={eachgenderDetails.name} key={i} className="dropdown-item">{eachgenderDetails.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="newSelect">
                      <select name="userType" onChange={this.handleChange} required>
                        <option value="0" className="dropdown-item active">Select User Type</option>
                        {this.state.usertype_details.map((eachUserTypeDetails, i) => (
                          <option value={eachUserTypeDetails.name} key={i} className="dropdown-item">{eachUserTypeDetails.name}</option>

                        ))}
                      </select>
                    </div>
                  </div>

                </div>

                <div className="formField">
                  <div className="form-group">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      id="datePicker-1"
                      name="dob"
                      onChange={this.handleDateSelect}
                      maxDate={moment().toDate()}
                      placeholder="Select Your Date Of Birth"
                      value={this.state.dob}
                      formatStyle="small"
                    />

                  </div>


                  <div className="form-group">
                    <div className={"newSelect " + this.state.divvalue}>
                      <select name="idType" onChange={this.handleChangeIDType} required>
                        <option value="0" className="dropdown-item active">Select ID Type</option>
                        {this.state.id_type_details.map((eachIdTypeDetails, i) => (
                          <option value={eachIdTypeDetails.name} key={i} className="dropdown-item">{eachIdTypeDetails.name}</option>

                        ))}
                      </select>
                      <span style={{ color: "white" }}>{errors.idType}</span>
                    </div>
                  </div>
                </div>

                <div className="formField">

                  <div className={"form-group " + this.state.divvalue}>
                    <input type="text" className="form-control" placeholder={this.state.placeholderval} name="aadhaarNo" onChange={this.handleChange} />
                    <span style={{ color: "white" }}>{errors.aadhaarNo}</span>

                  </div>
                </div>
                {/* <div className="scanAdhaar">
                            <div className="scanInn">
                                <span>Scan your Adhar</span>
                                <button><img src={process.env.PUBLIC_URL + '/assets/images/scan.png'} alt=""/></button>
                            </div>
                        </div> */}
                <div className="formBtm"><button type="submit" onClick={this.handleSubmit} className="btn btn-primary">Register</button></div>
                <p> <Link to="/" className="forgotPass">Already have an account? Login</Link></p>
              </form>
            </div>
          </div>
        </section>


        {/* <!-- Modal --> */}
        {/* <div className="modal fade cautionModal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="cautionHdn"><i className="fas fa-exclamation-triangle"></i> <span>Caution</span></div>
                <p>Are you sure you want to submit?</p>
                <button type="button" className="btn canclBtn" data-dismiss="modal">Cancel</button>
                <button type="button" onClick={this.handleSubmit} className="btn btn-primary">Confirm</button>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    )
  }
}
export default Registration;