import React from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_Registration_SOB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            adminId:"",
            participant_type:"",
            startdate:"",
            enddate:"",
            name: " User",
            participant_type_list: [],

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    } 
    async componentDidMount() {
        // Get userdetails from localstorage
       
        const userId = await localStorage.getItem('SOB_userId')
        const SOB_name = await localStorage.getItem('SOB_name')
        const adminId = await localStorage.getItem('SOB_adminId')
       
        if(userId===null || userId==="undefined"){
            localStorage.setItem("SOB_userId", this.props.location.state.SOB_userId);
        }
        if(SOB_name===null || SOB_name==="undefined"){
            localStorage.setItem("SOB_name", this.props.location.state.SOB_name);
        }
        const updated_userId = await localStorage.getItem('SOB_userId')
        const updated_SOB_name = await localStorage.getItem('SOB_name')
        this.setState({
            userId: updated_userId,
            name: updated_SOB_name,
            adminId: adminId,
        })
        if(this.state.userId===null){
            this.props.history.push("/offline_login");
        }
        this.getRegistrationDetailsAdmin();
    }

    validationCheck(){
        //console.log(this.state.participant_type);
        let isValidate=true;
        if(this.state.participant_type===''){
            isValidate = false;
       }   
        return isValidate;
      }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const json_value=JSON.parse(value);
        //console.log(json_value);
        this.setState({
            [name]: json_value.ptype,
            startdate:json_value.startDate,
            enddate:json_value.endDate,
        });
       
    }
    handleSubmit(event) { 
       // console.log(this.state.startdate); console.log(this.state.enddate);
        var today = new Date();
        var today_date = new Date(today).toISOString();
        //var today_date='2022-11-04T00:00:00.000Z';
        if(this.validationCheck()){
            localStorage.setItem('participant_type',this.state.participant_type)

            if(today_date<this.state.startdate){
                localStorage.setItem('reg_status','notstart')
                this.props.history.push("/amrit_registration_status_sob");
            }else if(today_date>this.state.enddate){
                localStorage.setItem('reg_status','closed')
                this.props.history.push("/amrit_registration_status_sob");
            }else{
                this.props.history.push("/amrit_participants_officials_sob");
            }
        }else{
            toast.error("Please Choose Participant Type", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
     }
     getRegistrationDetailsAdmin() {
        axios.post(path, {
            participantType:"",
            eventId:"61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetailsAdmin', 'userId': this.state.userId, 'AdminId': this.state.adminId }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res);
            const ParticipantTypeList = res.response.type;
            if (res.status === true) {
                this.setState({
                    participant_type_list:ParticipantTypeList
                });
                
                //console.log(ContentDetails);
              
               
            }
        });
    }
    
   

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                <div className="amritRegdsecondbg">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock noBgregd">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt=""/></Link>
                            <h3>Enrollment for Amrit Mahotsav</h3>
                            <h2>Hi, {this.state.name}</h2>
                            <form>
                                <div className="form-group">
                                <div className="newSelect" >
                              


                                <select name="participant_type" id="participant_type" onChange={this.handleChange} required>
                                    <option className="slRedbg" value="">Select Participant Type</option>
                                {this.state.participant_type_list.map((participantTypeDetails, i) => (
                                    <option value={JSON.stringify({"ptype":participantTypeDetails.type,"startDate":participantTypeDetails.startDate,"endDate":participantTypeDetails.endDate})} key={i}>{participantTypeDetails.type}</option>
                                ))}
                                </select>
                            </div>
                            
                            
                            </div>
                            <button type="submit" className="" onClick={this.handleSubmit}>Submit</button>
                            
                        </form>
                        {/* <Link to="/home">  <button className="backtohome">Back to Home</button></Link> */}
                        </div>
                        
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Registration_SOB;