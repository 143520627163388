
import React from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var sha1 = require('sha1');
var path = apiPath.path;
const regex_password_complex=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
class Password_Change extends React.Component {

   
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            password: "",
            errors: {
                password: "",
                confirm_password: "",
                
                
              },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        this.setState({
            userId: userId,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let errors = this.state.errors;
        switch (name) {
          case "confirm_password":
              // errors.password = value;
              errors.confirm_password =
                value !== this.state.password ? "Passwords do not match" : "";
              break;
        case "password":
        errors.password = regex_password_complex.test(value) ? "" : "should contain 8 characters,at least one digit,one lower case,one upper case !";
        break;
          default:
              break;
          }

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {  
     this.forgotPasswordChange(this.state.userId,sha1(this.state.password));
       event.preventDefault();
     }

     forgotPasswordChange(...params) {
        axios.post(path, {
            userid: params[0],
            password: params[1],
    
        }, {
            headers: { 'api-name': 'forgotPasswordChange'}
        }).then(serverResponse => {
            const res = serverResponse.data;
           console.log(res);
    
            if (res.status === true) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            this.props.history.push("/");
    
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");
    
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        const { errors } = this.state;
        return (
            <div className="fullHeight">
                <section className="loginBase regdBase otpBase">
                <div className="signuplogo"><img src={process.env.PUBLIC_URL + '/assets/images/otp.png'} alt=""/></div>
                <h4>Change Password</h4>
              
                    <div className="loginPannel">
                    <div className="loginFrm" style={{ borderRadius:0}}>
                        
                    <form>
                        <div className="form-group">
                            <input type="password" className="form-control" id="password" name="password" onChange={this.handleChange} aria-describedby="emailHelp" placeholder="Password" />
                            <span style={{color:"white"}}>{errors.password}</span>
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" id="confirm_password" name="confirm_password" onChange={this.handleChange} placeholder="Confirm Password" />
                            <span style={{color:"white"}}>{errors.confirm_password}</span>
                        </div>
                        <div className="formBtm">
                            <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Change Password</button>
                        </div>
                    </form>
                       
                        
                    </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Password_Change;