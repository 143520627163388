
import React from "react";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ForgotOTPComponent from "../components/forgot_otpcomponent";
class ForgotP_OTP_Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            otp: "",
        };
        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        this.setState({
            userId: userId,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        
    }

    handleChange = (otp) => {
        //console.log(otp)
        this.setState({ otp })};


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="loginBase regdBase otpBase">
                <div className="signuplogo"><img src={process.env.PUBLIC_URL + '/assets/images/otp.png'} alt=""/></div>
                <h4>Please Enter the One-Time Password to Verify Your Account</h4>
                <h5>A One-Time Password has been sent to your mobile no</h5>
                    <div className="loginPannel">
                    <div className="loginFrm loginFrm1" style={{ borderRadius:0}}>
                        <form>
                        <div className="otpBase">
                                <ForgotOTPComponent></ForgotOTPComponent>
                            </div>
                       
                        </form>
                    </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default ForgotP_OTP_Verification;