import React, { useEffect } from "react";
import socketIOClient from "socket.io-client";
//import { io } from "socket.io-client";
//const ENDPOINT = "ws://gnrtech.in:3060";
//const ENDPOINT = "wss://gnrtech.in:3060";
const ENDPOINT = "wss://specialolympicsbharat.com:3060";



function Socket(props) {
  //console.log(props);
  const socketClient = socketIOClient(ENDPOINT, { transports: ['websocket'] ,withCredentials:false});
  const userId = localStorage.getItem('SOB_userId')
  useEffect(() => {

    socketClient.on('connect', (socket) => {
     // console.log('Connected!');
      socketClient.emit("addClient", userId)
    });

    socketClient.on("added", (msg) => {
     // console.log(msg);
    });

    socketClient.on("openform", (scannedId) => {
     // console.log(scannedId);
      props.getQrVal(scannedId);
    });

    // return () => socket.disconnect();
  }, []);

  
  useEffect(() => {
    return () => {
      // window.addEventListener("beforeunload", (ev) => {
      //   ev.preventDefault();
      //   return ev.returnValue = 'Are you sure you want to end the session?';
      // });
      window.addEventListener("beforeunload", function (e) {
        let confirmationMessage = "o/";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        console.log("logout !");
        socketClient.disconnect();
        return confirmationMessage; //Webkit, Safari, Chrome
      });
    }

  });

  return (
    <p>
      {/* It's <time dateTime={response}>{response}</time> */}
    </p>
  );
}

export default Socket;

