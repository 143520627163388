import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Chapter_Participants_Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_details: [],
            search: "",
            scanType:"",
            scanID:""

        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const scanID = await localStorage.getItem('SOB_scanId')
      //  const scanType = await localStorage.getItem('SOB_scanType')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            scanID: scanID,
          //  scanType: scanType,
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanAMID();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    scanAMID() {
        axios.post(path, {
            scannedId: this.state.scanID,
            deviceType: "web",
            scanType: "status",

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res)
            if (res.status === true) {
                this.setState({
                    participant_details: res.response,
                });
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }
 
    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    
    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                    <li className="active"><Link to="/amrit_checkin_checkout_status"><span><img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete</Link></li>
                                        <li><Link to="/amrit_volunteers_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Participants Details</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg">
                                    <div className="participantTop">
                                        <div className="partUser">
                                            <img src={this.state.participant_details.facePhoto ? this.state.participant_details.facePhoto : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                            <h6>{this.state.participant_details.name}</h6>
                                            <p>{this.state.participant_details['ID']}</p>
                                        </div>
                                    </div>
                                    <p className="partcdesc">Participant Details</p>
                                    <div className="cpdBase">
                                        <div className="cpdLft">
                                            <p><strong>Name:</strong> <span>{this.state.participant_details.name}</span> </p>
                                            <p><strong>DOB:</strong> <span>{this.state.participant_details.dob}</span> </p>
                                            <p><strong>Gender:</strong> <span>{this.state.participant_details.gender}</span> </p>
                                            <p><strong>Contact No:</strong> <span>{this.state.participant_details.contactNo}</span> </p>
                                           
                                        </div>
                                        <div className="cpdRgt">
                                            <p><strong>Participant Type:</strong> <span>{this.state.participant_details.participantType}</span> </p>
                                            {this.state.participant_details.organizationName?
                                            <p><strong>Organization Name:</strong> <span>{this.state.participant_details.organizationName}</span> </p>
                                            :""}
                                             {this.state.participant_details.association?
                                            <p><strong>Association:</strong> <span>{this.state.participant_details.association}</span> </p>
                                            :""}
                                            <p><strong>State:</strong> <span>{this.state.participant_details.statePreference}</span> </p>
                                            <p><strong>District:</strong> <span>{this.state.participant_details.districtPreference}</span> </p>

                                        </div>
                                    </div>
                                    <hr />
                                   
                                    <div className="cpdBase">
                                    {this.state.participant_details.checkedInBy?
                                        <div className="cpdLft">
                                        <p><strong>Checked In By:</strong></p>
                                        <p><strong>Name:</strong> <span>{this.state.participant_details.checkedInBy && this.state.participant_details.checkedInBy["name"]}</span> </p>
                                            <p><strong>Email:</strong> <span>{this.state.participant_details.checkedInBy && this.state.participant_details.checkedInBy["email"]}</span> </p>
                                            <p><strong>Contact No:</strong> <span>{ this.state.participant_details.checkedInBy && this.state.participant_details.checkedInBy["contactNo"]}</span> </p>
                                           
                                        </div>
                                    :""}
                                    
                                        <div className="cpdRgt">
                                        {this.state.participant_details.general_health?
                                        <p><strong>General Health: </strong> <span>{this.state.participant_details.general_health && this.state.participant_details.general_health}</span> </p>
                                       
                                        :""}
                                          {this.state.participant_details.special_smile?
                                        <p><strong>Special Smile: </strong> <span>{this.state.participant_details.special_smile && this.state.participant_details.special_smile}</span> </p>
                                        :""}
                                         {this.state.participant_details.opening_eye?
                                        <p><strong>Opening Eye: </strong> <span>{this.state.participant_details.opening_eye && this.state.participant_details.opening_eye}</span> </p>
                                        :""}
                                           
                                        </div>
                                   
                                    </div>
                                   


                                    <div className="intBtn">
                                    <Link to="/amrit_checkin_checkout_status" className="intbtnb">Back</Link>

                                </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                {/* <!-- Modal --> */}
    <div className="modal fade modal-center" id="rejectModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body filterPop">
                    <h3>Reject Comment</h3>
                    <form>
                        <textarea placeholder="Comment"></textarea>
                        <div className="filterModalSubmit"><button type="submit" className="btn btn-primary">Submit</button></div>
                    </form>
                </div>
            </div>
        </div>
    </div>


            </div>
        )
    }
}
export default Amrit_Chapter_Participants_Details;