import React from "react";
import { Link } from "react-router-dom";
class Amrit_Participants_Volunteers extends React.Component {
    render() {
        return (
            <div>
                <section className="amritRegd">
                    <div className="amritRegdInn officialBase">
                        <div className="amritRegdBlock amritAfterregd officials">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt=""/></Link>
                            <h3 className="partPdn">Participants</h3>
                            <form>
                                <div className="newSelect" >
                                    <select name="Country" id="country" >
                                        <option className="slRedbg" value="">Volunteers</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                                <div className="officialInn">
                                <div className="uploadCertificate">                      
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label>Vaccination details</label>
                                            {/* <input className="form-control" type="text" placeholder="Enter details"/> */}
                                            <div className="newSelect" >
                                            <select name="Country" id="country" >
                                                <option className="slRedbg" value="">Details</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                        </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Upload vaccination certificate</label>
                                                <button className="" type="submit">Upload Now</button>
                                            </div>
                                        </div>                       
                                    </div>
                                </div>
                                <div className="form-group volunteerTextarea">
                                    <label>Details of previous SO engagement if any (200 words)</label>
                                    <textarea className="form-control"></textarea>
                                    </div>
                                    
                                    <div className="uploadCertificate">                      
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Participation City preference</label>
                                            {/* <input className="form-control" type="text" placeholder="Enter details"/> */}
                                            <div className="newSelect" >
                                                <select name="Country" id="country" >
                                                    <option className="slRedbg" value="">Select your city</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Designation</label>
                                                <input className="form-control" type="text" placeholder="Enter Designation"/>
                                            </div>
                                        </div>                        */}

                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                                    <span className="form-check-label" htmlFor="defaultCheck1">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry <a href="">Click Here</a>
                                    </span>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Participants_Volunteers;