import React from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
class Amrit_Eyes_Front_Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            page_no: "",
            page_no_array: [
                { id: '1', value: "Form 1" },
                { id: '2', value: "Form 2" },
                { id: '3', value: "Form 3" },
                { id: '4', value: "Form 4" },
                { id: '5', value: "Form 5" },
                { id: '6', value: "Form 6" },
                { id: '7', value: "Form 7" },
                { id: '8', value: "Form 8" },
            ],

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();


    }

    validationCheck() {
        //console.log(this.state.participant_type);
        let isValidate = true;
        if (this.state.page_no === '') {
            isValidate = false;
        }
        return isValidate;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });

    }
    handleSubmit(event) {

        if (this.validationCheck()) {
            if (this.state.page_no === "1") {
                this.props.history.push("/amrit_eyes_form1");
            } else if (this.state.page_no === "2") {
                this.props.history.push("/amrit_eyes_form2");
            } else if (this.state.page_no === "3") {
                this.props.history.push("/amrit_eyes_form3");
            } else if (this.state.page_no === "4") {
                this.props.history.push("/amrit_eyes_form4");
            } else if (this.state.page_no === "5") {
                this.props.history.push("/amrit_eyes_form5");
            } else if (this.state.page_no === "6") {
                this.props.history.push("/amrit_eyes_form6");
            } else if (this.state.page_no === "7") {
                this.props.history.push("/amrit_eyes_form7");
            } else if (this.state.page_no === "8") {
                this.props.history.push("/amrit_eyes_form8");
            }

        } else {
            toast.error("Please Choose Page Number", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            // <div className="fullHeight">
            //     <section className="amritRegd">
            //         <div className="amritRegdsecondbg">
            //             <div className="amritRegdInn">
            //                 <div className="amritRegdBlock noBgregd">
            //                     <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt="" /></Link>
            //                     {/* <h3>Register Yourself to Participate in the event</h3> */}
            //                     select fprm noMrgnBtm
            //                 select specific form for opening Amrit_Eyes_Form8

            //                     <h3></h3>
            //                     <form>
            //                         <div className="form-group">
            //                             <div className="newSelect" >
            //                                 <select name="page_no" id="page_no" onChange={this.handleChange} required>
            //                                     <option className="slRedbg" value="">Select Page No</option>
            //                                     {this.state.page_no_array.map((eachpageno, i) => (
            //                                         <option value={eachpageno.id} key={i}>{eachpageno.value}</option>
            //                                     ))}
            //                                 </select>
            //                             </div>


            //                         </div>
            //                         <button type="submit" className="" onClick={this.handleSubmit}>Submit</button>

            //                     </form>
            //                     <Link to="/amrit_medical_professional">  <button className="backtohome">Back</button></Link>
            //                 </div>

            //             </div>
            //         </div>
            //     </section>
            // </div>


            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritMed">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"><Link to="/amrit_medical_professional"><span><img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Scan Athlete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="participantBg">
                                    <div className="medicalProfBg volunteerBg">
                                        <h5> Select specific form for Opening Eyes</h5>
                                        <br></br>
                                        <div className="volBlock">

                                           
                                                <div className="newSelect" >
                                                    <select name="page_no" id="page_no" onChange={this.handleChange} required>
                                                        <option className="slRedbg" value="">Select Form No</option>
                                                        {this.state.page_no_array.map((eachpageno, i) => (
                                                            <option value={eachpageno.id} key={i}>{eachpageno.value}</option>
                                                        ))}
                                                    </select>
                                                </div>


                                           
                                            <button className="volSubmit" onClick={this.handleSubmit}>Open</button>
                                        </div>



                                    </div>


                                    <br /><br />
                                    <div className="intBtn">
                                        <Link to="/amrit_medical_professional" className="intbtnb">Back</Link>

                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Eyes_Front_Page;