import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class YA_Exercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            ya_contentDetails_list: [],
            yaItem_Id:"",
            yaItemDescription:"",
            inner_page_color:""
        };    
        this.getYaContentApp = this.getYaContentApp.bind(this);  
        this.getYaInnerContentApp = this.getYaInnerContentApp.bind(this);  

    } 

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const yaItem_Id = await localStorage.getItem('yaItem_Id')
        const yaItemDescription = await localStorage.getItem('yaItemDescription')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            yaItem_Id: yaItem_Id,
            yaItemDescription:yaItemDescription
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getYaContentApp();
    }
    getYaContentApp() {
        axios.post(path, {
            yaItemId: this.state.yaItem_Id
        }, {
            headers: { 'api-name': 'getYaContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ContentDetails = res.response.contents;
               // console.log(ContentDetails);

                this.setState({
                    ya_contentDetails_list: ContentDetails,
                    inner_page_color:ContentDetails[0].inner_page_color
                    
                });

            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

    getYaInnerContentApp(exercise_id,name,name_color) {
        axios.post(path, {
            yaContentId: exercise_id
        }, {
            headers: { 'api-name': 'getYaInnerContentApp', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // const ContentDetails = res.response;
                // console.log(ContentDetails);
                localStorage.setItem('exercise_id',exercise_id)
                localStorage.setItem('name',name)
                localStorage.setItem('name_color',name_color)
                localStorage.setItem('exercise_inner_page_color',this.state.inner_page_color)
                this.props.history.push("/ya_exercise_details")
               
            }
            else {
                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
    render() {
        return (
            <div>
            <Header {...this.props} />
                <section className="yaBg_a newSobBg" style={{backgroundColor:this.state.inner_page_color}}>
                    <div className="newSobRadiusbg" style={{backgroundColor:"#a9d6ee"}}>
                        <div className="newSobRadiusInn" style={{backgroundColor:"#fff"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="leadTop">
                                            <div className="fit5 introsx">
                                            <h3>{this.state.yaItemDescription}</h3>
                                            <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="commonContent ya10pdn">
                                <div className="ya10Main">
                                {this.state.ya_contentDetails_list.map((eachyacontentDetails, i) => (
                                    <div className="ya10Block" key={i}>
                                        <div className="ya10Imgbg"><img src={eachyacontentDetails.content_array[0].multimedia} alt=""/></div>
                                        <button type="button" onClick={()=>this.getYaInnerContentApp(eachyacontentDetails.id,eachyacontentDetails.content_array[0].name,eachyacontentDetails.content_array[0].name_color)} style={{color:eachyacontentDetails.content_array[0].name_color}}>{eachyacontentDetails.content_array[0].name}</button>
                                    </div>
                                      ))}             
                                </div>
                                                    
                            <div className="intBtnBx intautowidth"><Link to="/ya_home" className="intbtnax">Back to Home</Link></div>
                            
                            </div>
                        </div>
                    </div>
                    
                </div>
                </section>
        </div>
        )
    }
}
export default YA_Exercise;