import React from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
var path = apiPath.path;
var md5=require("md5");

//const regexPhone = /^(0|[1-9][0-9]*)$/;
class LoginSob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
  
    this.setState({
      [name]: value
    });
 
  }

 

  handleSubmit(event) {
    this.logIn(this.state.userName, md5(this.state.password));
    event.preventDefault();
  }

  logIn(...params) {
    axios
      .post(path, {
        userName: params[0],
        password: params[1],
      }, {
        headers: { 'Api-Name': 'login_admin' }
      })
      .then((serverRes) => {
        const res = serverRes.data;
        // console.log(res);
        
        if (res.status === true) {
          localStorage.setItem("SOB_adminname", res.response.name);
          localStorage.setItem("SOB_adminId", res.response.adminId);
            toast.success(res.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.history.push("/offline_listing");
        
        } else {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }

 

  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    })
    //const { errors } = this.state;
    return (
      <div className="fullHeight">
        <section className="loginBase loginBaseB">
        <h2>Admin Sign In</h2>
          <h2>For Offline Registration</h2>
          <div className="loginPannel">
            <div className="loginLft"><img src={process.env.PUBLIC_URL + '/assets/images/loginlogo.png'} alt="" /></div>
            <div className="loginFrm">
              <form>
                <div className="form-group loginSelect ">
                  <input type="text" id="userName" name="userName" onChange={this.handleChange} className="form-control" placeholder="UserName" />
                </div>
                <div className="form-group">
                  <input type="password" className="form-control" id="password" name="password" onChange={this.handleChange} placeholder="Password" />
                </div>
                <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Login</button>
              </form>
            </div>
          </div>
        </section>     
      </div>
    )
  }
}
export default LoginSob;