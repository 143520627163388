import React from "react";
import { Link } from "react-router-dom";
class Amrit_Registration_Status_SOB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            reg_status:"",
        };
    } 
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const reg_status = await localStorage.getItem('reg_status')
        this.setState({
            userId: userId,
            reg_status:reg_status
        })
        if(this.state.userId===null){
            this.props.history.push("/offline_login");
        }
    }

    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock amritAfterregd">
                            <Link to="#"><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt=""/></Link>
                            <h3>{this.state.reg_status==="notstart"?"Registration has not started yet.":"Registration is closed."}</h3>
                          <Link to="/amrit_registration_sob">  <button className="backtohome">Back</button></Link>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Amrit_Registration_Status_SOB;