import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
//import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class PhysicalActivity_D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            quizColor:"",
                quizHeading:"",
                quizQuestion:"",
                quizIcon:"",
                quizOptions:[],
                quizCategory:"",
                quizAnswer:"",
                isActive:true,
                selectOptionName:"",
            
            quizArr:[],
            quizNumber:0,
            addXpDetails:"",
            answerStatus:"",
            nextbuttonStatus:false,
            fitFiveUnlockDetails:""
        };
        this.addXP = this.addXP.bind(this);
        this.fitFiveUnlock=this.fitFiveUnlock.bind(this);

    }
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
       
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
       
        this.getContents();
       
    }

    getNextQuiz(){
        let qzArr = this.state.quizArr
        let qzNo = parseInt(this.state.quizNumber) + 1
        //console.log("qzNo",qzNo)
        //console.log("qzArr")
        //console.log(qzArr.length)
        if(qzNo+1<=qzArr.length){
            this.setState({
                quizHeading:qzArr[qzNo].contentDetails.quiz.heading,
                quizQuestion:qzArr[qzNo].contentDetails.quiz.question,
                quizIcon:qzArr[qzNo].contentDetails.quiz.icon,
                quizCategory:qzArr[qzNo].contentDetails.quiz.category,
                quizOptions:qzArr[qzNo].contentDetails.quiz.options,
                quizAnswer:qzArr[qzNo].contentDetails.quiz.answer,
                isActive:qzArr[qzNo].isActive,
                quizColor:qzArr[qzNo].color,
                quizNumber:qzNo,
                nextbuttonStatus: false,
                answerStatus: ''
            });
           // console.log(this.state);
           if(qzNo+1===qzArr.length){
                this.fitFiveUnlock();
           }
        }
        let correctClass ='optionclass-'+ this.state.quizAnswer
        let wrongClass ='optionclass-'+ this.state.selectOptionName
        document.getElementById(correctClass).classList.remove("piQuestCorrect");
        document.getElementById(wrongClass).classList.remove("piQuestWrong");
        document.getElementById("iconclass").classList.remove("piBoxCorrect");
        document.getElementById("iconclass").classList.remove("piBoxWrong");
    }

    getQuizAnswer(selectOptionName){
        this.setState({
            nextbuttonStatus: true
        });
        let correctAnswer=this.state.quizAnswer;
        //console.log(getQzAnswer);
        if(selectOptionName===correctAnswer){
           
            this.setState({
                answerStatus: true,
                selectOptionName:selectOptionName
            });
            let correctClass ='optionclass-'+ correctAnswer
            document.getElementById(correctClass).classList.add("piQuestCorrect")
            document.getElementById("iconclass").classList.add("piBoxCorrect");
            this.addXP();
        }else{
            this.setState({
                answerStatus: false,
                selectOptionName:selectOptionName
            });
            let correctClass ='optionclass-'+ correctAnswer
            document.getElementById(correctClass).classList.add("piQuestCorrect")
            let wrongClass ='optionclass-'+ selectOptionName
            document.getElementById(wrongClass).classList.add("piQuestWrong")
            document.getElementById("iconclass").classList.add("piBoxWrong");
        }
    }

    addXP(){
        axios.post(path, {
            "xp": 25
        }, {
            headers: { 'Api-Name': 'addXP', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const addXpDetails = res.response;
                this.setState({
                    addXpDetails: addXpDetails
                });
            }
            else {
               
            }
        });
    }

    fitFiveUnlock(){
        axios.post(path, {
        }, {
            headers: { 'Api-Name': 'fitFiveUnlock', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const fitFiveUnlockDetails = res.response;
                //console.log(fitFiveUnlockDetails);
                this.setState({
                    fitFiveUnlockDetails: fitFiveUnlockDetails
                });
            }
            else {
               
            }
        });
    }

    async getContents() {
        const item_id = await localStorage.getItem('itemId')
        axios.post(path, {
            itemId: item_id
        }, {
            headers: { 'Api-Name': 'getContents', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;

            if (res.status === true) {
               // console.log(res);
                const responseContents = res.response.contents;
                let quizContents = []
                responseContents.forEach(elem => {
                   if(elem.contentType==="dynamic"){
                    if(elem.category==="quiz"){
                        //console.log(elem)
                        quizContents.push(elem)                        
                    }
                   }
               });
               this.setState({
                quizArr:quizContents,
                quizHeading:quizContents[0].contentDetails.quiz.heading,
                quizQuestion:quizContents[0].contentDetails.quiz.question,
                quizIcon:quizContents[0].contentDetails.quiz.icon,
                quizCategory:quizContents[0].contentDetails.quiz.category,
                quizOptions:quizContents[0].contentDetails.quiz.options,
                quizAnswer:quizContents[0].contentDetails.quiz.answer,
                isActive:quizContents[0].isActive,
                quizColor:quizContents[0].color,
             });
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");


                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Introduction to FIT 5 </h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
          
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
          
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>{this.state.quizHeading}</h4>
                                <ul className="physicD">
                                    <li>Q{this.state.quizNumber+1}. {this.state.quizQuestion}</li>
                                </ul>
                                <div className="piBoxD">
                                    <div id="iconclass" className="piBoxDleft"><img src={path+this.state.quizIcon} alt=""/></div>
                                    <div className="piBoxDright">
                                       
                                    {this.state.quizOptions.map((eachoptionDetails, i) => (
                                    
                                             <div id={`optionclass-${eachoptionDetails}`} style={{pointerEvents:this.state.nextbuttonStatus===true?"none":""}} 
                                             className="piQuest" key={i} onClick={()=>this.getQuizAnswer(eachoptionDetails)}>
                                                <span>{String.fromCharCode(65+i)}</span>{eachoptionDetails}</div>
                                    ))}
               

              
                                {this.state.nextbuttonStatus===true &&  parseInt(this.state.quizNumber)+1<(this.state.quizArr).length?
                                        <button type="button" onClick={()=>this.getNextQuiz()}>Next</button>
                                    :''}
                                    </div>
                                </div>
                                {this.state.answerStatus===true?
                                <div className="suggestions">
                                    {/* <span className="heartCircle"><i className="fas fa-heartbeat"></i></span> */}
                                    <span className="iconCircle"><img className="imgIcon" src={path+this.state.addXpDetails.icon} alt=""/></span>
                                        <div className="pointsButton">Point - 25XP</div>
                                        <p>Physical Activity {this.state.addXpDetails.displayText}</p>
                                </div>
                                :""
                                    }
                                <div className="bordrs"></div>
                                <div className="intBtn">
                                <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default PhysicalActivity_D;