import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;
class Amrit_Form1_Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",
            divChekboxvalue: "hide",
            divvalue: "show",

            sport: "",
            heightValue: "",
            heightUnit: "cm",
            weightValue: "",
            weightUnit: "kg",
            bmiValue: "",
            bmiReferral: "No",
            doctorComments: "",
            bmi_unit:"",

            // circumferenceValue:"",
            // circumferenceUnit:"cm",
            // WHtr:"",
            // WHtrReferral:"No",
            boneMineralUnableValue: false,
            // unableReason: ["Age Under 20"],
            unableReason: [],
            unableReasonabove: [],
            leftHealTScore: "",
            rightHealTScore: "",
            BMDReferral: "",
            rightArmUp: "",
            rightArmLow: "",
            leftArmUp: "",
            leftArmLow: "",
            bpReferral: "No",
            sob_scanId: "",

            reasons: [
                { id: 'Age under 20', value: "Age under 20" },
                { id: "Athlete refused", value: "Athlete refused" },
                { id: "Athlete unable to cooperate", value: "Athlete unable to cooperate" },
                { id: "Unusual heel shape", value: "Unusual heel shape" }
            ],


        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUnit = this.handleChangeUnit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeCheckboxUnableToTest = this.handleChangeCheckboxUnableToTest.bind(this);
        this.handleChangeCheckboxAbove = this.handleChangeCheckboxAbove.bind(this);



    }

    async componentDidMount() {
      
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }

    handleChangeUnit(event) {

        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
       // console.log(name, value);

        this.setState({
            [name]: value
        });
        //this.setBmiVal();

    }

    handleChangeCheckboxUnableToTest(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
      //  const name_value = target.value;
      //  const name = target.name;
       // console.log(name, name_value, value);

        this.setState({
            divChekboxvalue: value,
            divvalue: value,
            boneMineralUnableValue: value
        })

        if (value === true) {
            this.setState({
                divChekboxvalue: "show",
            })
        } else {
            this.setState({
                divChekboxvalue: "hide",
            })
        }
        if (value === false) {
            this.setState({
                divvalue: "show",
            })
        } else {
            this.setState({
                divvalue: "hide",
            })
        }
    }

    handleChangeCheckbox(event) {
        const target = event.target;
     //   const value = target.type === "checkbox" ? target.checked : target.value;
        const name_value = target.value;
        const name = target.name;
       // console.log(name, name_value);
        // this.setState({
        //     unableReason:["Age Under 20"]
        // })
        const options = this.state.unableReason
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            [name]: options,

        });
    }

    handleChangeCheckboxAbove(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name_value = target.value;
        const name = target.name;
       // console.log(name, name_value);

        const options = this.state.unableReasonabove
        let index

        // check if the check box is checked or unchecked
        if (event.target.checked) {
            // add the numerical value of the checkbox to options array
            options.push(name_value)
        } else {
            // or remove the value from the unchecked checkbox from the array
            index = options.indexOf(name_value)
            options.splice(index, 1)
        }
        //console.log(options);
        this.setState({
            [name]: options,
            boneMineralUnableValue: value
        });
    }

    handleSubmit(event) {
       // console.log(this.state);
        this.healthPromotionFirstPage();
        event.preventDefault();
    }

    healthPromotionFirstPage() {
        let bmi_for, height_cm, weight_kg;

        if (this.state.participant_list.age < 20) {
            bmi_for = "Percentile (under 20 years of age)"
            // boneMineralUnable_val = false
        } else {
            bmi_for = "(20 years of age and over)"
            // boneMineralUnable_val = true
        }
        if (this.state.heightUnit === "inch") {
            height_cm = this.state.heightValue * 2.54
        } else {
            height_cm = this.state.heightValue;
        }

        if (this.state.weightUnit === "lbs") {
            weight_kg = this.state.weightValue * 0.4535923
        } else {
            weight_kg = this.state.weightValue;
        }
        // if (this.state.circumferenceUnit === "inch") {
        //     circumferenceUnit_cm = this.state.circumferenceValue * 2.54
        // } else {
        //     circumferenceUnit_cm = this.state.circumferenceValue;
        // }

        //let WHtr_val;
        let bmi_val;
        // WHtr_val = circumferenceUnit_cm + ":" + height_cm;
        bmi_val = parseFloat((weight_kg / (height_cm * height_cm)) * 10000).toFixed(2);
        //   console.log(bmi_val)
        //   console.log(WHtr_val)
        let dataToSend = {
            user_id: this.state.sob_scanId,
            heightUnit: this.state.heightUnit,
            weightUnit: this.state.weightUnit,
            bmiFor: bmi_for,
            boneMineralUnableValue: this.state.boneMineralUnableValue
        };
        let sport_1 = this.state.sport !== "" ? dataToSend["sport"] = this.state.sport : null;
        let heightValue_1 = this.state.heightValue !== "" ? dataToSend["heightValue"] = this.state.heightValue : null;
        let weightValue_1 = this.state.weightValue !== "" ? dataToSend["weightValue"] = this.state.weightValue : null;
        let bmi_val_1 = this.state.weightValue !== "" && this.state.heightValue !== "" ? dataToSend["bmiValue"] = bmi_val : null;
        let bmiReferral_1 = this.state.bmiReferral !== "" ? dataToSend["bmiReferral"] = this.state.bmiReferral : null;


        if (this.state.boneMineralUnableValue === false) {
            let unableReason_1 = this.state.unableReason !== "" ? dataToSend["boneMineralTestUnableReasons"] = this.state.unableReason : null;
            let leftHealTScore_1 = this.state.leftHealTScore !== "" ? dataToSend["leftHealTScore"] = this.state.leftHealTScore : null;
            let rightHealTScore_1 = this.state.rightHealTScore !== "" ? dataToSend["rightHealTScore"] = this.state.rightHealTScore : null;
            let BMDReferral_1 = this.state.BMDReferral !== "" ? dataToSend["BMDReferral"] = this.state.BMDReferral : null;
        }



        if (this.state.boneMineralUnableValue === true) {
            let unableReason_2 = this.state.unableReasonabove !== "" ? dataToSend["boneMineralTestUnableReasons"] = this.state.unableReasonabove : null;

        }

        let rightArmUp_1 = this.state.rightArmUp !== "" ? dataToSend["rightArmUp"] = this.state.rightArmUp : null;
        let rightArmLow_1 = this.state.rightArmLow !== "" ? dataToSend["rightArmLow"] = this.state.rightArmLow : null;
        let leftArmUp_1 = this.state.leftArmUp !== "" ? dataToSend["leftArmUp"] = this.state.leftArmUp : null;
        let leftArmLow_1 = this.state.leftArmLow !== "" ? dataToSend["leftArmLow"] = this.state.leftArmLow : null;
        let bpReferral_1 = this.state.bpReferral !== "" ? dataToSend["bpReferral"] = this.state.bpReferral : null;
        let doctorComments = this.state.doctorComments !== "" ? dataToSend["doctorComments"] = this.state.doctorComments : null;






        //console.log(dataToSend);


        axios
            .post(path, dataToSend, {
                headers: { 'api-name': 'healthPromotionFirstPage', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_form1_step2");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })
                if(res.response.pageDone!=="0"){
                    toast.error("Scan Correct Athlete", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.props.history.push("/amrit_medical_professional");
                }

            }else{
                toast.error("Scan Correct Athlete", {

                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.props.history.push("/amrit_medical_professional");
            }

        });
    }



    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }
    setBmiVal() {
        let height_cm, weight_kg, bmi_val;

       // console.log(this.state.heightUnit, this.state.weightUnit)

        if (this.state.heightValue !== "" && this.state.weightValue !== "") {
            if (this.state.heightUnit === "inch") {
                height_cm = this.state.heightValue * 2.54
            } else {
                height_cm = this.state.heightValue;
            }

            if (this.state.weightUnit === "lbs") {
                weight_kg = this.state.weightValue * 0.4535923
            } else {
                weight_kg = this.state.weightValue;
            }
            bmi_val = parseFloat((weight_kg / (height_cm * height_cm)) * 10000).toFixed(2);
            this.setState({
                bmiValue: bmi_val,
                bmi_unit:"kg/m"
            })
        }
    }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"><Link to="/amrit_medical_professional"><span><img src={process.env.PUBLIC_URL + '/assets/images/dbicon.png'} alt="" /></span> Scan ID</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/hpromotion.png'} alt="" />
                                    <form>
                                        <div className="hpTop">
                                            <div className="hpTxt hpTxtB">

                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span>{this.state.participant_list.contactNo}</span></p>
                                            </div>
                                            <div className="hpTxt">
                                                {/* <p><strong>DOB:</strong> <span>{this.state.participant_list.dob}</span></p> */}
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div>
                                        <div className="compositionBg">
                                            <h3>Body Composition</h3>
                                            <div className="row">
                                                <div className="col-md-6 rightcomBordr">
                                                    <h5>Height</h5>
                                                    <div className="heightFlex">
                                                        <input type="text" max={130} min={25} name="heightValue" className="form-control" onKeyUp={(e) => this.setBmiVal(e)} placeholder="00" onChange={this.handleChange} />
                                                        <div className="newSelect">
                                                            <select name="heightUnit" onChange={this.handleChangeUnit} onClick={(e) => this.setBmiVal(e)} defaultValue="cm">
                                                                <option value="cm">cm</option>
                                                                <option value="inch" >inch</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Weight</h5>
                                                    <div className="heightFlex">
                                                        <input type="text" name="weightValue" className="form-control" placeholder="00" onKeyUp={(e) => this.setBmiVal(e)} onChange={this.handleChange} />
                                                        <div className="newSelect">
                                                            <select name="weightUnit" onChange={this.handleChangeUnit} onClick={(e) => this.setBmiVal(e)} defaultValue="kg" >
                                                                <option value="kg">kg</option>
                                                                <option value="lbs">lbs</option>

                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bmi">
                                                <h4>{this.state.participant_list.age < 20 ? "BMI Percentile " : "BMI "}:
                                                    <span> {this.state.bmiValue} {this.state.bmi_unit}{this.state.bmiValue?<sup>2</sup>:""}</span>
                                                </h4>
                                                <div className="bordrBtmfrm1"></div>
                                                <h5>Referral made for BMI follow Up?</h5>
                                                <div className="newSelect">
                                                    <select name="bmiReferral" onChange={this.handleChange} defaultValue="No">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>

                                                    </select>
                                                </div>
                                            </div>


                                            {/* <div className="bmi">
                                                <div className="waistC">
                                                    <h4>Waist Circumference: </h4>
                                                    <div className="waistCselect">
                                                        <input type="text" className="form-control" placeholder="00" name="circumferenceValue" onChange={this.handleChange} />
                                                        <div className="newSelect">
                                                            <select name="circumferenceUnit" onChange={this.handleChange}>
                                                                <option value="cm" selected="selected">cm</option>
                                                                <option value="inch" >inch</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="bordrBtmfrm1"></div>
                                                <h4>WHtR: <span>Placeholder text</span> </h4><br />
                                                <h5>Referral made for WHtR</h5>
                                                <div className="newSelect">
                                                    <select name="WHtrReferral" onChange={this.handleChange} defaultValue="No">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                </div>
                                            </div> */}

                                            <br /><br />
                                            <h4>Bone Mineral Density Test (Athletes MUST be at least 20 years old to screen)</h4>
                                            {this.state.participant_list.age < 20 ?
                                                <div className="unabletoTest"><input type="checkbox" checked="checked" /> Unable to test</div>
                                                :
                                                <div className="unabletoTest"><input type="checkbox" name="unable_to_test" onChange={this.handleChangeCheckboxUnableToTest} /> Unable to test</div>
                                            }
                                            {this.state.participant_list.age >= 20 ?

                                                <div className={this.state.divvalue}>
                                                    <h6>T-score</h6>
                                                    <div className="row">
                                                        <div className="col-md-6 rightcomBordr">
                                                            <h5>Left heel</h5>
                                                            <div className="heightFlex">
                                                                <input type="text" className="form-control" placeholder="00" name="leftHealTScore" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h5>Right heel</h5>
                                                            <div className="heightFlex">
                                                                <input type="text" className="form-control" placeholder="00" name="rightHealTScore" onChange={this.handleChange} />
                                                            </div>
                                                        </div>
                                                    </div><br />
                                                    <h5>Referral made for BMD follow Up?</h5>
                                                    <div className="newSelect">
                                                        <select name="BMDReferral" onChange={this.handleChange} defaultValue="No">
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>

                                                        </select>
                                                    </div>
                                                </div> : ""}


                                            {this.state.participant_list.age < 20 ?
                                                <div>
                                                    <h5 >Reason For Unable to test?</h5>

                                                    <div className="unabletoTest stpBcheck">
                                                        {this.state.reasons.map((reason, i) => (
                                                            // defaultChecked={reason.value == "Age Under 20"} 
                                                            <span key={i}><input type="checkbox" name="unableReason" value={reason.id} onChange={this.handleChangeCheckbox} />{reason.value}</span>
                                                        ))
                                                        }


                                                    </div>
                                                </div>
                                                :
                                                <div className={this.state.divChekboxvalue}>
                                                    <h5 >Reason For Unable to test?</h5>

                                                    <div className="unabletoTest stpBcheck stpBcheckB">
                                                        {this.state.reasons.map((reason, i) => (
                                                            <span key={i}><input type="checkbox" name="unableReasonabove" value={reason.id} onChange={this.handleChangeCheckboxAbove} />{reason.value}</span>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                        {this.state.participant_list.age >= 20 ?
                                        <div className="compositionBg">
                                            <h3>Blood Pressure</h3>
                                            <div className="row">
                                                {/* <div className="col-md-6 rightcomBordr">
                                                    <h5>Right arm</h5>
                                                    <div className="heightFlex">
                                                        <input type="text" className="form-control" placeholder="00" name="rightArmLow" onChange={this.handleChange} />
                                                        <input type="text" className="form-control" placeholder="00" name="rightArmUp" onChange={this.handleChange} />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-6">
                                                    <h5>Left arm</h5>
                                                    <div className="heightFlex">
                                                        <input type="text" className="form-control" placeholder="00" name="leftArmLow" onChange={this.handleChange} />
                                                        <input type="text" className="form-control" placeholder="00" name="leftArmUp" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div><br />
                                            <h5>Referral made for BP follow Up?</h5>
                                            <div className="newSelect">
                                                <select name="bpReferral" onChange={this.handleChange} defaultValue="No">
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        :""}

                                        <div className="compositionBg">
                                            <div className="addlComment">
                                                <h3>Additional comments:</h3>
                                                <textarea className="form-control" name="doctorComments" placeholder="Comment" onChange={this.handleChange}></textarea>
                                            </div>

                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_medical_professional" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Next</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Form1_Step1;