import React from "react";
import { Link } from "react-router-dom";
class Amrit_Dashboard extends React.Component {
    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                <ul>
                                        <li className="active"><Link to="/amrit_dashboard"><span><img src={process.env.PUBLIC_URL + '/assets/images/dbicon.png'} alt="" /></span> Dashboard</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>
                                        <li><Link to="/amrit_chapter_participants"><span><img src={process.env.PUBLIC_URL + '/assets/images/participanticon.png'} alt="" /></span> Participants</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Dashboard</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={process.env.PUBLIC_URL + '/assets/images/usr.png'} alt="" /></span>
                                        <div className="">
                                            <h6>Ellina Abovian</h6>
                                            <p>ellina211@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dbfilter"><button data-toggle="modal" data-target="#filterModal"><img src={process.env.PUBLIC_URL + '/assets/images/dbfilter.png'} alt="" /> Filter</button></div>
                                <div className="dbPieChart">
                                    <div className="pieInner">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/pie1demo.png'} alt="" />
                                    </div>
                                    <div className="pieInner">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/pie2demo.png'} alt="" />
                                    </div>
                                </div>
                                <div className="dbProgressBar">
                                    <h5>Leadboard</h5>
                                    <div className="progress dbp1">
                                        <div className="progress-bar"  style={{width:"80%",backgroundColor:"#00A44E"}}>Approved (284)</div>
                                    </div>
                                    <div className="progress dbp2">
                                        <div className="progress-bar" style={{width:"50%",backgroundColor:"#F7941D"}}>Pending (310)</div>
                                    </div>
                                    <div className="progress dbp3">
                                        <div className="progress-bar" style={{width:"50%",backgroundColor:"#F7431C"}} >Rejected (140)</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


                {/* <!-- Modal --> */}
                <div className="modal fade modal-center" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">
                                <h3>Apply Filter</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                <div className="mulSelect">
                                                    <select id="state" name="State[]" multiple className="form-control">
                                                        <option value="Codeigniter">State 1</option>
                                                        <option value="CakePHP">State 2</option>
                                                        <option value="Laravel">State 3</option>
                                                        <option value="YII">State 4</option>
                                                        <option value="Zend">State 5</option>
                                                        <option value="Symfony">State 6</option>
                                                        <option value="Phalcon">State 7</option>
                                                        <option value="Slim">State 8</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>District</label>
                                                <div className="mulSelect">
                                                    <select id="dist" name="Dist[]" multiple className="form-control">
                                                        <option value="Codeigniter">Dist 1</option>
                                                        <option value="CakePHP">Dist 2</option>
                                                        <option value="Laravel">Dist 3</option>
                                                        <option value="YII">Dist 4</option>
                                                        <option value="Zend">Dist 5</option>
                                                        <option value="Symfony">Dist 6</option>
                                                        <option value="Phalcon">Dist 7</option>
                                                        <option value="Slim">Dist 8</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Venue</label>
                                            <div className="newSelect">
                                                <select name="Country" id="country">
                                                    <option className="slRedbg" value="">Select Venue</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Participants Type</label>
                                            <div className="newSelect">
                                                <select name="Country" id="country">
                                                    <option className="slRedbg" value="">Select Participants Type</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filterModalSubmit"><button type="submit" className="btn btn-primary">Submit</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Amrit_Dashboard;