import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ChartComponent from "../components/chartBlock";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import apiPath from '../constant';
var path = apiPath.path;
const animatedComponents = makeAnimated();
class Amrit_Officials_Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            profilePic: "",
            checkedIn_count: "",
            dashboard_list: [],
            district_options: [],
            selected_district_arr: [],
            selected_state_arr: [],
            isFullscreen:false,
            participant_type: [
                { id: '1', value: "athletes" },
                { id: "2", value: "officials" },
                { id: "3", value: "volunteers" },
                { id: "4", value: "medical professionals" }
            ],


            state_array:
                [
                    "Andhra Pradesh",
                    "Andaman and Nicobar Islands (UT)",
                    "Arunachal Pradesh",
                    "Assam",
                    "Bihar",
                    "Chandigarh (UT)",
                    "Chhattisgarh",
                    "Dadra and Nagar Haveli (UT)",
                    "Daman and Diu (UT)",
                    "Delhi (NCT)",
                    "Goa",
                    "Gujarat",
                    "Haryana",
                    "Himachal Pradesh",
                    "Jammu and Kashmir",
                    "Jharkhand",
                    "Karnataka",
                    "Kerala",
                    "Lakshadweep (UT)",
                    "Madhya Pradesh",
                    "Maharashtra",
                    "Manipur",
                    "Meghalaya",
                    "Mizoram",
                    "Nagaland",
                    "Odisha",
                    "Puducherry (UT)",
                    "Punjab",
                    "Rajasthan",
                    "Sikkim",
                    "Tamil Nadu",
                    "Telangana",
                    "Tripura",
                    "Uttarakhand",
                    "Uttar Pradesh",
                    "West Bengal"
                ],
            district_array:
                [
                    ["Anantapur", "Chittoor", "East Godavari", "Guntur", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari", "YSR Kadapa"],
                    ["Nicobar", "North and Middle Andaman", "South Andaman"],
                    ["Tawang", "West Kameng", "East Kameng", "Papum Pare", "Kurung Kumey", "Kra Daadi", "Lower Subansiri", "Upper Subansiri", "West Siang", "East Siang", "Siang", "Upper Siang", "Lower Siang", "Lower Dibang Valley", "Dibang Valley", "Anjaw", "Lohit", "Namsai", "Changlang", "Tirap", "Longding"],
                    ["Baksa", "Barpeta", "Biswanath", "Bongaigaon", "Cachar", "Charaideo", "Chirang", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Goalpara", "Golaghat", "Hailakandi", "Hojai", "Jorhat", "Kamrup Metropolitan", "Kamrup", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Majuli", "Morigaon", "Nagaon", "Nalbari", "Dima Hasao", "Sivasagar", "Sonitpur", "South Salmara-Mankachar", "Tinsukia", "Udalguri", "West Karbi Anglong"],
                    ["Araria", "Arwal", "Aurangabad", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran (Motihari)", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur (Bhabua)", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger (Monghyr)", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia (Purnea)", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali", "West Champaran"],
                    ["Chandigarh"], ["Balod", "Baloda Bazar", "Balrampur", "Bastar", "Bemetara", "Bijapur", "Bilaspur", "Dantewada (South Bastar)", "Dhamtari", "Durg", "Gariyaband", "Janjgir-Champa", "Jashpur", "Kabirdham (Kawardha)", "Kanker (North Bastar)", "Kondagaon", "Korba", "Korea (Koriya)", "Mahasamund", "Mungeli", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Sukma", "Surajpur  ", "Surguja"],
                    ["Dadra & Nagar Haveli"],
                    ["Daman", "Diu"],
                    ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East  Delhi", "North West  Delhi", "Shahdara", "South Delhi", "South East Delhi", "South West  Delhi", "West Delhi"],
                    ["North Goa", "South Goa"],
                    ["Ahmedabad", "Amreli", "Anand", "Aravalli", "Banaskantha (Palanpur)", "Bharuch", "Bhavnagar", "Botad", "Chhota Udepur", "Dahod", "Dangs (Ahwa)", "Devbhoomi Dwarka", "Gandhinagar", "Gir Somnath", "Jamnagar", "Junagadh", "Kachchh", "Kheda (Nadiad)", "Mahisagar", "Mehsana", "Morbi", "Narmada (Rajpipla)", "Navsari", "Panchmahal (Godhra)", "Patan", "Porbandar", "Rajkot", "Sabarkantha (Himmatnagar)", "Surat", "Surendranagar", "Tapi (Vyara)", "Vadodara", "Valsad"],
                    ["Ambala", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gurgaon", "Hisar", "Jhajjar", "Jind", "Kaithal", "Karnal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Yamunanagar"], ["Bilaspur", "Chamba", "Hamirpur", "Kangra", "Kinnaur", "Kullu", "Lahaul &amp; Spiti", "Mandi", "Shimla", "Sirmaur (Sirmour)", "Solan", "Una"],
                    ["Anantnag", "Bandipore", "Baramulla", "Budgam", "Doda", "Ganderbal", "Jammu", "Kargil", "Kathua", "Kishtwar", "Kulgam", "Kupwara", "Leh", "Poonch", "Pulwama", "Rajouri", "Ramban", "Reasi", "Samba", "Shopian", "Srinagar", "Udhampur"], ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridih", "Godda", "Gumla", "Hazaribag", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahibganj", "Seraikela-Kharsawan", "Simdega", "West Singhbhum"],
                    ["Bagalkot", "Ballari (Bellary)", "Belagavi (Belgaum)", "Bengaluru (Bangalore) Rural", "Bengaluru (Bangalore) Urban", "Bidar", "Chamarajanagar", "Chikballapur", "Chikkamagaluru (Chikmagalur)", "Chitradurga", "Dakshina Kannada", "Davangere", "Dharwad", "Gadag", "Hassan", "Haveri", "Kalaburagi (Gulbarga)", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysuru (Mysore)", "Raichur", "Ramanagara", "Shivamogga (Shimoga)", "Tumakuru (Tumkur)", "Udupi", "Uttara Kannada (Karwar)", "Vijayapura (Bijapur)", "Yadgir"],
                    ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasaragod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"],
                    ["Agatti", "Amini", "Androth", "Bithra", "Chethlath", "Kavaratti", "Kadmath", "Kalpeni", "Kilthan", "Minicoy"],
                    ["Agar Malwa", "Alirajpur", "Anuppur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Chhindwara", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha"],
                    ["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"],
                    ["Bishnupur", "Chandel", "Churachandpur", "Imphal East", "Imphal West", "Jiribam", "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal", "Thoubal", "Ukhrul"],
                    ["East Garo Hills", "East Jaintia Hills", "East Khasi Hills", "North Garo Hills", "Ri Bhoi", "South Garo Hills", "South West Garo Hills ", "South West Khasi Hills", "West Garo Hills", "West Jaintia Hills", "West Khasi Hills"],
                    ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip"],
                    ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunheboto"],
                    ["Angul", "Balangir", "Balasore", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Deogarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghapur", "Jajpur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar (Keonjhar)", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Sonepur", "Sundargarh"],
                    ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
                    ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Ferozepur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Muktsar", "Nawanshahr (Shahid Bhagat Singh Nagar)", "Pathankot", "Patiala", "Rupnagar", "Sahibzada Ajit Singh Nagar (Mohali)", "Sangrur", "Tarn Taran"],
                    ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalore", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"],
                    ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
                    ["Ariyalur", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Salem", "Sivaganga", "Thanjavur", "Theni", "Thoothukudi (Tuticorin)", "Tiruchirappalli", "Tirunelveli", "Tiruppur", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Vellore", "Viluppuram", "Virudhunagar"],
                    ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar Bhoopalpally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem Asifabad", "Mahabubabad", "Mahabubnagar", "Mancherial", "Medak", "Medchal", "Nagarkurnool", "Nalgonda", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Rangareddy", "Sangareddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal (Rural)", "Warangal (Urban)", "Yadadri Bhuvanagiri"],
                    ["Dhalai", "Gomati", "Khowai", "North Tripura", "Sepahijala", "South Tripura", "Unakoti", "West Tripura"],
                    ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri Garhwal", "Pithoragarh", "Rudraprayag", "Tehri Garhwal", "Udham Singh Nagar", "Uttarkashi"],
                    ["Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Amethi (Chatrapati Sahuji Mahraj Nagar)", "Amroha (J.P. Nagar)", "Auraiya", "Azamgarh", "Baghpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bhadohi", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hapur (Panchsheel Nagar)", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kanshiram Nagar (Kasganj)", "Kaushambi", "Kushinagar (Padrauna)", "Lakhimpur - Kheri", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "RaeBareli", "Rampur", "Saharanpur", "Sambhal (Bhim Nagar)", "Sant Kabir Nagar", "Shahjahanpur", "Shamali (Prabuddh Nagar)", "Shravasti", "Siddharth Nagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"],
                    ["Alipurduar", "Bankura", "Birbhum", "Burdwan (Bardhaman)", "Cooch Behar", "Dakshin Dinajpur (South Dinajpur)", "Darjeeling", "Hooghly", "Howrah", "Jalpaiguri", "Kalimpong", "Kolkata", "Malda", "Murshidabad", "Nadia", "North 24 Parganas", "Paschim Medinipur (West Medinipur)", "Purba Medinipur (East Medinipur)", "Purulia", "South 24 Parganas", "Uttar Dinajpur (North Dinajpur)"]
                ],
            stateBasedDistArr: []
        };
        this.handleChangeStateDropdown = this.handleChangeStateDropdown.bind(this);

    }


    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')


        this.setState({
            userId: userId,
            AuthToken: AuthToken,


        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.getDashboardOfficial();

        try {
            setInterval(async () => {
                this.getDashboardOfficial();
            }, 10000);
        } catch (e) {
           // console.log(e);
        }
    }

    handleChangeStateDropdown(stateArr) {
       // console.log(stateArr);

        let distSelArr = [];
        let selectedStateArr = [];
        stateArr.map(eachState => {
            selectedStateArr.push(eachState.label);
            distSelArr.push(...this.state.district_array[eachState.value])
        })

        this.setState({
            selected_state_arr: selectedStateArr
        })
        this.generateDistrictDropdown(distSelArr);
    }


    generateDistrictDropdown(distSelArr) {
        this.setState({
            district_options: distSelArr
        });
    }

    handleChangeDistrictDropdown(distArr) {
       // console.log(distArr);
        let selecteddistArr = [];
        distArr.map(eachDist => {
            selecteddistArr.push(eachDist.label);
        })

        this.setState({
            selected_district_arr: selecteddistArr
        })
    }

    getDashboardOfficial() {
        //console.log(this.state.selected_state_arr)
        //console.log(this.state.selected_district_arr)
        let serachval = [];
        if (this.state.selected_state_arr.length > 0 && this.state.selected_district_arr.length > 0) {
            serachval = [{ "state_preference": this.state.selected_state_arr }, { "district_preference": this.state.selected_district_arr }]
        } else if (this.state.selected_state_arr.length > 0) {
            serachval = [{ "state_preference": this.state.selected_state_arr }]
        } else if (this.state.selected_district_arr.length > 0) {
            serachval = [{ "district_preference": this.state.selected_district_arr }]
        } else {
            serachval = []
        }
       // console.log(serachval)

        //    if(serachval.length>0){
        //    document.getElementById("filterModal").classList.remove("show", "d-block");
        //    document.querySelectorAll(".modal-backdrop")
        //     .forEach(el => el.classList.remove("modal-backdrop"));
        //    }
        axios.post(path, {
            searchFilter: serachval,
        }, {
            headers: { 'api-name': 'getDashboardOfficial', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const checkedIn_count = res.response.checkedIn;
                const DashboardDetails = res.response && res.response.dashboardData;
               //console.log(DashboardDetails);

                this.setState({
                    dashboard_list: DashboardDetails,
                    checkedIn_count: checkedIn_count

                });
                //console.log(this.state.ya_athletes_list);
            }

        });
    }
    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    setFullScreen(status){
        this.setState({
            isFullscreen:status
        })
    }




    render() {
        return (
            <div className="fullHeight">


                <section className="amritRegd">
                    <div className="amritRegdInn amritDB respPdnNew">
                        <div className="amritRegdBlock amritAfterregd amritDashboard respPdnNew">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/images/dblogo.png"}
                                        alt=""
                                    />
                                </div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active">
                                            <Link to="/amrit_officials_dashboard">
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/images/dbicon.png"
                                                        }
                                                        alt=""
                                                    />
                                                </span>{" "}
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_officials_show_id">
                                                <span>
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/assets/images/showid.png"
                                                        }
                                                        alt=""
                                                    />
                                                </span>{" "}
                                                Show ID
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Dashboard</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img
                                                src={
                                                    this.state.profilePic
                                                        ? this.state.profilePic
                                                        : process.env.PUBLIC_URL +
                                                        "/assets/images/userimg.png"
                                                }
                                                alt=""
                                            />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dbfilter">
                                    <button data-toggle="modal" data-target="#filterModal">
                                        <img src="images/dbfilter.png" alt="" /> Filter
                                    </button>
                                </div>

                                <div className="amritOfDb">
                                    <div className={this.state.isFullscreen?" fullScreenActive":" amritOfdbChild"}>
                                        {this.state.checkedIn_count ? (
                                            <h1
                                                className="dbOfficialHdn"
                                                style={{ textAlign: "center" }}
                                            >
                                                <span className="doOfficHdnA">
                                                    Athletes entered in Fit 5 Arena:{" "}
                                                </span>
                                                <span className="doOfficHdnB">
                                                    {this.state.checkedIn_count}
                                                </span>
                                            </h1>
                                        ) : (
                                            ""
                                        )}
                                        <span className="dbFullScreen">
                                            <i className="far fa-search-plus" onClick={()=>this.setFullScreen(true)}></i>
                                            <i className="far fa-search-minus" onClick={()=>this.setFullScreen(false)}></i>
                                        </span>
                                        <div className="amritofDbPiebase">
                                            {this.state.dashboard_list.map((eachChart, i) => (
                                                <ChartComponent dashboard_data={eachChart} key={i} />
                                            ))}
                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_after_registration" className="intbtnb">
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Modal --> */}
                <div className="modal fade modal-center" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">
                                <h3>Apply Filter</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                {/* <div className="mulSelect">
                                                    <select id="state" name="state" multiple className="form-control">
                                                        {this.state.state_array.map((eachoption, j) => (
                                                            <option value={JSON.stringify({ "state": eachoption, "index": j })} key={j}>{eachoption}</option>

                                                        ))}

                                                    </select>
                                                </div> */}




                                                <Select
                                                    name="state"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    options={this.state.state_array.map((eachoption, j) => ({ label: eachoption, value: j }))}
                                                    isMulti
                                                    // onChange={this.handleChangeDropdown}
                                                    onChange={e => this.handleChangeStateDropdown(e)}
                                                />


                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>District</label>
                                                <Select
                                                    name="district"
                                                    closeMenuOnSelect={false}
                                                    components={animatedComponents}
                                                    options={this.state.district_options.map(e => ({ label: e, value: e }))}
                                                    isMulti
                                                    onChange={e => this.handleChangeDistrictDropdown(e)}
                                                />
                                            </div>




                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-6">
                                            <label>Participants Type</label>
                                            <div className="newSelect">
                                                <select name="participant_type" id="participant_type">
                                                    <option className="slRedbg" value="">Select Participants Type</option>
                                                    {this.state.participant_type.map((participantTypeDetails, i) => (
                                                        <option value={participantTypeDetails.value}>{participantTypeDetails.value}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="filterModalSubmit"><button type="button" data-dismiss="modal" onClick={(e) => this.getDashboardOfficial(e)} style={{ width: "50%" }} className="btn btn-primary btn-submit">Submit</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
export default Amrit_Officials_Dashboard;