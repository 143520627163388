import React from "react";
import Scanner from "react-webcam-qr-scanner";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class ScannerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            scannedId: "",
            participantId: "",
            scanStatus: false,
            medical_type: ""


        };


    }



    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }

    }
    handleDecode = (result) => {
        //console.log(result);
        if (this.state.scanStatus === false) {
            // console.log(result.data);

            if (result.data && result.data !== "") {
                // document.getElementById("filterModal1").classList.remove("show", "d-block");
                // console.log("---------------------------");
                document.getElementById("closeModalBtn").click();


                if (this.scanAMID(result.data)) {
                    //console.log("push new form")

                }
            }
        }
    }
    scanAMID(...params) {
        let status = "";
        axios.post(path, {
            scannedId: params[0],
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                //console.log(res)
                status = true;
                this.setState({
                    scanStatus: status,
                })
                localStorage.setItem("SOB_scanId", params[0]);
                if (res.response.scannerMedicalProgramme === "Health Promotion (General Health)") {
                    if (res.response.pageDone === "0") {
                        this.props.history.push("/amrit_form1_step1");
                    } else if (res.response.pageDone === "1") {
                        this.props.history.push("/amrit_form1_step2");
                    } else {
                        this.props.history.push("/amrit_form1_step1");
                    }
                } else if (res.response.scannerMedicalProgramme === "Special Smiles (Dentist)") {
                    this.props.history.push("/amrit_special_smile_form");
                } else if (res.response.scannerMedicalProgramme === "Opening Eyes (Eye Specialist)") {
                    this.props.history.push("/amrit_eyes_front_page");
                }


            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                status = false;
            }
            this.setState({
                scanStatus: status
            })
            return status;
        });
    }


    handleScannerLoad = (mode) => {
        // this.consoleLogger();
        // console.log(mode);
        // console.log(this.state.console.errors)
    }
    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>

                <Scanner
                    className="some-classname"
                    onDecode={this.handleDecode}
                    onScannerLoad={this.handleScannerLoad}
                    // playsinline
                    // disablepictureinpicture
                    constraints={{
                        audio: false,
                        video: {
                            facingMode: "environment"
                        }
                    }}
                    captureSize={{ width: 1280, height: 720 }}
                />
            </div>
        )
    }
}



export default withRouter(ScannerComponent);