
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import apiPath from '../constant';
import OTPComponent from "../components/otpComponent";
var path = apiPath.path;
class OTP_Verification extends React.Component {

   
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            otp: "",
        };
    //    handleChange = otp => this.setState({ otp });
        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        this.setState({
            userId: userId,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        
        this.sendOtp();
    }

    handleChange = (otp) => {
        //console.log(otp)
        this.setState({ otp })};

    // handleSubmit(event) {
    //     //console.log(this.state);
    //      this.verifyOtp(this.state.userId,this.state.otp);
    //      event.preventDefault();
    //  }

     sendOtp() {
        axios.post(path, {
            userid: this.state.userId
        }, {
            headers: { 'api-name': 'sendOtp'}
        }).then(serverResponse => {
            const res = serverResponse.data;
            //console.log(res);
            //console.log(this.state.userId);

            if (res.status === true) {
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }

    //  verifyOtp(...params) {
    //     axios.post(path + "registration", {
    //         userId: params[0],
    //         otp: params[1],
    //     }, {
    //         headers: { 'api-name': 'verifyOtp'}
    //     }).then(serverResponse => {
    //         const res = serverResponse.data;
    //         console.log(res);

    //         if (res.status === true) {
    //             toast.success(res.message, {
    //                 position: toast.POSITION.BOTTOM_RIGHT,
    //             });
    //         }
    //         else {
    //             // this.props.history.push("/");
    //             // localStorage.removeItem("SOB_userId");
    //             // localStorage.removeItem("SOB_authToken");
    //             // localStorage.removeItem("SOB_refreshToken");

    //             // toast.error("Some Error Occured", {
    //             //     position: toast.POSITION.BOTTOM_RIGHT
    //             // });
    //         }
    //     });
    // }

   


   
    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="loginBase regdBase otpBase">
                <div className="signuplogo"><img src={process.env.PUBLIC_URL + '/assets/images/otp.png'} alt=""/></div>
                <h4>Please Enter the One-Time Password to Verify Your Account</h4>
                <h5>A One-Time Password has been sent to your mobile no</h5>
                    <div className="loginPannel">
                    <div className="loginFrm loginFrm1" style={{ borderRadius:0}}>
                        <form>
                        <div className="otpBase">
                            {/* <div className="passcode-wrapper"> */}
                                {/* <input id="codeBox1" type="number" maxLength="1" value=""/>
                                // {/* onKeyUp={()=>this.onKeyUpEvent(1,event)} onFocus={()=>this.onFocusEvent(1)} */}
                                {/* <input id="codeBox2" type="number" maxLength="1" value=""/>
                                <input id="codeBox4" type="number" maxLength="1" value="" />
                                <input id="codeBox5" type="number" maxLength="1" value="" />
                                <input id="codeBox3" type="number" maxLength="1" value="" />
                                <input id="codeBox6" type="number" maxLength="1" value="" /> */} 


                                {/* <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    // type="number"
                                    name="otp"
                                    // onChange={otp => console.log(otp)}
                                    numInputs={6}
                                    separator={<span> </span>}
                                   
                                    /> */}

                                <OTPComponent></OTPComponent>
                                    
                            {/* </div> */}
                            </div>
                        {/* <div className="formBtm"><button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Validate</button></div> */}
                        <p>Didn’t Get the Number? <Link to="#" onClick={this.sendOtp} className="forgotPass">Resend OTP</Link></p>  
                        </form>
                    </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default OTP_Verification;