import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class YA_Page6 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/yabg2.png'})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="leadTop">
                                        <div className="fit5 introsx">
                                        <h3>Pre-Evaluation</h3>
                                        <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                        </div>

                                        {/* <div className="fitTopImg">
                                            <img src="images/fit1.png" alt=""/>
                                            <img src="images/fit2.png" alt=""/>
                                            <img src="images/fit3.png" alt=""/>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="fit_intro ya_intro">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="commonContent">
                                    <div className="ya4Top">
                                        <span className="preAct"></span>
                                        <span className="preAct"></span>
                                        <span className="current"></span>
                                        <span></span>
                                    </div>
                                    
                                                            
                                        
                                    <div className="page4borderbase page5Base">
                                        <h5>08. Outside of Young Athletes, what other  therapies or early childhood education does your child receive?  <em>(Check all that apply)</em></h5>
                                        <div className="page4Radio page5Radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioe" id="radio15" value="option15" checked/>
                                                <label className="form-check-label" for="radio15">
                                                    Physical therapy or Physiotherapy
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioe" id="radio16" value="option16"/>
                                                <label className="form-check-label" for="radio16">
                                                    Speech Therapy
                                                </label>
                                            </div>
                                        </div>                          
                                        <div className="page4Radio page6full">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radiof" id="radio17" value="option17" checked/>
                                                <label className="form-check-label" for="radio17">
                                                    Early intervention services, like theurapetic  preschool or home visits
                                                </label>
                                            </div>
                                        </div>
                                                                                            
                                        
                                        <div className="page4Radio page6Quarter">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radiog" id="radio18" value="option18"/>
                                                <label className="form-check-label" for="radio18">
                                                    Occupational therapy
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radiog" id="radio19" value="option19"/>
                                                <label className="form-check-label" for="radio19">
                                                    Other
                                                </label>
                                            </div>
                                                <input type="text" className="form-control" id="" placeholder="Specify Please..."/>                               
                                        </div>
                                        
                                        <h5>09. What has happened to those therapies  because of COVID-19?  <br/><em>(Check all that apply)</em></h5>
                                        <div className="page4Radio page5Radio">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioh" id="radio20" value="option20" checked/>
                                                <label className="form-check-label" for="radio20">
                                                    Therapies are still happening in person
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioh" id="radio21" value="option21"/>
                                                <label className="form-check-label" for="radio21">
                                                    Therapies are happening virtually
                                                </label>
                                            </div>
                                        </div> 
                                        
                                    </div>
                                    <div className="intBtnBx intautowidth"><Link to="/ya_page1" className="intbtnax">Back to Main Menu</Link> <Link to="#" className="intbtnbx">Save</Link> <Link to="/ya_page7" className="intbtnbx">Next</Link></div>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                    

    
            </div>
        )
    }
}
export default YA_Page6;