import Login from './pages/login';
import Home from './pages/home';
import Profile from './pages/profile';
import EditProfile from './pages/edit_profile';
import Feedback from './pages/feedback';
import Leaderboard from './pages/leaderboard';
import Leaderboard_B from './pages/leaderboard_b';
import Leaderboard_New from './pages/leaderboard_new';
import OTP_Verification from './pages/otp_verification';
import Fit_Five from './pages/fit_five';
import Registration from './pages/registration';
import Aadhar_Upload from './pages/aadhar_upload';
import Introduction from './pages/introduction';
import PhysicalActivity from './pages/physical_activity';
import PhysicalActivity_B from './pages/physical_activity_B';
import PhysicalActivity_C from './pages/physical_activity_C';
import PhysicalActivity_D from './pages/physical_activity_D';
import PhysicalActivity_E from './pages/physical_activity_E';
import PhysicalActivity_F from './pages/physical_activity_F';
import Exercise from './pages/exercise';
import Nutrition from './pages/nutrition';
import Nutrition2 from './pages/nutrition2';
import Nutrition3 from './pages/nutrition3';
import Nutrition4 from './pages/nutrition4';
import Hydration from './pages/hydration';
import Hydration2 from './pages/hydration2';
import Hydration3 from './pages/hydration3';
import Hydration4 from './pages/hydration4';
import Covid_Awarness from './pages/covid_awarness';
import Covid_Awarness2 from './pages/covid_awarness2';
import Covid_Awarness3 from './pages/covid_awarness3';
import Covid_Awarness4 from './pages/covid_awarness4';
import Covid_Awarness5 from './pages/covid_awarness5';
import Covid_Awarness6 from './pages/covid_awarness6';
import TestYourself from './pages/test_yourself';
import ForgotP_OTP_Verification from './pages/forgotp_otp_verification';
import Password_Change from './pages/password_change';


//YA
import YA_Home from './pages/ya_home';
import Ya_Athletes from './pages/ya_athletes';
import YA_Program from './pages/ya_program';
import Ya_Equipments from './pages/ya_equipments';
import YA_Form1 from './pages/ya_form1';
import YA_Form2 from './pages/ya_form2';
import YA_Page6 from './pages/ya_page6';
import YA_Page7 from './pages/ya_page7';
import YA_Page8 from './pages/ya_page8';
import YA_Page9 from './pages/ya_page9';
import YA_Exercise from './pages/ya_exercise';
import YA_Exercise_Details from './pages/ya_exercise_details';
import YA_View_Exercise from './pages/ya_view_exercise';

//Amrit
import Amrit_Registration from './pages/amrit_registration';
import Amrit_After_Registration from './pages/amrit_after_registration';
import Amrit_Participants_Officials from './pages/amrit_participants_officials';
import Amrit_Participants_Volunteers from './pages/amrit_participants_volunteers';
import Amrit_Registration_Status from './pages/amrit_registration_status';
import Amrit_Registration_Status_SOB from './pages/amrit_registration_status_sob';
import Amrit_Chapter_Participants from './pages/amrit_chapter_participants';
import Amrit_Dashboard from './pages/amrit_dashboard';
import Amrit_Dashboard_B from './pages/amrit_dashboardB';
import Amrit_Show_ID from './pages/amrit_show_id';
import Amrit_Participants_Resubmit from './pages/amrit_participants_resubmit';
import Amrit_After_Registration_Offline from './pages/amrit_after_registration_offline';
import Amrit_Chapter_Participants_Details from './pages/amrit_chapter_participants_details';
import Amrit_Medical_Professional from './pages/amrit_medical_professional';
import Amrit_Form1_Step1 from './pages/amrit_form1_step1';
import Amrit_Form1_Step2 from './pages/amrit_form1_step2';
import Amrit_Special_Smile from './pages/amrit_special_smile_form';
import Amrit_Checkin_Checkout_Status from './pages/amrit_checkin_checkout_status';
import Amrit_Officials_Dashboard from './pages/amrit_officials_dashboard';
import Amrit_Officials_Show_ID from './pages/amrit_officials_show_id';
import Amrit_Athletes_Show_ID from './pages/amrit_athletes_show_id';
import Amrit_Volunteers_Show_ID from './pages/amrit_volunteers_show_id';
import Amrit_Eyes_Form1 from './pages/amrit_eyes_form1';
import Amrit_Eyes_Form2 from './pages/amrit_eyes_form2';
import Amrit_Eyes_Form3 from './pages/amrit_eyes_form3';
import Amrit_Eyes_Form4 from './pages/amrit_eyes_form4';
import Amrit_Eyes_Form5 from './pages/amrit_eyes_form5';
import Amrit_Eyes_Form6 from './pages/amrit_eyes_form6';
import Amrit_Eyes_Form7 from './pages/amrit_eyes_form7';
import Amrit_Eyes_Form8 from './pages/amrit_eyes_form8';

import Amrit_Eyes_Front_Page from './pages/amrit_eyes_front_page';

//SOB Login
import LoginSob from './pages/login_sob';
import RegistrationSob from './pages/registration_sob';
import IdUploadSob from './pages/id_upload_sob';
import Amrit_Registration_SOB from './pages/amrit_registration_sob';
import Amrit_Participants_Officials_SOB from './pages/amrit_participants_officials_sob';
import Offline_Listing from './pages/offline_listing';

// import Login_FCM from './pages/fcm_login';

import './App.css';
import { HashRouter, Route,Switch } from "react-router-dom";

//const getBasename = path => path.substr(0, path.lastIndexOf('/'))

function App() {
  return (
    <div className="App">

      <HashRouter>
        <div>
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/" exact component={Login} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/edit_profile" exact component={EditProfile} />
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/leaderboard" exact component={Leaderboard} />
          <Route path="/leaderboard_b" exact component={Leaderboard_B} />
          <Route path="/leaderboard_new" exact component={Leaderboard_New} />
          <Route path="/otp_verification" exact component={OTP_Verification} />
          <Route path="/fit_five" exact component={Fit_Five} />
          <Route path="/registration" exact component={Registration} />
          <Route path="/id_upload" exact component={Aadhar_Upload} />
          <Route path="/introduction" exact component={Introduction} />
          <Route path="/physical_activity" exact component={PhysicalActivity} />
          <Route path="/physical_activity_B" exact component={PhysicalActivity_B} />
          <Route path="/physical_activity_C" exact component={PhysicalActivity_C} />
          <Route path="/physical_activity_D" exact component={PhysicalActivity_D} />
          <Route path="/physical_activity_E" exact component={PhysicalActivity_E} />
          <Route path="/physical_activity_F" exact component={PhysicalActivity_F} />
          <Route path="/exercise" exact component={Exercise} />
          <Route path="/nutrition" exact component={Nutrition} />
          <Route path="/nutrition2" exact component={Nutrition2} />
          <Route path="/nutrition3" exact component={Nutrition3} />
          <Route path="/nutrition4" exact component={Nutrition4} />
          <Route path="/hydration" exact component={Hydration} />
          <Route path="/hydration2" exact component={Hydration2} />
          <Route path="/hydration3" exact component={Hydration3} />
          <Route path="/hydration4" exact component={Hydration4} />
          <Route path="/covid_awarness" exact component={Covid_Awarness} />
          <Route path="/covid_awarness2" exact component={Covid_Awarness2} />
          <Route path="/covid_awarness3" exact component={Covid_Awarness3} />
          <Route path="/covid_awarness4" exact component={Covid_Awarness4} />
          <Route path="/covid_awarness5" exact component={Covid_Awarness5} />
          <Route path="/covid_awarness6" exact component={Covid_Awarness6} />
          <Route path="/test_yourself" exact component={TestYourself} />
          <Route path="/forgotp_otp_verification" exact component={ForgotP_OTP_Verification} />
          <Route path="/password_change" exact component={Password_Change} />
          

          {/* YA Routing */}
          <Route path="/ya_home" exact component={YA_Home} />
          <Route path="/ya_athletes" exact component={Ya_Athletes} />
          <Route path="/ya_program" exact component={YA_Program} />
          <Route path="/ya_equipments" exact component={Ya_Equipments} />
          <Route path="/ya_form1" exact component={YA_Form1} />
          <Route path="/ya_form2" exact component={YA_Form2} />
          <Route path="/ya_page6" exact component={YA_Page6} />
          <Route path="/ya_page7" exact component={YA_Page7} />
          <Route path="/ya_page8" exact component={YA_Page8} />
          <Route path="/ya_page9" exact component={YA_Page9} />
          <Route path="/ya_exercise" exact component={YA_Exercise} />
          <Route path="/ya_exercise_details" exact component={YA_Exercise_Details} />
          <Route path="/ya_view_exercise" exact component={YA_View_Exercise} />


          {/* Amrit Routing */}
          <Route path="/amrit_registration" exact component={Amrit_Registration} />
          <Route path="/amrit_after_registration" exact component={Amrit_After_Registration} />
          <Route path="/amrit_participants_officials" exact component={Amrit_Participants_Officials} />
          <Route path="/amrit_participants_volunteers" exact component={Amrit_Participants_Volunteers} />
          <Route path="/amrit_registration_status" exact component={Amrit_Registration_Status} />
          <Route path="/amrit_registration_status_sob" exact component={Amrit_Registration_Status_SOB} />
          <Route path="/amrit_chapter_participants" exact component={Amrit_Chapter_Participants} />
          <Route path="/amrit_dashboard" exact component={Amrit_Dashboard} />
          <Route path="/amrit_dashboardB" exact component={Amrit_Dashboard_B} />
          <Route path="/amrit_show_id" exact component={Amrit_Show_ID} />
          <Route path="/amrit_participants_resubmit" exact component={Amrit_Participants_Resubmit} />
          <Route path="/amrit_after_registration_offline" exact component={Amrit_After_Registration_Offline} />
          <Route path="/amrit_chapter_participants_details" exact component={Amrit_Chapter_Participants_Details} />
          <Route path="/amrit_medical_professional" exact component={Amrit_Medical_Professional} />
          <Route path="/amrit_form1_step1" exact component={Amrit_Form1_Step1} />
          <Route path="/amrit_form1_step2" exact component={Amrit_Form1_Step2} />
          <Route path="/amrit_special_smile_form" exact component={Amrit_Special_Smile} />
          <Route path="/amrit_checkin_checkout_status" exact component={Amrit_Checkin_Checkout_Status} />
          <Route path="/amrit_officials_dashboard" exact component={Amrit_Officials_Dashboard} />
          <Route path="/amrit_officials_show_id" exact component={Amrit_Officials_Show_ID} />
          <Route path="/amrit_athletes_show_id" exact component={Amrit_Athletes_Show_ID} />
          <Route path="/amrit_athletes_show_id" exact component={Amrit_Athletes_Show_ID} />
          <Route path="/amrit_volunteers_show_id" exact component={Amrit_Volunteers_Show_ID} />
          <Route path="/amrit_eyes_form1" exact component={Amrit_Eyes_Form1} />
          <Route path="/amrit_eyes_form2" exact component={Amrit_Eyes_Form2} />
          <Route path="/amrit_eyes_form3" exact component={Amrit_Eyes_Form3} />
          <Route path="/amrit_eyes_form4" exact component={Amrit_Eyes_Form4} />
          <Route path="/amrit_eyes_form5" exact component={Amrit_Eyes_Form5} />
          <Route path="/amrit_eyes_form6" exact component={Amrit_Eyes_Form6} />
          <Route path="/amrit_eyes_form7" exact component={Amrit_Eyes_Form7} />
          <Route path="/amrit_eyes_form8" exact component={Amrit_Eyes_Form8} />
          
          <Route path="/amrit_eyes_front_page" exact component={Amrit_Eyes_Front_Page} />


          {/* SOB Login */}
          <Route path="/offline_login" exact component={LoginSob} />
          <Route path="/offline_registration" exact component={RegistrationSob} />
          <Route path="/id_upload_sob" exact component={IdUploadSob} />
          <Route path="/amrit_registration_sob" exact component={Amrit_Registration_SOB} />
          <Route path="/amrit_participants_officials_sob" exact component={Amrit_Participants_Officials_SOB} />
          <Route path="/offline_listing" exact component={Offline_Listing} />

          {/* <Route path="/fcm_login" exact component={Login_FCM} /> */}


          </Switch>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
