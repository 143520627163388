import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Eyes_Form2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            participantId: "",
            divChekboxvalue: "hide",
            divvalue: "show",

            sport: "",
            visualAcuityUnableTestRE: "",
            visualAcuityValueRE: "",
            visualAcuityOptionRE: "",
            visualAcuityUnableTestLE: "",
            visualAcuityValueLE: "",
            visualAcuityOptionLE: "",
            visualAcuityUnableTestBE: "",
            visualAcuityValueBE: "",
            visualAcuityOptionBE: "",

            visualAcuityREOther: "",
            visualAcuityLEOther: "",
            visualAcuityBEOther: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            sob_scanId: sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();
        this.scanDetails();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
       // console.log(this.state.visualAcuityUnableTestLE);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.openingEyePageOne();
    }

    openingEyePageOne() {
        //console.log("I called");
        let dataToSend = {
             user_id: this.state.sob_scanId,
           
        };

        // console.log(this.state);

       // let sport_1 = this.state.sport != "" ? dataToSend["sport"] = this.state.sport : null;

        let visualAcuityUnableTestRE_1 = this.state.visualAcuityUnableTestRE != "" ? dataToSend['visualAcuityUnableTestRE'] = this.state.visualAcuityUnableTestRE : null;
        if (!this.state.visualAcuityUnableTestRE) {
            let visualAcuityValueRE_1 = this.state.visualAcuityValueRE != "" ? dataToSend['visualAcuityValueRE'] = this.state.visualAcuityValueRE : null;
        }
        let visualAcuityUnableTestLE_1 = this.state.visualAcuityUnableTestLE != "" ? dataToSend['visualAcuityUnableTestLE'] = this.state.visualAcuityUnableTestLE : null;
        if (!this.state.visualAcuityUnableTestLE) {
            let visualAcuityValueLE_1 = this.state.visualAcuityValueLE != "" ? dataToSend['visualAcuityValueLE'] = this.state.visualAcuityValueLE : null;
        }
        let visualAcuityUnableTestBE_1 = this.state.visualAcuityUnableTestBE != "" ? dataToSend['visualAcuityUnableTestBE'] = this.state.visualAcuityUnableTestBE : null;
        if (!this.state.visualAcuityUnableTestBE) {
            let visualAcuityValueBE_1 = this.state.visualAcuityValueBE != "" ? dataToSend['visualAcuityValueBE'] = this.state.visualAcuityValueBE : null;
        }

        if (this.state.visualAcuityOptionRE === "Other") {
            let visualAcuityOptionRE_1 = this.state.visualAcuityREOther != "" ? dataToSend['visualAcuityOptionRE'] = this.state.visualAcuityREOther : null;
        } else {
            let visualAcuityOptionRE_1 = this.state.visualAcuityOptionRE != "" ? dataToSend['visualAcuityOptionRE'] = this.state.visualAcuityOptionRE : null;
        }
        if (this.state.visualAcuityOptionLE === "Other") {
            let visualAcuityOptionLE_1 = this.state.visualAcuityLEOther != "" ? dataToSend['visualAcuityOptionLE'] = this.state.visualAcuityLEOther : null;
        } else {
            let visualAcuityOptionLE_1 = this.state.visualAcuityOptionLE != "" ? dataToSend['visualAcuityOptionLE'] = this.state.visualAcuityOptionLE : null;
        }
        if (this.state.visualAcuityOptionBE === "Other") {
            let visualAcuityOptionBE_1 = this.state.visualAcuityBEOther != "" ? dataToSend['visualAcuityOptionBE'] = this.state.visualAcuityBEOther : null;
        } else {
            let visualAcuityOptionBE_1 = this.state.visualAcuityOptionBE != "" ? dataToSend['visualAcuityOptionBE'] = this.state.visualAcuityOptionBE : null;
        }

        // console.log(dataToSend);

        axios
            .post(path, dataToSend, {
                 headers: { 'api-name': 'openingEyePageTwo', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
            })
            .then((serverRes) => {
                const res = serverRes;
               // console.log(res);

                if (res.data.status === true) {
                    this.props.history.push("/amrit_medical_professional");
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });


                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            });
    }

    scanDetails() {
        axios.post(path, {
            scannedId: this.state.sob_scanId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               // console.log(res.response)
                this.setState({
                    participant_list: res.response
                })

            }

        });
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" />
                                </div>
                                <div className="dbMenu">
                                <ul>
                                        <li className="active">
                                            <Link to="/amrit_medical_professional"><span>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/amrit_show_id"><span>
                                                <img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" />
                                            </span> Show ID</Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Athelete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg">
                                            <img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                        </span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="participantBg formstep">

                                    <img src={process.env.PUBLIC_URL + '/assets/images/openingeye.png'} alt="" />
                                    <form>
                                        <div className="hpTop">
                                            <div className="hpTxt hpTxtB">
                                                <p><strong>Name:</strong> <span>{this.state.participant_list.name}</span></p>
                                                <p><strong>Athlete ID:</strong> <span>{this.state.participant_list.atheleteId}</span></p>
                                                <p><strong>Date:</strong> <span>7th April,2022</span></p>
                                                <p><strong>Gender:</strong> <span>{this.state.participant_list.gender}</span></p>
                                                <p><strong>Contact No:</strong> <span></span></p>
                                            </div>
                                            <div className="hpTxt">
                
                                                <p><strong>Age:</strong> <span>{this.state.participant_list.age}</span></p>
                                                <p><strong>Event:</strong> <span>Amrit Mahotsav</span></p>
                                                <p><strong>Participant Type:</strong> <span>{this.state.participant_list.participantType}</span></p>
                                                <p><strong>Delegation:</strong> <span>{this.state.participant_list.delegation}</span></p>
                                                <p><strong>ICE No:</strong> <span>{this.state.participant_list['ICE No']}</span></p>
                                            </div>
                                        </div>
                                        {/* <h5>Sport</h5>
                                        <div className="newSelect">
                                            <select name="sport" onChange={this.handleChange}>
                                                <option className="slRedbg" value="">Select Sport</option>
                                                <option value="Cricket">Cricket</option>
                                                <option value="Football">Football</option>
                                                <option value="Hockey">Hockey</option>

                                            </select>
                                        </div> */}
                                        <div className="compositionBg">
                                            <h3>History</h3>
                                            <h4>Visual Acuity (FAR): RIGHT EYE</h4>
                                            <div className="stpSpdrink frmStpMain">
                                                <div className="unabletoTest stpBcheck frmStpd">
                                                    <span className="minWdt noPdnBtm"> <input type="checkbox" name="visualAcuityUnableTestRE" onChange={this.handleChange} /> Unable to test</span>
                                                </div>
                                                {
                                                    !this.state.visualAcuityUnableTestRE &&
                                                    <>
                                                        <span className="stp20">20/</span>
                                                        <input type="number" className="form-control" name="visualAcuityValueRE" onChange={this.handleChange} />
                                                    </>
                                                }
                                            </div>
                                            {
                                                !this.state.visualAcuityUnableTestRE &&
                                                <>
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="radioLft"><input type="radio" id="test1" name="visualAcuityOptionRE" onChange={this.handleChange} value="Lea" /><label for="test1">Lea</label></span>
                                                        <span className="radioLft"><input type="radio" id="test2" name="visualAcuityOptionRE" onChange={this.handleChange} value="Walk up" /><label for="test2">Walk up</label></span>
                                                        <span className="radioLft"><input type="radio" id="test3" name="visualAcuityOptionRE" onChange={this.handleChange} value="Light projection/Light perception" /><label for="test3">Light projection/Light perception</label></span>
                                                        <span className="radioLft"><input type="radio" id="test4" name="visualAcuityOptionRE" onChange={this.handleChange} value="No light perception" /><label for="test4">No light perception</label></span>
                                                    </div>
                                                    <div className="stpSpdrink">
                                                        <div className="unabletoTest stpBcheck frmStpd">
                                                            <span className="radioLft"><input type="radio" id="test5" name="visualAcuityOptionRE" onChange={this.handleChange} value="Other" /><label className="noMrgnBtm" for="test5">Other</label></span>
                                                        </div>
                                                        {
                                                            this.state.visualAcuityOptionRE === "Other" &&
                                                            <>
                                                                <input type="text" name="visualAcuityREOther" onChange={this.handleChange} className="form-control" />
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }

                                            <h4>Visual Acuity (FAR): LEFT EYE</h4>
                                            
                                            <div className="stpSpdrink frmStpMain">
                                                <div className="unabletoTest stpBcheck frmStpd">
                                                    <span className="minWdt noPdnBtm"> <input type="checkbox" name="visualAcuityUnableTestLE" onChange={this.handleChange} /> Unable to test</span>
                                                </div>
                                                {
                                                    (!this.state.visualAcuityUnableTestLE) &&
                                                    <>
                                                        <span className="stp20">20/</span>
                                                        <input type="number" className="form-control" name="visualAcuityValueLE" onChange={this.handleChange} />
                                                    </>
                                                }
                                            </div>
                                            {
                                                !this.state.visualAcuityUnableTestLE &&
                                                <>
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="radioLft"><input type="radio" id="test1" name="visualAcuityOptionLE" onChange={this.handleChange} value="Lea" /><label for="test1">Lea</label></span>
                                                        <span className="radioLft"><input type="radio" id="test2" name="visualAcuityOptionLE" onChange={this.handleChange} value="Walk up" /><label for="test2">Walk up</label></span>
                                                        <span className="radioLft"><input type="radio" id="test3" name="visualAcuityOptionLE" onChange={this.handleChange} value="Light projection/Light perception" /><label for="test3">Light projection/Light perception</label></span>
                                                        <span className="radioLft"><input type="radio" id="test4" name="visualAcuityOptionLE" onChange={this.handleChange} value="No light perception" /><label for="test4">No light perception</label></span>
                                                    </div>
                                                    <div className="stpSpdrink">
                                                        <div className="unabletoTest stpBcheck frmStpd">
                                                            <span className="radioLft"><input type="radio" id="test5" name="visualAcuityOptionLE" onChange={this.handleChange} value="Other" /><label className="noMrgnBtm" for="test5">Other</label></span>
                                                        </div>
                                                        {
                                                            this.state.visualAcuityOptionLE === "Other" &&
                                                            <>
                                                                <input type="text" name="visualAcuityLEOther" onChange={this.handleChange} className="form-control" />
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }

                                            <h4>Visual Acuity (NEAR): BOTH EYE</h4>
                                            <div className="stpSpdrink frmStpMain">
                                                <div className="unabletoTest stpBcheck frmStpd">
                                                    <span className="minWdt noPdnBtm"> <input type="checkbox" name="visualAcuityUnableTestBE" onChange={this.handleChange} /> Unable to test</span>
                                                </div>
                                                {
                                                    !this.state.visualAcuityUnableTestBE &&
                                                    <>
                                                        <span className="stp20">20/</span>
                                                        <input type="number" className="form-control" name="visualAcuityValueBE" onChange={this.handleChange} />
                                                    </>
                                                }
                                            </div>
                                            {
                                                !this.state.visualAcuityUnableTestBE &&
                                                <>
                                                    <div className="unabletoTest stpBcheck">
                                                        <span className="radioLft"><input type="radio" id="test1" name="visualAcuityOptionBE" onChange={this.handleChange} value="Lea" /><label for="test1">Lea</label></span>
                                                        <span className="radioLft"><input type="radio" id="test2" name="visualAcuityOptionBE" onChange={this.handleChange} value="Walk up" /><label for="test2">Walk up</label></span>
                                                        <span className="radioLft"><input type="radio" id="test3" name="visualAcuityOptionBE" onChange={this.handleChange} value="Light projection/Light perception" /><label for="test3">Light projection/Light perception</label></span>
                                                        <span className="radioLft"><input type="radio" id="test4" name="visualAcuityOptionBE" onChange={this.handleChange} value="No light perception" /><label for="test4">No light perception</label></span>
                                                    </div>
                                                    <div className="stpSpdrink">
                                                        <div className="unabletoTest stpBcheck frmStpd">
                                                            <span className="radioLft">
                                                                <input type="radio" id="test5" name="visualAcuityOptionBE" onChange={this.handleChange} value="Other" />
                                                                <label className="noMrgnBtm" for="test5">Other</label>
                                                            </span>
                                                        </div>
                                                        {
                                                            (this.state.visualAcuityOptionBE === "Other") &&
                                                            <>
                                                                <input type="text" name="visualAcuityBEOther" onChange={this.handleChange} className="form-control" />
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="intBtn">
                                            <Link to="/amrit_eyes_front_page" className="intbtnb">Back</Link>
                                            <button type="submit" className="backtohome" style={{ width: "50%" }} onClick={this.handleSubmit}>Submit</button><br /><br />

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Amrit_Eyes_Form2;