import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
Chart.register(ArcElement);


function DoughnutChart(props) {
    let nameArr = [], numberArr = [], colorArr = [];
    props.categoryArr.map(eactCat => {
        nameArr.push(eactCat.category_name);
        numberArr.push(eactCat.category_number)
        colorArr.push(eactCat.category_color)
    })


    const data = {
        labels: nameArr,
        datasets: [
            {
                label: 'test 1',
                data: numberArr,
                borderColor: ['rgba(255,206,86,0.2)'],
                backgroundColor: colorArr,
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
                //hoverOffset: 4

            }

        ]
    }

    const options = {
        plugins: {
            title: {
                display: true,
                // font: {
                //     size:34
                // },

                padding: {
                    top: 30,
                    bottom: 30
                },
                responsive: true,
                animation: {
                    animateScale: true,
                    color: true
                },

                tooltip: {
                    enabled: false

                }

            }, legend: { display: false },
        }

    }


    return (
        <div className="chartMainInn">
            <Doughnut data={data} options={options} />
        </div>
    )
}

export default DoughnutChart
