import React from "react";
import NewHeader from "../components/newheader";
import CoachComponent from "../components/coachComponent";
import CountdownComponent from "../components/countdownComponent";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
//import {osName,osVersion,browserName,deviceType} from 'react-device-detect';
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coach_details: [{
                id: '1',
                image_url: process.env.PUBLIC_URL + '/assets/images/1.png',
                title: 'ANIL SETH',
                description: 'Our coach since 5 years, he has brought great transformation in our athletes',
            },
            {
                id: '2',
                image_url: process.env.PUBLIC_URL + '/assets/images/2.png',
                title: 'YASH CHOUDARY',
                description: 'Our coach since 5 years, he has brought great transformation in our athletes',
            },
            {
                id: '3',
                image_url: process.env.PUBLIC_URL + '/assets/images/3.png',
                title: 'ANIL SETH',
                description: 'Our coach since 5 years, he has brought great transformation in our athletes',
            }],
            userId: "",
            AuthToken: "",
            trainingDetails_list: [],
            profilePic: "",
            participantUrl: false,
            days: 0,
            minutes: 0,
            hours: 0,
            seconds: 0,
            modalState: true,
            name:""


        };
        this.handleShow = this.handleShow.bind(this);


    }


    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')

        //if(userId && userId.hasOwnProperty('userId')){
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        // }
        // console.log("user"+this.state.userId);
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getTrainings();
        this.getProfile();
        this.getRegistrationDetails();
    }

    handleShow() {
        this.setState({ modalState: !this.state.modalState });
    }

    getTrainings() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getTrainings', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {

                const TrainingDetails = res.response.traning;
                // console.log(TrainingDetails);  


                // var newArray = TrainingDetails;
                // var index = [1, 0, 2];
                // var n = newArray.length;
                // console.log('newArray-'+TrainingDetails);
                // console.log('index-'+index);
                // console.log('n-'+n);
                // var convert_array=this.reorder(newArray,index,n)
                // console.log(convert_array);

                //console.log(TrainingDetails[2].traningStartDateTime)



                this.setState({
                    trainingDetails_list: TrainingDetails


                });
            }

        });
    }




    getProfile() {
        // var os_Name=osName;
        // console.log('osName-'+os_Name);
        // var os_Version=osVersion;
        // console.log('osVersion-'+os_Version);
        // var browser_Name=browserName;
        // console.log('browserName-'+browser_Name);
        // var device_Type=deviceType;
        // console.log('deviceType-'+device_Type);
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                this.setState({
                    profilePic: ProfileDetails.profilePic + "?",
                    name: ProfileDetails.name,
                });
            }
            // console.log(this.state.profilePic)
        });
    }

    //   participantRegStatus() {
    //     axios.post(path + "participantRegStatus", {

    //     }, {
    //         headers: { 'Api-Name': 'participantRegStatus', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
    //     }).then(serverResponse => {
    //         const res = serverResponse.data;
    //         if (res.status === true) {
    //             const ParticipantDetails = res.response;
    //             //console.log(ParticipantDetails);
    //             if(ParticipantDetails.isParticipating===true){
    //                 const ParticipantUrl='\amrit_after_registration';
    //                 this.setState({
    //                     participantUrl: ParticipantUrl
    //                 });
    //             }else{
    //                 const ParticipantUrl='\amrit_registration'
    //                 this.setState({
    //                     participantUrl: ParticipantUrl
    //                 });
    //             }

    //         }
    //     });
    // }

    getRegistrationDetails() {
        axios.post(path, {
            participantType: "",
            eventId: "61c95e248335ae7cb8df1699"
        }, {
            headers: { 'api-name': 'getRegistrationDetails', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res.response.data);
            if (res.response.data !== undefined) {
                const ParticipantUrl = '\amrit_after_registration';
                this.setState({
                    participantUrl: ParticipantUrl
                });
            } else {
                const ParticipantUrl = '\amrit_registration'
                this.setState({
                    participantUrl: ParticipantUrl
                });
            }
        });
    }




    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });

        return (
            <div>
                <NewHeader {...this.props} />
                <section className="contentTop">&nbsp;</section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                    <div className="profileBg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></div>
                                    <h2 className="mainHdn">Training</h2>
                                    <div className="resCarousel homeCarousel" data-items="1-2-3-3" data-slide="3" data-speed="900" data-animator="lazy">
                                        <div className="resCarousel-inner">

                                            {this.state.trainingDetails_list.map((eachtrainingDetails, i) => (
                                                <div className="item" key={i}>
                                                    {/* data-toggle="modal" data-target="#myModal1" */}
                                                    {/* {eachtrainingDetails.isDisable === true ? 
                                                        <div className="tile">
                                                            <img src={path+eachtrainingDetails.icon} alt="" />
                                                            {eachtrainingDetails.isDisable === true ? <h6>{eachtrainingDetails.traningName}</h6> : ''}
                                                        </div>

                                               
                                                    : */}
                                                    {eachtrainingDetails._id === "60a64678190b917dd724c910" ?
                                                        <Link to={

                                                            {
                                                                pathname: '/fit_five',
                                                                state: {
                                                                    trainingId: eachtrainingDetails._id
                                                                }
                                                            }
                                                        }>

                                                            <div className="tile active">
                                                                <img src={path + eachtrainingDetails.icon} alt="" />

                                                                <CountdownComponent isVisible={eachtrainingDetails.isTimerStart} futureDate={eachtrainingDetails.traningStartDateTime} />



                                                            </div>
                                                        </Link>
                                                        : (eachtrainingDetails._id === "60a647b1190b917dd724c911" ?
                                                            <Link to={

                                                                {
                                                                    pathname: '/ya_home',
                                                                    state: {
                                                                        yaTraningId: eachtrainingDetails._id
                                                                    }
                                                                }
                                                            }>
                                                                <div className="tile active">
                                                                    <img src={path + eachtrainingDetails.icon} alt="" />
                                                                    <CountdownComponent isVisible={eachtrainingDetails.isTimerStart} futureDate={eachtrainingDetails.traningStartDateTime} />

                                                                </div>
                                                            </Link>
                                                            :

                                                            <Link to={

                                                                {
                                                                    pathname: `${this.state.participantUrl}`,
                                                                }
                                                            }>
                                                                <div className="tile active">
                                                                    <img src={path + eachtrainingDetails.icon} alt="" />
                                                                    <CountdownComponent isVisible={eachtrainingDetails.isTimerStart} futureDate={eachtrainingDetails.traningStartDateTime} />
                                                                </div>
                                                            </Link>
                                                        )
                                                    }
                                                    {/* } */}

                                                </div>
                                            ))}


                                        </div>
                                        <button className='btn btn-default leftRs nwleftrs'><i className="fas fa-chevron-left"></i></button>
                                        <button className='btn btn-default rightRs nwleftrs'><i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contentB">
                                    <h2 className="mainHdn">Special Olympics Athletes in Action</h2>
                                    <div className="cardSection">

                                        {this.state.coach_details.map((eachcoachDetails, i) => (
                                            // <div className="card">
                                            // <img className="card-img-top" src={eachcoachDetails.image_url} alt="Card image cap"/>
                                            // <div className="card-body">
                                            //     <h5 className="card-title">{eachcoachDetails.title}</h5>
                                            //     <p className="card-text">{eachcoachDetails.description}</p>
                                            //     <a href="#" class="readMore">Read more</a>

                                            // </div>
                                            // </div>
                                            <CoachComponent
                                                key={i}
                                                image_url={eachcoachDetails.image_url}
                                            // title={eachcoachDetails.title}
                                            // description={eachcoachDetails.description}
                                            />
                                        ))}
                                    </div>
                                    {/* <div className="loadMore">Load More...</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Add-Modal */}
                <div className={"modal addModal fade" + (this.state.modalState ? " show d-block" : " d-none")} id="myModal1" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content" style={{backgroundColor:"#8ac37c"}}>
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleShow}><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                <h4>Amrit Mahotsav</h4>
                                <div className="specialImage" style={{backgroundColor:"white"}}><img src={process.env.PUBLIC_URL + '/assets/images/amritLogo.png'} alt="" /></div>
                               <div className="curvMnTxt" style={{paddingLeft:45,paddingRight:30,paddingTop:10,paddingBottom:10,textAlign:"left"}}>
                               <p style={{fontSize:18,color:"black"}}>Welcome <span style={{fontStyle:"bold"}}>{this.state.name}</span>,<br></br>
                               Congratulations on registering on the Special Olympics Bharat Application,
                               Please enter here to COMPLETE registration for Amrit Mahotsav.</p>
                               </div>
                                <div className="intBtn">
                                   
                                    <Link to={{pathname: `${this.state.participantUrl}`}}  className="intbtnb">Enter</Link>
                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        )
    }
}
export default Home;