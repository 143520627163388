import React from "react";
import Header from "../components/header";
class Leaderboard_B extends React.Component {
    render() {
        return (
            <div>
                <Header {...this.props} />
                <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5">
                                        <img src="images/fitb.png" alt="" />
                                        <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
                                    <div className="fitTopImg">
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit1.png'} alt="" />
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit2.png'} alt="" />
                                        <img src={process.env.PUBLIC_URL + '/assets/images/fit3.png'} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                    <ul className="lbLeaderBg">
                                        <li>
                                            <div className="leaderMain">
                                                <div className="intra intr_a">
                                                    <span>01.</span>
                                                    <span>Introduction to FIT 5 </span>
                                                </div>
                                                <span className="leadNumberB"><i className="fas fa-play-circle"></i></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="leaderMain">
                                                <div className="intra intr_b">
                                                    <span>01.</span>
                                                    <span>Introduction to FIT 5 </span>
                                                </div>
                                                <span className="leadNumberB"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="leaderMain">
                                                <div className="intra intr_c">
                                                    <span>01.</span>
                                                    <span>Introduction to FIT 5 </span>
                                                </div>
                                                <span className="leadNumberB"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="leaderMain">
                                                <div className="intra intr_d">
                                                    <span>01.</span>
                                                    <span>Introduction to FIT 5 </span>
                                                </div>
                                                <span className="leadNumberB"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="leaderMain">
                                                <div className="intra intr_e">
                                                    <span>01.</span>
                                                    <span>Introduction to FIT 5 </span>
                                                </div>
                                                <span className="leadNumberB"><i className="fas fa-lock"></i></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Leaderboard_B;