import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//import Scanner from "react-webcam-qr-scanner";
import ScannerComponent from "../components/scannerComponent";
import Socket from "../components/socket";
var path = apiPath.path;

class Amrit_Medical_Professional extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            search: "",
            scannedId: "",
            participantId: "",
            shouldUseCamera: false,
            medical_type: ""

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        const participant_id = await localStorage.getItem('participant_id')
        localStorage.removeItem("SOB_scanId");
        //  const sob_scanId = await localStorage.getItem('SOB_scanId')
        if (participant_id === null) {
            localStorage.setItem('participant_id', this.props.location.state.participant_id)
        }
        const updated_participant_id = await localStorage.getItem('participant_id')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
            participantId: updated_participant_id,
            // scannedId:sob_scanId
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event) {
        //console.log(this.state);

        this.scanAMID();
        event.preventDefault();
    }

    scanAMID() {
        axios.post(path, {
            scannedId: this.state.scannedId,
            deviceType: "web",
            scanType: "medical",
            participantId: this.state.participantId

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res)
            if (res.status === true) {
                localStorage.setItem("SOB_scanId", this.state.scannedId);
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                if (res.response.scannerMedicalProgramme === "Health Promotion (General Health)") {
                    if (res.response.pageDone === "0") {
                        this.props.history.push("/amrit_form1_step1");
                    } else if (res.response.pageDone === "1") {
                        this.props.history.push("/amrit_form1_step2");
                    } else {
                        this.props.history.push("/amrit_form1_step1");
                    }
                } else if (res.response.scannerMedicalProgramme === "Special Smiles (Dentist)") {
                  this.props.history.push("/amrit_special_smile_form");
                   //this.props.history.push("/amrit_eyes_front_page");
                   
                }else if (res.response.scannerMedicalProgramme === "Opening Eyes (Eye Specialist)") {
                    this.props.history.push("/amrit_eyes_front_page");
                 }

            }
            else {
               // document.getElementById("showfilterModal").click();
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }

    getQrVal = (qrVal) => {
        // console.log("Printing from parent",qrVal);
        this.setState({
            scannedId: qrVal,
            
        })
        //   document.getElementById("confirmModal").classList.add("show", "d-block");
        document.getElementById("showConfirmModal").click();
        // this.scanAMID();

    }



    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    cameraPermission() {
        this.setState({
            shouldUseCamera: true
        })
    }

    // scannerMedicalProgramme
     handleCloseModal(){
       // document.getElementById("filterModal").classList.remove("show");
        document.getElementById("filterModal").classList.remove("show", "d-block");
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });

        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritMed">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"><Link to="/amrit_medical_professional"><span><img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Scan Athlete</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="participantBg">
                                    <div className="medicalProfBg volunteerBg">
                                        <h5>Scan</h5>
                                        <div className="volBlock">
                                            <button className="backtohome" data-toggle="modal" data-target="#filterModal1" onClick={(e) => this.cameraPermission()}>Scan QR Code</button><span>Or</span>
                                            <input type="text" name="scannedId" className="form-control" placeholder="Enter the Athlete ID" required onChange={this.handleChange}/>
                                            <button  className="volSubmit" onClick={this.handleSubmit}>Submit</button>
                                        </div>
                                    </div>
                                  

                                    <br /><br />
                                    <div className="intBtn">
                                    <Link to="/amrit_after_registration" className="intbtnb">Back</Link>

                                </div>
                                </div>


                                
                            </div>

                        </div>
                    </div>
                </section>
                <button type="button" id="showConfirmModal" data-toggle="modal" data-target="#confirmModal" style={{ display: "none" }} >Hidden Button</button>

                <Socket getQrVal={this.getQrVal} />


                <button type="button" id="showfilterModal" data-toggle="modal" data-target="#filterModal" style={{ display: "none" }} >Hidden Button</button>
                {/* <!-- Modal --> */}


                <div className="modal fade modal-center" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.handleCloseModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">
                                <div className="unhappy"><img src={process.env.PUBLIC_URL + '/assets/images/unhappy.png'} alt="" /></div>
                                <h3>Sorry your Athlete ID is not valid.<br />Please try again</h3>
                                <div className="filterModalSubmit"><button type="submit" data-dismiss="modal" className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                <div className="modal fade modal-center " id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">

                                <h3>Do you want to continue?</h3>

                                <div className="filterModalSubmit"><button type="submit" data-dismiss="modal" className="btn btn-primary" onClick={(e) => this.scanAMID(e)}>Submit</button></div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* <!-- Modal --> */}
                {this.state.shouldUseCamera ?
                    (<div className="modal fade modal-center " id="filterModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel3" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 style={{ textAlign: "center" }}> SCAN QR CODE</h3>
                                    <button type="button" id="closeModalBtn" className="close" data-dismiss="modal" onClick={() => this.setState({ shouldUseCamera: false })} aria-label="Close">

                                        <span aria-hidden="true">&times;</span>

                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="scannerWrapper">
                                        {/* {this.state.shouldUseCamera? */}
                                        <ScannerComponent />
                                        {/* :null}                   */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>)
                    : null}





            </div>
        )
    }
}
export default Amrit_Medical_Professional;