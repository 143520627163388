import React from "react";
import Header from "../components/header";
import ButtonComponent from "../components/buttonComponent";
class PhysicalActivity extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop dbBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Introduction to FIT 5 </h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>Physical Activity</h4>
                                <ul className="physicalBullet">
                                    <li>Staying active can keep your body healthy and strong so you are ready when sports practice starts again. </li>
                                    <li>Do things that make your heart beat faster and make you breathe harder. </li>
                                    <li>There are many ways to be active at or near the home, even while practising social distancing!</li>
                                </ul>
                                <div className="distanceBox">
                                        <div className="distncLft"><img src={process.env.PUBLIC_URL + '/assets/images/grp.jpg'} alt=""/></div>
                                        <div className="distncRgt">
                                            <img src={process.env.PUBLIC_URL + '/assets/images/grp2.jpg'} alt=""/>
                                            <div className="distnPop">
                                                <h3>Social distancing</h3>
                                                <p>means stay 6 feet or 2 meters away from others</p>
                                            </div>
                                        </div>
                                    </div>
                                    <ButtonComponent {...this.props}/>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default PhysicalActivity;