import { blue } from "@material-ui/core/colors";
import React from "react";



class chartLegend extends React.Component {
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <div style={{"textAlign":"center"}}>
                <span>
                {this.props.categoryArr.map((eachCat,i) => (              
                        
                        <div className="graphLegendBlock" key={i}
                        style={{"backgroundColor":eachCat.category_color}}
                       
                        >
                            {eachCat.category_name} {eachCat.category_number}
                        </div>
                   
                ))}
                </span>
            </div>
        )
    }
}
export default chartLegend;