import React from "react";
import Header from "../components/header";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            name: "",
            rank: "",
            xp: "",
            profileImg: "",
            leaderboard_list: [],
            profilePic:"",
            dummy_user: "",
        };

    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')

        //if(userId && userId.hasOwnProperty('userId')){
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        // }
        this.getProfile();
        this.getLeaderboard();
    }


    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ProfileDetails = res.response;
                this.setState({
                    name: ProfileDetails.name,
                    rank: ProfileDetails.rank,
                    xp: ProfileDetails.xp,
                    profilePic:ProfileDetails.profilePic+"?"
                });
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }


    getLeaderboard() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getLeaderboard', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse["data"];
            if (res.status === true) {
                //console.log(res);
                const LeaderboardDetails = res["response"]["userList"];
                //console.log(LeaderboardDetails);

                this.setState({
                    leaderboard_list: LeaderboardDetails
                });
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                <Header {...this.props} />
                <section className="contentTop">&nbsp;</section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                    <div className="profileBg"><img src={process.env.PUBLIC_URL + '/assets/images/score2.png'} alt="" /></div>
                                    <Link to="/home" className="backBtn">Back</Link>
                                    <h2 className="mainHdn">LEADERBOARD</h2>
                                    <ul className="lbLeaderBg">
                                        <li className="active">
                                            <span className="numb">{this.state.rank}</span>
                                            <div className="leaderMain">
                                                <span><img src={this.state.profilePic} alt="" /><span>{this.state.name}</span></span>
                                                <span className="leadNumber">{this.state.xp}</span>
                                            </div>
                                        </li>
                                        {this.state.leaderboard_list.map((eachLeader, index) => (
                                            <li key={index}>
                                                <span className="numb">{eachLeader.rank}</span>
                                                <div className="leaderMain">
                                               
                                                    <span> {eachLeader.userDetails.hasOwnProperty('profilePic') ?
                                                    //  <img  src={eachLeader.userDetails.profilePic} alt="" />
                                                     <img src={eachLeader.userDetails.profilePic+"?"} onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL + '/assets/images/userimg.png'}} alt=""/>
                                                    : <img  src={process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" />
                                                    }
                                                    <span>{eachLeader.userDetails.name}</span></span>
                                                   
                                                    <span className="leadNumber">{eachLeader.xp}</span>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Leaderboard;