import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class Covid_Awarness6 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className="contentTop covidBg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>Covid Awareness</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
           
                                    {/* <div className="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
           
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">   
                                                                        
                                <div className="covidB">
                                    <h4>6 easy ways to wash hands</h4> 
                                    <div className="covidSpreadBgB covidSpreadBgC">
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen1.png'} alt=""/></div>
                                            <span className="blueCircle">1</span>
                                            <span>WET YOUR HANDS</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen2.png'} alt=""/></div>
                                            <span className="blueCircle">2</span>
                                            <span>APPLY SOAP</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen3.png'} alt=""/></div>
                                            <span className="blueCircle">3</span>
                                            <span>WASH YOUR HANDS FOR 20 SECONDS</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen4.png'} alt=""/></div>
                                            <span className="blueCircle">4</span>
                                            <span>RINSE WELL</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen5.png'} alt=""/></div>
                                            <span className="blueCircle">5</span>
                                            <span>DRY YOUR HANDS</span>
                                        </div>
                                        <div className="spreadBlogB">
                                            <div className="spreadImgB"><img src={process.env.PUBLIC_URL + '/assets/images/spreadgreen6.png'} alt=""/></div>
                                            <span className="blueCircle">6</span>
                                            <span>TURN OFF WATER WITH PAPER TOWEL</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                

            </div>
        )
    }
}
export default Covid_Awarness6;