import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PaginationComponent from "../components/pagination";

import apiPath from '../constant';
var path = apiPath.path;

class Offline_Listing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      email: "",
      name: "",
      user_list: [],
      adminId: "",
      currentPage: 1,
      adminname: ""



    };



  }

  loadData = (type) => {
    let cp = this.state.currentPage
    if (type === "P") {
      if (cp > 1) {
        cp = cp - 1
      }
    }
    if (type === "N") {
      cp = cp + 1
    }

    this.offlineDashboard(cp);
  }

  async componentDidMount() {
    localStorage.removeItem("SOB_name");
    localStorage.removeItem("SOB_userId");
    localStorage.removeItem("SOB_idType");

    window.history.pushState(null, null, document.URL);
    console.log(document.URL)
    window.addEventListener('popstate', function (event) {
        //   window.location.replace("/amrit_medical_professional");
        // history.push("/amrit_medical_professional");
        window.history.forward();
        
    });

    
    // Get userdetails from localstorage
    const adminId = await localStorage.getItem('SOB_adminId')
    const adminname = await localStorage.getItem('SOB_adminname')
    this.setState({
      adminId: adminId,
      adminname: adminname
    })
    if (this.state.adminId === null) {
      this.props.history.push("/offline_login");
    }
    this.offlineDashboard(1);

  }
  offlineDashboard(currentPageNum) {
    axios.post(path, {
      page_no: currentPageNum,
      records_per_page: 25
    }, {
      headers: { 'Api-Name': 'offlineDashboard', 'AdminId': this.state.adminId }
    }).then(serverResponse => {
      const res = serverResponse.data;
      if (res.status === true) {

        const UserDetails = res.response && res.response.userList;
        //console.log(UserDetails);

        this.setState({
          user_list: UserDetails,
          currentPage: currentPageNum
        });

      }

    });
  }




  render() {
    toast.configure({
      autoClose: 3000,
      draggable: false,
    });

    return (
      <div className="fullHeightReg">
        <section className="amritRegd">
          <div className="amritRegdInn">
            <div className="amritRegdBlock amritAfterregd amritDashboard">
              <div className="dbLft">
                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                <div className="">
                  <ul>
                    <li>
                      <h5 style={{ padding: 20, color: "green" }} className="menudisplay">List Of Participants Added By {this.state.adminname}</h5>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dbRgt">
                <div className="dbHead">
                  <h5></h5>

                  <Link to="/offline_registration"><button className="backtohome">Enroll A User</button></Link>

                </div>
                <div className="dbfilter">


                </div>
                <div className="participantTable">
                  <div className="participantTableB" id="results">
                    <div className='theader'>
                      <div className='table_header'>Participats Name</div>
                      <div className='table_header'>Email</div>
                      <div className='table_header'>Status</div>
                    </div>

                    {this.state.user_list.map((eachuserDetails, i) => (


                      <div className='table_row' key={i}>
                        <div className='table_small'>
                          <div className='table_cell'>Participats Name</div>
                          <div className='table_cell'>
                            <div className="boxProfile"><img src={eachuserDetails.facePhoto ? eachuserDetails.facePhoto : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /> <span>{eachuserDetails.name}</span></div>
                          </div>
                        </div>
                        <div className='table_small'>
                          <div className='table_cell'>Email</div>
                          <div className='table_cell'>{eachuserDetails.email}</div>

                        </div>
                        <div className='table_small'>
                          <div className='table_cell'>Status</div>
                          {eachuserDetails.isRejected === true ?
                            <div className='table_cell rejected'>Rejected</div>
                            : (eachuserDetails.isApproved === true ?

                              <div className='table_cell approved'>
                                <Link style={{ textDecoration: "none",color:"#4DBA6D" }} to={

                                  {
                                    pathname: '/amrit_after_registration_offline',
                                    state: {
                                      SOB_userId: eachuserDetails.userId,
                                      SOB_name: eachuserDetails.name
                                    }
                                  }
                                }>
                                  Approved
                                </Link>
                              </div>

                              : (eachuserDetails.isAadharUploaded === false && eachuserDetails.isVerified === false ?

                                <div className='table_cell incomplete'>
                                  <Link style={{ textDecoration: "none" }} to={

                                    {
                                      pathname: '/id_upload_sob',
                                      state: {
                                        SOB_userId: eachuserDetails.userId,
                                        SOB_idType: eachuserDetails.idType,
                                        SOB_name: eachuserDetails.name
                                      }
                                    }
                                  }>
                                    Incomplete
                                  </Link>
                                </div>

                                :
                                (eachuserDetails.isComplete === false ?

                                  <div className='table_cell incomplete'>
                                    <Link style={{ textDecoration: "none" }}
                                      to={

                                        {
                                          pathname: '/amrit_registration_sob',
                                          state: {
                                            SOB_userId: eachuserDetails.userId,
                                            SOB_name: eachuserDetails.name
                                          }
                                        }
                                      }>
                                      Incomplete
                                    </Link>
                                  </div>

                                  :
                                  <div className='table_cell approvalPending'>Approval Pending</div>
                                )))}
                        </div>

                      </div>



                    ))}

                    <div>

                    </div>

                  </div>

                </div>
                <div className="row paginationright">

                  <PaginationComponent loadData={this.loadData} pageNumber={this.state.currentPage} />
                </div>
              </div>

            </div>
          </div>

        </section>

      </div>
    )
  }
}
export default Offline_Listing;