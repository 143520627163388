import React from "react";
import NewHeader from "../components/newheader";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            name: "",
            dob: "",
            age: "",
            email: "",
            gender: "",
            countryCode: "",
            phone: "",
            userType: "",
            institution: "",
            experience_years:"",
            experience_months:"",
            flat_room: "",
            building_house: "",
            streetName: "",
            locality: "",
            city_town_village: "",
            state: "",
            rank: "",
            xp:"",
            profilePic:"",
            aadhaarNo:""
        };

    }

    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')

        //if(userId && userId.hasOwnProperty('userId')){
        this.setState({
            userId: userId,
            AuthToken: AuthToken
        })
        // }
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getProfile();
    }

    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;

                //console.log(res.response.address);
                // const address_array = Object.keys(res.response.address).length === 0 ? "":JSON.parse(res.response.address);
                // const experience_array =Object.keys(res.response.experience).length === 0 ?"":JSON.parse(res.response.experience);
                //console.log(res.response.address);

                const address_array = res.response.address;
                const experience_array =res.response.experience;

               // console.log(address_array);
                
                //Age calculate from dob
                // var today = new Date();
                // var birthDate = new Date(ProfileDetails.dob);  // create a date object directly from `dob1` argument
                // var age_now = today.getFullYear() - birthDate.getFullYear();
                // var m = today.getMonth() - birthDate.getMonth();
                // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                //     age_now--;
                // }
                //console.log('age'+age_now);

                this.setState({
                    name: ProfileDetails.name,
                    dob: ProfileDetails.dob,
                    //age: age_now,
                    email: ProfileDetails.email,
                    gender: ProfileDetails.gender,
                    countryCode: ProfileDetails.countryCode,
                    phone: ProfileDetails.phone,
                    userType: ProfileDetails.userType,
                    institution: ProfileDetails.institution,
                    experience_months: Object.keys(experience_array).length !== 0 && experience_array.hasOwnProperty('months')?experience_array.months:"0",
                    experience_years: Object.keys(experience_array).length !== 0 && experience_array.hasOwnProperty('years')?experience_array.years:"0",
                    flat_room: Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('flat_room')? address_array.flat_room:"",
                    building_house: Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('building_house')?address_array.building_house:"",
                    streetName: Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('streetName')?address_array.streetName:"",
                    locality: Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('locality')?address_array.locality:"",
                    city_town_village:Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('city_town_village')? address_array.city_town_village:"",
                    state: Object.keys(address_array).length !== 0 && address_array.hasOwnProperty('state')?address_array.state:"",
                    rank: ProfileDetails.rank,
                    xp: ProfileDetails.xp,
                    profilePic:ProfileDetails.profilePic+"?",
                    aadhaarNo:ProfileDetails.aadhaarNo
                });
               
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });
        return (
            <div>
                <NewHeader {...this.props} />
                <section className="contentTop">&nbsp;</section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent">
                                    <div className="profileBg"><img src={this.state.profilePic!==""?this.state.profilePic:process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></div>
                                    <h2 className="mainHdn">ALL TIME SCORE</h2>
                                    <div className="resCarousel profCaros" data-items="1-2-3-3" data-slide="3" data-speed="900" data-animator="lazy">
                                        <div className="resCarousel-inner">

                                            <div className="item">
                                                <div className="tile active">
                                                    <img src={process.env.PUBLIC_URL + '/assets/images/score.png'} alt="" />
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="tile">
                                                    <div className="titleScore"><img src={process.env.PUBLIC_URL + '/assets/images/numbr.png'} alt="" /> Score</div>
                                                    <h2>{this.state.xp}</h2>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="tile">
                                                    <div className="titleScore"><img src={process.env.PUBLIC_URL + '/assets/images/rank.png'} alt="" /> Rank</div>
                                                    <h2>{this.state.rank}</h2>
                                                </div>
                                            </div>

                                        </div>
                                        <button className='btn btn-default leftRs nwleftrs'><i className="fas fa-chevron-left"></i></button>
                                        <button className='btn btn-default rightRs nwleftrs'><i className="fas fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contentB">
                                    <Link to="/edit_profile" className="editCss">Edit</Link>
                                    <h2 className="mainHdn">Profile</h2>
                                    <div className="profileDetail">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Name:</th>
                                                    <td>{this.state.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>DOB:</th>
                                                    <td>{this.state.dob}</td>
                                                </tr>
                                                <tr>
                                                    <th>Gender:</th>
                                                    <td>{this.state.gender}</td>
                                                </tr>
                                                <tr>
                                                    <th>Aadhar Number:</th>
                                                    <td>{this.state.aadhaarNo!==""?this.state.aadhaarNo:<span style={{fontStyle:"italic",color:"#fc6161"}}>Please Update Aadhar Number</span>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address:</th>
                                                    <td>
                                                    {this.state.flat_room === ""&& this.state.flat_room   === "" && this.state.building_house === "" && this.state.streetName === "" && this.state.locality === "" && this.state.city_town_village === "" && this.state.state === ""? <span style={{fontStyle:"italic",color:"#fc6161"}}>Please Update Address Details</span>: ""}
                                                        {this.state.flat_room === "" ? "": this.state.flat_room +' '}
                                                        {this.state.building_house === "" ? " ": this.state.building_house +' '}
                                                        {this.state.streetName === "" ? "": this.state.streetName +' '}
                                                        {this.state.locality === "" ? "": this.state.locality +' '}
                                                        {this.state.city_town_village === "" ? " ": this.state.city_town_village +' '}
                                                        {this.state.state === "" ? "": this.state.state}
                                                   </td>
                                                </tr>
                                                <tr>
                                                    <th>Mobile:</th>
                                                    <td>{this.state.countryCode} {this.state.phone}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email:</th>
                                                    <td>{this.state.email!==""?this.state.email:<span style={{fontStyle:"italic",color:"#fc6161"}}>Email not available</span>}</td>
                                                </tr>
                                                <tr>
                                                    <th>User Category:</th>
                                                    <td>{this.state.userType}</td>
                                                </tr>
                                                <tr>
                                                    <th>Institutions associated with :</th>
                                                    <td>{this.state.institution!==""?this.state.institution:<span style={{fontStyle:"italic",color:"#fc6161"}}>Please Update Institution Name</span>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Experience in relevant field :</th>
                                                    {this.state.experience_years==="" && this.state.experience_months===""?
                                                    <td><span style={{fontStyle:"italic",color:"#fc6161"}}>Nil</span></td>
                                                    :
                                                    <td>
                                                         {this.state.experience_years} Year(s) {this.state.experience_months} Month(s)
                                                         </td>
                                                        /* {this.state.experience_years!==""?this.state.experience_years:"0"} Years {this.state.experience_months!==""?this.state.experience_months:"0"} Months</td> */
                                                        }
                                                    
                                              </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        )
    }
}
export default Profile;