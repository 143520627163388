import React from "react";
class CoachComponent extends React.Component {
    render() {
        return (
            <div className="card">
                <img className="card-img-top" src={this.props.image_url} alt="Card cap"/>
                {/* <div className="card-body">
                    <h5 className="card-title">{this.props.title}</h5>
                    <p className="card-text">{this.props.description}</p>
                    <Link to="#" className="readMore">Read more</Link>

                </div> */}
            </div>
        )
    }
}
export default CoachComponent;