import React from "react";
import { Link } from "react-router-dom";
class ButtonComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            nextRouteLink:"",
            previousRouteLink:""
        }
        this.getNextRoute = this.getNextRoute.bind(this);
        this.getPreviousRoute = this.getPreviousRoute.bind(this);
        this.saveCurrentPos = this.saveCurrentPos.bind(this);
    }

    async componentDidMount() {
        //this.saveCurrentPos(1);
        //read routeOrderArr from localstorage
        let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
        //console.log(routeOrderArrayString)
        let routeOrderArray = routeOrderArrayString.split(",")
        //console.log(routeOrderArray)
        //read currentPos from localstorage
        let currentPosition = await localStorage.getItem('currentPos')
        //console.log(currentPosition)
        let nextPosition = parseInt(currentPosition)+1
        //getValue of routeOrderArr[nextPosition] which will be an elem._id
        //console.log(nextPosition)
        this.saveCurrentPos(nextPosition);
        let elemIdForRoute = routeOrderArray[nextPosition]
        //console.log(elemIdForRoute)
        //get value from localStorage with elem._id
        let nextRouteLink = await localStorage.getItem(elemIdForRoute)
        
        //console.log(nextRouteLink)        
        this.setState({
            nextRouteLink:nextRouteLink
        })
    //console.log('newnextRouteLink-'+this.state.nextRouteLink);
        
    }

 //This function can be called from every sub pages for  next one
 async getNextRoute(){
   
    let nextRouteLink=this.state.nextRouteLink;
    //Navigate to routeLink
    this.props.history.push({
        pathname: `${nextRouteLink}`           
    })

    //same methodology can be used in case of previous route link (use -1 instead of +1)
}


//This function can be called from every sub pages for previous one
async getPreviousRoute(){
    //read routeOrderArr from localstorage
    let routeOrderArrayString = await localStorage.getItem('routeOrderArr')
    //console.log(routeOrderArrayString)
    let routeOrderArray = routeOrderArrayString.split(",")
    //console.log(routeOrderArray)
    //read currentPos from localstorage
    let currentPosition = localStorage.getItem('currentPos')
    let previousPosition = currentPosition-1
    this.saveCurrentPos(previousPosition);
    //console.log(previousPosition)
    //getValue of routeOrderArr[previousPosition] which will be an elem._id
    let elemIdForRoute = routeOrderArray[previousPosition]
    //console.log(elemIdForRoute)
    //get value from localStorage with elem._id
    let previousRouteLink = await localStorage.getItem(elemIdForRoute)
    // this.setState({
    //     previousRouteLink:previousRouteLink
    // })
    // let previousRouteLink=this.state.previousRouteLink.toString();
    //Navigate to routeLink
    this.props.history.push({
        pathname: `${previousRouteLink}`          
    })
}

saveCurrentPos(pos){
    // save currentPos variable to localstorage and set it to 0
  localStorage.setItem('currentPos',pos)
}
    render() {
        return (
            <div className="intBtn">
                <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                <Link to="#" onClick={()=>this.getPreviousRoute()} className="intbtnb">Previous</Link>
                <Link to="#" onClick={()=>this.getNextRoute()} className="intbtnb">Next</Link>
            </div>
        )
    }
}
export default ButtonComponent;