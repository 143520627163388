import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
var path = apiPath.path;
class Exercise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            enduranceDetails_list: [],
            currentPageOrder:1,
            contentDetails : [],
            pageColor : ""
        };
        this.getContents = this.getContents.bind(this); 
        this.handleNext = this.handleNext.bind(this); 
        this.saveDataToState=this.saveDataToState.bind(this);

    }
    async componentDidMount() {
        // Get userdetails from localstorage
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getContents();
    }

    async getContents() {
        const item_id = await localStorage.getItem('itemId')
        let current_page_order = this.state.currentPageOrder;
        axios.post(path, {
            itemId: item_id
        }, {
            headers: { 'Api-Name': 'getContents', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                const ContentPageDetails = res.response.contents; 
                console.log(ContentPageDetails);
                this.setState({
                    contentDetails : ContentPageDetails
                })     
                this.saveDataToState(this.state.contentDetails,current_page_order);
            }
            else {
                // this.props.history.push("/");
                // localStorage.removeItem("SOB_userId");
                // localStorage.removeItem("SOB_authToken");
                // localStorage.removeItem("SOB_refreshToken");

                // toast.error("Some Error Occured", {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
            }
        });
    }

    saveDataToState(PageDetails,current_page_order){
           const EnduranceDetails=PageDetails[current_page_order-1].contentDetails.content.contentArray;
            this.setState({
                enduranceDetails_list: EnduranceDetails,
                pageColor : PageDetails[current_page_order-1].color        
            });
    }

    handleNext(){
        window.scrollTo(0, 0);
        let nextPage = parseInt(this.state.currentPageOrder.toString())+1;
        this.setState({
            currentPageOrder : nextPage
        })
        this.saveDataToState(this.state.contentDetails,nextPage);
        
    }

  
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section className={"contentTop "+ 
                 (this.state.pageColor==="#FCB316"?'exerciseBg': //yellow
                 (this.state.pageColor==="#ED1C24"?'covidBg':  //red
                 (this.state.pageColor==="#1BA6D6"?'hydrationBg':'dbBg' //blue:green
                 )))}>  
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadTop">
                                    <div className="fit5 intros">
                                    <h3>EXERCISE</h3>
                                    <span className="fitspan"><i className="fal fa-clock"></i> 20 min</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fit_intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="commonContent ccB">
                                <h4>ENDURANCE</h4>
                                {this.state.enduranceDetails_list.map((eachenduranceDetails, i) => (

                                    <div className={(eachenduranceDetails.videoPosition==="right" ? 'exerBlog' : 'exerBlogB')} key={i}>
                                    <div className="exerBtn">
                                        <span style={{backgroundColor:this.state.pageColor,color:"white"}}>{eachenduranceDetails.instruction}</span>
                                        <span style={{backgroundColor:this.state.pageColor,color:"white"}}>{eachenduranceDetails.repeat}s</span>
                                    </div>
                                    <div className="exerMain">
                                        <div className="exerContent">
                                            <h4>{i+1}. {eachenduranceDetails.exerciseName}</h4>
                                            <ul>

                                            {eachenduranceDetails.description.split('\n\n').map(str => <li>{str}</li>)}
                                                {/* <li>{eachenduranceDetails.description}</li> */}
                                                
                                            </ul>
                                        </div>
                                        <div className="exerRight">
                                            <img src={path+eachenduranceDetails.video} alt="yogaimg"/>
                                            <p>{eachenduranceDetails.videoDescription}</p>
                                            
                                        </div>
                                    </div>
                                    
                                    </div>
                                ))}
                                <div className="intBtn">
                                    <Link to="/fit_five" className="intbtna">Back to Main Menu</Link> 
                                    {this.state.currentPageOrder<4?
                                    <Link to="#" className="intbtnb" onClick={()=>this.handleNext()} >Next</Link>
                                    :""}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Exercise;