import ChartMainComponent from "./chartMain";
import ChartLegendComponent from "./chartLegend";
import React from "react";
class ChartBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


        };

    }



    render() {
        return (

            <div className="pieInner">
                <div className="pieMain">
                    <div className="totalCount">
                        <p className="totalCountNum">
                            <strong>{this.props.dashboard_data.total}</strong>
                        </p>
                        <p className="totalCountType">
                            <strong>USER</strong>
                        </p>
                    </div>
                    <ChartMainComponent
                        categoryArr={this.props.dashboard_data.category}
                    />
                </div>
                <p
                    style={{ textAlign: "center", color: "black", padding: 2 }}
                    className="cbTxt"
                >
                    <strong>{this.props.dashboard_data.pie_name}</strong>
                    <br></br>
                    <span style={{ textAlign: "center", color: "gray", fontSize: 12 }}>
                        <strong>
                            Total Number Of Users - {this.props.dashboard_data.total}
                        </strong>
                    </span>
                </p>
                <ChartLegendComponent
                    categoryArr={this.props.dashboard_data.category}
                />
            </div>

        )
    }
}
export default ChartBlock;
