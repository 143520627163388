import React from "react";
import Header from "../components/header";
import { Link } from "react-router-dom";
class YA_Page8 extends React.Component {
    render() {
        return (
            <div>
                 <Header {...this.props} />
                 <section class="contentTop yaBg_a" style={{backgroundImage:`url(${process.env.PUBLIC_URL + '/assets/images/dbg.png'})`}}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="leadTop">
                                    <div class="fit5 yaHdn">
                                    <h3>Pre-Evaluation</h3>
                                    <span class="fitspan"><i class="fal fa-clock"></i> 20 min</span>
                                    </div>
         
                                    {/* <div class="fitTopImg">
                                        <img src="images/fit1.png" alt=""/>
                                        <img src="images/fit2.png" alt=""/>
                                        <img src="images/fit3.png" alt=""/>
                                    </div> */}
         
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="fit_intro ya_intro">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="commonContent">
                                <div class="ya4Top ya8Top">
                                    <span class="current"></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                </div>
                                
                                <div class="page4borderbase page8Bg">
                                    <p>The items in this section cover a wide range of skills. Your child is not expected to have all the skills described by all the items. Mark the rating that best describes how often your child performs the behavior without help or reminders, NOT whether the child can perform the behavior. Use N/A (not applicable) if your child does not perform a behavior because he or she has outgrown it.</p>
                                </div>                       
                                    
                                <div class="page4borderbase page7Base page8Bg">
                                    <h5>01. Handles changes in routine without getting too upset </h5>
                                    <div class="page4Radio">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radios" id="radio1" value="option1" checked/>
                                            <label class="form-check-label" for="radio1">
                                                Never
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radios" id="radio2" value="option1"/>
                                            <label class="form-check-label" for="radio2">
                                                Sometimes
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radios" id="radio3" value="option1"/>
                                            <label class="form-check-label" for="radio3">
                                                Usually
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radios" id="radio4" value="option1"/>
                                            <label class="form-check-label" for="radio4">
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div><br/><br/>
                                    
                                    <h5>02. Controls anger when not getting his/her way </h5>
                                    <div class="page4Radio">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radiox" id="radio5" value="option5" checked/>
                                            <label class="form-check-label" for="radio5">
                                                Never
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radiox" id="radio6" value="option6"/>
                                            <label class="form-check-label" for="radio6">
                                                Sometimes
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radiox" id="radio7" value="option7"/>
                                            <label class="form-check-label" for="radio7">
                                                Usually
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="radiox" id="radio8" value="option8"/>
                                            <label class="form-check-label" for="radio8">
                                                Not Applicable
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="intBtnBx intautowidth"><Link to="/ya_page1" className="intbtnax">Back to Main Menu</Link> <Link to="#" className="intbtnbx">Save</Link> <Link to="/ya_page9" className="intbtnbx">Next</Link></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
    
    
            </div>
        )
    }
}
export default YA_Page8;