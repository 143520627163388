import React from "react";
class PaginationComponent extends React.Component {
   
    loadPage(type){
        this.props.loadData(type)
    }

    render() {
        return (
            
            <div>
               <button onClick={()=>this.loadPage("P")}  className="pagination previous round">&#8249;</button>
               <button className="pagenumber roundp">{this.props.pageNumber}</button>

               <button onClick={()=>this.loadPage("N")}  className="pagination next round">&#8250;</button>
            </div>
        )
    }
}
export default PaginationComponent;