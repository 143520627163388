import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
var path = apiPath.path;

class Amrit_Chapter_Participants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            search:"",
           
        };  
        this.handleChange = this.handleChange.bind(this);  
    } 

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if(this.state.userId===null){
            this.props.history.push("/");
        }
        this.getParticipantList();
        this.getProfile();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
            this.setState({
            [name]: value
        });
    }
    getParticipantList() {
        axios.post(path, {
            searchFilter: [{"name":[this.state.search],"participant_type":[this.state.search]}],
            page_no:1,
        }, {
            headers: { 'Api-Name': 'getParticipantList', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
               
            const ParticipantDetails = res.response && res.response.participantList;
               console.log(ParticipantDetails);
            
                this.setState({
                    participant_list: ParticipantDetails,
                    
                });
                //console.log(this.state.ya_athletes_list);
            }
            
        });
    }
    getProfile() {
        axios.post(path, {
      
        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic:ProfileDetails.profilePic+"?"
                });
            }
        });
      }

      approvalOfParticipant(participantId) {
        axios.post(path, {
            participant_approval_id: participantId,
            isApproved:true

        }, {
            headers: { 'Api-Name': 'approvalOfParticipant', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                window.location.reload(false);
               toast.success(res.message, {
                   position: toast.POSITION.BOTTOM_RIGHT
               });
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        });
    }
    render() {
        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li><Link to="/amrit_dashboard"><span><img src={process.env.PUBLIC_URL + '/assets/images/dbicon.png'} alt="" /></span> Dashboard</Link></li>
                                        <li><Link to="/amrit_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>
                                        <li className="active"><Link to="/amrit_chapter_participants"><span><img src={process.env.PUBLIC_URL + '/assets/images/participanticon.png'} alt="" /></span> Participants</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Dashboard</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dbfilter">
                                    <form className="dbSearch">
                                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" name="search" onChange={this.handleChange} />
                                        <button className="btn btn-outline-success my-2 my-sm-0" type="button" onClick={()=>this.getParticipantList()}><i className="fal fa-search"></i></button>
                                    </form>
                                    <button data-toggle="modal" data-target="#filterModal"><img src={process.env.PUBLIC_URL + '/assets/images/dbfilter.png'} alt="" /> Filter</button>
                                </div>
                                <div className="participantTable">
                                    <div className="participantTableB" id="results">
                                        <div className='theader'>
                                            <div className='table_header'>Participats Name</div>
                                            <div className='table_header'>Participats Type</div>
                                            <div className='table_header'>State</div>
                                            <div className='table_header'>Status</div>
                                        </div>

                                         {this.state.participant_list.map((eachparticipantDetails, i) => (
                                        <div className='table_row' key={i}>
                                            <div className='table_small'>
                                                <div className='table_cell'>Participats Name</div>
                                                <div className='table_cell'>
                                                    <div className="boxProfile"><img src={eachparticipantDetails['Upload Your Face Photo'][0]} alt="" /> <span>{eachparticipantDetails.name}</span></div>
                                                </div>
                                            </div>
                                            <div className='table_small'>
                                                <div className='table_cell'>Participats Type</div>
                                                <div className='table_cell'>{eachparticipantDetails.participantType}</div>
                                            </div>
                                            <div className='table_small'>
                                                <div className='table_cell'>State</div>
                                                <div className='table_cell'>{eachparticipantDetails['Current State']}</div>
                                            </div>
                                            <div className='table_small'>
                                                <div className='table_cell'>Status</div>
                                                {eachparticipantDetails.isRejected === true ?
                                                 <div className='table_cell rejected'>Rejected</div> 
                                                 : (eachparticipantDetails.isApproved === true ? 
                                                     <div className='table_cell approved'>Approved</div>
                                                  : 
                                                //   <Link className="dropdown-item" to="#" onClick={()=>this.approvalOfParticipant(eachparticipantDetails._id)}><div className='table_cell approvalPending'>Approval Pending</div></Link>
                                                 <div className='table_cell approvalPending'>Approval Pending</div>)}
                                               
                                            </div>
                                            

                                        </div>
                                        ))}  
                                      
                                      {/* <Link to="/home">  <button className="backtohome">Back to Home</button></Link> */}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


                {/* <!-- Modal --> */}
                <div className="modal fade modal-center" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">
                                <h3>Apply Filter</h3>
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                <div className="mulSelect">
                                                    <select id="state" name="State[]" multiple className="form-control">
                                                        <option value="Codeigniter">State 1</option>
                                                        <option value="CakePHP">State 2</option>
                                                        <option value="Laravel">State 3</option>
                                                        <option value="YII">State 4</option>
                                                        <option value="Zend">State 5</option>
                                                        <option value="Symfony">State 6</option>
                                                        <option value="Phalcon">State 7</option>
                                                        <option value="Slim">State 8</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>District</label>
                                                <div className="mulSelect">
                                                    <select id="dist" name="Dist[]" multiple className="form-control">
                                                        <option value="Codeigniter">Dist 1</option>
                                                        <option value="CakePHP">Dist 2</option>
                                                        <option value="Laravel">Dist 3</option>
                                                        <option value="YII">Dist 4</option>
                                                        <option value="Zend">Dist 5</option>
                                                        <option value="Symfony">Dist 6</option>
                                                        <option value="Phalcon">Dist 7</option>
                                                        <option value="Slim">Dist 8</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Venue</label>
                                            <div className="newSelect">
                                                <select name="Country" id="country">
                                                    <option className="slRedbg" value="">Select Venue</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Participants Type</label>
                                            <div className="newSelect">
                                                <select name="Country" id="country">
                                                    <option className="slRedbg" value="">Select Participants Type</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filterModalSubmit"><button type="submit" className="btn btn-primary">Submit</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Amrit_Chapter_Participants;