import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiPath from '../constant';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import StatusScannerComponent from "../components/statusScannerComponent";
import CheckinScannerComponent from "../components/checkinScannerComponent";
import CheckoutScannerComponent from "../components/checkoutScannerComponent";
var path = apiPath.path;

class Amrit_Checkin_Checkout_Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            AuthToken: "",
            participant_list: [],
            scannedId: "",
            error_message:"",
            confirmModalShow: false,
            shouldUseCameraForStatus: false,
            shouldUseCameraForCheckin: false,

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const userId = await localStorage.getItem('SOB_userId')
        const AuthToken = await localStorage.getItem('SOB_authToken')
        localStorage.removeItem("SOB_scanId");
        this.setState({
            userId: userId,
            AuthToken: AuthToken,
        })
        if (this.state.userId === null) {
            this.props.history.push("/");
        }
        this.getProfile();

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event, scanType) {
        //console.log(this.state);
        //console.log(scanType)
        this.scanAMID(scanType);
        event.preventDefault();
    }

    scanAMID(scanType) {
        axios.post(path, {
            scannedId: this.state.scannedId,
            deviceType: "web",
            scanType: scanType,

        }, {
            headers: { 'api-name': 'scanAMID', 'userid': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
           // console.log(res)
            if (res.status === true) {
                localStorage.setItem("SOB_scanId", this.state.scannedId);
                // localStorage.setItem("SOB_scanType", scanType);
                if (scanType === "status") {
                     this.props.history.push("/amrit_chapter_participants_details");
                     toast.success(res.response.status_to_show, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }else{
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
                

            }
            else {
                this.setState({
                    error_message: res.message,
                })
                document.getElementById("showfilterModal").click();
                // if (scanType === "status") {
                //     toast.error(res.message, {
                //         position: toast.POSITION.BOTTOM_RIGHT
                //     });
                //  }
            }
        });
    }



    getProfile() {
        axios.post(path, {

        }, {
            headers: { 'Api-Name': 'getProfile', 'userId': this.state.userId, 'AuthToken': this.state.AuthToken }
        }).then(serverResponse => {
            const res = serverResponse.data;
            if (res.status === true) {
                //console.log(res);
                const ProfileDetails = res.response;
                let firstName = ProfileDetails.name
                this.setState({
                    name: firstName,
                    email: ProfileDetails.email,
                    profilePic: ProfileDetails.profilePic + "?"
                });
            }
        });
    }

    cameraPermissionStatus() {
        this.setState({
            shouldUseCameraForStatus: true
        })
    }

    cameraPermissionCheckin() {
        this.setState({
            shouldUseCameraForCheckin: true
        })
    }

    // scannerMedicalProgramme


    render() {
        toast.configure({
            autoClose: 3000,
            draggable: false,
        });

        return (
            <div className="fullHeight">
                <section className="amritRegd">
                    <div className="amritRegdInn amritDB">
                        <div className="amritRegdBlock amritAfterregd amritDashboard">
                            <div className="dbLft">
                                <div className="dblogo"><img src={process.env.PUBLIC_URL + '/assets/images/dblogo.png'} alt="" /></div>
                                <div className="dbMenu">
                                    <ul>
                                        <li className="active"><Link to="/amrit_checkin_checkout_status"><span><img src={process.env.PUBLIC_URL + '/assets/images/scanathle.png'} alt="" /></span> Scan Athlete</Link></li>
                                        <li><Link to="/amrit_volunteers_show_id"><span><img src={process.env.PUBLIC_URL + '/assets/images/showid.png'} alt="" /></span> Show ID</Link></li>



                                    </ul>
                                </div>
                            </div>
                            <div className="dbRgt">
                                <div className="dbHead">
                                    <h5>Scan Athlete / Enter Fit Five Arena</h5>
                                    <div className="dbUsr">
                                        <span className="usrImg"><img src={this.state.profilePic ? this.state.profilePic : process.env.PUBLIC_URL + '/assets/images/userimg.png'} alt="" /></span>
                                        <div className="">
                                            <h6>{this.state.name}</h6>
                                            <p>{this.state.email}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="participantBg">
                                   
                                    <div className="medicalProfBg volunteerBg">
                                        <h5>Enter Fit Five Arena</h5>
                                        <div className="volBlock">
                                            <button className="backtohome" data-toggle="modal" data-target="#checkinModal" onClick={(e) => this.cameraPermissionCheckin()}>Scan</button><span>Or</span>
                                            <input type="text" name="scannedId" className="form-control" placeholder="Enter the Athlete ID"  required onChange={this.handleChange}/>
                                            <button className="volSubmit" onClick={event => this.handleSubmit(event, "checkin")} >Submit</button>
                                        </div>
                                    </div>

                                    <div className="medicalProfBg volunteerBg">
                                        <h5>Status</h5>
                                        <div className="volBlock">
                                            <button className="backtohome" data-toggle="modal" data-target="#statusModal" onClick={(e) => this.cameraPermissionStatus()}>Scan</button><span>Or</span>
                                            <input type="text" name="scannedId" className="form-control" placeholder="Enter the Athlete ID"  required onChange={this.handleChange}/>
                                            <button className="volSubmit" onClick={event => this.handleSubmit(event, "status")}>Submit</button>
                                        </div>
                                    </div>

                                    <br /><br />
                                    <div className="intBtn">
                                    <Link to="/amrit_after_registration" className="intbtnb">Back</Link>

                                </div>
                                </div>

                                {/* For Checkout */}
                                {/* <div className="participantBg">
                                    <div className="medicalProfBg">
                                        <button className="backtohome" data-toggle="modal" data-target="#checkoutModal" onClick={(e) => this.cameraPermission()}>Scan</button><span>Or</span>
                                        <input type="text" name="scannedId" className="form-control" placeholder="Enter the Athlete ID" required onChange={this.handleChange} />
                                        <button type="submit" className="backtohome" onClick={event => this.handleSubmit(event, "checkout")} style={{ width: "50%" }}>Submit</button>
                                    </div>
                                   
                                </div> */}

                               
                            </div>

                        </div>
                    </div>
                </section>
                <button type="button" id="showfilterModal" data-toggle="modal" data-target="#filterModal" style={{ display: "none" }} >Hidden Button</button>


                {/* <!-- Modal --> */}
                <div className="modal fade modal-center" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body filterPop">
                                <div className="unhappy"><img src={process.env.PUBLIC_URL + '/assets/images/unhappy.png'} alt="" /></div>
                                {/* <h3>Sorry your Athlete ID is not valid.<br />Please try again</h3> */}
                                <h3>{this.state.error_message}<br /></h3>
                                
                                <div className="filterModalSubmit"><button type="submit" data-dismiss="modal" className="btn btn-primary">Close</button></div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!--Status Modal --> */}
                {this.state.shouldUseCameraForStatus ?
                    (<div className="modal fade modal-center " id="statusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel3" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 style={{ textAlign: "center" }}> SCAN QR CODE</h3>
                                    <button type="button" id="closeStatusModalBtn" className="close" data-dismiss="modal" onClick={() => this.setState({ shouldUseCameraForStatus: false })} aria-label="Close">

                                        <span aria-hidden="true">&times;</span>

                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="scannerWrapper">

                                        <StatusScannerComponent />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>)
                    : null}

                {/* <!--Checkin Modal --> */}
                {this.state.shouldUseCameraForCheckin ?
                    (<div className="modal fade modal-center " id="checkinModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 style={{ textAlign: "center" }}> SCAN QR CODE</h3>
                                    <button type="button" id="closeCheckinModalBtn" className="close" data-dismiss="modal" onClick={() => this.setState({ shouldUseCameraForCheckin: false })} aria-label="Close">

                                        <span aria-hidden="true">&times;</span>

                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="scannerWrapper">

                                        <CheckinScannerComponent />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>)
                    : null}

                {/* <!--Checkout Modal --> */}
                {/* {this.state.shouldUseCamera ?
                    (<div className="modal fade modal-center " id="checkoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel3" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 style={{ textAlign: "center" }}> SCAN QR CODE</h3>
                                    <button type="button" id="closeCheckoutModalBtn" className="close" data-dismiss="modal" onClick={() => this.setState({ shouldUseCamera: false })} aria-label="Close">

                                        <span aria-hidden="true">&times;</span>

                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="scannerWrapper">

                                        <CheckoutScannerComponent />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>)
                    : null} */}





            </div>
        )
    }
}
export default Amrit_Checkin_Checkout_Status;